// catalogReducers.js

import {FETCH_ALL_CATALOG, FETCH_ALL_MAP} from './catalogActions';

// const initialState = {
//   networkId: null,
//   catalogType: null,
// };
//
// export function catalogReducers (state = initialState, action) {
//   switch (action.type) {
//     case 'SET_NETWORK_ID':
//       return {
//         ...state,
//         networkId: action.payload,
//       };
//     case 'SET_CATALOG_TYPE':
//       return {
//         ...state,
//         catalogType: action.payload,
//       };
//     default:
//       return state;
//   }
// };

const initialState = {
  data: [],
  dataInsta: [],
};

export const catalogReducers = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_MAP:
      return {
        ...state,
        data: action.payload,
      }
    case FETCH_ALL_CATALOG:
      return {
        ...state,
        dataInsta: action.payload
      }
    default:
      return state;
  }
};
