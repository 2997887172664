export const Tables = ({ tab }) => {

    if (tab !== null) return null;
    const values = Object.values(tab);

    return (
        <table className="table-auto relative w-[950px]">
            <thead>
            <tr className="sticky z-10 top-0 text-left text-md font-medium text-slate-700 h-10">
                <th className="p-2">Name</th>
                <th className="p-2">Analytical</th>
                <th className="p-2">Mesure parameter</th>
                <th className="p-2">Precision</th>
                <th className="p-2">Required related</th>
            </tr>
            </thead>
            <tbody>
            {values.map((item, index) =>
                <tr className={`border border-slate-200 text-sm text-slate-400 text-left h-10 ${index % 2 === 0 ? 'bg-slate-100' : 'bg-slate-200'}`}>
                    <td className="p-2 border border-slate-200">{item.name}</td>
                    <td className="p-2 border border-slate-200">{item.analytical}</td>
                    <td className="p-2 border border-slate-200">{item.measure_param}</td>
                    <td className="p-2 border border-slate-200">{item.precision}</td>
                    <td className="p-2 border border-slate-200">{item.required_related}</td>
                </tr>
            )}

            </tbody>
        </table>
    );
};


