import AxiosService from "../../../services/AxiosService";
const urlSelectOptions = "api/select_options";

export class SelectOptionsService {
    getAllSelectsOptions (token) {
        return AxiosService.getWithToken(urlSelectOptions, token);
    }
    getSelectOptionsById (id, token) {
        return AxiosService.getWithToken(`${urlSelectOptions}/config/${id}`, token, "jsonld");
    }
    getSelectOptionsConfigById(id, token) {
        return AxiosService.getWithToken(`${urlSelectOptions}/config/${id}`, token, "jsonld");
    }

    postSelectOptions(data, token) {
        data["token"] = token;
        return AxiosService.postWithToken(`${urlSelectOptions}`, data, "jsonld");
    }
    deleteSelectOptionsById(id, token) {
        return AxiosService.deleteWithToken(`${urlSelectOptions}/${id}`, token, "jsonld");
    }
    putSelectOptionsConfigById(id, data, token) {
        return AxiosService.putWithToken(`${urlSelectOptions}/${id}`, data, token, "jsonld");
    }

}