import * as React from "react";
import {Box, Drawer, IconButton} from "@mui/material";
import { LuPanelRightClose } from "react-icons/lu";
import {DrawerLoader} from "./loaders/Loaders";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

// .Env
const url = process.env.REACT_APP_API_URL;


const DrawerContent = ({ open,
                         drawerWidth,
                         handleDrawerOpen,
                         handleDrawerClose,
                         activeStep,
                         drawerDatas,
                         logo,
                         isLoading,
                         centralHubName,
                         installationNumber,
                         ppaForm
                       }) => {

    const content = () => (

        <Box sx={{ width: '100%', height: '100%', minHeight: '100vh', display: 'flex', flexFlow: 'column wrap', flexGrow: '1', position: 'relative' }}>

            <IconButton onClick={handleDrawerClose}
                        sx={{ position: 'absolute',
                            zIndex: '1',
                            top: '4px',
                            right: '4px',
                            fontSize: '24px',
                            ...!ppaForm && ({
                                display: { xs: 'block', lg: 'none' }
                            })
                        }}
            >
                <LuPanelRightClose />
            </IconButton>


            <div className="network flex relative">
                <Box className="content"
                     sx={{ width: '100%',
                           display: 'flex',
                           flexFlow: 'row wrap',
                           padding: '20px 30px',
                           ...open && ({
                               padding: '20px 30px 80px',
                           })
                        }}
                >
                    { isLoading ? (
                        <DrawerLoader />
                    ) : (
                        <>
                            { (logo && drawerDatas.hasOwnProperty('logo')) && (
                                <div className={"logo"}>
                                    <img
                                        className="w-1/4"
                                        loading="lazy"
                                        width="auto"
                                        src={logo}
                                        alt={ drawerDatas.name }
                                    />
                                </div>
                            )}
                            <div className={"name"}>{ drawerDatas.name }</div>
                            { (drawerDatas.hasOwnProperty('mainImage') && drawerDatas.mainImage !== '') && (
                                <div className={"image flex items-center"}>
                                    <figure className="w-full">
                                        <img src={url + drawerDatas.mainImage} alt={ drawerDatas.name } className="h-full"/>
                                    </figure>
                                </div>
                            )}

                            {activeStep === 0 && (
                                <>
                                    {centralHubName && (
                                        <div className={"central-hub-name text-sm mb-4"}>
                                            <span className={"underline"}>Central Hub Installation :</span> {centralHubName}
                                        </div>
                                    )}
                                    {installationNumber > 0 && (
                                        <div className={"installation-number text-sm mb-4"}>
                                            {installationNumber} installation{installationNumber > 1 && "s"}
                                        </div>
                                    )}
                                </>
                            )}

                            <div className={"description"}
                                 dangerouslySetInnerHTML={{__html: drawerDatas.description}}></div>
                        </>
                    )}
                </Box>
            </div>
        </Box>

    );
    // console.log(drawerDatas)
    return (
        <>
            {/* Mobile Drawer */}
            <SwipeableDrawer
                anchor={'right'}
                open={open}
                onClose={handleDrawerClose}
                onOpen={handleDrawerOpen}
                sx={{ display: {xs: 'block', lg: 'none'} }}
                className="ppa-drawer-mobile"
            >
                {content()}
            </SwipeableDrawer>

            {/* Desktop Drawer */}
            <Drawer
                className="ppa-drawer"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                    },
                    display: {xs: 'none', lg: 'block'},
                }}
                variant="persistent"
                anchor="right"
                open={open}
            >
                { content() }
            </Drawer>
        </>
    )
}

export default DrawerContent;