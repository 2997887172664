const INITIAL_STATE = null;

// Si state == null, dans ce cas il prend INITIAL_STATE sinon le state fournit
export function changeInfrastructure (state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'CHANGE_INFRASTRUCTURE':
            return action.payload;
        case 'REMOVE_INFRASTRUCTURE':
            return null;
        default:
            return state;
    }
}