import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { displayMessage } from '../../../redux/alertAction'
import { DrawerTemporaryContext } from '../../../components/drawer/DrawerTemporary'
import { createSelector } from 'reselect'
import { Controller, useForm } from 'react-hook-form'
import { Loader } from '../../../components/loader/Loader'
import { FormLoader } from '../../servicetype/loaders/Loaders'
import Select from 'react-select'
import * as React from 'react'
import { FiInfo, FiUpload } from 'react-icons/fi'
import { Forbidden } from '../../../pages/Forbidden/Forbidden'
import { useFetchInfrastructure } from '../../admin/hooks/api/useFetchInfrastructure'
import { motion } from 'framer-motion'
import { MyEditor } from '../../../components/editor/MyEditor'
import Tooltip from '@mui/material/Tooltip'
import { encodeFilesToBase64 } from '../../../utils/encodeFilesToBase64'
import InfrastructureService from '../../installation/service/InfrastructureService'
import ConfirmDialog from '../../../components/confirm/ConfirmDialog'

const selectUser = createSelector(
    state => state.login.user,
    user => user,
)

export function InstallationForm({ increment, listInfrastructuresParent }) {

    // User token
    const user = useSelector(selectUser)

    const dispatch = useDispatch()

    const context = useContext(DrawerTemporaryContext)
    const method = context.method ?? 'CREATE'
    const isUpdate = method === 'UPDATE'
    const [submitting, setSubmitting] = useState(false)
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    const [pathLogo, setPathLogo] = useState(null)
    const [logoName, setLogoName] = useState(null)

    const {
        control,
        register,
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: '',
            logo: null,
            fileLogo: null,
            parent: null,
            code: '',
            isGroupOnly: false,
            description: '',
            showable: false,
        },
    })

    /****
     * CKEDITOR
     ****/
    const handleEditorChange = (content) => {
        setValue('description', content)
    }

    const handleNameBlur = () => {
        if (!watch('code')) {
            setValue('code', watch('name').replace(/\W/g, '').toLowerCase())
        }
    }

    const { infrastructure } = useFetchInfrastructure(context.idSelected, user.token, increment, setIsLoading)

    useEffect(() => {
        if (!isLoading) {
            if (isUpdate && undefined !== infrastructure && null !== infrastructure) {
                setValue('name', infrastructure.name)
                setValue('logo', infrastructure.logo)
                setPathLogo(infrastructure.logo)
                setLogoName(infrastructure.logo?.split('/').pop())
                setValue('parent', infrastructure.parent)
                setValue('code', infrastructure.code)
                setValue('isGroupOnly', infrastructure.isGroupOnly)
                setValue('description', infrastructure.description)
                setValue('showable', infrastructure.showable)
            } else {
                reset({
                    name: '',
                    logo: null,
                    fileLogo: null,
                    parent: null,
                    code: '',
                    isGroupOnly: false,
                    description: '',
                    showable: false,
                })
                setPathLogo(null)
                setLogoName(null)
            }
        }
    }, [
        isLoading,
        infrastructure,
    ])


    /****
     * SUBMIT FORMS FUNCTION
     * ****/
    const onSubmit = async (data) => {
        try {
            setSubmitting(true)

            let logoBase64 = null
            const logo = watch('logo')

            // Custom logo
            if (logo && typeof logo == 'object') {
                // convert to array
                const filesArray = Array.from(data.logo)
                logoBase64 = await encodeFilesToBase64(filesArray)
            }


            const requestData = {

                name: data.name,
                logo: pathLogo ? pathLogo : null,
                fileLogo: logoBase64 ? logoBase64[0] : null,
                parent: data.parent ? data.parent['@id'] : null,
                code: data.code,
                isGroupOnly: Boolean(data.isGroupOnly),
                description: data.description,
                showable: Boolean(data.showable),

            }

            const infrastructureService = new InfrastructureService();

            if (isUpdate) {
                infrastructureService.putInfrastructure(infrastructure?.id, requestData, user.token).then(() => {
                    reset({
                        name: '',
                        logo: null,
                        fileLogo: null,
                        parent: null,
                        code: '',
                        isGroupOnly: false,
                        description: '',
                        showable: false,
                    })

                    dispatch(displayMessage('Success', `The installation '${infrastructure?.name}' has been updated`, 'success'))

                    context.onDrawerClose()
                }, (error) => {
                    setSubmitting(false)
                    console.log(error)
                    dispatch(displayMessage(error.data.title, error.data.detail, 'error'))
                })
            } else {
                infrastructureService.postInfrastructure(requestData, user.token).then(() => {
                    reset({
                        name: '',
                        logo: null,
                        fileLogo: null,
                        parent: null,
                        code: '',
                        isGroupOnly: false,
                        description: '',
                        showable: false,
                    })

                    dispatch(displayMessage('Success', `The installation ${requestData?.name} has been created`, 'success'))

                    context.onDrawerClose()
                }, (error) => {
                    setSubmitting(false)
                    console.log(error)
                    dispatch(displayMessage(error.data.title, error.data.detail, 'error'))
                })
            }

            setSubmitting(false)
        } catch (error) {
            setSubmitting(false)
            console.log(error)
            dispatch(displayMessage(error.data.title, error.data.detail, 'error'))
        }
    }

    const handleDelete = async () => {
        try {

            setSubmitting(true);
            const infrastructureService = new InfrastructureService();
            infrastructureService.deleteInfrastructure(infrastructure?.id, user.token).then(() => {
                reset({
                    name: '',
                    logo: null,
                    fileLogo: null,
                    parent: null,
                    code: '',
                    isGroupOnly: false,
                    description: '',
                    showable: false,
                })

                dispatch(displayMessage('Success', `The installation '${infrastructure?.name}' has been deleted`, 'success'))
                setSubmitting(false);
                context.onDrawerClose()
            })


        } catch (error) {
            setSubmitting(false)
            console.log(error)
            dispatch(displayMessage(error.data.title, error.data.detail, 'error'))
        }
    }

    if (!user || !user.isSuperAdministrator) {
        return <Forbidden isInDrawer={true} />
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8">

            {submitting ? (
                    <Loader content="Submitting" />
                ) :
                (
                    <>
                        {/* 01 */}
                        {isLoading ?
                            (<FormLoader />)
                            :
                            (
                                <>
                                    <div className="flex flex-col gap-4">
                                        {isUpdate &&
                                            <h2 className="text-xl font-bold mb-2 tracking-wide">{infrastructure?.name}</h2>
                                        }
                                        <div className="flex flex-col gap-4 ">
                                            <div>
                                                <h3 className="text-xl font-bold mb-2 tracking-wide required">
                                                    Name
                                                </h3>
                                            </div>
                                            <div className="w-full">
                                                <input type="text"
                                                       placeholder="Infrastructure name"
                                                       {...register('name', {
                                                           required: 'Infrastructure name is required',
                                                       })
                                                       }
                                                       onBlur={() => handleNameBlur()}
                                                       className="border border-slate-300 border-solid rounded-sm w-full grow h-12 p-4 focus:outline-none focus:ring focus:ring-blue-200"
                                                />
                                                {errors.name && !watch('name') && (
                                                    <span className={'text-sm text-red-500/70'}
                                                          role="alert">{errors.name.message}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-4 ">
                                            <div>
                                                <h3 className="text-xl font-bold mb-2 tracking-wide required">
                                                    Code
                                                </h3>
                                            </div>
                                            <div className="w-full">
                                                <input type="text"
                                                       placeholder="Code name"
                                                       {...register('code', {
                                                           required: 'Infrastructure code is required',
                                                       })
                                                       }
                                                       className="border border-slate-300 border-solid rounded-sm w-full grow h-12 p-4 focus:outline-none focus:ring focus:ring-blue-200"
                                                />
                                                {errors.code && !watch('code') && (
                                                    <span className={'text-sm text-red-500/70'}
                                                          role="alert">{errors.code.message}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    {/* Upload file */}
                                    <div className="flex flex-col gap-4 ">
                                        <div>
                                            <h3 className="text-xl font-bold mb-2 tracking-wide">
                                                Logo
                                            </h3>
                                        </div>
                                        <div
                                            className="flex flex-col items-start gap-0 divide-y divide-slate-300 border border-solid border-slate-300 rounded-sm">
                                            {/* Logo */}
                                            <div
                                                className="w-full h-12 flex gap-2 items-center justify-start relative px-4">
                                                <motion.label
                                                    htmlFor="logo"
                                                    className={`border border-slate-200 border-solid rounded-sm h-12 flex items-center justify-center px-2 cursor-pointer absolute right-0 text-slate-500 bg-slate-100`}
                                                >
                                                    <div className="flex">
                                                        <span className="text-sm px-2">Click to upload</span>
                                                        <span
                                                            className={`border rounded-full text-xs p-1 ${pathLogo ? 'border-blue-50 text-blue-50 bg-blue-500' : 'text-slate-500 border-slate-300'}`}><FiUpload /></span>
                                                    </div>
                                                </motion.label>

                                                <div>{logoName ?
                                                    <a href={process.env.REACT_APP_API_URL + infrastructure?.logo}
                                                       target="_blank" rel="noopener noreferrer">
                                                        <p className="flex items-center text-sm text-slate-500 gap-2">{logoName}
                                                            <span className=" text-slate-950 text-md"><FiInfo /></span>
                                                        </p>
                                                    </a> :
                                                    <p className="text-sm text-slate-500"> {pathLogo ? 'logo selected' : 'Choose new Logo'}</p>}</div>

                                                <input
                                                    type="file"
                                                    name="logo"
                                                    id="logo"
                                                    multiple
                                                    className="sr-only"
                                                    {...register('logo')}
                                                    onChange={(e) => {
                                                        const fileNameLogo = e.target.files[0]?.name
                                                        setPathLogo(fileNameLogo)
                                                    }}
                                                />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="flex flex-col gap-4 ">
                                        <div>
                                            <h3 className="text-xl font-bold mb-2 tracking-wide">
                                                Description
                                            </h3>
                                        </div>
                                        <div className="w-full">
                                            <Controller
                                                control={control}
                                                name="description"
                                                id="description"
                                                render={({ field }) => (
                                                    <MyEditor
                                                        initialvalue={watch('description')}
                                                        setEditorData={handleEditorChange}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex flex-col gap-4">
                                        <label htmlFor="parent" className="font-semibold text-md">Installation's
                                            parent</label>

                                        <div className="w-full">
                                            <Controller
                                                name="parent"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        {...register('parent')}
                                                        onChange={(e) => {
                                                            setValue('parent', e)
                                                        }}
                                                        id="parent"
                                                        isClearable
                                                        options={listInfrastructuresParent}
                                                        getOptionLabel={(option) => option.name} // Récupère le nom de l'option
                                                        getOptionValue={(option) => option.id} // Récupère la valeur de l'option (id)
                                                        isSearchable
                                                    >
                                                    </Select>
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex flex-col gap-4 ">
                                        <div>
                                            <h3 className="text-xl font-bold mb-2 tracking-wide">
                                                Visible in the installation catalog
                                            </h3>
                                        </div>
                                        <div className="w-full">
                                            <div>
                                                <label
                                                    htmlFor="isHidden"
                                                    className={`relative w-10 h-6 flex items-center justify-center rounded-full cursor-pointer text-xs font-medium border px-1 ${watch('showable') ? 'text-green-400 bg-green-100 border-green-300' : 'text-orange-400 bg-orange-100 border-orange-300'}`}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        id="isHidden"
                                                        value={infrastructure?.showable}
                                                        {...register('showable')}
                                                        className="sr-only"
                                                    />

                                                    <span
                                                        className={`${watch('showable') ? 'right-0 border-green-400' : 'border-orange-400 left-0'} transition ease-linear h-6 w-6 rounded-full bg-white shadow-sm border absolute top-1/2 transform -translate-y-1/2`}></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div>
                                            <Tooltip
                                                title={'If you check "group only" there will be no installation but a category to organize your installations'}>
                                                <div><h3 className="flex text-xl font-bold mb-2 tracking-wide">
                                                    Group only <FiInfo className={'ml-2 text-blue-500'} />
                                                </h3>
                                                </div>
                                            </Tooltip>
                                        </div>
                                        <div className="w-full">
                                            <div>
                                                <label
                                                    htmlFor="isGroupOnly"
                                                    className={`relative w-10 h-6 flex items-center justify-center rounded-full cursor-pointer text-xs font-medium border px-1 ${watch('isGroupOnly') ? 'text-green-400 bg-green-100 border-green-300' : 'text-orange-400 bg-orange-100 border-orange-300'}`}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        id="isGroupOnly"
                                                        value={infrastructure?.isGroupOnly}
                                                        {...register('isGroupOnly')}
                                                        className="sr-only"
                                                    />

                                                    <span
                                                        className={`${watch('isGroupOnly') ? 'right-0 border-green-400' : 'border-orange-400 left-0'} transition ease-linear h-6 w-6 rounded-full bg-white shadow-sm border absolute top-1/2 transform -translate-y-1/2`}></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={'flex'}>
                                        <motion.button
                                            className={`w-1/2 bg-blue-500 text-slate-100 hover:bg-blue-400 rounded-full border h-10 transition ease-linear`}
                                            type="submit"
                                            title={infrastructure ? 'Save the new information' : 'Create new Infrastructure'}
                                        >
                                            {infrastructure ? 'Save' : 'Create'}
                                        </motion.button>

                                        {infrastructure &&
                                            <>
                                                <motion.button
                                                    className={`w-1/2 bg-red-500 text-slate-100 hover:bg-red-400 rounded-full border h-10 transition ease-linear`}
                                                    type="button"
                                                    onClick={() => setOpenConfirmation(true)}
                                                    title={'Delete Infrastructure'}
                                                >
                                                    Delete
                                                </motion.button>
                                                <ConfirmDialog
                                                    isOpen={openConfirmation}
                                                    onClose={() => setOpenConfirmation(false)}
                                                    setIsOpen={setOpenConfirmation}
                                                    onAgree={() => handleDelete()}
                                                    content={`Are you sure you want to delete infrastructure '${infrastructure.name}' ?`}
                                                />
                                            </>
                                        }
                                    </div>
                                </>
                            )
                        }
                    </>
                )}
        </form>
    )
}