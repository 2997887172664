import {NetworkList} from './NetworkList';
import {useState} from "react";

export const NetworkBox = ({ networkId, setNetworkId, setIsShow }) => {
    const [totalItems, setTotalItems] = useState(null);
    const [itemsPerPage, setItemsPerPage] = useState(8);


    return (
        <NetworkList
            itemsPerRow={itemsPerPage}
            setNetworkId={setNetworkId}
            setTotalItems={setTotalItems}
            networkId={networkId}
            setIsShow={setIsShow}
            setItemsPerPage={setItemsPerPage}
        />
    )
}
