import { useState, useEffect } from 'react';
import NewsService from "../../../services/NewsService";
export const useFecthNewsById = (page) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
            async function fetchAllNews() {
                try {
                    // setIsLoading(true);
                    const res = await NewsService.getNewsById(page);
                    setData(res);
                    setIsLoading(false);
                } catch (error) {
                    setError(error);
                    setIsLoading(false);
                }
            }

            fetchAllNews();

    }, []);

    return { data, isLoading, error };
}