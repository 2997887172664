import AxiosService from "./AxiosService";

// Tabs/Forms Requests
const urlFormTabs = 'api/tabs';
const urlFormForms = 'api/forms';
const urlFormPreloadServices = 'api/service_types/list/network';

// Installation
const urlInfrasructureStandardDescriptionsPerInfra = 'api/standard_descriptions/platform/infra'
const urlInfrastructureAdditionalFieldsPerInfra = 'api/network_infrastructure_descriptions/infra'
const urlInfrastructureDescriptionsPerInfra = 'api/infrastructures/descriptions'

// Service
const urlServiceStandardDescriptionsPerService = 'api/standard_descriptions/service'
const urlServiceAdditionalFieldsPerInfra = 'api/network_service_descriptions/infra'
const urlServiceDescriptionsPerService = 'api/service_types/descriptions'


class DescriptionService {

    // Tabs/Forms Requests
    getFormTabById(id, token) {
        return AxiosService.getWithToken(urlFormTabs + "/" + id, token, 'jsonld');
    }
    getFormForms(id, token) {
        return AxiosService.getWithToken(urlFormForms + "/" + id, token, 'jsonld');
    }

    getFormPreloadServices(id) {
        return AxiosService.getWithoutToken(urlFormPreloadServices + "/" + id, "jsonld");
    }

    // Installation
    getInfrastructureAdditionalFieldsPerInfra(id, token){
        return AxiosService.getWithToken(urlInfrastructureAdditionalFieldsPerInfra + '/' + id, token, 'jsonld');
    }

    getInfrastructureStandardDescriptionsPerInfra(id, token){
        return AxiosService.getWithToken(urlInfrasructureStandardDescriptionsPerInfra + '/' + id, token, 'jsonld');
    }

    getInfrastructureDescriptionsPerInfra(id, token){
        return AxiosService.getWithToken(urlInfrastructureDescriptionsPerInfra + '/' + id, token, 'jsonld');
    }

    putInfrastructureDescription(idInfra, data, token) {
        return AxiosService.putWithToken(urlInfrastructureDescriptionsPerInfra + "/" + idInfra, data, token, 'jsonld')
    }

    // Service
    getServiceStandardDescriptionsPerService(id, token){
        return AxiosService.getWithToken(urlServiceStandardDescriptionsPerService + '/' + id, token, 'jsonld');
    }

    getServiceAdditionalFieldsPerInfra(id, token){
        return AxiosService.getWithToken(urlServiceAdditionalFieldsPerInfra + '/' + id, token, 'jsonld');
    }

    getServiceDescriptionsPerService(id, token){
        return AxiosService.getWithToken(urlServiceDescriptionsPerService + '/' + id, token, 'jsonld');
    }

    putServiceDescription(idService, data, token) {
        return AxiosService.putWithToken(urlServiceDescriptionsPerService + "/" + idService, data, token, 'jsonld')
    }

}

export default DescriptionService;