import {FETCH_ALL_NETWORK_CONFIG, ADD_NETWORK} from "./networkConfigAction";

const initialState = {
    data: [],
}

export const networkConfigReducers = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_NETWORK_CONFIG :
            return {
                ...state,
                data: action.payload
            }
        case ADD_NETWORK:
            return {
                ...state,
                data: [...state.data, action.payload]
            }
        default:
            return state;
    }
}