import { useState, useEffect } from 'react';
import ServiceService from "../service/ServiceService";
import {useDispatch} from "react-redux";
import {fetchSuccess} from "../../../redux/catalog/catalogActions";

export const useFetchAllServices = (id, page, itemsPerPage, filter, list, queryString) => {

    const dispatch = useDispatch();

    const [service, setService] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [totalItems, setTotalItems] = useState([]);

    const [error, setError] = useState(null);

    async function fetchAllServices() {
        setIsLoading(true)
        try {
            const serviceService = new ServiceService();
            const res = queryString
                ? await serviceService.getServiceByNetworkIdWithFilter(id, page, itemsPerPage, filter, list)
                : await serviceService.getServiceByNetworkId(id, page, itemsPerPage, list);

            // const res = await serviceService.getServiceByNetworkIdWithFilter(id, page, itemsPerPage, filter, list)

            dispatch(fetchSuccess(res["hydra:member"]));

            setService(res["hydra:member"]);
            setTotalItems(res["hydra:totalItems"]);
            setIsLoading(false);
        } catch (error) {
            setError(error);
            setIsLoading(false);
        }
    }

    useEffect( () => {
        fetchAllServices();

    }, [id, page, itemsPerPage, filter, list]);

    const numberItem =  service?.length;

    return { service, totalItems, isLoading, error, numberItem, fetchAllServices };
};