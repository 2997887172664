import { ResourceTree } from '../ResourceTree'
import { useTreeViewApiRef } from '@mui/x-tree-view'
import { useForm } from 'react-hook-form'
import { useContext, useEffect, useState } from 'react'
import { DrawerTemporaryContext } from '../../../../components/drawer/DrawerTemporary'
import ResourceLinkService from '../../services/ResourceLink'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { displayMessage } from '../../../../redux/alertAction'
import { useFetchLinkById } from '../../hooks/useFetchLinkById'
import ConfirmDialog from '../../../../components/confirm/ConfirmDialog'
import { FormLoader } from '../../../resourcetype/loaders/Loaders'

export default function LinkForm({ resource, resourceType }) {
    const dispatch = useDispatch()
    const selectUser = createSelector(
        (state) => state.login.user,
        (user) => user
    )
    const user = useSelector(selectUser)
    const apiRefLink = useTreeViewApiRef()
    const context = useContext(DrawerTemporaryContext)
    const isUpdate = context.method === 'UPDATE'
    const { link, isLoading } = useFetchLinkById(context.idSelected, user.token)
    const [openConfirmation, setOpenConfirmation] = useState(false)

    const {
        control,
        register,
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { errors },
        getValues,
    } = useForm({
        defaultValues: {
            resourceToLink: null,
            resourceOne: null,
        },
    })

    useEffect(() => {
        if (!isLoading && isUpdate && link !== null) {
            if (resource.id === link.resourceOne.id) {
                setValue('resourceToLink', link?.resourceToLink.id)
            } else {
                setValue('resourceOne', link?.resourceOne.id)
            }
        }
    }, [isLoading, isUpdate, link])
    const onSubmit = async (data) => {
        try {
            const body = { resourceOne: null, resourceToLink: null }
            if (isUpdate && link.resourceOne.id !== resource.id) {
                body.resourceOne = `api/resources/${getValues('resourceOne')}`
                body.resourceToLink = `api/resources/${resource.id}`
            } else {
                body.resourceOne = `api/resources/${resource.id}`
                body.resourceToLink = `api/resources/${getValues('resourceToLink')}`
            }
            const resourceLinkService = new ResourceLinkService()
            if (isUpdate) {
                await resourceLinkService.putResourceLink(context.idSelected, body, user.token).then()
            } else {
                await resourceLinkService.postResourceLink(body, user.token).then()
            }
            reset()
            dispatch(displayMessage('Success', `Element ${isUpdate ? 'created' : 'updated'}`, 'success'))
            context.onDrawerClose()
        } catch (e) {
            dispatch(displayMessage('Error', e.data.detail, 'error'))
        }
    }
    const setResourceToLink = (data) => {
        setValue('resourceToLink', data)
    }

    const setResourceOne = (data) => {
        setValue('resourceOne', data)
    }

    const handleDelete = async () => {
        try {
            const resourceLinkService = new ResourceLinkService()
            resourceLinkService.deleteResourceLink(context.idSelected, user.token).then()
            reset()
            dispatch(displayMessage('Success', 'Element deleted', 'success'))
            context.onDrawerClose()
        } catch (error) {
            dispatch(displayMessage(error.data.title, error.data.detail, 'error'))
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {isLoading ? (
                <FormLoader />
            ) : (
                <div className="flex flex-col gap-4">
                    <div>
                        <h3 className="text-xl font-bold mb-2 tracking-wide">Resource to link with</h3>
                    </div>
                    <div className="w-full">
                        <ResourceTree
                            setResource={
                                resource.id !== link?.resourceOne.id && isUpdate ? setResourceOne : setResourceToLink
                            }
                            setIsLoadingResource={(isLoading) => {}}
                            setRid={
                                resource.id !== link?.resourceOne.id && isUpdate ? setResourceOne : setResourceToLink
                            }
                            rid={
                                resource.id !== link?.resourceOne.id && isUpdate
                                    ? link?.resourceOne.id.toString()
                                    : link?.resourceToLink.id.toString()
                            }
                            apiRef={apiRefLink}
                        />
                    </div>
                    <input
                        type="submit"
                        value={isUpdate ? 'Update' : 'Create'}
                        className="py-3 px-4 bg-blue-500 text-white text-sm hover: rounded-full max-w-1/6 cursor-pointer"
                    />
                    {isUpdate ? (
                        <>
                            <input
                                type="button"
                                value="Delete"
                                onClick={() => {
                                    setOpenConfirmation(true)
                                }}
                                className="py-3 ml-2 px-4 bg-red-500 text-white text-sm hover: rounded-full max-w-1/6 cursor-pointer"
                            />
                            <ConfirmDialog
                                isOpen={openConfirmation}
                                onClose={() => setOpenConfirmation(false)}
                                setIsOpen={setOpenConfirmation}
                                onAgree={() => handleDelete()}
                            />
                        </>
                    ) : null}
                </div>
            )}
        </form>
    )
}
