import Drawer from '@mui/material/Drawer';
import {useFetchNetworkById} from "../../features/network/hooks/useFetchNetworkById";
import {AnimatePresence, motion} from "framer-motion";
import {Typography} from "@mui/material";

import {FiX} from "react-icons/fi";
import {useMobile} from "../../hooks/useMobile";
import {useEffect} from "react";

import StandardImageNetwork from "../../assets/images/standardnetworkimage.webp"
import {StandardImageByNetwork} from "../../utils/StandardImageByNetwork";

export default function MyDrawer({ setCloseDrawer, closeDrawer, setIsOpen, isOpen, networkId, setNetworkName, buttonClose, variantDrawer }) {
    const isMobile = useMobile();

    const { data, isLoading } = useFetchNetworkById(networkId);

    if (setNetworkName) {
        setNetworkName(data.name)
    }

    const handleClose = () => {
        setIsOpen( false )
    }
    // overflow the body
    useEffect(() => {
        if (isMobile && closeDrawer) {
            document.querySelector('body').style.overflow = 'hidden';
        } else {
            document.querySelector('body').style.overflow = '';
        }
    }, [isMobile]);


    const handleHiddenDrawer = () => {
        setCloseDrawer(false);
    }

    return (

        <Drawer
            sx={{
                width: { sx: "100%", lg: "550px"},
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: { sx: "100%", lg: "550px"},
                },
            }}
            variant={variantDrawer ? variantDrawer : "persistent"}
            anchor={ isMobile ? "bottom" :"right"}
            open={isOpen}
            ModalProps={{
                keepMounted: true,
            }}
            PaperProps={{
                sx: {
                    borderTopRightRadius:{xs: "50px", lg:"0px"},
                    borderTopLeftRadius:{xs: "50px", lg:"0px"},
                    height: {xs: closeDrawer ? "100%": "0px", lg:"100%"},
                    overflow: !closeDrawer ? "hidden" : "",
                },
            }}
        >
            <AnimatePresence>
                { !isLoading  &&
                    data &&
                    <motion.div className="w-full min-h-screen flex flex-col relative overflow-hidden pb-4">
                        <div className="py-4 px-8 top-0 bg-slate-50 border border-b-slate-300 h-[70px] flex items-center justify-between order-first">
                            <div className="flex items-center justify-between w-full">
                                <div className="flex items-center gap-4">
                                    <div className="w-auto h-8">
                                        <figure className="w-auto h-8 overflow-hidden">
                                            <img
                                                src={`${process.env.REACT_APP_API_URL}${data.logo}`}
                                                alt={data.title}
                                                className="w-full h-full object-contain"
                                            />
                                        </figure>
                                    </div>

                                    <Typography component="p" variant="subtitle1" className="mb-0">{data.name}</Typography>
                                </div>

                                { buttonClose &&
                                    <motion.button
                                        className="border border-solid border-slate-200 p-3 rounded-full"
                                        onClick={handleClose}

                                        whileHover={{scale: 1.095}}
                                        whileTap={{scale: .945}}
                                        title="Close sidebar"
                                        transition={{type: "spring", duration: .6, ease: "easeIn"}}
                                    >
                                        <span className="text-slate-500 text-xl"><FiX /></span>
                                    </motion.button>
                                }

                                { isMobile &&
                                    !buttonClose &&

                                    <motion.button
                                        onClick={handleHiddenDrawer}
                                        title="Close"
                                        className="flex items-center gap-2"

                                        whileHover={{scale: 1.0045}}
                                        whileTap={{scale: .945}}
                                    >
                                        <span
                                            className="rounded-full border border-slate-200 p-2 flex items-center justify-center w-10 h-10"><FiX/></span>
                                    </motion.button>
                                }
                            </div>
                        </div>

                        <div className="overflow-x-hidden overflow-scroll min-h-[35vh] p-4">
                            <div className={`border border-slate-200 rounded-3xl bg-slate-50 h-96 w-full mb-8 overflow-hidden`}>
                                <figure className={`h-96`}>
                                    <img
                                        src={ data.mainImage ? `${process.env.REACT_APP_API_URL}${data.mainImage}` :  StandardImageByNetwork(data.code) }
                                        alt={data.title}

                                        className={`w-full h-full object-cover`}
                                    />
                                </figure>
                            </div>
                            <div dangerouslySetInnerHTML={{__html: data.description}}></div>
                        </div>
                    </motion.div>
                }
            </AnimatePresence>

        </Drawer>
    );
}
