export const isValidEmail = (email) => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return regex.test(email);
}

export const isValidUrl = (str) => {
    const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$", // fragment locator
        "i"
    );
    return pattern.test(str);
}

export const missingFieldsChecking = (hide, required, fieldUniqueId, reloadMissingFieldsCheckingContext, setReloadMissingFieldsCheckingContext, statement, refElement) => {

    if(!hide && required) {
        const unfilledInput = document.getElementById(`${fieldUniqueId}`);

        if (unfilledInput) {
            if (statement) {
                unfilledInput.classList.add('no-value');
                refElement?.current.classList.add('no-value'); // For File Upload Design

                setReloadMissingFieldsCheckingContext(!reloadMissingFieldsCheckingContext);
            } else {
                unfilledInput.classList.remove('no-value');
                refElement?.current.classList.remove('no-value'); // For File Upload Design

                setReloadMissingFieldsCheckingContext(!reloadMissingFieldsCheckingContext);
            }
        }
    }

}