import React from 'react'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { useFetchResourceFromResourceTypeId } from '../hooks/useFetchResourceFromResourceTypeId'

import { useEffect, useState } from 'react'
import { RichTreeView, TreeItem2, TreeItem2Label, useTreeItem2Utils } from '@mui/x-tree-view'
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material'
import { useFetchResourceById } from '../hooks/useFetchResourceById'
import { useSearchParams } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'
import { FiCheck, FiChevronDown, FiChevronUp, FiCopy, FiEdit, FiPlusCircle, FiTrash } from 'react-icons/fi'
import { TreeItem2LabelInput } from '@mui/x-tree-view/TreeItem2LabelInput'
import { IconButton } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import ConfirmDialog from '../../../components/confirm/ConfirmDialog'
import { Loader } from '../../../components/loader/Loader'
import { useFetchUserRole } from '../../../hooks/useFetchUserRole'

const selectCurrentInfrastructure = createSelector(
    (state) => state.currentInfrastructure,
    (ci) => ci
)

export const ResourceTree = ({
    rid,
    setRid,
    resourceType,
    setResource,
    setIsLoadingResource,
    apiRef,
    isMainTree = false,
    handleCreateResourceClick,
    handleDuplicateResourceClick,
    handleOnItemLabelChange,
    handleDelete,
    isMultiple = true,
}) => {
    const selectUser = createSelector(
        (state) => state.login.user,
        (user) => user
    )
    const currentInfrastructure = useSelector(selectCurrentInfrastructure)

    const [isLoading, setIsLoading] = useState(true)

    const user = useSelector(selectUser)

    const [increment, setIncrement] = useState(0)

    const { listResources } = useFetchResourceFromResourceTypeId(
        resourceType?.id,
        user.token,
        setIsLoading,
        0,
        currentInfrastructure
    )

    const [treeItems, setTreeItems] = useState([])

    const [curResourceId, setCurResourceId] = useState(null)

    const { resource } = useFetchResourceById(curResourceId, resourceType?.id, user.token, setIsLoadingResource)

    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedItems, setSelectedItems] = useState([])
    const [expandedItems, setExpandedItems] = useState([])

    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [isEditResource, setIsEditResource] = useState(false)
    const [isItemExpansion, setIsItemExpansion] = useState(false)

    const role = useFetchUserRole()

    const handleItemClick = (event, itemId) => {
        if ((!isItemExpansion && !event.defaultMuiPrevented) || (!isEditResource && !event.defaultMuiPrevented)) {
            setIncrement(increment + 1)
            const alreadyExist = selectedItems.indexOf(itemId) > -1
            if (isMainTree) {
                if (!alreadyExist) {
                    setCurResourceId(itemId)
                    updateUrl(itemId)
                } else {
                    setCurResourceId(null)
                    updateUrl(null)
                }
            } else {
                setResource(itemId)
            }

            if (!alreadyExist) {
                setSelectedItems([itemId])
            } else {
                setSelectedItems([])
            }
        }
        setIsItemExpansion(false)
    }

    const updateUrl = (itemId) => {
        if (null !== itemId) {
            setSearchParams({
                rid: itemId,
            })
        } else {
            setSearchParams({})
        }
        setRid(itemId)
    }

    const handleItemExpansionToggle = (event, itemIds, isExpanded) => {
        event.defaultMuiPrevented = true
        setIsItemExpansion(true)
        let newItemIds = []
        if (isExpanded) {
            newItemIds = expandedItems.concat(itemIds)
        } else {
            newItemIds = expandedItems.toSpliced(expandedItems.indexOf(itemIds), 1)
        }

        setExpandedItems(newItemIds)
    }

    const handleExpandClick = () => {
        setExpandedItems((oldExpanded) => (oldExpanded.length === 0 ? getAllItemsWithChildrenItemIds() : []))
    }

    const getAllItemsWithChildrenItemIds = () => {
        const itemIds = []
        const registerItemId = (item) => {
            if (item.children?.length) {
                itemIds.push(item.id.toString())
                item.children.forEach(registerItemId)
            }
        }

        treeItems.forEach(registerItemId)

        return itemIds
    }

    useEffect(
        (event) => {
            setSelectedItems([rid])
            setCurResourceId(rid)
        },
        [rid]
    )

    useEffect(
        (event) => {
            setResource(resource)
        },
        [resource]
    )

    useEffect(() => {
        setTreeItems(listResources['hydra:member'] ?? [])
        if (null !== curResourceId) {
            const parentList = getParentList(curResourceId)
            setExpandedItems(parentList)
        }
    }, [curResourceId, listResources])

    const getParentList = (rId) => {
        let result = []
        const r = apiRef?.current?.getItem(rId)
        if (undefined !== r && null !== r) {
            result.push(rId)
            if (r.parent) {
                const splittedValue = r.parent.split('/')
                const parentId = splittedValue.pop()
                const curParent = apiRef?.current?.getItem(parentId)
                result.push(parentId)
                if (curParent && curParent.parent) {
                    const splittedValueParent = r.parent.split('/')
                    const parentParentId = splittedValueParent.pop()
                    const parentResult = getParentList(parentParentId)
                    parentResult.map((pr) => {
                        return result.push(pr)
                    })
                }
            }
            return result
        }
        return []
    }

    function CustomLabel({ editing, editable, children, toggleItemEditing, itemId, ...other }) {
        return (
            <TreeItem2Label
                {...other}
                editable={editable}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    justifyContent: 'space-between',
                }}
            >
                {children}
                {editable && itemId === curResourceId && resource?.canManage && (
                    <div>
                        <IconButton
                            size="small"
                            onClick={() => {
                                setIsEditResource(true)
                                toggleItemEditing()
                            }}
                            className={`opacity-20 hover:opacity-100 hover:text-blue-500`}
                            sx={{
                                color: 'text.secondary',
                            }}
                        >
                            <FiEdit />
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={() => setOpenConfirmation(true)}
                            className={`opacity-20 hover:opacity-100 hover:text-red-500`}
                            sx={{
                                color: 'text.secondary',
                            }}
                        >
                            <FiTrash />
                        </IconButton>
                    </div>
                )}
            </TreeItem2Label>
        )
    }

    function CustomLabelInput(props) {
        const { handleCancelItemLabelEditing, handleSaveItemLabel, value, itemId, ...other } = props

        return (
            <React.Fragment>
                <TreeItem2LabelInput {...other} value={value} />
                <IconButton
                    color="success"
                    size="small"
                    onClick={(event) => {
                        handleSaveItemLabel(event, value)
                    }}
                >
                    <FiCheck />
                </IconButton>
                <IconButton
                    color="error"
                    size="small"
                    onClick={(event) => {
                        setIsEditResource(false)
                        event.defaultMuiPrevented = false
                        handleCancelItemLabelEditing()
                    }}
                >
                    <CancelIcon />
                </IconButton>
            </React.Fragment>
        )
    }

    const CustomTreeItem2 = React.forwardRef(function CustomTreeItem2(props, ref) {
        const { interactions, status } = useTreeItem2Utils({
            itemId: props.itemId,
            children: props.children,
        })

        const handleContentDoubleClick = (event) => {
            event.defaultMuiPrevented = true
        }

        const handleInputBlur = (event) => {
            event.defaultMuiPrevented = true
        }

        const handleInputKeyDown = (event) => {
            event.defaultMuiPrevented = true
        }

        const handleEdit = (event) => {
            setIsEditResource(true)
            return interactions.toggleItemEditing()
        }

        const handleClick = (event) => {
            event.defaultMuiPrevented = true
            setCurResourceId(props.itemId)
            updateUrl(props.itemId)
        }

        return (
            <TreeItem2
                {...props}
                ref={ref}
                slots={{ label: CustomLabel, labelInput: CustomLabelInput }}
                slotProps={{
                    label: {
                        onDoubleClick: handleContentDoubleClick,
                        editable: status.editable,
                        editing: status.editing,
                        onClick: handleClick,
                        toggleItemEditing: handleEdit,
                        itemId: props.itemId,
                    },
                    labelInput: {
                        onBlur: handleInputBlur,
                        onKeyDown: handleInputKeyDown,
                        handleCancelItemLabelEditing: interactions.handleCancelItemLabelEditing,
                        handleSaveItemLabel: interactions.handleSaveItemLabel,
                        itemId: props.itemId,
                    },
                }}
            />
        )
    })

    const handlePrivateOnItemLabelChange = (itemId, newLabel) => {
        setIsEditResource(false)
        if ('function' === typeof handleOnItemLabelChange) {
            handleOnItemLabelChange(itemId, newLabel)
        }
    }

    const updateTreeItems = (item, itemId, newLabel) => {
        let children = []
        if (item.children && item.children.length > 0) {
            children = item.children.map((child) => updateTreeItems(child, itemId, newLabel))
        }
        return item?.id.toString() === itemId.toString()
            ? { ...item, children: children, name: newLabel }
            : {
                  ...item,
                  children: children,
              }
    }
    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className={'p-2'}>
                        {isMainTree && (
                            <>
                                {'function' === typeof handleCreateResourceClick && role.canCreateResources && (
                                    <Tooltip
                                        title={'Create a new resource'}
                                        placement={'top-end'}
                                        children={
                                            <button
                                                type="button"
                                                className="bg-blue-300 hover:bg-blue-400 border border-blue-400 text-slate-50 font-medium rounded-full p-2 "
                                                onClick={handleCreateResourceClick}
                                            >
                                                <FiPlusCircle />
                                            </button>
                                        }
                                    />
                                )}
                                {'function' === typeof handleDuplicateResourceClick && role.canCreateResources && (
                                    <Tooltip
                                        title={'Duplicate a resource'}
                                        placement={'top-end'}
                                        children={
                                            <button
                                                type="button"
                                                className="bg-blue-300 hover:bg-blue-400 border border-blue-400 text-slate-50 font-medium rounded-full p-2 mx-2"
                                                onClick={handleDuplicateResourceClick}
                                            >
                                                <FiCopy />
                                            </button>
                                        }
                                    />
                                )}
                                {null !== resource && role.canDeleteResources && (
                                    <ConfirmDialog
                                        isOpen={openConfirmation}
                                        onClose={() => setOpenConfirmation(false)}
                                        setIsOpen={setOpenConfirmation}
                                        onAgree={() => handleDelete()}
                                        content={`Are you sure you want to delete resource "${resource.name}" and their children ?`}
                                    />
                                )}
                            </>
                        )}
                        {listResources.length <= 100 && (
                            <Tooltip
                                title={expandedItems.length === 0 ? 'Expand all' : 'Collapse all'}
                                placement={'top-end'}
                                children={
                                    <button
                                        type="button"
                                        className="bg-blue-300 hover:bg-blue-400 border border-blue-400 text-slate-50 font-medium rounded-full p-2"
                                        onClick={handleExpandClick}
                                    >
                                        {expandedItems.length === 0 ? <FiChevronDown /> : <FiChevronUp />}
                                    </button>
                                }
                            />
                        )}
                    </div>
                    <RichTreeView
                        apiRef={apiRef}
                        slots={{
                            expandIcon: KeyboardArrowRight,
                            collapseIcon: KeyboardArrowDown,
                            item: CustomTreeItem2,
                        }}
                        getItemId={(item) => item?.id?.toString()}
                        getItemLabel={(item) => item.name}
                        items={treeItems}
                        isItemEditable={isMainTree}
                        experimentalFeatures={{ labelEditing: isMainTree }}
                        expansionTrigger="iconContainer"
                        checkboxSelection
                        selectedItems={selectedItems}
                        expandedItems={expandedItems}
                        onItemClick={handleItemClick}
                        onSelectedItemsChange={handleItemClick}
                        onItemExpansionToggle={handleItemExpansionToggle}
                        onItemLabelChange={(itemId, newLabel) => {
                            const newTreeItems = treeItems.map((item) => updateTreeItems(item, itemId, newLabel))
                            setTreeItems(newTreeItems)
                            handlePrivateOnItemLabelChange(itemId, newLabel)
                        }}
                    />
                </>
            )}
        </>
    )
}
