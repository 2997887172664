import AxiosService from '../../../services/AxiosService'

const urlResource = 'api/resources'

class ResourceService {
    getAllResourceFromResourceTypeId(resourceTypeId, token) {
        return AxiosService.getWithToken(`${urlResource}?resourceType.id=${resourceTypeId}`, token, 'jsonld')
    }

    getAllResourceFromInfrastructureId(infrastructureId, token) {
        return AxiosService.getWithToken(`${urlResource}?resourceType.infrastructure.id=${infrastructureId}`, token, 'jsonld')
    }

    getAllResourceFromParentId(parentId, token) {
        return AxiosService.getWithToken(`${urlResource}?parent.id=${parentId}`, token, 'jsonld')
    }

    getResourceById(id, resourceTypeId, token) {
        return AxiosService.getWithToken(`${urlResource}/${id}?resourceType.id=${resourceTypeId}`, token, 'jsonld')
    }

    postResource(data, token) {
        data['token'] = token
        return AxiosService.postWithToken(`${urlResource}`, data, 'jsonld')
    }

    postDuplicateResource(data, token) {
        data['token'] = token
        return AxiosService.postWithToken(`${urlResource}/duplicate`, data, 'jsonld')
    }

    putResource(data, id, token) {
        data['token'] = token
        return AxiosService.putWithToken(`${urlResource}/${id}`, data, token, 'jsonld')
    }

    deleteResource(id, token) {
        return AxiosService.deleteWithToken(`${urlResource}/${id}`, token, 'jsonld')
    }

    putResourceAccess(id, body, token) {
        return AxiosService.putWithToken(`${urlResource}/${id}/access`, body, token, 'jsonld')
    }

    getResourceInfos(id, token) {
        return AxiosService.getWithToken(`${urlResource}/description/${id}`, token, 'jsonld')
    }

    putResourceInfos(id, data, token) {
        return AxiosService.putWithToken(`${urlResource}/description/${id}`, data, token, 'jsonld')
    }
}

export default ResourceService
