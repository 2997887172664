import AxiosService from "./AxiosService";


const urlAllNews = "api/news"

class NewsService {

    static getAllNews(page, itemsPerPage) {
        return AxiosService.getWithoutToken(urlAllNews + "?page=" + page + '&itemsPerPage=' + itemsPerPage, "jsonld");
    }

    static getNewsById(id) {
        return AxiosService.getWithoutToken(urlAllNews + "/" + id, "jsonld");
    }

    postNews(jsonFormData) {
        return AxiosService.postWithToken(urlAllNews, jsonFormData, "jsonld");

    }

    postNewsWithToken(data, token) {
        data["token"] = token;
        return AxiosService.postWithToken(urlAllNews, data,"jsonld");

    }

    static putNewsByIdWithToken(id, jsonFormData, token) {
        return AxiosService.putWithToken(urlAllNews + "/" + id, jsonFormData, token, "jsonld");

    }

    static deleteNewsByIdWithToken(id, token) {

        return AxiosService.deleteWithToken(urlAllNews + "/" + id, token, "jsonld");
    }
}

export default NewsService;