const initialState= {
    keywords: [],
    categories: [],
    scientificDomains: [],
    infrastructure: "0",
    page: 1
}

const serviceCatalogReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_KEYWORD':
            return {
                ...state,
                keywords: action.payload,
                page: 1,
            };
        case 'SET_CATEGORY':
            return {
                ...state,
                categories: action.payload,
                page: 1,
            };
        case 'SET_SCIENTIFIC_DOMAIN':
            return {
                ...state,
                scientificDomains: action.payload,
                page: 1,
            };
        case 'SET_INFRASTRUCTURE':
            return {
                ...state,
                infrastructure: action.payload,
                page: 1,
            };
        case 'RESET_FILTERS':
            return initialState;
        case 'UPDATE_CURRENT_PAGE':
            return {
                ...state,
                page: action.payload,
            };
        default:
            return state;
    }
};

export default serviceCatalogReducer;