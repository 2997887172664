import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const ppaSlice = createSlice({
    name: "ppa",
    initialState: {
        listTypeFormsDatas: [],
        listTypeFormsVisual: [],
    },
    reducers: {
        addListTypeForms: (state, action) => {
            return {...state, listTypeFormsDatas: [...action.payload]};
        },
        addListTypeFormsVisual: (state, action) => {
            return {...state, listTypeFormsVisual: [...action.payload]};
        },
        updateListTypeFormsVisual: (state, action) => {
            return {...state, listTypeFormsVisual: action.payload};
        },
        addListTabs: (state, action) => {
            return {...state, listTabs: action.payload};
        }
    },
    selectors: {
        selectListTypeFormsDatas: (state) => state.listTypeFormsDatas,
        selectListTypeFormsVisual: (state) => state.listTypeFormsVisual,
    }
})