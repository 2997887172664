import "./core/assets/scss/index.scss";
import {BrowserRouter as Router } from "react-router-dom";
import {Provider} from "react-redux";
import {store} from "./core/redux/store";
import {persistStore} from 'redux-persist'
import { MuiTheme } from "./core/assets/theme/Mui-Theme"
import {ThemeProvider} from "@mui/material";
import {VersionComp} from "./core/components/VersionComp";
import {DrawerProvider} from "./core/contexts/DrawerContext";
import AnimatedRoutes from "./core/routes/AnimatedRoutes";
import {Toaster} from "sonner";

persistStore(store);

const App = () => {

    const isNotProd = process.env.REACT_APP_ENV === "test" || process.env.REACT_APP_ENV === "dev";

    const url = window.location.href;
    const isCatalog = (url.split("/")[2] ?? null) === "catalog.isia.cnrs.fr";
    const isNotCatalog = !(isCatalog && process.env.REACT_APP_ENV === "test");

    return (
        <Provider store={store}>
            <DrawerProvider>
                <ThemeProvider theme={MuiTheme}>
                    { (isNotProd && isNotCatalog) && <VersionComp /> }
                    <Toaster position="bottom-right" richColors closeButton />
                    <Router>
                        <AnimatedRoutes />
                    </Router>
                </ThemeProvider>
            </DrawerProvider>
        </Provider>
    );
};

export default App;