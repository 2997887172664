import {useEffect, useState} from "react";
import ResourceTypeService from "../services/ResourceType";

export const useFetchResourceType = (id, token, increment) => {

    const [resourceType, setResourceType] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchResourceType = async () => {
            try {
                const resourceType = new ResourceTypeService();
                const res = await resourceType.getAllResourceTypeByInfraId(id, token);
                setResourceType(res);
                setIsLoading(false);
            } catch (err) {
                setError(err);
                setIsLoading(false);
            }
        }

        fetchResourceType().then();

    }, [id, token, increment])

    return {resourceType, isLoading, error}
}

export const useFetchOneResourceType = (id, token, increment, setIsLoading) => {

    const [resourceType, setResourceType] = useState(null);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchResourceType = async () => {
            try {
                setIsLoading(true);
                if (undefined !== id && id > 0 && null !== id) {
                    const resourceType = new ResourceTypeService();
                    const res = await resourceType.getOneResourceTypeById(id, token);
                    setResourceType(res);
                } else {
                    setResourceType(null);
                }
                setIsLoading(false);
            } catch (err) {
                setError(err);
                setIsLoading(false);
            }
        }

        fetchResourceType().then();

    }, [id, token, increment])

    return {resourceType, error}
}

export const useFetchResourceTypeSearch = (id, searchTerm, token) => {

    const [resourceTypes, setResourceTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            const fetchResourceType = async () => {
                try {
                    if (
                        undefined !== id
                        && id > 0
                        && null !== id
                        && undefined !== searchTerm
                        && '' !== searchTerm
                        && 2 < searchTerm.length
                    ) {
                        const resourceType = new ResourceTypeService();
                        const res = await resourceType.searchResourceByInfraId(5, searchTerm, token);
                        setResourceTypes(res['hydra:member']);
                        setIsLoading(false);
                    } else {
                        setIsLoading(true);
                        setResourceTypes([]);
                    }
                } catch (err) {
                    setError(err);
                    setIsLoading(false);
                }
            }
            fetchResourceType().then();
        }, 200)
        return () => clearTimeout(timer)

    }, [id, searchTerm, token])

    return {resourceTypes, isLoading, error}
}