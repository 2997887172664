import {SectionComponent} from "../../components/SectionComponent";
import Aboutimage from "../../assets/images/aboutbg.webp"
import {AboutCard} from "../../components/cards/AboutCard";
import {AboutList, AboutListUp} from "../../utils/dataList";
import {AboutListDown} from "../../utils/dataList";
import { motion } from "framer-motion";
import {useNavigate} from "react-router-dom";
import {isPPARoute} from "../../utils/variables";
import BoxSwipper from "../../components/slider/BoxSwipper";
import {FiTarget} from "react-icons/fi";

export const About = () => {

    const navigate = useNavigate();

    return (
        <motion.section
            initial={{ translateY: -15, opacity: 0 }}
            transition={{type:"spring", duration:.3, ease:"easeInOut" }}
            animate={{ translateY: 0, opacity: 1 }}
            exit={{ opacity: 0 }}

            className="flex flex-col"
        >
            <SectionComponent
                title={infos.title}
                titleColor={ infos.titleColor}
                description={infos.description}
                image={infos.image}
                subDesc={infos.subDesc}
                subDesc2={infos.subDesc2}
                imageVisible
                partner
                v2
            />

            <article id="" className="w-full min-h-screen flex flex-col items-center">
                <div className="container">
                    <div className="wrapper flex flex-col gap-10">
                        <div className="flex items-center justify-center relative w-full m-auto min-h-72 bg-white order-3">
                            <div className="w-full lg:p-10 p-4 flex flex-col gap-4 items-center max-w-[1024px] border border-slate-300 shadow-sm rounded-2xl relative overflow-hidden">
                                <h2 className="lg:text-3xl text-xl font-semibold mb-4 flex flex-col items-center text-center">Our perspective on <span className="block">the future of ISIA</span>
                                    <span className="order-first text-slate-500 font-medium flex items-center text-xs">Our vision</span>
                                </h2>
                                <p className="text-slate-700 text-sm max-w-[600px] text-center">
                                    The ISIA application is now deployed in the AnaEE-France and AnaEE-ERIC
                                    infrastructures, as well as in the European infrastructure networks AgroServ,
                                    Microbr4Climate and IRIISC.
                                    It has already convinced 250 research facilities and almost a thousand users.
                                    <span className="block mt-2"> Its level of integration and flexibility of configuration mean that it can be adapted to any type of activity beyond the field of research.</span>

                                </p>

                                <span className="bg-blue-500 h-2 w-full absolute top-0 left-0"></span>
                            </div>
                        </div>

                        <hr/>

                        <BoxSwipper boxData={AboutList} />

                        <div className="flex flex-col gap-12 mb-10 order-last mt-10">
                            <p className="lg:text-3xl text-xl font-medium text-center fontfamilly">
                                Empowering researchers, sparking innovation, connecting potential
                            </p>
                            <motion.button
                                className="border border-slate-300 rounded-sm w-full p-4 text-slate-500 hover:text-slate-700 transition linear"
                                whileTap={{scale: .945}}
                                whileHover={{scale: 1.001}}
                                transition={{type: "spring", duration: .3, ease: "easeInOut"}}
                                // onClick={() => navigate(`${isPPARoute ? "/ppa" : "/catalog"}`)}
                                onClick={() => {
                                    window.open("mailto:support-isia@bio.ens.psl.eu", "blank_")
                                }}
                            >
                                {/*{isPPARoute ? "Submit pre-proposal project" : "Discover catalog"}*/}
                                Contact us
                            </motion.button>
                        </div>
                    </div>
                </div>

            </article>
        </motion.section>
    )
}

const infos = {
    title: "ISIA Your Partner to Build Up your",
    titleColor: "Projects",
    subDesc:"In addition to the need for excellence in the conduct of research, there is now the need to open up projects to a wider range of players, and to enhance the value of all the data produced by implementing the FAIR (Findable, Accessible, Interoperable, Reusable) principles.",
    subDesc2:"The ISIA application therefore integrates project and resource management tools to better plan and ensure the reliability of experiments, FAIRization tools for the data collected to ensure better use of the data produced, and tools to improve networking and give visibility to the services offered to the community through calls for projects and catalogs.",
    description: "In a context where projects are becoming increasingly complex, interdisciplinary and innovative, the French and European infrastructures AnaEE-France (Analysis and Experimentation on Ecosystems) and ERIC AnaEE (European Research Infrastructure Consortium) have decided to commit to the development of tools for the scientific community to meet ever more demanding expectations.",
    image: Aboutimage
}