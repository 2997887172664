import {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import SelectService from "../../../services/SelectService";

export const UseFetchSelect = (networkId, increment) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const token = useSelector(state => state.login.user?.token) ?? null;

    useEffect(() => {
        const fetchSelect = async () => {
            try {
                setIsLoading(true);
                if (null !== networkId) {
                    const selectService = new SelectService();
                    const res = await selectService.getSelectFromNetwork(networkId, token);
                    setData(res['hydra:member']);
                } else {
                    setData([]);
                }
            } catch (error) {
                setError(error);
            }
            setIsLoading(false);
        }
        fetchSelect().then();
    }, [
        networkId,
        increment,
        token
    ]);

    return {data, isLoading, error};
}
