import * as React from "react";
import {useContext, useState} from "react";
import {useParams} from "react-router-dom";

// Design
import {Box} from "@mui/material";

// Components
import {Loader} from "../../../components/loader/Loader";
import {NavigationTabsWrapper} from "../navigation/NavigationTabsWrapper";
import {NavigationTabPanel, a11yProps} from "../navigation/NavigationTabPanel";
import {DescriptionFormContainer} from "../components/DescriptionFormContainer";

// Hooks
import {
    useFetchDescriptions,
} from "../hooks/useFetchDescriptionData";

// Context
import { LayoutWithInstallationContext } from "../../../components/layouts/LayoutWithInstallation";
import {useFetchUserRole} from "../../../hooks/useFetchUserRole";
import {DescriptionLinkToCatalog} from "../components/DescriptionLinkToCatalog";
import {NavigationSelectionsMenu} from "../navigation/NavigationSelectionsMenu";



export const ServicesDescriptionContainer = () => {

    // From Context
    const layoutWithInstallationContext = useContext(LayoutWithInstallationContext);
    const { user } = layoutWithInstallationContext;

    const role = useFetchUserRole();
    const isRead = role && (!user.isSuperAdministrator && role.canAccessServiceTypes && !role.canUpdateServiceTypes);

    // Get service ID from params (:slug)
    const params = useParams();
    const { serviceId } = params;

    // Descriptions Data for one Service
    const { isDescriptionsDataLoading, descriptionsData, setDescriptionSubmitted } = useFetchDescriptions('service', serviceId, user?.token)
    // console.dir(descriptionsData)

    const { listServiceStandardDescription: standardDescriptions, listServiceNetworkDescription: additionalDescriptions } = descriptionsData;

    const additionalDescriptionsWithListTabs = additionalDescriptions?.filter((additional) => additional.listTabs.length !== 0);


    // Vertical Tab Management
    const [standardTabValue, setStandardTabValue] = useState(standardDescriptions?.length !== 0 ? 0 : false);
    const [additionalTabValue, setAdditionalTabValue] = useState(standardDescriptions?.length !== 0 ? false : 0);
    const [descriptionType, setDescriptionType] = useState(standardDescriptions?.length !== 0 ? 'standard' : 'additional');


    const handleChangeStandard = (event, newValue) => {
        setAdditionalTabValue(false)

        setStandardTabValue(newValue);
        setDescriptionType('standard');
    };

    const handleChangeAdditional = (event, newValue) => {
        setStandardTabValue(false);

        setAdditionalTabValue(newValue);
        setDescriptionType('additional');
    };



    return (
        <Box sx={{ display: "flex", flexGrow: '1' }}>

            {(isDescriptionsDataLoading ) && (
                <Loader content={'Loading Service Descriptions'} />
            )}

            <NavigationSelectionsMenu>
                {/* Navigation Tabs */}
                {(!isDescriptionsDataLoading && descriptionsData.length !== 0) && (
                    <NavigationTabsWrapper handleChangeStandard={handleChangeStandard}
                                           standardTabValue={standardTabValue}
                                           featureStandardDescriptions={standardDescriptions}

                                           handleChangeAdditional={handleChangeAdditional}
                                           additionalTabValue={additionalTabValue}
                                           featureAdditionalDescriptions={additionalDescriptionsWithListTabs}

                                           descriptionTitle={"Service descriptions"}
                                           descriptionSubTitle={descriptionsData.name}
                                           a11yProps={a11yProps}
                    />
                )}
            </NavigationSelectionsMenu>

            {(!isDescriptionsDataLoading && descriptionsData.length !== 0) && (
                <React.Fragment>

                    {/* Navigation Panels Content */}
                    {standardDescriptions?.map((description, index) => (

                        descriptionType === 'standard' && (
                            <NavigationTabPanel key={index}
                                                value={standardTabValue}
                                                index={index}
                            >
                                <DescriptionFormContainer descriptionData={description}

                                                          // Send Request Method
                                                          serviceId={serviceId}
                                                          service
                                                          setDescriptionSubmitted={setDescriptionSubmitted}
                                                          isRead={isRead}
                                />
                            </NavigationTabPanel>
                        )

                    ))}

                    {additionalDescriptionsWithListTabs?.map((description, index) => (

                        descriptionType === 'additional' && (
                            <NavigationTabPanel key={index}
                                                value={additionalTabValue}
                                                index={index}
                            >
                                <DescriptionFormContainer descriptionData={description}

                                                          // Send Request Method
                                                          serviceId={serviceId}
                                                          service
                                                          setDescriptionSubmitted={setDescriptionSubmitted}
                                                          isRead={isRead}
                                />

                                {description?.networkId && (
                                    <DescriptionLinkToCatalog descriptionTypeText={'services'}
                                                              description={description}
                                                              elementId={serviceId}
                                    />
                                )}
                            </NavigationTabPanel>
                        )

                    ))}

                </React.Fragment>
            )}

        </Box>
    );
}
