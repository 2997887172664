
 const UserListTeamInfra = ({ userListTeamInfra }) => {
    return (
        <>
            { userListTeamInfra &&
                <div className="w-11/12 flex flex-col gap-2 text-slate-500 mx-auto border-slate-200 border rounded-sm p-2 md:mt-4">
                    <p className="text-slate-500 font-semibold md:text-left text-center underline">User Teams</p>

                    { userListTeamInfra?.length === 0 ?
                        <span>You don't have any teams</span>
                        :
                        <ul className="rounded-2xl min-h-14 w-full flex items-center justify-start p-0 gap-2 flex-wrap">
                            {userListTeamInfra?.slice(0, 8).map((team) =>
                                <li key={team.id} className="border border-slate-300 h-10 text-xs text-slate-500 px-2 flex items-center justify-center rounded-full border-dashed">{team.name}</li>
                            )}
                        </ul>
                    }
                </div>
            }
        </>
    );
};

export default UserListTeamInfra;
