import Logo1 from "../assets/images/logo-anaee-france.png";
import Logo2 from "../assets/images/logo-anaee-europe.svg";
import Logo3 from "../assets/images/logo-agroserv.png";
import Logo4 from "../assets/images/logo-microbe-4-climate.svg";
import Logo5 from "../assets/images/logo-iriscc.webp";

export const Partner = () => {


    const handleClickPartner = (path) => {
        window.open(path, '_blank');
    };

    return (
        <div id="partner" className="w-full">
            <div className="flex flex-col items-center justify-center">
                <ul className=
                    "
                       grid
                       lg:grid-cols-5
                       grid-cols-2
                       mx-auto
                       justify-center
                       my-4
                       gap-10
                       lg:my-10
                       lg:gap-10
                       lg:w-9/12
                    "
                >
                    { partner.map( item =>
                        <li
                            key={item.id}
                            className="lg:w-32 cursor-pointer"
                            title={item.title}
                            onClick={() => handleClickPartner(item.path)}
                        >
                            <figure className="lg:w-32">
                                <img src={item.logo} alt={item.title} className="h-full w-full object-cover"/>
                            </figure>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    )
}

const partner = [
    {
        id:1,
        title:"Anaee France",
        logo:Logo1,
        path:" https://www.anaee-france.fr/"
    },
    {
        id:2,
        title:"Anaee Eric",
        logo:Logo2,
        path:"https://www.anaee.eu/"
    },
    {
        id:3,
        title:"Agroserv",
        logo:Logo3,
        path:"https://agroserv.eu/"
    },
    {
        id:4,
        title:"Microbe 4 Climates",
        logo:Logo4,
        path:"https://microbes4climate.eu/"
    },
    {
        id:5,
        title:"Iriscc",
        logo:Logo5,
        path:"https://www.iriscc.eu/"
    },
]