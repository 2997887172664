import { useEffect, useState } from 'react'
import EventService from '../../../services/EventService'

export const useFetchListEventByResourceType = (userInfra, resource, token) => {
    const [events, setEvents] = useState([])
    const [error, setError] = useState(false)
    const [eventIsLoading, setEventIsLoading] = useState(true)

    useEffect(() => {
        if (eventIsLoading) {
            fetchEvents()
            setEventIsLoading(false)
        }
    }, [setEventIsLoading, events, eventIsLoading, resource])

    const fetchEvents = async () => {
        const eventService = new EventService()
        const response = await eventService.getListEventResource(userInfra, resource, token)
        setEvents(response)
    }

    return { events, error, eventIsLoading, setEventIsLoading }
}
