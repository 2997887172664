import {FETCH_SELECT_OPTION, ADD_SELECT_OPTION, DELETE_SELECT_OPTION, UPDATE_SELECT_OPTION} from "./selectOptionAction";

const initialState = {
    data: [],
}

export const selectOptionReducers = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SELECT_OPTION :
            return {
                ...state,
                data: action.payload
            }
        case ADD_SELECT_OPTION:
            return {
                ...state,
                data: [...state.data, action.payload]
            }
        case DELETE_SELECT_OPTION:
            return {
                ...state,
                data: state.data?.filter(item => item.id !== action.payload)
            };
        case UPDATE_SELECT_OPTION:
            return {
                ...state,
                data: state.data?.map(item =>
                    item.id === action.payload.id ? action.payload : item
                )
            };
        default:
            return state;
    }
}