import { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Pagination, Navigation } from 'swiper/modules';
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {useMobile} from "../../hooks/useMobile";
import {FiBox, FiCheck, FiLock} from "react-icons/fi";

const BoxSwipper = ({ boxData }) => {

    const isMobile = useMobile();

    return (
        <Swiper
            slidesPerView={isMobile ? 1 : 3}
            spaceBetween={isMobile ? 15 : 55}
            grabCursor={true}
            pagination={false}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
        >
            {boxData.map(item =>
                <SwiperSlide key={item.id}>
                    <div className="flex flex-col gap-3 lg:p-10 p-6 border border-slate-200 rounded-2xl shadow-sm">
                        <div className="flex flex-col flex-wrap gap-10">
                            <div className="w-full flex flex-col gap-2">
                                <h3 className={`text-xl text-left font-medium mb-4 `}>
                                    {item.title}
                                </h3>

                                <span className="flex items-center justify-center h-8 w-8 border border-slate-500 bg-transparent order-first text-slate-500 rounded-full"><FiCheck /></span>
                                <ul className="flex flex-col gap-2">
                                    {item.list.map((m, index) =>
                                        <li key={index + 1}>
                                            <p className="text-slate-700 text-sm flex items-start gap-2">
                                                <span className="flex items-center justify-center">{
                                                    <CheckCircleOutlineIcon fontSize="smalle"/>}</span>{m}
                                            </p>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            )}

        </Swiper>
    );
};

export default BoxSwipper;
