import React, {useState} from "react";

// Design
import {Button, FormControlLabel, Grid, Radio} from "@mui/material";
import {motion, AnimatePresence} from "framer-motion";
import Alert from "@mui/material/Alert";


const CardItem = ({ searchQuery, networkData, searchList, nextItems, handleDrawerOpen }) => {

    const [lockedMessage, setLockedMessage ] = useState(false);

    // const handleLockMessage = () => {
    //     setLockedMessage(!lockedMessage)
    // }
    const handleMouseEnter = () => {
        setLockedMessage(true);
    };

    const handleMouseLeave = () => {
        setLockedMessage(false);
    };

    const handleClick = () => {
        handleDrawerOpen();
        lockedMessage && setLockedMessage(false)
    }

    return (
        <Grid container rowSpacing={2} columnSpacing={{ xs: 2 }} sx={{ margin: '20px 0' }}>
            {((searchQuery === '') ? networkData : searchList).slice(0, nextItems)?.map( network =>
                <Grid item key={network.id} xs={12} sm={4} lg={3}>

                    <AnimatePresence>
                        {lockedMessage && (
                            <motion.div
                                initial={{x: 40, y: 70, opacity: 0}}
                                animate={{x: 0, opacity: 1}}
                                transition={{duration: .4}}
                                exit={{x: 40, opacity: 0}}
                                className={`absolute z-10 w-max right-0 lg:right-auto`}
                            >
                                {network.isLocked && (
                                    <Alert severity="error"
                                           variant="filled"
                                           sx={{
                                               fontSize: '13px',
                                               fontWeight: '600',
                                               padding: '4px 10px',
                                               maxWidth: '300px'
                                           }}
                                    >
                                        <div dangerouslySetInnerHTML={{__html: network.lockMessage}}></div>
                                    </Alert>

                                )}
                            </motion.div>
                        )}
                    </AnimatePresence>

                    <motion.div
                        className="flex
                                   flex-col
                                   relative
                                   bg-zinc-50
                                   cursor-pointer
                                   rounded-md
                                   w-full
                                   p-1
                                   sm:max-w-[230px]"
                        whileHover={{scale: .95}}
                        transition={{type: "spring", stiffness: 100, duration: .5, delay: .1}}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                    >
                        <div>
                            <FormControlLabel control={
                                                        <>
                                                            {!network.isLocked && (
                                                                <>
                                                                    <Radio value={network.id}
                                                                           id={network.code}
                                                                           onClick={handleClick}
                                                                           className={`radio-item`}
                                                                    />
                                                                    {network.isDefault && (
                                                                        <div className={"default-tag bg-[#3383BB] rounded-sm"}>Default</div>
                                                                    )}
                                                                </>
                                                            )}

                                                            {network.isLocked && (
                                                                <Button onMouseEnter={handleMouseEnter}
                                                                        onMouseLeave={handleMouseLeave}
                                                                >
                                                                    <Radio value={network.id}
                                                                           id={network.code}
                                                                           className={`radio-item`}
                                                                           disabled
                                                                    />
                                                                </Button>
                                                            )}
                                                        </>
                                                        }
                                              label={network.name}
                                              labelPlacement="start"
                                              sx={{ maxWidth: { lg: '80%' } }}
                                              className={`card-item ${network.isLocked && "locked"}`}
                            />
                        </div>
                    </motion.div>
                </Grid>
            )}
        </Grid>
    )
}

export default CardItem;