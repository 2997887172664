import AxiosService from '../../../services/AxiosService'

const url = 'api/resource_event_types'
class ResourceEventTypeService {
    getAllResourceEventTypeFromResourceTypeId(resourceTypeId, token) {
        return AxiosService.getWithToken(
            `${url}?resourceType.id=${resourceTypeId}&exists[deletedAt]=false`,
            token,
            'jsonld'
        )
    }
    getResourceEventType(id, token) {
        return AxiosService.getWithToken(`${url}/${id}`, token, 'jsonld')
    }

    postResourceTypeWithToken(data, token) {
        data['token'] = token
        return AxiosService.postWithToken(`${url}`, data, 'jsonld')
    }
    putResourceTypeWithToken(data, id, token) {
        data['token'] = token
        return AxiosService.putWithToken(`${url}/${id}`, data, token, 'jsonld')
    }

    deleteResourceTypeEvent(id, token) {
        return AxiosService.deleteWithToken(`${url}/${id}`, token, 'jsonld')
    }
}
export default ResourceEventTypeService
