export const stringifyKeys = values =>
    Object.keys(values).reduce((result, key) => {
        result[`key${key}`] = values[key]
        return result
    }, {})

export const destringifyKeys = values =>
    Object.keys(values).reduce((result, key) => {
        result[Number(key.substring(3))] = values[key]
        return result
    }, {})

export const noSpaceToLower = (text) => {
    return text.toLowerCase().replace(/\s/g, '')
}

export const isValidEmail = (email) => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return regex.test(email);
}

// export const validateLogo = (validLogo) => {
//     const imageUrlRegex = /\.(jpeg|jpg|gif|png|svg)$/i;
//     return imageUrlRegex.test(validLogo);
// }
