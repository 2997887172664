import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

// Design
import {Box, Chip, MenuItem} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {CustomizedInputLabel, CustomizedSelect} from "./items/FormCustomizedInputs";
import {SlArrowDown} from "react-icons/sl";


export const FormListInfrastructure = ({ selectInfras, values, setCheckListInfrastructures }) => {


    /***** SELECT LIST INFRASTRUCTURE - VALUES MANAGEMENT *****/
    const [selectedInfrasOptions, setSelectedInfrasOptions] = useState([]);
    let { infraCode } = useParams();

    const handleChange = (event) => {

        const infrasSelection = event.target.value;
        setSelectedInfrasOptions(infrasSelection);
        localStorage.setItem("listInfrastructures", JSON.stringify(infrasSelection));

    };

    const formatListInfrastructuresValues = (listinfrastructures) => {
        const listInfrastructuresArray = [];

        for (let infra of listinfrastructures) {
            listInfrastructuresArray.push('api/infrastructures/' + infra);
        }

        return listInfrastructuresArray;
    }

    // Retrieve Values (if datas in Local Storage)
    useEffect(() => {

        if (values.token !== null && infraCode === undefined) {

            const infraFromStorage = localStorage.getItem("listInfrastructures");

            if (infraFromStorage && selectedInfrasOptions.length === 0) {

                const infraFromStorageArray = JSON.parse(infraFromStorage)
                setSelectedInfrasOptions([...infraFromStorageArray])

                values.listInfrastructures = formatListInfrastructuresValues(infraFromStorageArray)
            }
        }

    }, [values.token, infraCode]);

    // Values assigning
    useEffect(() => {

        values.listInfrastructures = formatListInfrastructuresValues(selectedInfrasOptions);

        selectedInfrasOptions.length !== 0 ? setCheckListInfrastructures(true) : setCheckListInfrastructures(false)

    }, [selectedInfrasOptions]);


    /***** PREFILL FROM CODE IN URL *****/
    // Retrieve Infrastructure Code
    useEffect(() => {

        if (infraCode !== undefined) {

            const infraCodeInUrl = selectInfras.find(item => item.code === infraCode);

            if (infraCodeInUrl && selectedInfrasOptions.length === 0) {

                const infraCodeId = infraCodeInUrl.id;

                values.listInfrastructures = formatListInfrastructuresValues([infraCodeId])
                setSelectedInfrasOptions([infraCodeId]);

                localStorage.setItem("listInfrastructures", JSON.stringify([infraCodeId]));
            }
        }

    }, [infraCode]);


    return (
        <div className="flex
                        mt-auto
                        relative
                        w-full
                        min-h-[60px]
                        mb-[20px]
                        lg:mb-0">
            {selectInfras && (
                <FormControl fullWidth sx={{ margin: 'auto 0 0' }}>
                    <CustomizedInputLabel shrink id="list_infrastructure" sx={{ position: 'relative' }}>
                        Installations list *
                    </CustomizedInputLabel>
                    <CustomizedSelect
                        id="list_infrastructure"
                        labelId="list_infrastructure"
                        name="listInfrastructures"

                        value={selectedInfrasOptions}
                        onChange={handleChange}

                        endAdornment={
                            <SlArrowDown className={"select-icon"} />
                        }
                        className={"select"}
                        multiple

                        MenuProps={{
                            sx: {
                                "&& .Mui-selected": {
                                    backgroundColor: "rgba(118, 137, 250, 0.26)"
                                },
                                "& .MuiMenuItem-root:hover": {
                                    backgroundColor: "rgba(0, 0, 0, 0.2)"
                                },
                            }
                        }}

                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={selectInfras.find(item => item.id === value).name} className="select-chip" />
                                ))}
                            </Box>
                        )}
                    >
                        {selectInfras.map((infra, index) => (
                            <MenuItem label={infra.name} value={ infra.id } key={index}>{ infra.name }</MenuItem>
                        ))}
                    </CustomizedSelect>
                </FormControl>
            )}
        </div>
    )
}
