import AxiosService from '../../../services/AxiosService'


const urlAllServiceTypes = "api/service_types/list/infra/"
const urlServiceTypes = "api/service_types"

class ServiceTypeService {

    getAllServiceTypeByInfraId(id, token) {
        return AxiosService.getWithToken(`${urlAllServiceTypes}${id}`, token, "jsonld");
    }

    getOneServiceTypeById(id, token) {
        return AxiosService.getWithToken(`${urlServiceTypes}/${id}`, token, "jsonld");
    }

    postServiceTypeWithToken(data, token) {
        data["token"] = token;
        return AxiosService.postWithToken(urlServiceTypes, data, "jsonld");
    }

    putServiceTypeWithToken(data, id, token) {
        return AxiosService.putWithToken(urlServiceTypes + "/" + id, data, token, "jsonld");
    }

    deleteServiceTypeWithToken(id, token) {
        return AxiosService.deleteWithToken(urlServiceTypes + "/" + id, token, "jsonld");
    }
}

export default ServiceTypeService;