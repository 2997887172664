import {AuthButtons} from "./auth/AuthButtons";
import {NavBar} from "../header/NavBar";
import {isLoginRoute} from "../../utils/variables";
import {LogoutBtn} from "../../features/auth/components/button/LogoutBtn";
import {ProfilBtn} from "../../features/auth/components/button/ProfilBtn";
import {useRef, useState} from "react";
import NavbarMiddle from "../../features/auth/components/header/NavbarMiddle";
import UserListTeamInfra from "../../features/user/components/UserListTeamInfra";
import {NavBarAuth} from "../header/NavBarAuth";

export const MainMenu = ({ show, setShow, isMobile, auth, userRoleInInfra, userListTeamInfra, currentPlatform }) => {

    const [openBox, setOpenBox] = useState(false);
    const profilMenu = useRef(null);

    return (
        <div className={`w-full z-20 flex items-center ${ isMobile && "flex-col pt-4"} navbar ${show ? 'show' : ''}`}>

            { (isLoginRoute && auth && currentPlatform) ?
                <NavBarAuth
                    show={show}
                    setShow={setShow}
                    isMobile={isMobile}
                />
                :
                <NavBar show={show} isMobile={isMobile} />
            }


            { isMobile && auth &&
                <>
                    <div className="mb-4 border border-slate-200 rounded-2xl bg-slate-50 min-h-56 w-11/12 text-slate-800 p-4 flex flex-col gap-4">
                        <ProfilBtn
                            openBox={openBox}
                            setOpenBox={setOpenBox}
                            profilMenu={profilMenu}
                            userRoleInInfra={userRoleInInfra}
                        />

                        <hr/>

                        <NavbarMiddle />
                    </div>

                    <UserListTeamInfra userListTeamInfra={userListTeamInfra} />

                    <LogoutBtn full/>
                </>
            }

            {/*{ auth && <UserCardInfos isOpen={true} profilMenu={profilMenu} /> }*/}
            {isLoginRoute && !auth && <AuthButtons/>}
        </div>
    )
}
