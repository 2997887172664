import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import FullCalendar from '@fullcalendar/react'
import { useFetchListEventByResourceType } from '../../../hooks/useFetchListEventByResourceType'
import { FormLoader } from '../../../../servicetype/loaders/Loaders'
import listPlugin from '@fullcalendar/list'

export const EventsCalendar = ({ resource }) => {
    const selectUser = createSelector(
        (state) => state.login.user,
        (user) => user
    )
    const userInfra = useSelector((state) => state.platformReducers.userInfra) ?? null
    const user = useSelector(selectUser)

    const { events, eventIsLoading } = useFetchListEventByResourceType(userInfra, resource, user.token)

    return eventIsLoading ? (
        <FormLoader />
    ) : (
        <FullCalendar
            plugins={[listPlugin]}
            initialView="listWeek"
            aspectRatio={1.8}
            headerToolbar={{
                left: 'prev,next',
                center: 'title',
                right: 'listWeek',
            }}
            locale="en"
            businessHours={{
                daysOfWeek: [1, 2, 3, 4, 5],
                startTime: '08:00',
                endTime: '20:00',
                allDay: false,
            }}
            firstDay={1}
            displayEventEnd={true}
            eventTimeFormat={{ hour: '2-digit', minute: '2-digit', meridiem: false, hour12: false }}
            events={events.map((event) => {
                return {
                    id: event.id,
                    title: event.title,
                    start: event.startingDate,
                    end: event.endingDate,
                    editable: true,
                    allDay: false,
                    backgroundColor: event.resourceEventType.color,
                }
            })}
        />
    )
}
