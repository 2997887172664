import {InputAdornment, TextField} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

export const SearchTerm = ({ searchTerm, setSearchTerm, placeHolder, network }) => {
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };


    return (
        <TextField
            id="search"
            label=""
            placeholder={placeHolder}
            autoComplete="off"
            value={searchTerm && searchTerm}
            fullWidth={ !!network }
            sx={{
                '& input': {
                    border: 'none', // Supprime les bordures
                    '&:focus': {
                        outline: 'none', // Supprime le focus
                    },
                },
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment
                        position="start"
                    >
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
            onChange={handleSearchChange}
        />

    )
}