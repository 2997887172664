import {useEffect, useState} from 'react';
import {SelectService} from "../services/Select";
import {useDispatch, useSelector} from "react-redux";

const useFetchSelectById = (id) => {

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const token = useSelector(state => state.login.user?.token);
    // const dispatch = useDispatch();

    useEffect(() => {
        fetchSelectById();
    }, [token, id])

    const fetchSelectById = async () => {

        if (id === null || id === undefined) return;

        setIsLoading(true);

        try {
            const selectService = new SelectService();
            const res = await  selectService.getSelectById(id, token);

            if (!res) throw new Error("Unable to fetch select id for select");

            // dispatch(fetchSelectConfigById(id));

            setData(res);
            setIsLoading(false);

        }catch (error) {
            setError(error);
            setIsLoading(false);
        }
    }
    return { data, isLoading, error, fetchSelectById };
};

export default useFetchSelectById;
