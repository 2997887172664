import React, {useEffect, useState} from "react";
import {Navigate} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {logout} from "../../../redux/user/authentificationAction";

import {removeInfrastructure} from "../../../redux/user/changeInfrastructureAction";
import {resetStore} from "../../../redux/resetStoreAction";
import {displayMessage} from "../../../redux/alertAction";

export const  Logout = () => {
    const [isDisconnected, setIsDisconnected] = useState(false);
    const isConnected = useSelector(state => state.isConnected);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(removeInfrastructure());
        dispatch(logout());
        setIsDisconnected(true);
        dispatch(resetStore());
        dispatch(displayMessage("Success", "You are well disconnected.", "success"))
    }, [dispatch]);

    return (
        <>
            {isDisconnected || !isConnected ?
                (<Navigate to="/" replace={true}/>)
                : ("Still connected ?")
            }
        </>
    );
}