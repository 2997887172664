import {CompCustom} from "../../CompCustom";

export const InstallationBox = ({ id, filters, networkId, setLengthofTabFilters }) => {

    return (
        <div className="wrapper flex flex-col items-start h-full relative">
            <CompCustom
                id={id}
                networkId={networkId}
                filters={filters}
                type="installation"
                setLengthofTabFilters={setLengthofTabFilters}
            />
        </div>
    )
}