import React from 'react';
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import AlertCommon from "../alert/AlertCommon";
import {Outlet} from "react-router-dom";


// Create selector
const alertSelector = createSelector(
    state => state.displayMessage && state.displayMessage.alert,
    alert => {
        // ... computed data from alert
        return {
            title: alert ? alert.title : null,
            message: alert ? alert.message : null,
            type: alert ? alert.type : null,
            isNew: alert ? alert.isNew : null,
        };
    }
);

const MessageLayout = () => {

    const alert = useSelector(alertSelector);

    return (
        <React.Fragment>
            {alert.isNew && (
                <AlertCommon
                    title={alert.title}
                    message={alert.message}
                    type={alert.type}

                />
            )}
            <Outlet/>
        </React.Fragment>
    );
}


export default MessageLayout;