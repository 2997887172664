import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmDialog({isOpen, setIsOpen, onAgree, onDisagree, onClose, title, content, textBtnConfirm, textBtnCancel}) {
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={`rounded-xl`}
        >
            <DialogTitle id="alert-dialog-title">
                {title ?? "Confirmation require"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {content ?? "Are you sur you want to delete this element ?"}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <div className="w-full flex justify-between items-center">
                    <button
                        className="h-10 border border-slate-300 rounded-sm bg-slate-100 text-slate-500 min-w-32"
                        onClick={() => {
                            setIsOpen(false);
                            if (typeof onDisagree === 'function') {
                                onDisagree();
                            }
                        }}
                    >Cancel
                    </button>
                    <button
                        className="h-10 border border-red-300 rounded-sm bg-red-500 text-slate-100 min-w-32"
                        onClick={() => {
                            setIsOpen(false);
                            if (typeof onAgree === "function") {
                                onAgree();
                            }
                        }}
                    >Delete
                    </button>
                </div>

            </DialogActions>
        </Dialog>
    );
}