import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import PropTypes from 'prop-types';

export const CustomButton = ({ title, color, variant, size, path, style, roundedborder, icon, width, catalog, absolutebtn }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if (catalog) {
            localStorage.removeItem("activeStep");
            localStorage.removeItem("selectedData");
        }

        return navigate(`/${path}`);
    }

    return (
        <Button
            variant={variant}
            endIcon={icon ? icon : ""}
            size={size}
            color={color}
            onClick={handleClick}
            style={{ color: style ? "#fafafa" : "#272727", borderRadius: `${roundedborder ? "50px" : "2px"}`, width: width && "100%", margin: "0", }}
            sx={{
                position: absolutebtn ? {xs:"absolute", md:"relative"} : "",
                bottom: absolutebtn ? {xs:"0"} : ""
            }}
        >
            { title }
        </Button>
    )
}
