export const UPDATE_INFRASTRUCTURE_FILTERS = 'UPDATE_INFRASTRUCTURE_FILTERS';
export const UPDATE_INFRASTRUCTURE_PAGE = 'UPDATE_INFRASTRUCTURE_PAGE';

export const updateInfrastructureFilters = (filters) => {
    return {
        type: UPDATE_INFRASTRUCTURE_FILTERS,
        payload: filters
    }
}

export const updateInfrastructurePage = (page) => {
    return {
        type: UPDATE_INFRASTRUCTURE_PAGE,
        payload: page
    }
}