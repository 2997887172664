import {BiMeteor} from "react-icons/bi";

const ConstructionPage = ({ namePage, soon }) => {
    return (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center justify-center gap-2 ">
            <span className="text-2xl text-slate-500 animate-pulse transition"><BiMeteor/></span>
            <p className={`text-sm text-slate-700`}>This <span className="text-slate-700 font-semibold underline">{namePage}</span> page {soon ? "Coming Soon..." : "on construction" } </p>
        </div>
    );
};

export default ConstructionPage;
