import React from "react";
import {motion} from "framer-motion";

export const Loader = ( { content, color, hfull, absLoad }) => {
    return (
        <div className={`${hfull ? 'h-full' : 'min-h-screen'} w-full flex items-center justify-center ${absLoad && "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"}`}>
            <div className="flex flex-col gap-2 items-center justify-center relative">

                <div className="honeycomb animate-pulse mb-10">
                    <div className="bg-blue-500"></div>
                    <div className="bg-blue-500"></div>
                    <div className="bg-blue-500"></div>
                    <div className="bg-blue-500"></div>
                    <div className="bg-blue-500"></div>
                    <div className="bg-blue-500"></div>
                    <div className="bg-blue-500"></div>
                </div>
                <motion.span
                    className="text-blue-500 text-sm text-center block animate animate-pulse"
                    initial={{ opacity:0, translateY:-25 }}
                    animate={{ opacity:1, translateY:0 }}
                    transition={{ type:"spring", duration:.6, ease:"easeInOut"}}
                >
                    {content ? content : "Loading, please wait a moment"}
                </motion.span>
            </div>

        </div>
    )
}