import {useEffect, useState} from "react";
import UserService from "../../../../services/UserService";

export const useFetchInfrastructureUsers = (infrastructureId, token, incrementList) => {

    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchUsersData = async () => {
            try {
                setIsLoading(true);
                if (infrastructureId > 0) {
                    const userService = new UserService();
                    const res = await userService.getListUserFromInfrastructure(infrastructureId, token, 'jsonld');
                    setUsers(res["hydra:member"] ?? []);
                }
            } catch (error) {
                setUsers([]);
                setError(error);
            }

            setIsLoading(false);
        }

        fetchUsersData().then();

    }, [infrastructureId, token, incrementList]);

    return {users, isLoading, error}
}