import React, {createContext, useContext, useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";

// Design
import { Grid, IconButton,} from "@mui/material";
import { GrFormAdd } from "react-icons/gr";
import {AnimatePresence, motion} from "framer-motion";

// Components
import {ListTypeFormsTableView} from "./ListTypeFormsTableView";

// Custom Hooks
import {useMobile} from "../../../../../../hooks/useMobile";

// Redux
import { reusableFormSlice } from "../../../../store/reusableFormSlice";

// Context
import { DescriptionFormContext } from "../../../../../../features/description/components/DescriptionFormContainer";

export const ListTypeFormContext = createContext(null)


export const ListTypeFormsContainer = ({ form, joinedInputs, selectOptions, preloadServices, values, setJoinedInputs, setState, isRead }) => {

    // Add new form design
    const [addFormShow, setAddFormShow ] = useState(false);
    const isMobile = useMobile();

    // General datas declarations
    const [initialJoinedInputsLength, setInitialJoinedInputsLength] = useState(joinedInputs.length);
    const mapListInputs = form.listInputs.map((listinput) => listinput);
    const chunkSize = mapListInputs.length;

    // The listInputsValues array from state MUST HAVE the same length as the total of inputs present in the form
    const retrievedListInputValues = values.listInputValues;

    // Redux
    const dispatch = useDispatch();
    const { selectListTypeFormsDatas, selectListTypeFormsVisual  } = reusableFormSlice.selectors
    const listTypeForms = useSelector(selectListTypeFormsDatas)
    const listInputsArray = useSelector(selectListTypeFormsVisual)

    // For Context
    const [cancelFieldsData, setCancelFieldsData] = useState(false);
    const [remainingLinesData, setRemainingLinesData] = useState(false);

    // useContext
    const descriptionFormContext = useContext(DescriptionFormContext);
    const {
        descriptionRetrieveData: descriptionRetrieveDataContext,
        setDescriptionRetrieveData: setDescriptionRetrieveDataContext,
    } = descriptionFormContext !== null && descriptionFormContext;

    // ListInputsValuesToDelete
    const [listInputsValuesToRemove, setListInputsValuesToRemove] = useState([]);

    // Add Default Forms
    const [retrieveDataDone, setRetrieveDataDone] = useState(false);



    /****** Displayed Array - OK  *******/
    const displayedArray = (arraytowork) => {

        /* We have to slice the arraytowork (list of objects) in multiple sub-arrays  */
        let listTypeFormArray = [];

        for (let i = 0; i < arraytowork.length; i += chunkSize) {
            const chunked = arraytowork.slice(i, i + chunkSize);

            /* push() : to add the first array */
            if (listInputsArray.length === 0) {

                // keyLinkNumber Management
                let chunkedWithKeyLink = chunked.concat({ keyLinkNumber : 0, isFirstEdition: true, formId: form.id })

                // Push
                listTypeFormArray.push(chunkedWithKeyLink)

            }

            /* toSpliced() : to insert the other arrays at a given index (here : listInputsArray.length) */
            else {

                // keyLinkNumber Management
                const lastFoundList = listInputsArray.findLast((element) => element);
                const lastKeyLinkNumber = lastFoundList.findLast((last) => last).keyLinkNumber
                let chunkedWithKeyLink = chunked.concat({ keyLinkNumber : lastKeyLinkNumber + 1, isFirstEdition: true, formId: form.id })

                // toSpliced
                listTypeFormArray = listInputsArray.toSpliced(listInputsArray.length, 0, chunkedWithKeyLink)

            }
        }

        // Redux - Add Visual Array
        dispatch(reusableFormSlice.actions.addListTypeFormsVisual([...listTypeFormArray]))

    }


    /*********** Add Inputs Management  - OK ***********/
    const handleAddFields = () => {

        let filledArray = [];

        /* concat() : to return a new array containing the inputs group merged into initial inputs */
        let newInputsList = joinedInputs.concat(mapListInputs)

        /* Loop to keep the indexes of newInputsList */
        for (let i = joinedInputs.length; i < newInputsList.length; i++) {
            mapListInputs.find((itemfind) => newInputsList[i] === itemfind) && filledArray.push({ data : newInputsList[i], indexValue : i })
        }

        /***** joinedInputs Update *****/
        setJoinedInputs(newInputsList)

        displayedArray(filledArray);

    }


    /*********** Add Default List Type Form On Load ***********/
    const retrieveExistingListFormsVisual = listInputsArray.map((item) => item.findLast((found) => found).formId);

    // No List Type Forms previously saved
    const addDefaultFormsWhenVisualEmpty = () => {
        handleAddFields();
        setRetrieveDataDone(false);
    }

    useEffect(() => {
        (retrieveDataDone && retrieveExistingListFormsVisual.length === 0) && addDefaultFormsWhenVisualEmpty();
    }, [listInputsArray, retrieveDataDone]);


    // List Type Forms previously saved
    const addDefaultFormsWhenVisualContainsData = () => {
        !retrieveExistingListFormsVisual.includes(form.id) ? handleAddFields() : setRetrieveDataDone(false);
    }

    useEffect(() => {
        (retrieveDataDone && retrieveExistingListFormsVisual.length !== 0) && addDefaultFormsWhenVisualContainsData();
    }, [joinedInputs, retrieveDataDone]);



    /*********** Remove Inputs Management - OK  ***********/
    const handleRemoveInputs = (indexform, listinputarray) => {

        /***** joinedInputs Update *****/
            // We have to update the total of inputs present in the form
        let removalJoinedInputs = [...joinedInputs];

        const updatedJoinedInputs = removalJoinedInputs.filter((value, removeindex) => (
            !(listinputarray.find((itemfind) => itemfind.data && itemfind.data['@id'] === value['@id']) && listinputarray.find((itemfind) => itemfind?.indexValue === removeindex)))
        );
        setJoinedInputs(updatedJoinedInputs);


        /***** Visual Array Update *****/
        // Update visual/local state (the array to display)
        const newListInputsArray = listInputsArray.map((item, index) =>
                index === indexform ? item.toSpliced(Number(item.length) - 1, 0, { isDeleted: true }) : item
            );


        /***** ListInputsValues Update *****/
        // Get the KeyLinkNumber to remove
        const removalKeyLinkNumber = listinputarray.findLast((last) => last).keyLinkNumber

        // Create an array to be filled with elements to delete (detected with uri && keyLink comparisons)
        let itemsToDelete = [];

        for (let i = initialJoinedInputsLength; i < retrievedListInputValues.length; i++) {
            if (listinputarray.find((itemfind) => itemfind.data && itemfind.data['@id'] === retrievedListInputValues[i].input) &&
                listinputarray.find((itemfind) => itemfind?.indexValue === i) &&
                retrievedListInputValues[i].keyLink === removalKeyLinkNumber) {
                itemsToDelete.push(retrievedListInputValues[i])
            }
        }

        // ListInputsValuesToDelete
        const inputValuesIds = itemsToDelete.map((item) => item.id && (item.id).toString());
        !inputValuesIds.includes(undefined) && setListInputsValuesToRemove([...listInputsValuesToRemove, ...inputValuesIds]);

        // Array to update general state
        let updatedListInputValues = [];

        updatedListInputValues = retrievedListInputValues.filter((value) => !itemsToDelete.includes(value));

        // Form State Update
        setState(prevState => ({
            ...prevState,
            project: {
                ...prevState.project,
                listInputValues: updatedListInputValues,
            }
        }));

        // indexValue Consistency in Displayed Array
        const indexValuesReload = [...newListInputsArray];

        const withRemoved = indexValuesReload.filter((element) => element.find((found) => found.isDeleted))

        const withRemaining = indexValuesReload.filter((element) => !element.find((found) => found.isDeleted))

        const modifiedIndexes = withRemaining.map((item) => item.filter((found) => !found.data))

        withRemaining.forEach((element) => {

            const keyLinkTarget = element.findLast((last) => last).keyLinkNumber
            const formIdTarget = element.findLast((last) => last).formId

            for (let u in updatedListInputValues) {

                modifiedIndexes.forEach((modified) => {

                    let updatedIndexesAfterRemove = []

                    if (modified.find((found) => found.keyLinkNumber === keyLinkTarget && found.formId === formIdTarget)) {

                        if (keyLinkTarget === updatedListInputValues[u].keyLink) {

                            element.forEach((item) => {
                                if (item.indexValue && (item.data['@id'] === updatedListInputValues[u].input)) {
                                    updatedIndexesAfterRemove.push({ data : item.data, indexValue : Number(u) })
                                }
                            })

                            // splice start -1 : start from 2nd element of array end => Allow right sorted input order
                            modifiedIndexes.find((found) => found.find((find) => find.keyLinkNumber === keyLinkTarget && find.formId === formIdTarget)).splice(-1, 0, ...updatedIndexesAfterRemove)
                        }

                    }

                })
            }

        })

        // Insert deleted datas on top of Visual Array
        const updatedIndexesArray = modifiedIndexes.toSpliced(0,0, ...withRemoved);

        // Redux - Update Visual Array after line removal
        dispatch(reusableFormSlice.actions.updateListTypeFormsVisual([...updatedIndexesArray]));

    }

    // Mainly use to Restore Data from LocalStorage AFTER a Line Removal
    useEffect(() => {
        setRemainingLinesData(true);
    }, [values.listInputValues]);

    // ListInputsValuesToDelete
    useEffect(() => {
        setState(prevState => ({
            ...prevState,
            project: {
                ...prevState.project,
                listInputValuesToDelete: listInputsValuesToRemove,
            }
        }));
    }, [listInputsValuesToRemove]);



    /****** Retrieve Saved Values *******/
    const flattenedMapListInputs = listTypeForms.flatMap((form) => form.listInputs.map((listinput) => listinput));

    const retrievedValuesTable = () => {

        let retrievedTable = [];
        let createTableCountArray = [];
        let concatToJoinedInputs = [];

        listTypeForms.forEach((listtype) => {

            // Loop to find the number of forms to create (Coming from a previous save)
            retrievedListInputValues.forEach((element, retrievedindex) => {
                if (listtype.listInputs.find((itemfind) => element.input === itemfind['@id'])) {

                    if (createTableCountArray.length === 0) {

                        // Push
                        createTableCountArray.push(Object.assign(element, { retrievedIndex: retrievedindex }))

                    } else {

                        // toSpliced
                        createTableCountArray = createTableCountArray.toSpliced(createTableCountArray.length, 0, Object.assign(element, { retrievedIndex: retrievedindex }))

                    }

                }
            })

            if(createTableCountArray.length !== 0) {

                // Sort to have same order between retrievedListInputValues and joinedInputs
                createTableCountArray.sort((a,b) => a.retrievedIndex - b.retrievedIndex)

                let newInputsList = [];

                for (let i = 0; i < createTableCountArray.length; i++) {
                    flattenedMapListInputs.forEach((flattened) => {
                        if(flattened['@id'] === createTableCountArray[i].input) {
                            newInputsList.push(flattened)
                        }
                    })
                }

                // Update the total of inputs present in the form
                concatToJoinedInputs = joinedInputs.concat(...newInputsList)
                setJoinedInputs(concatToJoinedInputs)

            }
        })

        for (let i = initialJoinedInputsLength; i < concatToJoinedInputs.length; i++) {

            if(flattenedMapListInputs.find((itemfind) => concatToJoinedInputs[i] === itemfind)) {

                listTypeForms.forEach((listtype) => {

                    if(listtype.listInputs.includes(concatToJoinedInputs[i])) {

                        retrievedTable.push({data: concatToJoinedInputs[i], indexValue: i, parentFormId: listtype.id})

                    }

                })

            }

        }

        if(retrievedTable.length !== 0) {
            // Find the KeyLinks from indexes in listInputsValues
            retrievedTable.forEach((displayed) => {

                for (let i = 0; i < retrievedListInputValues.length; i++) {
                    if (i === displayed.indexValue) {
                        Object.assign(displayed, {keyLink: retrievedListInputValues[i].keyLink})
                    }
                }

            })
        }

        // Group retrieveTable by KeyLink Number
        // Group By keyLink
        let groupedArrays = retrievedTable.reduce((accumulator, obj) => {
            let keyLink = obj.keyLink;
            if (!accumulator[keyLink]) {
                accumulator[keyLink] = [];
            }
            accumulator[keyLink].push(obj);
            return accumulator;
        }, {});


        let arrayOfGroupedArrays = Object.values(groupedArrays);

        // Group By Parent Form ID
        let groupByParentFormId = []

        // for (const tab of toTreat) {
        for (const array of arrayOfGroupedArrays) {
            const accumulated = array.reduce((accumulator, obj) => {
                let parentFormId = obj.parentFormId;
                if (!accumulator[parentFormId]) {
                    accumulator[parentFormId] = [];
                }
                accumulator[parentFormId].push(obj);
                return accumulator;
            }, {});

            groupByParentFormId.push(accumulated);
        }

        let finalGroupedArrays = groupByParentFormId.flatMap((item) => Object.values(item));

        let finalVisualArray = [];

        finalGroupedArrays.forEach((element) => {
            const findParentFormId = element.findLast((last) => last).parentFormId
            const retrievelKeyLinkNumber = element.findLast((last) => last).keyLink
            let chunkedWithKeyLink = element.concat({ keyLinkNumber : retrievelKeyLinkNumber, isFirstEdition: false, formId: findParentFormId })

            finalVisualArray.push(chunkedWithKeyLink)
        })

        // Redux - Update Visual Array with saved ListInputValues consistency
        dispatch(reusableFormSlice.actions.updateListTypeFormsVisual([...finalVisualArray]))

        // DESCRIPTIONS - Prevent retrievedValuesTable() to be launched more than once
        if (descriptionFormContext !== null && values.listInputValues.length !== 0) {
            // setDescriptionRetrieveDataContext(false)
            listInputsArray.length !== 0 && setDescriptionRetrieveDataContext(false);
        }

        setRetrieveDataDone(true);
    }

    // PPA (Case : listInputValues set/provided directly in API response)
    useEffect(() => {
        (values.token !== null || !descriptionRetrieveDataContext) && retrievedValuesTable();
    }, []);

    // DESCRIPTIONS (Case : listInputValues set during data treatment with mandatory deepCopy)
    useEffect(() => {
        if (descriptionRetrieveDataContext && values.listInputValues.length !== 0) {
            listInputsArray.length === 0 && retrievedValuesTable();
        }
    }, [descriptionRetrieveDataContext, values.listInputValues]);



    /****** Save Inputs - OK *******/
    const handleSaveInputs = (savekeylink) => {

        const newListInputsArray = [...listInputsArray];

        const updatedFirstEdit = newListInputsArray.map(
            (list) => (list.findLast((last) => last).keyLinkNumber === savekeylink) ? [...list, { keyLinkNumber : savekeylink, isFirstEdition: false, formId: form.id }] : [...list]
        );

        // Redux - Save Visual Array
        dispatch(reusableFormSlice.actions.updateListTypeFormsVisual(updatedFirstEdit))

        // For Cancel feature
        let saveInLocalStorageTable = [];

        for (let i = initialJoinedInputsLength; i < retrievedListInputValues.length; i++) {
            saveInLocalStorageTable.push(retrievedListInputValues[i])
        }

        // Save in local storage
        localStorage.setItem("listTypeItem", JSON.stringify(saveInLocalStorageTable));
    }


    // ON LOAD => Remove listTypeItem from Local Storage
    useEffect(() => {
        localStorage.listTypeItem && localStorage.removeItem("listTypeItem");
    }, [])



    /****** Cancel Last Inputs - OK  ******/
    const handleCancelInputs = (indexform) => {

        setCancelFieldsData(true);

        const listTypeItemFromStorage = localStorage.getItem("listTypeItem");
        const listTypeItemFromStorageArray = JSON.parse(listTypeItemFromStorage)

        const selectedKeyLinkNumber = listInputsArray[indexform].findLast((last) => last).keyLinkNumber;

        const previousListInputValues = values.listInputValues;

        for (let storage in listTypeItemFromStorageArray) {

            // For listInputValues that matches with items in Local Storage
            for (let listinputvalue in previousListInputValues) {

                // Check same inputs uris + same keyLinks
                if((listTypeItemFromStorageArray[storage].input === previousListInputValues[listinputvalue].input) &&
                    (previousListInputValues[listinputvalue].keyLink === selectedKeyLinkNumber) &&
                    (listTypeItemFromStorageArray[storage].keyLink === selectedKeyLinkNumber)) {

                    // Replace listInputValues "value" with Local Storage one
                    previousListInputValues[listinputvalue].value = listTypeItemFromStorageArray[storage].value

                }

            }
        }

        // Reset State with previous datas
        setState(prevState => ({
            ...prevState,
            project: {
                ...prevState.project,
                listInputValues: previousListInputValues,
            }
        }));
    }



    return (
        <Grid container rowSpacing={3} columnSpacing={{xs: 2}} sx={{ margin: '0', padding: '0 10px' }} className={`list-type-forms ${isRead ? 'disabled' : ''}`}>

            {listInputsArray.length !== 0 && (
                <>
                    {listInputsArray.map((listinputarray, listindexarray) => (
                        <React.Fragment key={listindexarray}>

                            {!listinputarray.find((found) => found.isDeleted) && (
                                <>
                                    {(listinputarray[listinputarray.length - 1].formId === form.id) && (

                                        <ListTypeFormContext.Provider value={{ cancelFieldsData, setCancelFieldsData, remainingLinesData, setRemainingLinesData }}>

                                            <ListTypeFormsTableView initialJoinedInputsLength={initialJoinedInputsLength}
                                                                    mapListInputs={mapListInputs}

                                                                    listinputarray={listinputarray}
                                                                    listindexarray={listindexarray}
                                                                    keyLinkValue={listinputarray[listinputarray.length - 1].keyLinkNumber}

                                                                    handleSaveInputs={handleSaveInputs}
                                                                    handleRemoveInputs={handleRemoveInputs}
                                                                    handleCancelInputs={handleCancelInputs}

                                                                    selectOptions={selectOptions}
                                                                    formId={form.id}
                                                                    preloadServices={preloadServices}
                                                                    values={values}
                                                                    isRead={isRead}

                                                                    // Fix for parentFormId
                                                                    listTypeForms={listTypeForms}
                                            />

                                        </ListTypeFormContext.Provider>

                                    )}
                                </>
                            )}

                        </React.Fragment>
                    ))}
                </>
            )}

            <Grid item xs={12} sx={{ display: 'flex', margin: '0 0 0 auto', justifyContent: 'flex-end' }}>
                <div>
                    <IconButton onClick={() => handleAddFields()}
                                onMouseEnter={() => setAddFormShow(true)}
                                onMouseLeave={() => setAddFormShow(false)}
                                aria-label="Add"
                                sx={{
                                    '&:hover': { background: 'transparent' }
                                }}
                                disableRipple
                    >
                        <AnimatePresence>
                            {(addFormShow || isMobile) && (
                                <motion.div initial={{x: 80, opacity: 0}}
                                            animate={{x: 20, opacity: 1}}
                                            transition={{duration: .2}}
                                            exit={{x: 80, opacity: 0}}
                                >
                                    <div className={'font-semibold text-base text-stone-500 bg-white rounded-l-3xl py-3 pl-6 pr-10'}>
                                        Add element to the list
                                    </div>
                                </motion.div>
                            )}
                        </AnimatePresence>

                        <motion.div whileHover={{scale: 1.04}}
                                    className="flex items-center justify-center z-10 h-14 w-14 p-2 rounded-full bg-slate-50/90 border border-solid border-slate-400 hover:shadow-lg shadow-stone-400"
                        >
                            <span className="text-stone-500 p-2 rounded-full bg-slate-50/90 border border-solid border-slate-300">
                                <GrFormAdd/>
                            </span>
                        </motion.div>
                    </IconButton>
                </div>
            </Grid>

        </Grid>
    )
}