import {Outlet} from "react-router-dom";
import {BackButton} from "../button/BackButton";

export const LayoutWithoutHeaderFooter = () => {
    return (
        <main>
            <BackButton />
            <Outlet/>
        </main>
    )
}