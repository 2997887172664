import { useForm } from 'react-hook-form'
import { createSelector } from 'reselect'
import { useDispatch, useSelector } from 'react-redux'
import { useContext, useEffect, useState } from 'react'
import { DrawerTemporaryContext } from '../../../../components/drawer/DrawerTemporary'
import { Loader } from '../../../../components/loader/Loader'
import { FormLoader } from '../../../servicetype/loaders/Loaders'
import { useFetchResourceEventType } from '../../hooks/useFetchResourceEventType'
import { TiTick } from 'react-icons/ti'
import { displayMessage } from '../../../../redux/alertAction'
import ResourceEventTypeService from '../../services/ResourceEventType'
import ConfirmDialog from '../../../../components/confirm/ConfirmDialog'

const components = {
    DropdownIndicator: null,
}

const selectUser = createSelector(
    (state) => state.login.user,
    (user) => user
)

const selectCurrentInfrastructure = createSelector(
    (state) => state.currentInfrastructure,
    (ci) => ci
)

export function ResourceEventTypeForm(resourceType) {
    const user = useSelector(selectUser)

    const dispatch = useDispatch()

    const context = useContext(DrawerTemporaryContext)
    const method = context.method ?? 'CREATE'
    const isCreate = method === 'CREATE'
    const isUpdate = method === 'UPDATE'
    const [submitting, setSubmitting] = useState(false)
    const [openConfirmation, setOpenConfirmation] = useState(false)

    const {
        control,
        register,
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { errors },
        getValues,
    } = useForm({
        defaultValues: {
            name: '',
            color: '#2d7ef5',
            isAvailableTicket: false,
            isAvailable: false,
            notifyProject: false,
        },
    })

    const [isLoading, setIsLoading] = useState(true)
    const { resourceEventType } = useFetchResourceEventType({
        resourceTypeId: context.idSelected,
        setIsLoading,
    })
    useEffect(() => {
        if (!isLoading) {
            if (null !== resourceEventType) {
                setValue('name', resourceEventType?.name ?? '')
                setValue('color', resourceEventType?.color ?? '#2d7ef5')
                setValue('isAvailable', resourceEventType?.isAvailable ?? false)
                setValue('isAvailableTicket', resourceEventType?.isAvailableTicket ?? false)
                setValue('notifyProject', resourceEventType?.notifyProject ?? false)
            } else {
                reset({
                    name: '',
                    color: '#2d7ef5',
                    isAvailableTicket: false,
                    isAvailable: false,
                    notifyProject: false,
                })
            }
        }
    }, [isLoading, resourceEventType])

    const onSubmit = async (data) => {
        try {
            setSubmitting(true)
            if (isCreate || isUpdate) {
                const requestData = {
                    name: data.name,
                    color: data.color,
                    isAvailable: data.isAvailable,
                    isAvailableTicket: data.isAvailableTicket,
                    notifyProject: data.notifyProject,
                    resourceType: `/api/resource_types/${resourceType.resourceType.id}`,
                }

                const resourceEventTypeService = new ResourceEventTypeService()
                if (isCreate) {
                    await resourceEventTypeService.postResourceTypeWithToken(requestData, user.token)
                } else if (isUpdate) {
                    await resourceEventTypeService.putResourceTypeWithToken(requestData, context.idSelected, user.token)
                }

                reset({
                    name: '',
                    color: '',
                })

                dispatch(displayMessage('Success', isCreate ? 'Element created' : 'Element updated', 'success'))
                context.onDrawerClose()
            }
            setSubmitting(false)
        } catch (error) {
            setSubmitting(false)
            dispatch(displayMessage(error.data.title, error.data.detail, 'error'))
        }
    }

    const handleDelete = async () => {
        try {
            const resourceEventTypeService = new ResourceEventTypeService()
            setSubmitting(true)
            resourceEventTypeService.deleteResourceTypeEvent(resourceEventType.id, user.token).then(() => {
                reset({})
                setSubmitting(false)

                dispatch(
                    displayMessage(
                        'Success',
                        `The resource type event '${resourceEventType.name}' has been deleted`,
                        'success'
                    )
                )

                context.onDrawerClose()
            })
        } catch (error) {
            setSubmitting(false)
            dispatch(displayMessage(error.data.title, error.data.detail, 'error'))
        }
    }

    const colors = [
        {
            id: 1,
            value: '#2d7ef5',
            classCss: 'bg-[#2d7ef5]',
        },
        {
            id: 2,
            value: '#02b991',
            classCss: 'bg-[#02b991]',
        },
        {
            id: 3,
            value: '#272727',
            classCss: 'bg-[#272727]',
        },
        {
            id: 4,
            value: '#e1a64f',
            classCss: 'bg-[#e1a64f]',
        },
        {
            id: 5,
            value: '#9d61f8',
            classCss: 'bg-[#9d61f8]',
        },
        {
            id: 6,
            value: '#ef6d6d',
            classCss: 'bg-[#ef6d6d]',
        },
    ]
    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8">
            {submitting ? (
                <Loader content="Submitting" />
            ) : (
                <>
                    {/* 01 */}
                    {isLoading ? (
                        <FormLoader />
                    ) : (
                        <>
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col gap-4">
                                    <div>
                                        <h3 className="text-xl font-bold mb-2 tracking-wide required">
                                            Event type name
                                        </h3>
                                    </div>
                                    <div className="w-full">
                                        <input
                                            type="text"
                                            placeholder="Event type name"
                                            {...register('name', {
                                                required: 'Event type name is required',
                                            })}
                                            className="border border-slate-300 border-solid rounded-sm w-full grow h-12 p-4 focus:outline-none focus:ring focus:ring-blue-200"
                                        />
                                        {errors.name && !watch('name') && (
                                            <span className={'text-sm text-red-500/70'} role="alert">
                                                {errors.name.message}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h3 className="text-xl font-bold tracking-wide required">Color</h3>
                            </div>
                            <div className="flex flex-col items-start gap-4">
                                <ul className={'flex gap-2'}>
                                    {colors.map((item, key) => (
                                        <li key={key}>
                                            <label
                                                className={`${item.classCss} block w-10 h-10 content-center align-center z-2 cursor-pointer`}
                                            >
                                                {item.value === watch('color') ? (
                                                    <TiTick className="fill-white ml-2.5 h-5 w-5" />
                                                ) : (
                                                    ''
                                                )}
                                                <input
                                                    type="radio"
                                                    placeholder="Color"
                                                    value={item.value}
                                                    {...register('color', {
                                                        required: 'Color is required',
                                                    })}
                                                    className="sr-only"
                                                />
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                                {errors.color && !watch('color') && (
                                    <span className={'text-sm text-red-500/70'} role="alert">
                                        {errors.color.message}
                                    </span>
                                )}
                            </div>
                            <ul className="flex flex-col gap-2 bg-white border border-slate-200 rounded-xl p-4">
                                <li className="flex justify-between gap-2 w-full text-sm text-slate-500">
                                    <div className="w-1/2 grow">
                                        <label htmlFor="isAvailable" className="flex items-center gap-1">
                                            {' '}
                                            <span className="text-sm"></span>
                                            Available
                                        </label>
                                    </div>
                                    <div className=" text-blue-500 flex items-center gap-2">
                                        <label
                                            htmlFor="isAvailable"
                                            className={`relative w-10 h-6 flex items-center justify-center rounded-full cursor-pointer text-xs font-medium border px-1 ${watch('isAvailable') ? 'text-orange-400 bg-orange-100 border-orange-300' : 'text-green-400 bg-green-100 border-green-300'}`}
                                        >
                                            <input
                                                type="checkbox"
                                                id="isAvailable"
                                                {...register('isAvailable')}
                                                className="sr-only"
                                            />

                                            <span
                                                className={`${watch('isAvailable') ? 'border-orange-400 left-0' : 'right-0 border-green-400'} transition ease-linear h-6 w-6 rounded-full bg-white shadow-sm border absolute top-1/2 transform -translate-y-1/2`}
                                            ></span>
                                        </label>
                                    </div>
                                </li>
                                <li className="flex justify-between gap-2 w-full text-sm text-slate-500">
                                    <div className="w-1/2 grow">
                                        <label htmlFor="isAvailableTicket" className="flex items-center gap-1">
                                            {' '}
                                            <span className="text-sm"></span>
                                            Is Available Ticket
                                        </label>
                                    </div>
                                    <div className=" text-blue-500 flex items-center gap-2">
                                        <label
                                            htmlFor="isAvailableTicket"
                                            className={`relative w-10 h-6 flex items-center justify-center rounded-full cursor-pointer text-xs font-medium border px-1 ${watch('isAvailableTicket') ? 'text-orange-400 bg-orange-100 border-orange-300' : 'text-green-400 bg-green-100 border-green-300'}`}
                                        >
                                            <input
                                                type="checkbox"
                                                id="isAvailableTicket"
                                                {...register('isAvailableTicket')}
                                                className="sr-only"
                                            />

                                            <span
                                                className={`${watch('isAvailableTicket') ? 'border-orange-400 left-0' : 'right-0 border-green-400'} transition ease-linear h-6 w-6 rounded-full bg-white shadow-sm border absolute top-1/2 transform -translate-y-1/2`}
                                            ></span>
                                        </label>
                                    </div>
                                </li>
                                <li className="flex justify-between gap-2 w-full text-sm text-slate-500">
                                    <div className="w-1/2 grow">
                                        <label htmlFor="notifyProject" className="flex items-center gap-1">
                                            {' '}
                                            <span className="text-sm"></span>
                                            Notify project
                                        </label>
                                    </div>
                                    <div className=" text-blue-500 flex items-center gap-2">
                                        <label
                                            htmlFor="notifyProject"
                                            className={`relative w-10 h-6 flex items-center justify-center rounded-full cursor-pointer text-sm font-medium border px-1 ${watch('notifyProject') ? 'text-orange-400 bg-orange-100 border-orange-300' : 'text-green-400 bg-green-100 border-green-300'}`}
                                        >
                                            <input
                                                type="checkbox"
                                                id="notifyProject"
                                                {...register('notifyProject')}
                                                className="sr-only"
                                            />

                                            <span
                                                className={`${watch('notifyProject') ? 'border-orange-400 left-0' : 'right-0 border-green-400'} transition ease-linear h-6 w-6 rounded-full bg-white shadow-sm border absolute top-1/2 transform -translate-y-1/2`}
                                            ></span>
                                        </label>
                                    </div>
                                </li>
                            </ul>
                            <input
                                type="submit"
                                value={isCreate ? 'Create' : 'Update'}
                                className="py-3 px-4 bg-blue-500 text-white text-sm hover: rounded-full max-w-1/6 cursor-pointer"
                            />
                            {isUpdate ? (
                                <>
                                    <input
                                        type="button"
                                        value="Delete"
                                        onClick={() => {
                                            setOpenConfirmation(true)
                                        }}
                                        className="py-3 ml-2 px-4 bg-red-500 text-white text-sm hover: rounded-full max-w-1/6 cursor-pointer"
                                    />
                                    <ConfirmDialog
                                        isOpen={openConfirmation}
                                        onClose={() => setOpenConfirmation(false)}
                                        setIsOpen={setOpenConfirmation}
                                        onAgree={() => handleDelete()}
                                    />
                                </>
                            ) : null}
                        </>
                    )}
                </>
            )}
        </form>
    )
}
