import AxiosService from "./AxiosService";


const urlAccesses = "api/accesses"
const urlAccessesInfra = "api/accesses/infrastructure"
class AccessesService {

    getAccessesById(id, token) {
        return AxiosService.getWithToken(urlAccessesInfra + "/" + id, token, "jsonld");
    }

    postAccessWithToken(data, token) {
        data["token"] = token;
        return AxiosService.postWithToken(urlAccesses, data,  "jsonld");

    }

}

export default AccessesService;