import {createTheme} from "@mui/material";

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});
export const MuiTheme = createTheme({
    components: {
        MuiAccordion: {
            styleOverrides: {
                root: {
                    boxShadow: "none"
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    boxShadow: "none",
                    fontWeight: 500,
                    transition:"250ms ease",
                    padding:"0.65rem 1.5rem",
                    '&:hover': {
                        boxShadow: "none",
                        transform: "scale(.945)"
                    },

                    margin: "0.25rem",

                }
            }
        },
        MuiButtonGroup: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    border: "solid 1px #e5e7eb",
                    boxShadow: "none",
                    padding: "1rem"
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {},
                filled: {
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    // neutral 10
                    borderColor: "#e5e7eb",
                }
            }
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    borderBottom: '1px solid #e5e7eb',
                },
                indicator: {
                    backgroundColor: "#017B79",
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    padding: '0 8px',
                    textTransform: 'none',
                },
                textColorPrimary: {
                    fontWeight: 400,
                    "&.Mui-selected": {},
                },

            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    backgroundColor: "#fff"
                }
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    fontWeight: 400,
                    textTransform: 'none',
                    "&.Mui-selected": {
                        color: "#000000",
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 2,
                },
                notchedOutline: {},
            }
        },
    },
    palette: {
        primary: {
            main: '#3380FF',
            light: '#5986D1',
        },
        secondary: {
            main: '#054241',
            light: '#0B767F',
        },
        other:{
            main:"#f39c12"
        },
        neutral: {
            main: '#6b7280',
        },
        warning: {
            main: '#FFFBDB',
        },
        error: {
            // main: '#FE5353',
            main: '#e53935', // Mui red[600]
            light: '#FFD6D6',
        },
        success: {
            // main: '#6df14f',
            // light: '#EDFFD6'
            main: '#2e7d32',
            light: '#4caf50',
            dark: '#1b5e20'
        },
        dark:{
            main: '#000',
            light: '#272727'
        }
    },
    typography: {
        h1: {
            fontSize: '3.0em',
            fontFamily: '"poppins", sans-serif',
            fontWeight:'900'
        },
        h2: {
            fontFamily: '"poppins", sans-serif',
            fontSize: '2.4em',
            fontWeight:'600'
        },
        h3: {
            fontFamily: '"poppins", sans-serif',
            fontSize: '1.2rem',
            fontWeight:"500",
            marginBottom:'10px'
        },
        body1: {
            // fontFamily: '"Inter", sans-serif',
            fontFamily: '"Lato", sans-serif',
            fontSize: '1.0rem',
            // allow override from tailwind
            fontWeight: null
        },
        body2: {
            // fontFamily: '"Inter", sans-serif',
            fontFamily: '"Lato", sans-serif',
            fontWeight: null,
            fontSize:'.945rem'
        },
        subtitle1: {
            // fontFamily: '"Inter", sans-serif',
            fontFamily: '"Lato", sans-serif',
            fontSize: '1.2em',
            fontWeight:600
        },
        subtitle2: {
            // fontFamily: '"Inter", sans-serif',
            fontFamily: '"Lato", sans-serif',
        },
        button: {
            // fontFamily: '"Inter", sans-serif',
            fontFamily: '"Lato", sans-serif',
        },
        caption: {
            // fontFamily: '"Inter", sans-serif',
            fontFamily: '"Lato", sans-serif',
        },
        overline: {
            // fontFamily: '"Inter", sans-serif',
            fontFamily: '"Lato", sans-serif',
        },
        /* CUSTOM */
        display1: {
            fontSize: '5.1625em',
            fontFamily: '"Lora", serif',
        },
        display2: {
            fontSize: '4.3em',
            fontFamily: '"Lora", serif',
        },
        ppaHead: {
            fontFamily: '"Poppins", sans-serif',
            fontSize: '.9em',
            fontWeight: 500,
            [theme.breakpoints.up('md')]: {
                fontSize: '1.25em',
            },
        },
        "h1-sans": {
            // fontFamily: '"Inter", sans-serif',
            fontFamily: '"Lato", sans-serif',
            fontSize: '3.6em',
            fontWeight: 600,
        },
        "h2-sans": {
            // fontFamily: '"Inter", sans-serif',
            fontFamily: '"Lato", sans-serif',
            fontSize: '2.9em',
            fontWeight: 600,
        },
        "h3-sans": {
            // fontFamily: '"Inter", sans-serif',
            fontFamily: '"Lato", sans-serif',
            fontWeight: 600,
            fontSize: '2.4em',
        },
        "h4-sans": {
            // fontFamily: '"Inter", sans-serif',
            fontFamily: '"Lato", sans-serif',
            fontWeight: 600,
            fontSize: '2.1em',
        },
        "h5-sans": {
            // fontFamily: '"Inter", sans-serif',
            fontFamily: '"Lato", sans-serif',
            fontWeight: 500,
            fontSize: '1.7em',
        },
        "h6-sans": {
            // fontFamily: '"Inter", sans-serif',
            fontFamily: '"Lato", sans-serif',
            fontWeight: 500,
            fontSize: '1.4em',
        },
        "h1-poppins": {
            fontFamily: '"Poppins", sans-serif',
            fontSize: '2.9em',
            fontWeight: 700,
        },
        "h2-poppins": {
            fontFamily: '"Poppins", sans-serif',
            fontSize: '1.6em',
            fontWeight: 700,
            [theme.breakpoints.up('md')]: {
                fontSize: '2.5em',
            },
        },
        "h3-poppins": {
            fontFamily: '"Poppins", sans-serif',
            fontSize: '1.2em',
            fontWeight: 700,
            [theme.breakpoints.up('md')]: {
                fontSize: '1.4em',
            },
        },
        "h4-poppins": {
            fontFamily: '"Poppins", sans-serif',
            fontSize: '1em',
            fontWeight: 400,
            [theme.breakpoints.up('md')]: {
                fontSize: '1em',
            },
        },
        // Paragraph
        large: {
            // fontFamily: '"Inter", sans-serif',
            fontFamily: '"Lato", sans-serif',
            fontSize: '1.125rem',
        },
        regular: {
            // fontFamily: '"Inter", sans-serif',
            fontFamily: '"Lato", sans-serif',
            fontSize: '1rem',
            [theme.breakpoints.down('lg')]: {
                fontSize: '0.889rem',
            },
        },
        small: {
            // fontFamily: '"Inter", sans-serif',
            fontFamily: '"Lato", sans-serif',
            fontSize: '0.889rem',
        },
        tiny: {
            // fontFamily: '"Inter", sans-serif',
            fontFamily: '"Lato", sans-serif',
            fontSize: '0.79rem',
        },
        "subtitle1-medium": {
            // fontFamily: '"Inter", sans-serif',
            fontFamily: '"Lato", sans-serif',
            fontWeight: 500,
            fontSize: '1.2em'
        },
        "subtitle1-semibold": {
            // fontFamily: '"Inter", sans-serif',
            fontFamily: '"Lato", sans-serif',
            fontWeight: 600,
            fontSize: '1.2em'
        },
        "subtitle1-bold": {
            // fontFamily: '"Inter", sans-serif',
            fontFamily: '"Lato", sans-serif',
            fontWeight: 700,
            fontSize: '1.2em'
        },
        "body1-medium": {
            // fontFamily: '"Inter", sans-serif',
            fontFamily: '"Lato", sans-serif',
            fontWeight: 500,
            fontSize: '1em'
        },
        "body1-semibold": {
            // fontFamily: '"Inter", sans-serif',
            fontFamily: '"Lato", sans-serif',
            fontWeight: 600,
            fontSize: '1em'
        },
        "body1-bold": {
            // fontFamily: '"Inter", sans-serif',
            fontFamily: '"Lato", sans-serif',
            fontWeight: 700,
            fontSize: '1em'
        },
        // fontFamily: '"Inter", sans-serif',
        fontFamily: '"Lato", sans-serif',
        fontSize: 16,
    },
});