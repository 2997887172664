import { createSelector } from 'reselect'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Loader } from '../../../../components/loader/Loader'
import { AnimatePresence } from 'framer-motion'
import { ResourceInfosFormContainer } from './ResourceInfosFormContainer'
import { useFetchDescriptions } from '../../../description/hooks/useFetchDescriptionData'
import './assets/scss/_Resource.scss'

const selectUser = createSelector(
    (state) => state.login.user,
    (user) => user,
)

export const Info = ({ resource }) => {

    const [increment, setIncrement] = useState(0)
    const user = useSelector(selectUser)

    const {
        isDescriptionsDataLoading,
        descriptionsData,
        setDescriptionSubmitted,
    } = useFetchDescriptions('resource', resource.id, user?.token)

    return (
        <AnimatePresence>
            <div id="resource-infos">
                {isDescriptionsDataLoading ? (
                    <Loader content="Loading" />
                ) : (
                    <>
                        {(!isDescriptionsDataLoading && descriptionsData.length !== 0) && (
                            <ResourceInfosFormContainer
                                resourceInfos={descriptionsData}
                                setDescriptionSubmitted={setDescriptionSubmitted}
                                isRead={!resource.canManage}
                                increment={increment}
                            />
                        )}
                    </>
                )}
            </div>
        </AnimatePresence>
    )
}