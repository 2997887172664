import React from 'react';
import {Link} from "react-router-dom";
import {NavbarMiddleLink} from "../../utils/data";
import {useMobile} from "../../../../hooks/useMobile";

const NavbarMiddle = () => {

    const isMobile = useMobile();
    
    return (
        <ul className="flex flex-col">
            {NavbarMiddleLink.map(item =>
                <li key={item.id} className={`h-12  ${ isMobile ? "p-2" : "hover:bg-slate-50 p-4"}`}>
                    <Link to={item.link} title={item.title}
                          className="flex items-center gap-2 ">
                        <span className="text-slate-500">{item.icon}</span>{item.title}
                    </Link>
                </li>
            )}
        </ul>
    );
};

export default NavbarMiddle;