import { useState, useEffect } from 'react';
import InfrastructureService from "../service/InfrastructureService";
import {fetchCatalog} from "../../../redux/catalog/catalogActions";
import {useDispatch} from "react-redux"; // Assurez-vous d'importer le service News approprié

export const useFetchAllInstallationsByNetwork = (id, page, itemsPerPage, filter, list) => {

    const dispatch = useDispatch();

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [totalItems, setTotalItems] = useState([]);

    useEffect(() => {
        async function fetchNetworkInfra() {
            try {
                const installation = new InfrastructureService()

                const res = filter.length > 0
                    ? await installation.getInstallationByNetworkIdWithFilter(id, page, itemsPerPage, filter, list)
                    : await installation.getInfrastructureNetworkById(id, page, itemsPerPage, list);

                dispatch(fetchCatalog(res["hydra:member"]));

                setData(res["hydra:member"]);
                setTotalItems(res["hydra:totalItems"]);
                setIsLoading(false);
            } catch (error) {
                setError(error);
                setIsLoading(false);
            }
        }

        fetchNetworkInfra();

    }, [id, page, itemsPerPage, filter, list]);

    const numberItem =  data?.length;

    return { data, totalItems, isLoading, error, numberItem };
}
