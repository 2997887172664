export const FETCH_ALL_PLATFORM = 'FETCH_ALL_PLATFORM';

export const fetchAllPlatform = (data) => ({
    type: FETCH_ALL_PLATFORM,
    payload: data
})

export const FETCH_USER_INFRA = 'FETCH_USER_INFRA';

export const  fetchUserSelect = (data) => ({
    type: FETCH_USER_INFRA,
    payload: data
})

export const RESET = 'RESET';

export const reset = () => ({
    type: RESET
})