import {useEffect, useState} from "react";
import NetworkService from "../../../services/NetworkService";

export const useFetchNetworkById = (id) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {

        if(!id) return;

        async function fetchOneNetwork() {
            try {
                const networkService = new NetworkService()
                const res = await networkService.getNetworkById(id);
                setData(res);
                setIsLoading(false);
            } catch (error) {
                setError(error);
                setIsLoading(false);
            }
        }

        fetchOneNetwork();

    }, [id]);

    return { data, isLoading, error };
}
