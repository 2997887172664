import { FiHome, FiShield } from 'react-icons/fi'
import React, { useEffect, useState } from 'react'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { Loader } from '../../../components/loader/Loader'
import InstallationsSuperAdmin from '../components/InstallationsSuperAdmin'
import UsersSuperAdmin from '../components/UsersSuperAdmin'

///// Start get user / infra  ///////
const selectUser = createSelector(
    state => state.login.user,
    user => user
);

export default function SuperAdminContainer() {

    const [isLoading, setIsLoading] = useState(false);
    const [activeMenu, setActiveMenu] = useState('home');
    const [activeComponent, setActiveComponent] = useState();
    const user = useSelector(selectUser);

    const handleClickMenuActive = (id) => {
        setActiveMenu(id);
    }

    const menuDashboardSuperAdmin = [
        {
            id: 0,
            code: 'home',
            title: <FiHome />
        },{
            id: 1,
            code: 'installations',
            title: "Installations"
        },
        {
            id: 2,
            code: 'users',
            title: "Users"
        },
        // {
        //     id: 3,
        //     title: "Personal tasks"
        // },
        // {
        //     id: 4,
        //     title: "My notes"
        // },
        // {
        //     id: 5,
        //     title: "My activity"
        // }
    ]

    useEffect(() => {
        switch (activeMenu) {
            case 'installations':
                setActiveComponent(<InstallationsSuperAdmin />)
                break;
            case 'users':
                setActiveComponent(<UsersSuperAdmin />)
                break;
            default:
                setActiveComponent(<><FiHome /> You must choose a Tab</>)
        }
    }, [activeMenu])

    if(isLoading) return <Loader content="Loading Super Admin dashboard"/>

    return (
        <article className="pt-10">
            <div className="container">
                <div class="wrapper">
                    <div
                        className="relative h-[100px] flex justify-between items-center mb-10 border border-slate-200 rounded-2xl overflow-hidden">

                        <div
                            className="text-center absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 text-slate-800  flex items-center flex-col gap-2">
                            <h2 className="flex text-4xl font-bold mb-4">
                                <FiShield className={'text-slate-100 bg-blue-500 rounded-full mr-3 p-1'} /> Super Admin dashboard
                            </h2>
                        </div>

                        <div className="absolute bottom-0 w-full z-10 text-slate-500">
                            <ul className="flex items-center gap-10 h-full justify-center font-bold text-slate-500">
                                {menuDashboardSuperAdmin.map(item =>
                                    <li key={item.id} onClick={() => handleClickMenuActive(item.code)}
                                        className={`${activeMenu === item.code ? "bg-slate-50 text-slate-700" : "bg-transparent"} h-10 flex items-center justify-center text-sm px-4 rounded-full cursor-pointer`}>{item.title}</li>
                                )}
                            </ul>
                        </div>
                    </div>

                <div className="flex gap-2 flex-wrap items-stretch">
                        <div className="w-full grow flex flex-col gap-10">
                            <div className="flex flex-col gap-2">
                                <div
                                    className="min-h-[500px] border border-slate-100 rounded-2xl shadow-sm flex items-center justify-center ">
                                    {activeComponent}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </article>
    )
}