import { CompConfig } from '../../../components/CompConfig'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { useFetchResourceType } from '../hooks/useFetchResourceType'
import { createSelector } from 'reselect'
import { useEffect, useState } from 'react'
import { ResourceType } from './ResourceType'
import { FormResourceType } from './form/FormResourceType'
import { FiBox } from 'react-icons/fi'

export const ListResourceType = ({ isEdit }) => {
    const selectUser = createSelector(
        (state) => state.login.user,
        (user) => user
    )
    const selectCurrentInfrastructure = createSelector(
        (state) => state.currentInfrastructure,
        (ci) => ci
    )
    const [data, setData] = useState([])
    const [totalItem, setTotalItem] = useState(0)
    const [isOpenDrawer, setIsOpenDrawer] = useState(false)
    const [incrementList, setIncrementList] = useState(0)
    const [incrementEdit, setIncrementEdit] = useState(0)

    const user = useSelector(selectUser)
    const currentInfrastructure = useSelector(selectCurrentInfrastructure)

    const { resourceType, isLoading, error } = useFetchResourceType(currentInfrastructure, user.token, incrementList)

    useEffect(() => {
        if (!isLoading) {
            setData(resourceType['hydra:member'])
            setTotalItem(resourceType['hydra:member']?.length ?? 0)
        }
    }, [isLoading, resourceType])

    const onDrawerCloseForm = () => {
        setIncrementList(incrementList + 1)
    }

    const onDrawerOpenForm = () => {
        setIncrementEdit(incrementEdit + 1)
    }

    return (
        <CompConfig
            title={isEdit ? 'Resource type config' : 'Resource type list'}
            titleDrawer="Create a new resource type"
            titleDrawerUpdate="Update a resource type"
            logoTitle={<FiBox />}
            data={data}
            isLoading={isLoading}
            error={error}
            totalItem={totalItem}
            btntitle="Add resource type"
            content={<ResourceType />}
            typeForm="resource-type"
            setIsOpenDrawer={setIsOpenDrawer}
            onDrawerClose={onDrawerCloseForm}
            onDrawerOpen={onDrawerOpenForm}
            isEdit={isEdit}
            drawerFormCreate={<FormResourceType increment={incrementEdit} />}
        />
    )
}
