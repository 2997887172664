import {FiHelpCircle, FiSettings, FiUser} from "react-icons/fi";

export const NavbarMiddleLink = [
    {
        id: 1,
        title: "My Account",
        link: "/profil",
        icon: <FiUser/>
    },
    {
        id: 2,
        title: "Settings",
        link: "/settings",
        icon: <FiSettings/>
    },
    // {
    //     id: 3,
    //     title: "Help",
    //     link: "/docs",
    //     icon: <FiHelpCircle/>
    // }
]