import StandardAgroserv from "../assets/images/standardAgroservNetwork.webp";
import StandardAnaeeFrance from "../assets/images/standardAnaeeFranceNetwork.webp";
import StandardAnaeeEurope from "../assets/images/standardAnaeeEuropeNetwork.webp";
import StandardMirri from "../assets/images/standardMirriNetwork.webp";
import StandardNetwork from "../assets/images/standardnetworkimage.webp";

let standardImage;

export const StandardImageByNetwork = (networkCode) => {
    switch (networkCode) {
        case "agroserv":
            standardImage = StandardAgroserv
            break;
        case "anaee_europe":
            standardImage = StandardAnaeeEurope
            break;
        case "anaee_france":
            standardImage = StandardAnaeeFrance
            break;
        case "mirri":
            standardImage = StandardMirri
            break;
        default:
            // Ajoutez un cas par défaut au besoin
            standardImage = StandardNetwork
    }

    return standardImage
}