import AxiosService from "./AxiosService";


const urlListActivities = 'api/activities/userInfra/infrastructure/';

class EventService {

    getListActivities(infrastructure, token){
        return AxiosService.getWithToken(urlListActivities + infrastructure, token, 'json');
    }
}

export default EventService;