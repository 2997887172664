import {useEffect, useState} from 'react';
import {SelectService} from "../services/Select";
import {useDispatch, useSelector} from "react-redux";
import {fetchSelectConfigByNetwork} from "../../../redux/selects/selectConfigByNetworkAction";

const useFetchConfigByNetworkId = (id) => {
    const  [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [ error, setError] = useState(null);

    const dispatch = useDispatch();

    const token = useSelector(state => state.login.user?.token) ?? null;

    useEffect(() => {
        fetchConfigByNetworkId()
    }, [id, token]);

    const fetchConfigByNetworkId = async () => {
        setIsLoading(true);

        try {
            const selectService = new SelectService();

            const res = await selectService.getSelectConfigByNetworkId(id, token);

            if (!res) throw new Error("Unable to fetch configuration. Please check your network ID or try again later.")

            dispatch(fetchSelectConfigByNetwork(res["hydra:member"]));

            setData(res["hydra:member"]);
            setIsLoading(false);

        } catch (error) {
            setError(error || "An error occurred while fetching the data.");
            setIsLoading(false)
        }
    }

    return { data, isLoading, error, fetchConfigByNetworkId }
};

export default useFetchConfigByNetworkId;
