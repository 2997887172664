export const DataDoc = [
    {
        id: 2,
        title: "Youtube Channel",
        description: "Need something cleared up? Here are our most frequently asked question.",
        mydata : [
            {
                id: 1,
                title: "Youtube Channel",
                description: "Need something cleared up? Here are our most frequently asked question.",
                link: "https://www.youtube.com/channel/UCL1KdG49SF1t3y6g9AiH0Kw",
                type: "video"
            },
            {
                id: 2,
                title: "Agroerv - UC-001 - How to update your installations and services informations",
                description: "",
                link: "/about",
                type: "pdf"
            },
            {
                id: 3,
                title: "Agroerv - UC-001 - How to update your installations and services informations",
                description: "",
                link: "/about",
                type: "pdf"
            },
            {
                id: 4,
                title: "Agroerv - UC-001 - How to update your installations and services informations",
                description: "",
                link: "/about",
                type: "pdf"
            },
            {
                id: 5,
                title: "Agroerv - UC-001 - How to update your installations and services informations",
                description: "",
                link: "/about",
                type: "pdf"
            },
            {
                id: 6,
                title: "Agroerv - UC-001 - How to update your installations and services informations",
                description: "",
                link: "/about",
                type: "pdf"
            }
        ]
    },
    {
        id: 3,
        title: "FaQ",
        mydata :
            [
                {
                    id:1,
                    title: "What is ISIA",
                    description: "Need something cleared up? Here are our most frequently asked question."
                },
                {
                    id:2,
                    title: "What is ISIA",
                    description: "Need something cleared up? Here are our most frequently asked question."
                },
                {
                    id:3,
                    title: "What is ISIA",
                    description: "Need something cleared up? Here are our most frequently asked question."
                },
                {
                    id:4,
                    title: "What is ISIA",
                    description: "Need something cleared up? Here are our most frequently asked question."
                },
                {
                    id:5,
                    title: "What is ISIA",
                    description: "Need something cleared up? Here are our most frequently asked question."
                },
                {
                    id:6,
                    title: "What is ISIA",
                    description: "Need something cleared up? Here are our most frequently asked question."
                },
                {
                    id:7,
                    title: "What is ISIA",
                    description: "Need something cleared up? Here are our most frequently asked question."
                }
        ]
    }
]