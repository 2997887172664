import * as React from 'react';

// Design
import './ppaactivity.scss';
import { motion } from "framer-motion";
import { Timeline } from "@mui/lab";
import {FiArrowLeft} from "react-icons/fi";

// Components
import {CustomTimeLine} from "./timeline/CustomTimeLine";
import {Link} from "react-router-dom";
import {PpaSuccessContent} from "../form/PpaSuccessContent";



export const PpaActivity = ({ tokenDatas, showSubmitSuccess }) => {

    const { ppaActivities, status, pdfName } = tokenDatas;

    // const { ppaActivities, status, pdfName } = fakeTokenDatas;
    // console.log(fakeTokenDatas)

    const draftStatus = ppaActivities.filter((item) => item.status === 'draft');
    const totatSavedStatus = draftStatus.length;
    const lastDraftStatus = draftStatus.slice(-1);
    const postSubmitStatus = ppaActivities.filter((item) => item.status !== 'draft');


    return (
        <motion.div
            id="ppa_activity"
            className="h-full flex flex-col"
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{duration: .5, delay: .3}}
        >
            <Link to="/"
                  className="flex items-center w-full h-fit gap-1 top-10 lg:top-20 text-md text-slate-500 z-10 hover:text-slate-800 mb-6 lg:mb-0">
                <span><FiArrowLeft/></span>
                Home
            </Link>

            { showSubmitSuccess && <PpaSuccessContent /> }

            <Timeline
                sx={{
                    margin: {xs: '40px 0 100px', md: '60px 0 0'},
                    padding: {xs: '0', md: 'initial'},
                    ...(!showSubmitSuccess && {
                        margin: {xs: '5px 0 100px', md: 'auto 0'},
                        justifyContent: 'center'
                    })
                }}
            >

                {lastDraftStatus && (
                    <CustomTimeLine datas={lastDraftStatus}
                                    status={status}
                                    totatSavedStatus={totatSavedStatus}
                    />
                )}

                {postSubmitStatus && (
                    <CustomTimeLine datas={postSubmitStatus}
                                    status={status}
                                    pdfName={pdfName && pdfName}
                    />
                )}

            </Timeline>
        </motion.div>
    )
}