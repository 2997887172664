import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import PropTypes from "prop-types";
import {useState} from "react";

export const BasicAccordion = ({ data, noexpand }) => {

    const [expanded, setExpanded] = useState(null);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div className="flex items-center justify-start gap-10 py-10">
            <div className="w-full">
                { data?.map(item =>
                    <Accordion
                        key={item.id}
                        expanded={expanded === item.expandedAcc }
                        onChange={handleChange(item.expandedAcc)}
                    >
                        <AccordionSummary
                            expandIcon={!noexpand && <ControlPointIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{ py: 1}}
                        >
                            <h3 className={`font-medium text-lg`}>{item.title}</h3>

                        </AccordionSummary>
                        <AccordionDetails>
                            <p className="text-slate-500">
                                {item.description}
                            </p>
                        </AccordionDetails>
                    </Accordion>
                )}
            </div>
        </div>
    );
}