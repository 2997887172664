import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {Loader} from "../../../components/loader/Loader";
import {CompConfig} from "../../../components/CompConfig";
import {FiUsers} from "react-icons/fi";
import {useFetchTeamData} from "../hooks/api/useFetchTeamData";
import {ListTeams} from "../components/ListTeams";
import {TeamForm} from "../components/form/TeamForm";

export function TeamManagementContainer() {
    const [data, setData] = useState([]);
    const [totalItem, setTotalItem] = useState(0);
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [incrementList, setIncrementList] = useState(0);
    const [incrementEdit, setIncrementEdit] = useState(0);

    const selectUser = createSelector(
        state => state.login.user,
        user => user
    );

    const user = useSelector(selectUser);

    const selectCurrentInfrastructure = createSelector(
        state => state.currentInfrastructure,
        ci => ci
    );
    const infrastructureId = useSelector(selectCurrentInfrastructure);

    const {teams, isLoading, error } =  useFetchTeamData(infrastructureId, user.token, false, incrementList);

    useEffect(() => {
        if(!isLoading) {
            setData(teams);
            setTotalItem(teams?.length ?? 0);
        }
    }, [isLoading, teams]);

    const onDrawerCloseForm = () => {
        setIncrementList(incrementList + 1);
    }

    const onDrawerOpenForm = () => {
        setIncrementEdit(incrementEdit + 1);
    }

    const searchMethod = (data, searchTerm) => {
        return data?.filter(item => {
                return (item.name?.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    ((item?.listUserInfras?.map(u =>
                        (u?.user?.fullName ?? "").toLowerCase()
                    ).join(",")).includes(searchTerm.toLowerCase())) ||
                    ((item?.listUserInfras?.map(u =>
                        (u.user?.email ?? "").toLowerCase()
                    ).join(",")).includes(searchTerm.toLowerCase()))
            }
        );
    }

    return (
        undefined === teams ?
            (<Loader/>)
            :
            (<CompConfig
                title={"Manage teams"}
                titleDrawer="Create a new team"
                titleDrawerUpdate="Update a team"
                logoTitle={<FiUsers />}
                data={data}
                isLoading={isLoading}
                error={error}
                totalItem={totalItem}
                btntitle="Create a new team"
                content={<ListTeams />}
                typeForm="teamsmanage"
                setIsOpenDrawer={setIsOpenDrawer}
                onDrawerClose={onDrawerCloseForm}
                onDrawerOpen={onDrawerOpenForm}
                isEdit={true}
                drawerFormCreate={<TeamForm increment={incrementEdit} />}
                searchMethod={searchMethod}
            />)
    )
}