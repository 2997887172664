import * as React from "react";

// Design
import {Grid, Skeleton} from "@mui/material";
import './loaders.scss';


export const HexaLoader = ({ itemsPerRow }) => {
    return (
        <Grid container rowSpacing={2} columnSpacing={{ xs: 2 }} sx={{ margin: 'auto', justifyContent: 'center' }}>
            <Grid item xs={3} lg={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '25vh' }}>
                <div id="clock">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                         x="0px" y="0px" width="63.333px" height="73.333px" viewBox="0 0 63.333 73.333"
                    >
                        <defs>
                            <linearGradient id="MyGradient">
                                <stop offset="5%" stopColor="#FF7E00"/>
                                <stop offset="55%" stopColor="#EF6D6D"/>
                            </linearGradient>
                        </defs>
                        <polygon className="clockline"
                                 points="4.701,21.098 4.701,52.235 31.667,67.804 58.632,52.235 58.632,21.098 31.667,5.529 "
                        />
                    </svg>
                    <div className={"name"}>ISIA</div>
                </div>
            </Grid>
        </Grid>
    )
}

export const CardsLoader = ({itemsPerRow}) => {
    return (
        <Grid container rowSpacing={2} columnSpacing={{xs: 2}} sx={{margin: '20px 0 0'}}>

                {/* Mobile Skeleton */}
                {Array.from(new Array(2)).map((row, index) => (
                    <Grid item xs={12} key={index} sx={{ display: { lg: 'none'} }}>
                        <Skeleton variant="rectangular" width="100%" sx={{height: '110px', borderRadius: '10px'}}/>
                    </Grid>
                ))}

                {/* Desktop Skeleton */}
                {Array.from(new Array(itemsPerRow)).map((row, index) => (
                    <Grid item xs={3} key={index} sx={{ display: { xs: 'none', lg: 'block'} }}>
                        <Skeleton variant="rectangular" width="100%" sx={{height: '110px', borderRadius: '10px'}}/>
                    </Grid>
                ))}

        </Grid>
    )
}
export const DrawerLoader = () => {
    return (
        <div className="w-full">
            <Skeleton width="30%" height="40px"/>
            <Skeleton width="50%" height="25px"/>
            <Skeleton variant="rectangular"
                      sx={{ margin: '25px 0 30px', borderRadius: '10px' }}
                      width="100%"
                      height="20vh"
            />
            <Skeleton width="100%" />
            <Skeleton width="60%" />
            <Skeleton width="100%" height="32vh" sx={{ transform: 'none', margin: '10px 0' }}/>
            <Skeleton width="60%" />
            <Skeleton width="100%" height="15vh" sx={{ transform: 'none', margin: '10px 0' }}/>
        </div>
    )
}


export const BouncingDotsLoader = (props) => {
    return (
        <>
            <div className="bouncing-loader">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </>
    );
};

export const FlashingDotsLoader = (props) => {
    return (
        <div className="snippet" data-title="dot-flashing">
            <div className="stage">
                <div className="dot-flashing"></div>
            </div>
        </div>
    );
};