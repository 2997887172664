import React, {useState} from 'react';

// Design
import {IconButton, Link} from "@mui/material";
import {AnimatePresence, motion} from "framer-motion";
import {FaExternalLinkAlt} from "react-icons/fa";

// Custom Functions
import {useMobile} from "../../../hooks/useMobile";


export const DescriptionLinkToCatalog = ({ descriptionTypeText, description, elementId }) => {

    const [showLink, setShowLink] = useState(false);
    const tagsTitle = `See ${description.networkName} description in ${descriptionTypeText} Catalog`;
    const isMobile = useMobile();


    return (
        <Link
            href={`/catalog/${description?.networkId}/${descriptionTypeText}/${elementId}`}
            sx={{ display: 'block', textDecoration: 'none' }}
            target={"_blank"}
            title={ tagsTitle }
        >
            <IconButton onMouseEnter={() => setShowLink(true)}
                        onMouseLeave={() => setShowLink(false)}
                        aria-label={ tagsTitle }
                        sx={{
                            '&:hover': { background: 'transparent' },
                            zIndex: '10',
                            position: 'fixed',
                            right : {xs: '10px', lg:'20px', xl:'30px'},
                            top: {xs: '84px', lg:'310px', xl:'100px'},
                            display: 'flex',
                            alignItems: 'center',
                            height: '60px'
                        }}
                        disableRipple
            >
                <AnimatePresence>
                    {showLink && (
                        <motion.div initial={{x: 80, opacity: 0}}
                                    animate={{x: 20, opacity: 1}}
                                    transition={{duration: .2}}
                                    exit={{x: 80, opacity: 0}}
                                    className={`${isMobile ? 'hidden' : 'block'}`}
                        >
                            <div className="text-blue-500 text-sm bg-white max-w-[180px]
                                            border-r border border-slate-200 rounded-lg
                                            p-2 z-10 shadow-sm
                                            lg:px-4 lg:py-2
                                            lg:max-w-[240px]"
                            >
                                See <strong>{description?.networkName}</strong> description in <span className={'capitalize'}>{ descriptionTypeText }</span>&nbsp;Catalog
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>

                <motion.div className="flex items-center justify-center
                                       text-[20px] text-blue-500 bg-white
                                       z-10 h-10 w-10 lg:h-12 lg:w-12
                                       p-2 rounded-full border border-solid
                                       border-slate-300 hover:shadow-md shadow-gray-300"
                >
                    <FaExternalLinkAlt/>
                </motion.div>

            </IconButton>
        </Link>
    );
};