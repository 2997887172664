import {useContext, useEffect, useState} from "react";
import {DrawerLoader} from "../../../ppa/components/loaders/Loaders";
import {useFetchTeam} from "../../hooks/api/useFetchTeam";
import TeamService from "../../../../services/TeamService";
import {useFetchInfrastructureUsers} from "../../hooks/api/useFetchInfrastructureUsers";
import {useDispatch, useSelector} from "react-redux";
import {displayMessage} from "../../../../redux/alertAction";
import {DrawerTemporaryContext} from "../../../../components/drawer/DrawerTemporary";
import {Controller, useForm} from "react-hook-form";
import {Loader} from "../../../../components/loader/Loader";
import Alert from "@mui/material/Alert";
import Select from "react-select";
import ConfirmDialog from "../../../../components/confirm/ConfirmDialog";
import * as React from "react";
import {createSelector} from "reselect";
import {useFetchUserRole} from "../../../../hooks/useFetchUserRole";
import {Forbidden} from "../../../../pages/Forbidden/Forbidden";
import {FiAlertTriangle, FiTrash} from "react-icons/fi";


const selectUser = createSelector(
    state => state.login.user,
    user => user
);

const selectCurrentInfrastructure = createSelector(
    state => state.currentInfrastructure,
    ci => ci
);

export function TeamForm({increment})
{

    const user = useSelector(selectUser);
    const currentInfrastructureId = useSelector(selectCurrentInfrastructure);
    const context = useContext(DrawerTemporaryContext);
    const method = context.method ?? "CREATE";
    const isCreate = method === "CREATE";
    const isUpdate = method === "UPDATE";
    const userInfraRole = useFetchUserRole();
    const isRead = !userInfraRole || (!user.isSuperAdministrator && (!userInfraRole.canUpdateTeams && !userInfraRole.canCreateTeams));

    const defaultFormValues = {
        name: "",
        infrastructure: '/api/infrastructures/' + currentInfrastructureId,
        listUserInfras: []
    }

    const {
        control,
        register,
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { errors },
        getValues} = useForm({
        defaultValues: defaultFormValues
    });

    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const {team} = useFetchTeam(context.idSelected, user.token, increment, setLoading);
    const {users} = useFetchInfrastructureUsers(currentInfrastructureId, user.token, increment);
    const [teamId, setTeamId] = useState(0);


    useEffect(() => {
        setTeamId(team?.id ?? 0);
        if (!isLoading) {
            if (null !== team) {
                setValue('name', team.name ?? "");
                setValue('listUserInfras', team.listUserInfras ?? []);
                setIsDisabled(isRead || team.isTeamAll || team.isTeamNotificationNewProject || team.isTeamNotificationGeneralTickets || team.isProject);
            } else {
                reset(defaultFormValues);
                setIsDisabled(false);
            }
        }
    }, [
        isLoading,
        team
    ]);

    const onSubmit = async (data) => {
        try {
            if(!isDisabled) {
                const teamService = new TeamService();
                setSubmitting(true);
                const requestData = {
                    name: data.name,
                    infrastructure: data.infrastructure,
                    listUserInfras: data.listUserInfras.map((userInfra) => userInfra['@id']),
                }

                if (isCreate) {
                    teamService.createTeam(requestData, user.token).then(() => {
                        reset(defaultFormValues);

                        dispatch(displayMessage("Success", `The team '${requestData.name}' has been created`, "success"));

                        context.onDrawerClose();
                    }).catch((e) => {
                        dispatch(displayMessage(e.title, e.detail, 'error'))
                    })
                } else {
                    teamService.updateTeam(teamId, requestData, user.token).then(() => {
                        reset(defaultFormValues);

                        dispatch(displayMessage("Success", `The team '${team?.name}' has been updated`, "success"));

                        context.onDrawerClose();
                    }).catch((e) => dispatch(displayMessage(e.title, e.detail, 'error')))
                }
                setSubmitting(false);
            }
        } catch (error) {
            setSubmitting(false);
            console.log(error);
            dispatch(displayMessage(error.data.title, error.data.detail, "error"));
        }
    };

    const handleDelete = async () => {
        try {
            if(!isDisabled && !team?.isProject && (user.isSuperAdministrator || userInfraRole?.canDeleteTeams)) {
                const teamService = new TeamService();
                setSubmitting(true);
                teamService.deleteTeam(teamId, user.token).then(() => {
                    reset(defaultFormValues);
                    setSubmitting(false);

                    dispatch(displayMessage("Success", `The team '${team.name}' has been deleted`, "success"));

                    context.onDrawerClose();
                });
            }
        } catch (error) {
            setSubmitting(false);
            console.log(error);
            dispatch(displayMessage(error.data.title, error.data.detail, "error"));
        }
    }
    if (isCreate && isRead) {
        return <Forbidden isInDrawer={true}/>
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8">
            {submitting ? (
                    <Loader content="Submitting"/>
                ) :
                (
                    <>
                        {isLoading
                            ? (<DrawerLoader/>)
                            : (
                                <>
                                    {(isDisabled && team) &&
                                        <div
                                            className="border border-orange-100 bg-orange-50 text-orange-500 h-14 flex gap-2 items-center justify-center px-4 text-sm rounded-sm">
                                            <p className="w-2/3 grow text-orange-400">The team <span
                                                className="text-orange-500 font-bold underline">{team.name}</span> cannot
                                                be
                                                modified {team.isProject ? 'because you don\'t have right to do so' : 'as it is a default role'}
                                            </p>
                                            <span
                                                className=" order-first w-6 h-6 text-xs flex items-center justify-center border border-orange-400 rounded-full text-orange-400"><FiAlertTriangle/></span>
                                        </div>

                                    }
                                    <div className="flex flex-col gap-4">
                                        <div className="flex flex-col gap-4">
                                            <div className="flex flex-col gap-2">
                                                <label htmlFor="name" className="text-slate-700 text-md font-bold">Name of team</label>

                                                <div className="w-full">
                                                    <input type="text"
                                                           placeholder="Role name"
                                                           {...register("name", {
                                                               required: "Team name is required",
                                                               disabled: isDisabled || team?.isProject
                                                           })
                                                           }
                                                           className="border border-slate-300 border-solid rounded-sm w-full grow h-12 p-4 focus:outline-none focus:ring focus:ring-blue-200"
                                                    />
                                                    {errors.name && !watch("name") && (
                                                        <span className={"text-sm text-red-500/70"}
                                                              role="alert">{errors.name.message}</span>
                                                    )}
                                                </div>

                                                </div>
                                            </div>
                                        </div>

                                    <div className="flex flex-col gap-4">
                                        <div className="flex flex-col gap-4">
                                            <div className="w-full flex flex-col gap-2">
                                                <label htmlFor="name" className="text-slate-700 text-md font-bold">Users having team</label>

                                                <fieldset disabled={isDisabled}>
                                                    <Controller
                                                        name="listUserInfras"
                                                        control={control}
                                                        render={({field}) => (
                                                            <Select
                                                                {...register("listUserInfras")}
                                                                {...field}
                                                                onChange={(t) => {
                                                                    setValue("listUserInfras", t);
                                                                }}
                                                                isMulti
                                                                options={users}
                                                                getOptionLabel={(option) => option.user?.fullName.trim() === "" ? option.user?.email : option.user?.fullName} // Récupère le nom de l'option
                                                                getOptionValue={(option) => option.id} // Récupère la valeur de l'option (id)
                                                                isSearchable
                                                            />
                                                        )}
                                                    />
                                                </fieldset>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        {!isDisabled && (
                                            <div className="flex items-center gap-4">
                                                {(isCreate || isUpdate) &&
                                                    (
                                                        <button
                                                            type="submit"
                                                            className={`grow border border-slate-200 border-dashed rounded-sm text-slate-700 bg-slate-200 text-md h-10 flex items-center justify-center hover:bg-blue-500 hover:text-slate-50 transition ease-linear`}
                                                        >
                                                            {isCreate ? "Create new team" : "Save change"}
                                                        </button>
                                                    )
                                            }
                                                {(isUpdate && !team?.isProject && (user.isSuperAdministrator || userInfraRole?.canDeleteTeams)) &&
                                                    <>
                                                        <button
                                                            type="button"
                                                            onClick={() => setOpenConfirmation(true)}
                                                            className={`border border-red-200 rounded-full bg-red-100 text-red-500 text-md h-10 w-10 flex items-center justify-center hover:bg-red-500 hover:text-slate-50 transition ease-linear`}
                                                        >
                                                            <FiTrash/>
                                                        </button>
                                                        <ConfirmDialog
                                                            isOpen={openConfirmation}
                                                            onClose={() => setOpenConfirmation(false)}
                                                            setIsOpen={setOpenConfirmation}
                                                            onAgree={() => handleDelete()}
                                                        />
                                                    </>
                                                }
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                    </>
                )}
        </form>
    )
}