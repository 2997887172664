import {
    FETCH_SELECT_CONFIG_BY_NETWORK,
    ADD_SELECT,
    DELETE_SELECT,
    UPDATE_SELECT,
    FETCH_SELECT_CONFIG_BY_ID
} from "./selectConfigByNetworkAction";

const initialState = {
    data: [],
}

export const selectConfigByNetworkReducers = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SELECT_CONFIG_BY_NETWORK :
            return {
                ...state,
                data: action.payload
            }
        case FETCH_SELECT_CONFIG_BY_ID:
            return {
                ...state,
                data: state.data?.find(item => item.id === action.payload)
            };
        case ADD_SELECT:
            return {
                ...state,
                data: [...state.data, action.payload]
            }
        case DELETE_SELECT:
            return {
                ...state,
                data: state.data?.filter(item => item.id !== action.payload)
            };
        case UPDATE_SELECT:
            return {
                ...state,
                data: state.data?.map(item =>
                    item.id === action.payload.id ? action.payload : item
                )
            };
        default:
            return state;
    }
}