import { useFetchNetworkConfig } from '../hooks/useFetchNetworkConfig';
import {CompConfig} from "../../../components/CompConfig";
import {Network} from "../components/Network";
import {FaNetworkWired} from "react-icons/fa";
import {FormNetwork} from "../components/form/FormNetwork";
import {useEffect, useState} from "react";

export const NetworkListConfig = () => {

    const [isSubmitting, setIsSubmitting] = useState(false);

    const { data, isLoading, error, totalItem, fetchAllNetworksConfig } = useFetchNetworkConfig();

    useEffect(() => {
        setIsSubmitting(false);
    }, [data]);

    useEffect(() => {
        if (!isSubmitting) return;

        fetchAllNetworksConfig();
    }, [isSubmitting]);

    // const data = useSelector(state => state.networkConfigReducers.data);
    return (
        <CompConfig
            title="Network List"
            titleDrawer="New Network: Create it Easily"
            logoTitle={<FaNetworkWired/>}
            data={data}
            isLoading={isLoading}
            error={error}
            totalItem={totalItem}
            btntitle="Add network"
            content={<Network />}
            typeForm="network"
            isEdit={true}
            drawerFormCreate={<FormNetwork method={"CREATE"} setIsSubmitting={setIsSubmitting}/>}
        />
    );
}
