import {Item} from "./Item";
import {AnimatePresence, motion} from "framer-motion";
import {Loader} from "../../../components/loader/Loader";
import {useEffect} from "react";
import {Skeleton} from "@mui/material";
export const ListCatalog = ({ drawerOpen, data, pageLoad, setPageLoad, isLoading, catalogType }) => {

    useEffect(() => {
        changeLoadPage()
    }, [pageLoad])

    const changeLoadPage = () => {
        if (!pageLoad) return;

        setTimeout(() => {
            setPageLoad(false)
        }, 1000);
    }

    if (isLoading) return <Loader content="load service"/>;
    return (
        <AnimatePresence>
            { !isLoading ?
                <motion.ul
                    className={`min-h-screen w-full grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 h-full py-4 gap-8`}
                    initial={{opacity: 0, translateY: 25}}
                    animate={{opacity: 1, translateY: 0}}
                    exit={{opacity: 0, translateY: 25}}
                    transition={{type: "spring", duration: .5, ease: "linear"}}
                >
                    { data.map((item) =>
                        (
                            !pageLoad || isLoading ?
                                <motion.li
                                    className="flex flex-col gap-4"
                                    initial={{ opacity:0, translateY:25 }}
                                    animate={{ opacity:1, translateY:0 }}
                                    transition={{ type:"spring", duration:.5, ease:"linear" }}
                                    key={item.id}
                                >
                                    <Item item={item} catalogType={catalogType} pageLoad={pageLoad} />
                                </motion.li>
                                :
                                <li key={`skeleton-${item.id}`} className="flex flex-col gap-4">
                                    <div className="flex flex-col gap-2 w-full">
                                        <Skeleton width="60%"/>
                                        <Skeleton width="25%"/>
                                        <Skeleton width="15%"/>
                                    </div>

                                    <div className="h-60 w-full relative overflow-hidden order-first rounded-lg">
                                        <Skeleton
                                            variant="rectangular"
                                            width="100%"
                                            height="100%"
                                        />
                                    </div>
                                </li>
                        )

                    )}
                </motion.ul>
                :
                <Loader content="Loading"/>
            }
        </AnimatePresence>

    )
}