import * as React from 'react';
import {useState} from "react";

// Design
import { motion, AnimatePresence } from "framer-motion";
import {Box} from "@mui/material";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Alert from "@mui/material/Alert";
import {FiArrowLeftCircle, FiArrowRightCircle} from "react-icons/fi";
import {PpaStepConnector, PpaStepIcon} from "../../utils/StepperAssets";

// Slider
import {Swiper, SwiperSlide} from "swiper/react";
import { Navigation, Pagination } from 'swiper/modules';

// Custom Functions
import {useMobile} from "../../../../hooks/useMobile";


export const FormContentSwipeStepper = ({ steps, activeStep, setActiveStep, validMandatory, valid, missingFields, hideMissingFields, hasMandatoryFieldsStep }) => {

    const handleClick = (stepIndex) => {
        setActiveStep(stepIndex)
    }
    const isMobile = useMobile();

    const [totalSlide, setTotalSlide] = useState(0);
    const [parentClass, setParentClass] = useState(false);

    return (
        <Stepper activeStep={activeStep}
                 alternativeLabel
                 className="stepper w-full py-4 mt-20 lg:mt-6 lg:mb-9"
                 connector={<PpaStepConnector className={"step-connector"} />}
        >
            <Swiper
                style={{width: "100%", padding: '10px 0 50px' }}
                onSwiper={(swiper) => {
                    setTotalSlide(swiper.slides.length);
                    swiper.slides.length < 5 && setParentClass(true)
                }}
                slidesPerView={isMobile ? 1 : 5}

                grabCursor={true}
                pagination={false}
                navigation={{ prevEl: ".arrow-left", nextEl: ".arrow-right" }}
                modules={[Navigation]}
                className={`mySwiper ${parentClass && 'swiper-wrapper-parent-class'}`}
            >

                {steps.filter((step) => !step.isHidden).map((label, index) => (

                    <SwiperSlide key={label.id}
                                 className={`${totalSlide < 4 && 'style-override'}`}
                                 style={{ background: 'transparent' }}
                    >
                        <Step sx={{
                                  height: '100%',
                                  flexFlow: 'row wrap',
                                  justifyContent: 'center',
                                  ...index === 0 && {
                                      '& > .step-connector': { display: 'none' }
                                  }
                              }}
                              onClick={() => hasMandatoryFieldsStep ? (validMandatory && valid) && handleClick(index) : handleClick(index)}
                              className={`single-step ${index}`}
                        >
                            {/* error= Return Boolean depending on missing fields value PER TAB ID */}
                            <StepLabel sx={{
                                // Clickable Step
                                ...((hasMandatoryFieldsStep ? (validMandatory && valid) : valid) && {
                                    '&:hover': {cursor: 'pointer'},
                                }),
                                position: 'relative',
                            }}
                                       StepIconComponent={PpaStepIcon}
                                       error={(missingFields.filter((item) => ((item.id === label.id) && (item.value > 0))).map((item) => item.value) > 0) && !hideMissingFields}

                                // For Slider
                               icon={index + 1}
                            >
                                <div
                                    className={`name w-full mb-[20px] lg:mb-0 ${activeStep === index ? "text-base font-bold" : 'text-sm'} overflow-hidden text-ellipsis whitespace-nowrap`}>
                                    {label.name}
                                </div>

                                <div className="error-alert mt-auto absolute z-20">
                                    {missingFields.map((item, index) => (
                                        <React.Fragment key={index}>
                                            {item.id === label.id && (
                                                <AnimatePresence>
                                                    {(item.value > 0 && !hideMissingFields) && (
                                                        <motion.div
                                                            initial={{y: -40, opacity: 0}}
                                                            animate={{y: 30, opacity: 1}}
                                                            transition={{duration: .4}}
                                                            exit={{y: -40, opacity: 0}}
                                                        >
                                                            <Alert severity="error"
                                                                   variant="filled"
                                                                   sx={{
                                                                       minWidth: '180px',
                                                                       fontSize: '13px',
                                                                       fontWeight: '600',
                                                                       justifyContent: 'center',
                                                                       alignItems: 'flex-start',
                                                                       padding: '0 6px',
                                                                       minHeight: '36px',
                                                                       margin: '0 auto',
                                                                       position: 'relative',
                                                                       bottom: {xs: 20, lg: 0}
                                                                   }}
                                                                   icon={false}
                                                            >
                                                                {item.value > 1 ? (
                                                                    <div>{item.value} required fields left</div>
                                                                ) : (
                                                                    <div>One missing required field</div>
                                                                )}

                                                            </Alert>
                                                        </motion.div>
                                                    )}
                                                </AnimatePresence>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </div>
                            </StepLabel>

                        </Step>

                    </SwiperSlide>
                ))}

                <Box component={"div"} className={`w-full ${totalSlide > 5 ? 'flex' : 'none'} relative bottom-[-14px] z-10`}>

                    <motion.button
                        whileHover={{scale: 1.04}}
                        type="button"
                        className="arrow-left arrow mr-auto"
                        title="Previous Tab"
                    >
                        <FiArrowLeftCircle size={36} className={'text-blue-500'}/>
                    </motion.button>

                    <motion.button whileHover={{scale: 1.04}}
                                   type="button"
                                   className="arrow-right arrow ml-auto"
                                   title="Next Tab"
                    >
                        <FiArrowRightCircle size={36} className={'text-blue-500'}/>
                    </motion.button>

                </Box>

            </Swiper>

        </Stepper>
    )
}