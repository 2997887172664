import { useForm } from 'react-hook-form'
import { createSelector } from 'reselect'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { DrawerTemporaryContext } from '../../../../components/drawer/DrawerTemporary'
import { Loader } from '../../../../components/loader/Loader'
import { FormLoader } from '../../../servicetype/loaders/Loaders'
import { displayMessage } from '../../../../redux/alertAction'
import ConfirmDialog from '../../../../components/confirm/ConfirmDialog'
import { useFetchResourceById } from '../../hooks/useFetchResourceById'
import { ResourceTree } from '../ResourceTree'
import ResourceService from '../../services/Resource'


const selectUser = createSelector(
    (state) => state.login.user,
    (user) => user,
)

export function ResourceForm({ resourceType, apiRef }) {
    const user = useSelector(selectUser)

    const dispatch = useDispatch()


    const context = useContext(DrawerTemporaryContext)
    const method = context.method ?? 'CREATE'
    const isCreate = method === 'CREATE'
    const isUpdate = method === 'UPDATE'
    const [submitting, setSubmitting] = useState(false)
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [resourceParent, setResourceParent] = useState(null)
    const [isLoadingResourceParent, setIsLoadingResourceParent] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()

    const {
        control,
        register,
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { errors },
        getValues,
    } = useForm({
        defaultValues: {
            name: '',
            parent: null,
        },
    })

    const [isLoading, setIsLoading] = useState(true)
    const { resource } = useFetchResourceById(context.idSelected, resourceType.id, user.token, setIsLoading)

    useEffect(() => {
        if (!isLoading) {
            if (null !== resource) {
                setValue('name', resource?.name ?? '')
                setValue('parent', resource?.parent ?? null)
            } else {
                reset({
                    name: '',
                    parent: null,
                })
            }
        }
    }, [
        isLoading,
        resource,
    ])

    const onSubmit = async (data) => {
        try {
            setSubmitting(true)
            if (isCreate || isUpdate) {

                const requestData = {
                    name: data.name,
                    parent: null !== resourceParent ? `/api/resources/${resourceParent}` : null,
                    resourceType: `/api/resource_types/${resourceType?.id}`,
                }

                const resourceService = new ResourceService()
                if (isCreate) {
                    await resourceService.postResource(requestData, user.token).then((result) => {
                        setParamRid(result.id)
                    })
                } else if (isUpdate) {
                    await resourceService.putResource(requestData, context.idSelected, user.token).then((result) => {
                        setParamRid(result.id)
                    })
                }

                reset({
                    name: '',
                    parent: null,
                })

                dispatch(displayMessage('Success', isCreate ? 'Element created' : 'Element updated', 'success'))

                context.onDrawerClose()
            }
            setSubmitting(false)
        } catch (error) {
            setSubmitting(false)
            dispatch(displayMessage(error?.data?.title, error?.data?.detail, 'error'))
        }
    }

    const setParamRid = (itemId) => {
        if (null !== itemId) {
            setSearchParams({
                rid: itemId,
            })
        } else {
            setSearchParams({})
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8">
            {submitting ? (
                <Loader content="Submitting" />
            ) : (
                <>
                    {/* 01 */}
                    {isLoading ? (
                        <FormLoader />
                    ) : (
                        <>
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col gap-4">
                                    <div>
                                        <h3 className="text-xl font-bold mb-2 tracking-wide required">
                                            Resource name
                                        </h3>
                                    </div>
                                    <div className="w-full">
                                        <input
                                            type="text"
                                            placeholder="Resource name"
                                            autoFocus
                                            {...register('name', {
                                                required: 'Resource name is required',
                                            })}
                                            className="border border-slate-300 border-solid rounded-sm w-full grow h-12 p-4 focus:outline-none focus:ring focus:ring-blue-200"
                                        />
                                        {errors.name && !watch('name') && (
                                            <span className={'text-sm text-red-500/70'} role="alert">
                                                {errors.name.message}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col gap-4">
                                    <div>
                                        <h3 className="text-xl font-bold mb-2 tracking-wide">
                                            Resource parent
                                        </h3>
                                    </div>
                                    <div className="w-full">
                                        <ResourceTree
                                            rid={context.idSelected}
                                            resourceType={resourceType}
                                            setIsLoadingResource={setIsLoadingResourceParent}
                                            setResource={setResourceParent}
                                            apiRef={apiRef}
                                        />
                                    </div>
                                </div>
                            </div>
                            <input
                                type="submit"
                                value={isCreate ? 'Create' : 'Update'}
                                className="py-3 px-4 bg-blue-500 text-white text-sm hover: rounded-full max-w-1/6 cursor-pointer"
                            />
                        </>
                    )}
                </>
            )}
        </form>
    )
}
