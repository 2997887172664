import {useDispatch, useSelector} from "react-redux";
import {SelectService} from "../services/Select";
import {deleteSelect} from "../../../redux/selects/selectConfigByNetworkAction";
import {toast} from "sonner";

const useDeleteSelect = () => {
    const token = useSelector(state => state.login.user?.token);
    const dispatch = useDispatch();

    const handleDelete = (id) => {

        if (!id) return;

        try {
            const selectService = new SelectService();
            const res = selectService.deleteSelectById(id, token);

            if (!res) throw new Error("Unable to delete");

            dispatch(deleteSelect(id));

            return toast.success("successfully deleted!");

        } catch (error) {
            console.error(error);
            return toast.error("Something is wrong, please try late");
        }
    }

    return { handleDelete };

};

export default useDeleteSelect;
