import {useContext, useEffect, useState} from 'react';
import {DrawerTemporaryContext} from "../../../../components/drawer/DrawerTemporary";
import useFetchSelectById from "../../hooks/useFetchSelectById";
import CustomError from "../../../../components/error/CustomError";
import {Loader} from "../../../../components/loader/Loader";
import {
    FiAlertTriangle,
    FiHexagon,
    FiMinus,
    FiTrash
} from "react-icons/fi";
import {useForm} from "react-hook-form";
import ConfirmDialog from "../../../../components/confirm/ConfirmDialog";
import useUpdateSelect from "../../hooks/useUpdateSelect";
import FormSelectOptions from "../../../selectoptions/components/form/FormSelectOptions";
import useDeleteSelectOption from "../../../selectoptions/hooks/useDeleteSelectOption";
import useDeleteSelect from "../../hooks/useDeleteSelect";
import CustomTables from "../../../network/components/table/CustomTables";

const FormSelect = ({ id, token, setOpenConfirmation, openConfirmation }) => {

    const [isSubmittingSelect, setIsSubmittingSelect] = useState(false);
    const drawerData = useContext(DrawerTemporaryContext);

    const { handleDeleteSelectOption } = useDeleteSelectOption();

    const {data, isLoading, error, fetchSelectById } = useFetchSelectById(drawerData.idSelected);

    const [isCreateSelectOption, setIsCreateSelectOption] = useState(false);
    const [isUpdateSelectOption, setIsUpdateSelectOption] = useState(false);

    const [isOptionIdSelected, setIsOptionIdSelected] = useState(null);

    const { handleUpdate } = useUpdateSelect();
    const { handleDelete } = useDeleteSelect(id);

    const {
        register,
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: {errors},
        getValues
    } = useForm({
        defaultValues: {
            name: "",
            code: "",
        },
    });

    useEffect(() => {
        if (!isSubmittingSelect) return;

        fetchSelectById(drawerData.idSelected);
        setIsSubmittingSelect(false);
    }, [data, isSubmittingSelect]);

    const listOptions = data?.listSelectOptions;

    useEffect(() => {
        if (!drawerData.isOpenDrawer) {
            setIsUpdateSelectOption(false)
            setIsCreateSelectOption(false)
        }
    }, [drawerData.isOpenDrawer]);

    useEffect(() => {
        if (data) {
            setValue("name", data.name);
            setValue("code", data.code);
        }
    }, [data]);


    const onSubmit = async (myData) => {
        handleUpdate(id, myData)

        drawerData.setIsOpenDrawer(false);
        drawerData.setSubmitting(true);
    }

    const handleDeleteOnUpdateBox = () => {
        handleDelete(id);
        drawerData.setIsOpenDrawer(false);
    }

    // Select options
    const handleOpenSelectOption = () => {
        setIsCreateSelectOption(!isCreateSelectOption);
    }

    const handleSelectOption = (idSelected) => {
        setIsOptionIdSelected(idSelected);
        setIsUpdateSelectOption(true);
    }

    const handleClickDelete = (id) => {
        handleDeleteSelectOption(id)
        setIsSubmittingSelect(true)
    }

    if (error) return <CustomError content="No result find"/>

    if (isLoading) return <Loader content="loading select" hfull absLoad />

    return (
        <div className="flex flex-col gap-10 min-h-screen">
            <div className="h-auto flex flex-col gap-4">
                <div className="flex gap-4 flex-col border-b border-slate-200">
                    {(data?.isStandard) ?
                        <div
                            className="border border-orange-100 bg-orange-50 text-orange-500 h-20 flex gap-2 items-center justify-center px-4 text-sm rounded-sm">
                            <p className="w-2/3 grow text-orange-400">The Select <span
                                className="text-orange-500 font-bold underline">{data.name}</span> cannot be modified or
                                delete as it is a standard</p>
                            <span
                                className=" order-first w-6 h-6 text-xs flex items-center justify-center border border-orange-400 rounded-full text-orange-400"><FiAlertTriangle/></span>
                        </div>

                        :
                        <p className="flex flex-col gap-2 text-slate-700">Description
                            <span className="text-slate-700 text-sm border border-slate-200 rounded-sm bg-slate-100 p-4">
                                This page allows you to update your forms with new information while viewing the list of available options, ensuring that all your data is up-to-date and easily accessible.
                            </span>
                        </p>
                    }

                </div>
                <div>
                    <p className="text-slate-600 text-semibold flex gap-2 items-center mb-2 underline">General</p>

                    <form onSubmit={handleSubmit(onSubmit)} className="px-4 py-2 flex flex-col gap-4">
                        <ul className="flex flex-col gap-4 w-full">
                            <li className="flex justify-between gap-2 w-full text-sm text-slate-500">
                                <div className="w-1/2">
                                    <label htmlFor="name" className="flex gap-1 items-center">
                                        <span className="text-xs"><FiHexagon/></span>Name of form
                                    </label>
                                </div>
                                <div className="w-1/2">
                                    <input
                                        type="text"
                                        disabled={!data.isModifiable || data.isStandard}
                                        {...register("name")}
                                        className={`bg-slate-50 focus:outline-none pl-1 ${(!data.isModifiable || data.isStandard) ? "disabled:border-slate-100 cursor-not-allowed" : "border border-slate-200 rounded-sm"}`}
                                    />
                                </div>
                            </li>

                            <li className="flex justify-between gap-2 w-full text-sm text-slate-500">
                                <div className="w-1/2">
                                    <label htmlFor="code" className="flex gap-1 items-center"> <span
                                        className="text-xs"><FiMinus/></span>Code of Select
                                    </label>
                                </div>
                                <div className="w-1/2">
                                    <input
                                        type="text"
                                        disabled={!data.isModifiable || data.isStandard}
                                        {...register("code")}
                                        className={`border border-slate-200 rounded-sm bg-slate-50 focus:outline-none pl-1 ${(!data.isModifiable || data.isStandard) ? "disabled:border-slate-100 cursor-not-allowed" : "border border-slate-200 rounded-sm"}`}
                                    />
                                </div>
                            </li>
                        </ul>

                        {(data.isStandard) ?
                            ""
                            :
                            data.isModifiable &&
                            <div className="flex items-center gap-4 justify-between mt-4">
                                <button
                                    title="Save updated form"
                                    type="submit"
                                    className="bg-blue-400 border border-blue-500 hover:bg-blue-500 transition ease-linear text-slate-50 font-medium rounded-full p-2 w-20"
                                >Save</button>

                                <button
                                    title="Delete form"
                                    type="button"
                                    className="hover:border-red-500 hover:bg-red-500 border-red-400 bg-red-400 text-slate-50 rounded-full w-8 h-8 flex items-center justify-center transition ease-linear border"
                                    onClick={() => setOpenConfirmation(true)}
                                ><FiTrash/></button>

                                <ConfirmDialog
                                    isOpen={openConfirmation}
                                    onClose={() => setOpenConfirmation(false)}
                                    setIsOpen={setOpenConfirmation}
                                    onAgree={() => handleDeleteOnUpdateBox()}
                                />
                            </div>
                        }

                    </form>
                </div>
            </div>

            <hr/>

            { listOptions &&
                <div className="h-3/6 flex flex-col gap-4 relative">
                    {isUpdateSelectOption ?
                        <FormSelectOptions
                            setformSelectOption={setIsUpdateSelectOption}
                            optionId={isOptionIdSelected}
                            data={listOptions}
                            setIsSubmitting={setIsSubmittingSelect}
                            setIsOpenBox={setIsUpdateSelectOption}
                            method="PUT"
                        />
                        :
                        <CustomTables
                            data={listOptions}
                            handler={handleOpenSelectOption}
                            handlerView={handleSelectOption}
                            handlerDelete={handleClickDelete}
                            id={drawerData.idSelected}
                            itemPerPage={5}
                            tableFor="selectOption"
                            fullWidth
                            setIsSubmitting={setIsSubmittingSelect}
                            method="PUT"
                            isModified={data.isModifiable}
                            isStandard={data.isStandard}
                        />
                    }
                </div>

            }
        </div>

    );
};

export default FormSelect;
