import {SectionComponent} from "../../components/SectionComponent";
import DocsImage from "../../assets/images/docsbg.webp";

export const Documentation = () => {
    return (
            <SectionComponent
                title={infos.title}
                titleColor={ infos.titleColor}
                description={infos.description}
                image={infos.image}
                docs
                borderBottom
            />
    )
}

const infos = {
    title: "Full documentation of functionalities",
    titleColor: "(French only)",
    description: "Explore our documentation hub for essential insights and tips. Unlock the full potential of our platform with step-by-step guides, troubleshooting solutions, and more. Your go-to resource for mastering every aspect of our features.",
    image: DocsImage
}
