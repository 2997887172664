import { useEffect, useState } from 'react'
import ResourceService from '../services/Resource'

export const useFetchResourceFromResourceTypeId = (resourceTypeId, token, setIsLoading, increment, infrastructureId) => {

    const [listResources, setListResources] = useState([])
    const [error, setError] = useState(false)

    useEffect(() => {
        async function fetchAllResources() {
            try {
                setIsLoading(true)
                if (undefined !== resourceTypeId && null !== resourceTypeId) {
                    const resource = new ResourceService()
                    const result = await resource.getAllResourceFromResourceTypeId(resourceTypeId, token)
                    setListResources(result)
                } else if (undefined !== infrastructureId && null !== infrastructureId) {
                    const resource = new ResourceService()
                    const result = await resource.getAllResourceFromInfrastructureId(infrastructureId, token)
                    setListResources(result)
                }
            } catch (err) {
                setError(err)
            }
            setIsLoading(false)
        }

        fetchAllResources().then()
    }, [
        resourceTypeId,
        token,
        increment,
    ])

    return { listResources, error }
}