import * as React from 'react';
import {useEffect, useState, useRef, createContext} from "react";
import {Form} from "react-final-form";

// Design
import {Grid} from "@mui/material";

// Components
import {FormContentButtons} from "../components/navigation/FormContentButtons";

// Custom Hooks
import { useFetchFormPreloadServices, useFetchFormSelectInfras } from "../hooks/useFetchFormContent";

// Captcha
import {Captcha} from "../../captcha/Captcha";

// Context
export const ReusableFormContext = createContext(null)


export default function ReusableFinalFormContainer ({
                                              // Fetching Datas
                                              networkIdOrigin,
                                              listInfrastructures,

                                              // Form
                                              mainState,
                                              validate,

                                              // Submit Form
                                              isLoadingState,
                                              sendingFormRequest,

                                              // Form tag
                                              isPpaLocked,

                                              // FormSpecific
                                              listTabs,
                                              joinedInputs,

                                              // List Type Form
                                              setState,

                                              // FormContentButtons
                                              validMandatory,

                                              // Component
                                              featureEdition,

                                              isRead,

                                              hideCaptcha,
                                          }) {

    // General Hooks
    const containerRef = useRef();

    // Fetch List Infrastructures
    const { selectInfras, isLoadingSelectInfras } = useFetchFormSelectInfras(listInfrastructures, networkIdOrigin);

    // Fetch Preload Services
    const { preloadServices } = useFetchFormPreloadServices(networkIdOrigin);

    // Steppers Management
    const [activeStep, setActiveStep] = useState(0);


    /************ Toggle Missing Fields ************
     ** Used for :
     **  - Prevent Submit
     **  - On Save
     **  - Focus On Missing Fields  *****************/
    const [hideMissingFields, setHideMissingFields] = useState(true);

    /***** Required Fields - Live Count Error Message *****/
    const [missingFields, setMissingFields] = useState([]);
    const [missingFieldsChanges, setMissingFieldsChanges] = useState(0);

    const missingFieldsCounter = () => {

        const tabsIds = listTabs.map((item) => item.id);

        let severalClasses = [];
        let singleClass = [];

        // .slice() to Exclude Mandatory Fields
        // for (let tab of tabsIds.slice(1)) {
        for (let tab of tabsIds) {
            const hiddenUnfilledTab = document.getElementsByClassName(`hidden_${tab}`)

            // Several Forms sharing same Tab class
            if (hiddenUnfilledTab.length > 1) {
                let mergedValues = 0;
                for(let unfilleds of hiddenUnfilledTab) {
                    mergedValues += Number(unfilleds.value)
                }
                severalClasses.push({ id: tab, value : mergedValues })
            }

            // Only one Form in a Tab
            if (hiddenUnfilledTab.length === 1) {
                for(let unfilled of hiddenUnfilledTab) {
                    singleClass.push({ id: tab, value : Number(unfilled.value) })
                }
            }
        }

        const mergeResult = [...severalClasses, ...singleClass]

        mergeResult.length !== 0 && setMissingFields(mergeResult)

    }

    useEffect(() => {
        missingFieldsCounter();
    }, [missingFieldsChanges]);

    // console.log(missingFieldsChanges)

    /********************* ***********************/
    /********** Sending Form management **********/
    /********************* ***********************/

    /***** Captcha *****/
    const [isValidCaptcha, setIsValidCaptcha] = useState(hideCaptcha ?? false);

    const onVerify = () => {
        // Submit the form
        setIsValidCaptcha(true);
    };

    /***** Required Fields Validation On Submit *****/
    const requiredFieldsValidation = (submit) => {

        if(!hideCaptcha) {
            // Captcha Error Message
            const captchaMessage = document.getElementById('captcha_message')
            captchaMessage.style.display = !isValidCaptcha ? 'block' : 'none'
        }

        // Required Fields Total Check
        const unfilledTotal = document.getElementById(`unfilled_total`);
        let unfilledTotalCount = 0;
        unfilledTotal && (unfilledTotalCount = unfilledTotal.value);

        if (unfilledTotalCount > 0 || !isValidCaptcha) {

            setHideMissingFields(false);
            missingFieldsCounter();

            // Scroll to see errors notifications
            if (unfilledTotalCount > 0) {
                window.scrollTo({ behavior: "smooth", top: containerRef.current.offsetTop });
            }

            return false;

        } else {
            submit();
        }
    }


    const handleSave = (values) => {

        setState(prevState => ({
            ...prevState,
            isLoading: true,
        }));

        // Save => Hide Missing Fields Errors
        const { saveform } = values;
        saveform && setHideMissingFields(true);

        // Set empty values to indexes with no datas - based on all inputs length
        const changedArray = values.listInputValues

        for (let i = 0; i < joinedInputs.length; i++) {

            // console.log(changedArray[i].value);

            // Fill with empty value
            if (!changedArray[i]) {
                changedArray[i] = { value: '', keyLink: null, input: '/api/inputs/' + joinedInputs[i].id }
            }

            // Single Number Value  (Ex : id)
            if (typeof changedArray[i].value === 'number') {
                changedArray[i].value = (changedArray[i].value).toString();
            }

            // Array of Number Values  (Ex : array of ids)
            if (Array.isArray(changedArray[i].value)) {
                changedArray[i].value = (changedArray[i].value).join(',');
            }

            // Boolean (Ex: checkbox)
            if (typeof changedArray[i].value === 'boolean') {
                changedArray[i].value = (changedArray[i].value).toString();
            }

            // null (Ex: date)
            if (changedArray[i].value === null) {
                changedArray[i].value = '';
            }
        }

        /***** Sending Form Request from FeatureContainer *****/
        sendingFormRequest(values)
    }


    return (
        <Form
            onSubmit={handleSave}
            initialValues={mainState}
            keepDirtyOnReinitialize

            validateOnBlur={true}
            validate={validate}

            // Base
            render={({ handleSubmit,
                       form,
                       submitting,
                       values,
                       valid
                     }) => (
                <form className={`reusableform
                                  h-full
                                  flex
                                  flex-col
                                  ${!hideMissingFields ? 'missing-fields-focus' : ''}
                                  ${isPpaLocked && 'hidden'}
                                 `}
                      onSubmit={handleSubmit}
                      ref={containerRef}
                >


                    <ReusableFormContext.Provider value={{
                        // Data states
                        handleSave,
                        valid,
                        selectInfras,
                        isLoadingSelectInfras,
                        preloadServices,
                        missingFields,
                        setMissingFieldsChanges,
                        hideMissingFields,
                        activeStep,
                        setActiveStep,

                        // Form
                        form,
                        values
                    }}>
                        { featureEdition }
                    </ReusableFormContext.Provider>


                    {/* Captcha */}
                    {(!hideCaptcha && !isRead) &&
                        <Grid container sx={{
                            position: 'relative',
                            margin: 'auto 0 0',
                            ...(!(activeStep === listTabs.length - 1) && {
                                display: 'none'
                            })
                        }}
                        >
                            <Captcha onVerify={onVerify}/>
                        </Grid>
                    }

                    {/* Buttons Navigation */}
                    <FormContentButtons requiredFieldsValidation={requiredFieldsValidation}
                                        handleSubmit={handleSubmit}
                                        activeStep={activeStep}
                                        setActiveStep={setActiveStep}
                                        // listTabs={listTabs}
                                        listTabs={listTabs.filter((tab) => !tab.isHidden)}
                                        submitting={submitting}
                                        form={form}
                                        valid={valid}
                                        validMandatory={validMandatory}

                                        isRead={isRead}

                                        joinedInputs={joinedInputs} // TODO - Temporary control
                                        isSubmitting={isLoadingState}
                    />

                </form>
            )}
        />
    );
}