import React, {useEffect, useState} from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {Button, TextField} from "@mui/material";
import {
    FiAlignLeft,
    FiArrowLeft,
    FiArrowRight,
    FiMap,
    FiX
} from "react-icons/fi";
import {useFetchServicesFilterById} from "../../features/service/hooks/useFetchServicesFilterById";
import {AnimatePresence, motion} from "framer-motion";
import { FiChevronDown } from "react-icons/fi";
import {useSelector} from "react-redux";

import { useMemo } from "react";
import {truncateTextLetter} from "../../utils/truncateText";
import { extractDigit } from "../../features/catalog/utils/extractDigit";
import {useLocation, useNavigate} from "react-router-dom";
import {toast} from "sonner";
import {removeAccentAndSpecialCharacters} from "../../utils/RemoveAccentAndSpecialCharacters";
import {FaQuestion} from "react-icons/fa";
import {FreeTextExample} from "../../data/freeTextExample";
import {useMobile} from "../../hooks/useMobile";
import {PiListChecksBold} from "react-icons/pi";
import ListCheckBox from "../../features/catalog/components/ListCheckBox";

export const CustomFilter = ({ id, handleApplyFilter, handleResetFilter, totalItems, handleList, list, setPageLoad, totalDataVisible}) => {


    const [activeTab, setActiveTab] = useState(null);
    const [displayInfo, setDisplayInfo] = useState(null);

    const [checkboxStates, setCheckboxStates] = useState({});
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

    // States for various filter options
    const [filterString, setFilterString] = useState("");
    const [scientificDomainFilters, setScientificDomainFilters] = useState([]);
    const [scientificSubDomainFilters, setScientificSubDomainFilters] = useState([]);
    const [countryFilters, setCountryFilters] = useState([]);
    const [riFilters, setRiFilters] = useState([]);
    const [keywordFilters, setKeywordFilters] = useState([]);
    const [textFilters, setTextFilters] = useState([]);
    const [textFiltersArray, setTextFiltersArray] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const [allResult, setAllResult] = useState([]);
    const [subDomainDataFilters, setSubDomainDataFilters] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [hoveredItem, setHoveredItem] = useState(null);

    const [domainIds, setDomainIds] = useState(new Set());

    // Fetch data for filters based on ID
    const {  isLoading} = useFetchServicesFilterById(id);

    // Redux data of filter
    const data = useSelector((state) => state.filterReducers.data);

    const navigate = useNavigate();
    const location = useLocation();
    const isMobile = useMobile();

    // Filter scientific SubDomain when scientific domain is select
    useEffect(() => {
        // If the id corresponding to agroserv Id add id to domainIds
        if (Number(id) !== 2) return;

        if (scientificDomainFilters) {
            const catchDomainIds = new Set(scientificDomainFilters.map(item => item.id));

            setDomainIds(catchDomainIds);
        }

    }, [scientificDomainFilters]);

    useEffect(() => {
        if (Number(id) !== 2) return;
        if (!domainIds) return;

        const findSubDomain = data?.find( item => String(item.code) === "scientificSubDomain");
        const filteredSubDomain = findSubDomain?.data.filter(item => domainIds.has(item.scientificDomainId));

        setSubDomainDataFilters(filteredSubDomain)

    }, [domainIds])

    // Effect to filter search results based on active tab and search term
    useEffect(() => {

        const results = data?.find(tab => tab.code === activeTab)?.data.filter(item =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase())
        );

        setSearchResults(results);

    }, [activeTab, data, searchTerm]);

    // Effect to extract query parameters from URL and initialize filter states
    useEffect(() => {
        const extractQueryParams = () => {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const paramsArray = [];
            const allResultArray = [];

            // Extraire les segments d'URL
            const pathSegments = window.location.pathname.split('/');
            const networkId = pathSegments[2];
            const catalogTypeSegment = pathSegments[3];
            const catalogType = catalogTypeSegment === 'installations' ? '2' : '3';

            // Stocker dans localStorage si ce n'est pas déjà présent
            const selectedData = { networkId, catalogType };
            if (!localStorage.getItem('selectedData')) {
                localStorage.setItem('selectedData', JSON.stringify(selectedData));
            }

            urlParams.forEach((value, key) => {
                const type = key.replace('[]', '');
                value.split(',').forEach(id => {
                    if (type === 'searchTerm') {
                        setTextFilters(id);
                        allResultArray.push([`${type}[]=${id}`, id]);
                    } else {
                        const param = { type, id: parseInt(id, 10) };
                        paramsArray.push(param);

                        // Mettre à jour l'état des cases à cocher
                        setCheckboxStates(prevState => ({
                            ...prevState,
                            [param.id]: true
                        }));

                        // Mettre à jour les filtres
                        updateFilters(param.id, type, true);

                        // Ajouter à allResult
                        const name = data?.find(tab => tab.code === type)?.data.find(item => item.id === param.id)?.name || '';
                        allResultArray.push([`${type}[]=${param.id}`, name]);
                    }
                });
            });

            setFilterString(paramsArray);
            setAllResult(allResultArray);
        };

        extractQueryParams();
    }, [data]);

    // Handle tab selection and search term reset || Handle checkbox changes and update filters
    const handleClickChecked = (code) => {
        setCurrentPage(1);

        if (code === activeTab){
            return setActiveTab(activ => activ = null)
        }

        setActiveTab(ac => ac = code);
        setSearchTerm("");
    };
    const handleCheckboxChange = (id, name, type) => {
        setCheckboxStates((prevCheckboxStates) => {
            const isChecked = !prevCheckboxStates[id];
            // Update filters
            updateFilters(id, type, name, isChecked);

            // Update checkbox
            setSelectedCheckboxes((prevSelectedCheckboxes) => {
                if (isChecked) {
                    return [...prevSelectedCheckboxes, {id, name}];
                } else {
                    return prevSelectedCheckboxes.filter((checkboxId) => checkboxId.id !== id);
                }
            });

            // display checkbox
            return {
                ...prevCheckboxStates,
                [id]: isChecked,
            };
        });
    };

    // Update filter State based on checkbox changes || Update filters based on active tab or filter type
    const updateFilterState = (prevFilters, id, name, isChecked) => {
        let uniqueData;
        if (isChecked) {
            const filter = [...prevFilters, { id, name }];
            const uniqueId = [...new Set(filter.map(item => item.id))];
            uniqueData = uniqueId.map(id => filter.find(item => item.id === id));
            return uniqueData;
        } else {
            return prevFilters.filter(filter => filter.id !== id);
        }
    };

    const updateFilters = (id, type, name, isChecked) => {
        switch (activeTab || type) {
            case 'scientificDomain':
                setScientificDomainFilters(prevFilters => updateFilterState(prevFilters, id, name, isChecked));
                break;
            case 'scientificSubDomain':
                setScientificSubDomainFilters(prevFilters => updateFilterState(prevFilters, id, name, isChecked));
                break;
            case 'ri':
                setRiFilters(prevFilters => updateFilterState(prevFilters, id, name, isChecked));
                break;
            case 'country':
                setCountryFilters(prevFilters => updateFilterState(prevFilters, id, name, isChecked));
                break;
            case 'keyword':
                setKeywordFilters(prevFilters => updateFilterState(prevFilters, id, name, isChecked));
                break;
            case 'searchTerm':
                setTextFilters(id);
                break;
            default:
                break;
        }
    };

    // Handle search term changes || Handle find to value
    const handleSearchChange = (event) => {
        const value = event.target.value;
        setTextFilters(value);
    };

    const handleFindValue = (e) => {

        if (currentPage !== 1) {
            setCurrentPage(1)
        }
        const term = e.target.value;
        setSearchTerm(term);
    };

    //Apply filter and update URL || Reset filter and update URL
    const handleApply = (e) => {
        e.preventDefault();

        e.stopPropagation()

        // Check if isn't empty
        if (
            countryFilters.length === 0 &&
            scientificDomainFilters.length === 0 &&
            scientificSubDomainFilters.length === 0 &&
            keywordFilters.length === 0 &&
            riFilters.length === 0 &&
            textFilters.length === 0
        ) {
            // Add a message for the user
            return toast.warning("Please select at least one filter first.");
        }

        let filterParts = [];
        // Add value in the filterPart array when the condition is accepted

        if (textFilters.length > 0) {
            const textFiltersArray = textFilters.split(',').map(item => item.trim());
            filterParts.push(...textFiltersArray.map(key => [`searchTerm[]=${removeAccentAndSpecialCharacters(key)}`, key]));
        }

        // Add checkbox value selected
        filterParts.push(
            ...scientificDomainFilters.map((key) => [`scientificDomain[]=${key.id}`, key.name]),
            ...scientificSubDomainFilters.map((key) => [`scientificSubDomain[]=${key.id}`, key.name]),
            ...countryFilters.map((key) => [`country[]=${key.id}`, key.name]),
            ...riFilters.map((key) => [`ri[]=${key.id}`, key.name]),
            ...keywordFilters.map((key) => [`keywords[]=${key.name}`, key.name])
        );

        // Add in the element checked in the all result tab
        setAllResult(filterParts);

        const filterStrings = filterParts.map(item => item[0]).join("&");
        // setFilterString(filterString)
        navigate(`?${filterStrings}`);

        // Launch the handleApplyFilter with the filter array
        handleApplyFilter(filterStrings);

        setActiveTab(null);
        setSearchResults([]);
        setSearchTerm("");

        // Load item
        setPageLoad(true);
    };

    const handleReset = (e) => {
        e.preventDefault();

        //Check if the allResult is empty
        if (!window.location.search) {
            return toast.warning("You didn't any filters");
        }
        // Reset filter states after application
        setActiveTab(null);
        setCheckboxStates({});
        // Reset specific filter statuses if necessary
        setScientificDomainFilters([]);
        setScientificSubDomainFilters([]);
        setKeywordFilters([]);
        setCountryFilters([]);
        setRiFilters([]);
        setTextFilters("");
        setSearchResults([]);
        setSearchTerm([]);
        setAllResult([])

        // Load item
        setPageLoad(true);

        // Update URL
        navigate(window.location.pathname);

        // Reset filters
        handleResetFilter()
    };

    // Remove a specific filter element || Reset all filters
    const handleRemoveElementInFilter = (id) => {
        let tabFilter = allResult?.filter(item => String(item[0]) !== String(id));

        const searchParams = new URLSearchParams(location.search);
        const params = {};

        // Iterate over all keys in the search parameters
        for (let [key] of searchParams.entries()) {
            params[key] = searchParams.getAll(key);
        }

        // Remove element in the filters
        setCountryFilters(countryFilters.filter(item => String(item.id) !== extractDigit(id)));
        setScientificSubDomainFilters(scientificSubDomainFilters.filter(item => String(item.id) !== extractDigit(id)));
        setScientificDomainFilters(scientificDomainFilters.filter(item => String(item.id) !== extractDigit(id)));
        setRiFilters(riFilters.filter(item => String(item.id) !== extractDigit(id)));
        setKeywordFilters(keywordFilters.filter(item => String(item.id) !== extractDigit(id)));

        // Remove element from textFiltersArray
        setTextFiltersArray(prevArray => prevArray.filter(item => String(removeAccentAndSpecialCharacters(item)) !== removeAccentAndSpecialCharacters(id)));

        // Update the textFilters state
        const newTextFilters = textFiltersArray.filter(item => String(removeAccentAndSpecialCharacters(item)) !== removeAccentAndSpecialCharacters(id));
        setTextFilters(newTextFilters.join(', '));

        // Add element checked in the allResult to display data
        setAllResult(tabFilter);

        // Remove the corresponding checkbox state
        delete checkboxStates[extractDigit(id)];

        // Generate new query string
        let el = tabFilter?.map(item => item[0]).join("&");

        // Apply the filters
        handleApplyFilter(el);
        navigate(`?${el}`);

        setActiveTab(null);
        setSearchResults([]);
        setSearchTerm([]);

        // Load item
        setPageLoad(true);
    };

    // Use values directly from data based on activeTab
    const values = useMemo( () => {
        return data?.find((tab) => tab.code === activeTab) || { type: "text", data: [] }
    }, [data, activeTab])


    // Pagination in the filter
    const ITEMS_PER_PAGE = 20;

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const currentItems = searchResults?.slice(startIndex, endIndex);

    const currentItemFiltersSubDomain = subDomainDataFilters?.slice(startIndex, endIndex);


    const totalPages = Math.ceil(searchResults?.length / ITEMS_PER_PAGE);

    const handleNextPage = (e) => {
        e.preventDefault()
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    };

    const handlePrevPage = (e) => {
        e.preventDefault()
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleDisplayInfo = () => {
        setDisplayInfo(true)
    }
    const handleRemoveDisplayInfo = () => {
        setDisplayInfo(false)
    }

    return (
        <div className={`w-full flex flex-col items relative`}>
            <motion.div
                className="flex flex-col gap-2 relative"
                initial={{opacity: 0, height: 0}}
                animate={{
                    opacity: 1,
                    height: "auto",
                    transition: {
                        type:"spring",
                        bounce: .3,
                    }

                }}
                exit={{
                    height:0,
                    opacity:0
                }}
                transition={{
                    duration:.2,
                    type: "spring",
                    bounce:0,
                }}
            >
                <div className={`overflow-y-hidden`}>
                    <ul className="flex lg:flex-wrap flex-nowrap items-center gap-5 min-h-14 w-[900px]">
                        {data?.map(item =>
                            <li key={item.id} className="relative">
                                <button
                                    className={`flex w-full items-center gap-2 max-lg:text-[.845rem]  ${activeTab === item.code ? "text-blue-500" : ""}`}
                                    onClick={() => handleClickChecked(item.code)}
                                >
                                    {item.title} <span
                                    className={`transition ease-in duration-300 ${activeTab === item.code ? "rotate-180" : "rotate-0"} `}><FiChevronDown/></span>
                                </button>

                                { item.code === "scientificSubDomain" && domainIds.size !== 0 &&
                                    <motion.span
                                        className={`cursor-pointer bg-blue-500 w-5 h-5 text-xs absolute -top-3 -right-3 rounded-full flex items-center justify-center text-slate-50`}
                                        title="You have applied a filter on the scientific domain."

                                        initial={{ opacity:0, scale:.9 }}
                                        animate={{ opacity:1, scale:1 }}
                                        exit={{ opacity:0 }}

                                        transition={{ type:"spring", duration:.3, ease:"linear"}}
                                    >
                                        {domainIds.size}
                                    </motion.span>
                                }
                            </li>
                        )}
                    </ul>
                </div>

                {allResult.length !== 0 &&
                    <ul className="flex flex-wrap items-center gap-2 p-2 border-slate-200 border-t">
                        {allResult.map((item, index) =>
                            <li key={index + 1}
                                className="bg-slate-100 py-2 px-4 text-xs rounded-full flex gap-2 items-center justify-center">
                                {item[1]}
                                <button
                                    className="text-xs"
                                    onClick={
                                        (e) => {
                                            e.preventDefault();
                                            handleRemoveElementInFilter(String(item[0]))
                                        }
                                    }
                                >
                                    <motion.span
                                        whileHover={{scale: 1.100}}
                                        whileTap={{scale: .945}}
                                        transition={{type: "spring", duration: .5, ease: "linear"}}
                                    ><FiX/></motion.span>
                                </button>
                            </li>
                        )}
                        { allResult.length > 1 &&
                            <li>
                                <button
                                    className={`bg-red-500 text-slate-50 rounded-full text-xs py-2 px-4`}
                                    onClick={handleReset}
                                    title={"Reset all filters"}
                                >
                                    Reset
                                </button>
                            </li>

                        }
                    </ul>
                }


                <AnimatePresence>
                    {activeTab !== null && (
                        <motion.form
                            onSubmit={handleApply}
                            initial={{opacity: 0, translateY: -25}}
                            animate={{opacity: 1, translateY: 0}}
                            exit={{opacity: 0, translateY: -25}}

                            transition={{type: "spring", duration: .5, ease: "linear"}}

                            className={`w-full p-4`}
                        >
                            <div className="formresult">
                                {values.type !== "text" &&

                                    <div className="flex items-center justify-start gap-4 mb-10">
                                        <TextField
                                            id="search"
                                            label="Search"
                                            variant="outlined"
                                            autoComplete="off"
                                            value={searchTerm}
                                            sx={{
                                                width: "400px",
                                                height: "45px"
                                            }}
                                            onChange={handleFindValue}
                                        />
                                        {   searchResults?.length > 20 &&
                                            <div className="px-4 flex gap-4 items-center">
                                                {/* Pagination buttons */}
                                                <button
                                                    onClick={handlePrevPage}
                                                    disabled={currentPage === 1}
                                                    className={`${currentPage === 1 ? "border-slate-50 bg-slate-50 text-slate-300" : "border-slate-100 bg-slate-100 p-2 text-slate-600"} border border-solid  rounded-full transition`}
                                                >
                                                    <FiArrowLeft/>
                                                </button>
                                                <button
                                                    onClick={handleNextPage}
                                                    disabled={currentPage === totalPages}
                                                    className={`${currentPage === totalPages ? "border-slate-50 bg-slate-50 text-slate-300" : "border-slate-100 bg-slate-100 p-2 text-slate-600"} border border-solid  rounded-full transition`}
                                                >
                                                    <FiArrowRight/>
                                                </button>
                                            </div>
                                        }


                                    </div>

                                }

                                <AnimatePresence>
                                    {!isLoading &&
                                        <ul className="grid grid-cols-2 lg:grid-cols-4 gap-0 auto-rows-max max-h-[400px] mb-5">
                                            {values.type === "text" ?
                                                <li key={1} className="col-span-full lg:w-1/3 w-full relative">
                                                    <TextField
                                                        id="search"
                                                        label="Search"
                                                        variant="standard"
                                                        autoComplete="off"
                                                        value={textFilters && textFilters}
                                                        sx={{
                                                            width: "100%"
                                                        }}

                                                        onChange={handleSearchChange}
                                                    />

                                                    <button
                                                        type="button"
                                                        className="border border-slate-200 rounded-full w-6 h-6 flex items-center justify-center text-xs absolute right-2 bottom-2 z-30"
                                                        onMouseEnter={ handleDisplayInfo }
                                                        onMouseLeave={ handleRemoveDisplayInfo }
                                                    >
                                                        <FaQuestion />
                                                    </button>

                                                    {displayInfo &&
                                                        <motion.div
                                                            className={`border border-slate-200 p-2 max-w-72 min-h-10 absolute -right-14 -bottom-64 bg-white z-10 rounded-xl flex flex-col gap-2`}
                                                            initial={{opacity: 0, scale: .8}}
                                                            animate={{opacity: 1, scale: 1}}
                                                            exit={{opacity: 0, scale: .8}}

                                                            transition={{type: "spring", duration: .5, ease: "linear"}}
                                                        >
                                                            <ul className="divide-y divide-slate-200">
                                                                {FreeTextExample.map(item =>
                                                                    <li key={item.id} className="text-slate-700 text-xs flex flex-col gap-2 py-2">
                                                                        <p>{item.description}</p>
                                                                        <p className="flex flex-col gap-2"><span className="underline">Example: </span><span className="italic">{item.example}</span></p>
                                                                    </li>
                                                                )}
                                                            </ul>

                                                        </motion.div>
                                                    }
                                                </li>
                                                :
                                                <>
                                                    { domainIds.size > 0 && activeTab === "scientificSubDomain" ?
                                                        <>
                                                            { subDomainDataFilters.length === 0 ?
                                                                <p>Oops, this filter returned no results...</p>
                                                                :
                                                                currentItemFiltersSubDomain.map(item =>
                                                                    <ListCheckBox
                                                                        item={item}
                                                                        key={item.id}
                                                                        checkboxStates={checkboxStates}
                                                                        handleCheckboxChange={handleCheckboxChange}
                                                                    />
                                                            )}
                                                        </>
                                                        :
                                                        <>
                                                            {currentItems?.map((item, idx) =>
                                                                <ListCheckBox
                                                                    item={item}
                                                                    key={idx+1}
                                                                    checkboxStates={checkboxStates}
                                                                    handleCheckboxChange={handleCheckboxChange}
                                                                />
                                                            )}
                                                        </>
                                                    }
                                                </>
                                            }
                                        </ul>
                                    }
                                </AnimatePresence>

                                <div className="flex items-center justify-between">
                                    <div className="flex items-center gap-2">
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="dark"
                                            sx={{
                                                color: "#fafafa",
                                                borderRadius: "25px"
                                            }}
                                            onClick={handleApply}
                                        >
                                            Apply
                                        </Button>
                                    </div>

                                    {/*<ul className="flex items-center divide-x">*/}
                                    {/*    <li className="flex items-center justify-center px-4">*/}
                                    {/*        <p className="text-slate-500 lg:text-md text-xs"> There*/}
                                    {/*            are {totalDataVisible} of {totalItems} results</p>*/}
                                    {/*    </li>*/}
                                    {/*</ul>*/}

                                </div>

                            </div>
                        </motion.form>
                    )}
                </AnimatePresence>

            </motion.div>
        </div>
    );
};
