// CKEditorWrapper.jsx
import React, {useContext, useEffect, useState} from 'react';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// Context
import {ListTypeFormContext} from "./listtypeform/ListTypeFormsContainer";
import {FormContentFormsContext} from "../FormContentForms";

// Custom Functions
import {missingFieldsChecking} from "../../../utils/CustomFunctions";


const CKEditorWrapper = ({ id, inputIndex, values, input, meta, hide, required, keyLinkValue, ...rest }) => {
    // const { name, onChange, onFocus } = input;
    const { onFocus } = input;
    const { touched, error } = meta;
    const [writtenDatas, setWrittenDatas] = useState('');

    // From Context (Cancel Field Management)
    const listTypeFormContext = useContext(ListTypeFormContext);
    const {
        cancelFieldsData: cancelFieldsDataContext,
        setCancelFieldsData: setCancelFieldsDataContext,
        remainingLinesData: remainingLinesDataContext,
        setRemainingLinesData: setRemainingLinesDataContext
    } = listTypeFormContext !== null && listTypeFormContext;

    // From Context (Missing Field Management)
    const formContentFormsContext = useContext(FormContentFormsContext);
    const {
        reloadMissingFieldsChecking: reloadMissingFieldsCheckingContext,
        setReloadMissingFieldsChecking: setReloadMissingFieldsCheckingContext,
    } = formContentFormsContext !== null && formContentFormsContext;


    // Avoid duplicate id Browser Issue
    const fieldUniqueId = inputIndex + '_' + id

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        // onChange(data);

        setWrittenDatas(data);
    };


    const textAreaValuesSetAfterSave = () => {

        const listTypeItemFromStorage = localStorage.getItem("listTypeItem");
        const listTypeItemFromStorageArray = JSON.parse(listTypeItemFromStorage)

        // Restore data for remaining lines after a cancel or a line removal
        for (let i = 0; i < listTypeItemFromStorageArray.length; i++) {
            if ((listTypeItemFromStorageArray[i].input) === ('/api/inputs/' + id) &&
                (listTypeItemFromStorageArray[i].keyLink === keyLinkValue)) {

                const loadedFromStorage = (typeof listTypeItemFromStorageArray[i].value !== "string") ? "" : listTypeItemFromStorageArray[i].value;
                setWrittenDatas(loadedFromStorage)
            }
        }

        if (listTypeFormContext !== null) {
            setCancelFieldsDataContext(false);
            setRemainingLinesDataContext(false);
        }
    }

    // Retrieve Values
    const retrieveExistingListInputValues = () => {
        if (values.listInputValues[inputIndex]) {
            if (values.listInputValues[inputIndex].value !== "") {
                const loadedWrittenValues = values.listInputValues[inputIndex].value;
                setWrittenDatas(loadedWrittenValues)
            }
        }
    }

    useEffect(() => {
        (values.token !== null && values.listInputValues.length !== 0) && retrieveExistingListInputValues();
    }, [values]);


    // Values assigning
    useEffect(() => {
        // if(writtenDatas !== '') {

            if (values.token !== null && values.listInputValues[inputIndex]) {

                values.listInputValues[inputIndex].value = (writtenDatas !== '') ? writtenDatas : '';

            } else {
                values.listInputValues[inputIndex] = { value : writtenDatas };
                Object.assign(values.listInputValues[inputIndex], { keyLink: keyLinkValue, input: '/api/inputs/' + id });
            }
        // }
    }, [writtenDatas]);


    // Required Fields Detection
    useEffect(() => {
        missingFieldsChecking(hide, required, fieldUniqueId, reloadMissingFieldsCheckingContext, setReloadMissingFieldsCheckingContext, writtenDatas === '');
    }, [writtenDatas]);


    // List Type Form Saved Data Verification
    useEffect(() => {
        if (cancelFieldsDataContext || remainingLinesDataContext) {
            localStorage.listTypeItem && textAreaValuesSetAfterSave();
        }
    }, [cancelFieldsDataContext, remainingLinesDataContext]);


    const editorConfig = {
        toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
        heading: {
            options: [
                {model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph'},
                {model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1'},
                {model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2'},
                {model: 'heading3', view: 'h3', title: 'Heading 2', class: 'ck-heading_heading3'},
                {model: 'heading4', view: 'h4', title: 'Heading 2', class: 'ck-heading_heading4'},
            ],
        },
        heading2: {
            options: [
                {model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph'},
                {model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2'},
            ],
        },
    };

    return (
        <div id={fieldUniqueId} data-inputid={id}>
            <CKEditor
                config={editorConfig}
                editor={ClassicEditor}
                data={writtenDatas}
                onChange={handleEditorChange}
                onFocus={onFocus}
                {...rest}
            />
            {touched && error && <span style={{ color: 'red' }}>{error}</span>}
        </div>
    );
};

export default CKEditorWrapper;