const INITIAL_STATE = {
    user: null,
    isConnected: false,
    token: '',
    role: null,
    isSuperAdministrator: false,
}

// Si state == null, dans ce cas il prend INITIAL_STATE sinon le state fournit
export function login (state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'AUTH_LOGIN':
            return {
                user: action.payload,
                isConnected: true
            }
        case 'AUTH_LOGOUT':
            return {
                user: null,
                isConnected: false
            }
        default:
            return state;
    }
}