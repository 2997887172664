import {useParams} from "react-router-dom";
import {useFecthNewsById} from "../hooks/useFecthNewsById";
import { AnimatePresence, motion } from "framer-motion";
import {formatDateToCustom} from "../../../utils/CustomDate";
import {CustomImageNews} from "../utils/CustomImageNews";
import {NotFound} from "../../../pages/NotFound/NotFound";
export const DetailNews = () => {
    const { id } = useParams();

    const { data, isLoading, error } = useFecthNewsById(id);

    if (error) return <NotFound />

    return (
        <AnimatePresence>
            { !isLoading &&
                <motion.article
                    className={` bg-white min-h-screen flex flex-col items-center lg:pt-10 pt-52 mb-10`}
                    initial={{ opacity: 0 }}
                    transition={{type:"spring", duration:.1 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    <div className="container">
                        <div className="wrapper flex flex-wrap justify-center items-strecht gap-14">
                            <div className="lg:w-1/3 w-full grow flex flex-col items-center gap-10">
                                <div className="flex flex-col gap-8 items-center justify-center max-w-[900px]">
                                    <h2 className="lg:text-4xl text-3xl lg:font-bold font-medium lg:text-center text-left max-w-[800px] text-slate-900"> {data.title} </h2>
                                    <span className="block text-sm text-slate-700 order-first text-center">{formatDateToCustom(data.createdAt)} </span>
                                </div>


                                {/*<CustomBackButton title="All news" linktoback="news" />*/}

                                <div className="mb-5 overflow-hidden rounded-2xl lg:h-[500px] h-[350px]">
                                    <figure className="lg:h-[500px] h-[350px]">
                                        {CustomImageNews(data.type)}
                                    </figure>
                                </div>

                                <div className="text-left mt-8 max-w-[900px]" dangerouslySetInnerHTML={{__html: data.content}}/>

                            </div>

                        </div>
                    </div>

                </motion.article>
            }
        </AnimatePresence>

    )
}