import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import NetworkService from "../../../services/NetworkService";


export const useFetchNetworkDescription = (id) => {
    const token = useSelector(state => state.login.user?.token) ?? null;
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [ error, setError ] = useState(null);

    useEffect(() => {
       fetchNetworkDescription();
    }, [token, id]);

    const fetchNetworkDescription = async () => {
        try {
            const networkInfraDescription = new NetworkService();
            const res = await networkInfraDescription.getNetworkInfraDescription(id, token);

            setData(res);
            setIsLoading(false)
        } catch (e) {
            setError(e);
            setIsLoading(false)
        }
    }

    return { data, isLoading, error, fetchNetworkDescription }
}