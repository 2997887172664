import * as React from "react";

// Design
import {Grid, Typography} from "@mui/material";

// Components
import HCaptcha from "@hcaptcha/react-hcaptcha";
import './captcha.scss';


export const Captcha = ({ onVerify }) => {

    return (
        <Grid item justifyContent="center" alignSelf="flex-end" xs={12} lg={6}
              sx={{margin: '0 auto'}}>
            <div className={`flex
                         flex-wrap
                         mt-auto
                         w-full
                         justify-center`
            }
            >
                <Typography component={"p"}
                            variant="small"
                            id="captcha_message"
                            className="captcha-error"
                            sx={{ margin: '1rem auto' }}
                >
                    Please complete the captcha to verify that you are not a robot.
                </Typography>
                <HCaptcha
                    sitekey="af9d5104-0c4a-446d-8f3d-16dbcc551da7"
                    onVerify={onVerify}
                    languageOverride="en"
                />
            </div>
        </Grid>
    )

}
