import RoleService from "../../../../services/RoleService";
import {useEffect, useState} from "react";

export const useFetchRole = ( roleId, token, increment, setIsLoading ) => {

    const [role, setRole] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchRolesData =  async () => {
            try {
                setIsLoading(true);
                if (null !== roleId && undefined !== roleId) {
                    const roleService = new RoleService();
                    const res = await roleService.getRole(roleId, token, 'jsonld');
                    setRole(res);
                } else {
                    setRole(null);
                }
            } catch (error) {
                setError(error);
            }
            setIsLoading(false)
        }
        fetchRolesData().then();
    }, [roleId, error, token, increment]);

    return { role }
}