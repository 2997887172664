import AxiosService from "./AxiosService";


const urlSelect = 'api/selects';
const urlInputs = 'api/inputs';
const urlFrominfra = urlSelect + '?page=1&order%5Bname%5D=asc&exists%5BdeletedAt%5D=false&infrastructure=';
const urlFromNetwork = 'api/selects/config/network/';

class SelectService {

    getSelectOptions(id) {
        return AxiosService.getWithoutToken(urlSelect + '/' + id, 'jsonld');
    }

    getSelectInputs(id) {
        return AxiosService.getWithoutToken(urlInputs + '/' + id, 'jsonld');
    }

    getSelectInputsForDescription(id, token) {
        return AxiosService.getWithToken(urlInputs + '/' + id, token, 'jsonld');
    }

    getSelect(id, token) {
        return AxiosService.getWithToken(urlSelect + id, token, 'jsonld');
    }

    getSelectFromInfra(infraId, token) {
        return AxiosService.getWithToken(urlFrominfra + infraId, token, 'jsonld');
    }

    getSelectFromNetwork(networkId, token) {
        return AxiosService.getWithToken(urlFromNetwork + networkId, token, 'jsonld');
    }

    deleteSelect(id, token) {
        return AxiosService.deleteWithToken(urlSelect + '/' + id, token, 'jsonld');
    }

    createSelect(name, infraId, token, code = null){
        let selectCode = code !== null ? code : null;
        let data = {
            'name': name,
            'infrastructure': 'api/infrastructures/' + infraId,
            'code' : selectCode,
            'token': token,
        };
        return AxiosService.postWithToken(urlSelect,data,'jsonld')

    }

    updateSelect(selectId, name, infraId, token, code = null){
        let selectCode = code !== null ? code : null;
        let data = {'id': infraId, 'name': name,  'code' : selectCode};
        return AxiosService.putWithToken(urlSelect + '/' + selectId, data, token, 'jsonld')

    }

}

export default SelectService;

