import React, {useContext, useEffect, useState} from "react";
import {Field} from "react-final-form";

// Design
import {Checkbox, FormControlLabel, Grid} from "@mui/material";
import {FormLabelTooltip} from "./FormLabelTooltip";
import {IoInformationCircleOutline} from "react-icons/io5";

// Context
import {ListTypeFormContext} from "./listtypeform/ListTypeFormsContainer";
import {FormContentFormsContext} from "../FormContentForms";

// Custom Functions
import {missingFieldsChecking} from "../../../utils/CustomFunctions";


export const FormCheckBox = ({ name, description, label, id, type, required, isBlocked, hide, inputIndex, values, keyLinkValue, isRead }) => {

    const [checkedValues, setCheckedValues] = useState(false);

    // From Context (Cancel Field Management)
    const listTypeFormContext = useContext(ListTypeFormContext);
    const {
        cancelFieldsData: cancelFieldsDataContext,
        setCancelFieldsData: setCancelFieldsDataContext,
        remainingLinesData: remainingLinesDataContext,
        setRemainingLinesData: setRemainingLinesDataContext
    } = listTypeFormContext !== null && listTypeFormContext;

    // From Context (Missing Field Management)
    const formContentFormsContext = useContext(FormContentFormsContext);
    const {
        reloadMissingFieldsChecking: reloadMissingFieldsCheckingContext,
        setReloadMissingFieldsChecking: setReloadMissingFieldsCheckingContext,
    } = formContentFormsContext !== null && formContentFormsContext;


    // Avoid duplicate id Browser Issue
    const fieldUniqueId = inputIndex + '_' + id

    /***** CHECKBOXES *****/
    // Check status assigning
    const handleChange = (event) => {
        setCheckedValues(event.target.checked)
    };


    const checkboxValueSetAfterSave = () => {

        const listTypeItemFromStorage = localStorage.getItem("listTypeItem");
        const listTypeItemFromStorageArray = JSON.parse(listTypeItemFromStorage)

        // Restore data for remaining lines after a cancel or a line removal
        for (let i = 0; i < listTypeItemFromStorageArray.length; i++) {
            if ((listTypeItemFromStorageArray[i].input) === ('/api/inputs/' + id) &&
                (listTypeItemFromStorageArray[i].keyLink === keyLinkValue)) {

                const loadedCheckboxValueFromStorage = listTypeItemFromStorageArray[i].value;
                if (typeof loadedCheckboxValueFromStorage === "boolean") {

                    // Transform string from listInputValues in Boolean
                    if (loadedCheckboxValueFromStorage === true) {
                        setCheckedValues(true)
                    }

                    if (loadedCheckboxValueFromStorage === false) {
                        setCheckedValues(false)
                    }
                }

            }
        }

        if (listTypeFormContext !== null) {
            setCancelFieldsDataContext(false);
            setRemainingLinesDataContext(false);
        }
    }

    // Retrieve Values
    const retrieveExistingListInputValues = () => {
        if (values.listInputValues[inputIndex]) {
            if (values.listInputValues[inputIndex].value !== "") {

                const loadedCheckboxValue = values.listInputValues[inputIndex].value;

                // Transform string from listInputValues in Boolean
                if (loadedCheckboxValue === "true") {
                    setCheckedValues(true)
                }

                if (loadedCheckboxValue === "false") {
                    setCheckedValues(false)
                }

            }
        }
    }

    useEffect(() => {
        (values.token !== null && values.listInputValues.length !== 0) && retrieveExistingListInputValues();
    }, [values]);


    // Values assigning
    useEffect(() => {

        if (values.token !== null && values.listInputValues[inputIndex]) {
            values.listInputValues[inputIndex].value = checkedValues;
        } else {
            values.listInputValues[inputIndex] = { value : checkedValues };
            Object.assign(values.listInputValues[inputIndex], { keyLink: keyLinkValue, input: '/api/inputs/' + id });
        }

    }, [checkedValues]);


    // Required Fields Detection
    useEffect(() => {
        missingFieldsChecking(hide, required, fieldUniqueId, reloadMissingFieldsCheckingContext, setReloadMissingFieldsCheckingContext, !checkedValues);
    }, [checkedValues]);


    // List Type Form Saved Data Verification
    useEffect(() => {
        if (cancelFieldsDataContext || remainingLinesDataContext) {
            localStorage.listTypeItem && checkboxValueSetAfterSave();
        }
    }, [cancelFieldsDataContext, remainingLinesDataContext]);


    return (
        <Grid item xs={12} lg={6} hidden={hide}>
            <Field name={ name }
                   type={type}
            >
                {props => (
                    <FormControlLabel control={
                                        <Checkbox checked={typeof checkedValues === 'boolean' && checkedValues}
                                                  id={ `${fieldUniqueId}` }
                                                  className={"checkbox"}
                                                  disabled={isRead}
                                                  inputProps={{
                                                      'data-inputid':`${id}`
                                                  }}
                                        />
                                      }
                                      label={
                                        <div className={"flex items-center gap-2"}>
                                            <span>{ label } {required && "*"}</span>
                                            { description && (
                                                <FormLabelTooltip title={ <div dangerouslySetInnerHTML={{__html: description}}/> }>
                                                    <span>
                                                        <IoInformationCircleOutline className={"mt-[1.6px] text-blue-500 text-xl"}/>
                                                    </span>
                                                </FormLabelTooltip>
                                            )}
                                        </div>
                                      }
                                      required={required}
                                      name={props.input.name}
                                      value={props.input.value}
                                      onChange={handleChange}
                                      disabled={isRead || isBlocked}
                                      sx={{
                                          '& .MuiFormControlLabel-asterisk': { display: 'none' },
                                          '& .MuiFormControlLabel-label.Mui-disabled': { color: 'rgba(0,0,0, 1)'},
                                      }}
                    />
                )}
            </Field>
        </Grid>
    )
}
