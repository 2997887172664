import { useEffect, useState } from 'react'
import EventService from '../../../services/EventService'

export const useFetchEventById = (id, token) => {
    const [event, setEvent] = useState(null)
    const [error, setError] = useState(false)
    const [eventIsLoading, setEventIsLoading] = useState(true)

    useEffect(() => {
        async function fetchEventById() {
            try {
                if (null !== id) {
                    const eventService = new EventService()
                    const res = await eventService.getEvent(id, token)
                    setEvent(res)
                    setEventIsLoading(false)
                } else {
                    setEvent(null)
                    setEventIsLoading(false)
                }
            } catch (err) {
                setError(err)
            }
        }
        fetchEventById().then()
    }, [id])

    return { event, error, eventIsLoading }
}
