import React from "react";

const UserActivities = () => {
    return (
        <></>


    );
};

export default UserActivities