import { useState, useEffect } from 'react';
import ServiceService from "../service/ServiceService";
import { fetchallfilter } from "../../../redux/filter/filterActions";

import {useDispatch, useSelector} from "react-redux";
export const useFetchServicesFilterById = (id) => {

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchServicesFilter() {
            try {
                const servicesFilter = new ServiceService();
                const res = await servicesFilter.getServicesFilterById(id);
                dispatch(fetchallfilter(res["hydra:member"]));
                setIsLoading(false);
            } catch (error) {
                setError(error);
                setIsLoading(false);
            }
        }

        fetchServicesFilter();

    }, [ id ]);

    return { isLoading, error };
}