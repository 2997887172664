import {useForm} from "react-hook-form";
import {toast} from "sonner";
import {motion} from "framer-motion";
import ErrorMessageRF from "../../../../components/form/errorMessage/ErrorMessageRF";
import {SelectService} from "../../services/Select";
import {createSelector} from "reselect";
import {useDispatch, useSelector} from "react-redux";
import {addSelect} from "../../../../redux/selects/selectConfigByNetworkAction";

const selectUser = createSelector(
    state => state.login.user,
    user => user
);

const CreateSelect = ({ method, data, id, type, setIsSubmitting, setIsOpenBox  }) => {

    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        watch,
        formState: { errors },
        reset
    } = useForm({
        defaultValues: {
            name: "",
            infrastructure:type === "infrastructure" ? `api/infrastructures/${id}` : null,
            network: type === "network" ? `api/networks/${id}` : null,
            code:"",
        }
    })

    const user = useSelector(selectUser);
    const token = user.token;

    // useEffect(() => {
    //     handleNameBlur()
    // }, [watch("name")])

    const onSubmit = async (mydata) => {
        try {
            const selectService = new SelectService();

            const res = await selectService.postSelectConfigByNetworkId(mydata, token);

            if (!res) throw  new Error("Something is wrong when to post data");

            dispatch(addSelect({
                id: res.id,
                name: mydata.name,
                code: mydata.code,
                listSelectOptions: res.listSelectOptions,
                isModifiable: true,
            }));

            reset({
                name:"",
                code:""
            })

            setIsSubmitting(false);
            setIsOpenBox(false);

            return toast.success("Select added successfully!");

        } catch (err) {
            console.error(err)
            setIsSubmitting(false);
            return toast.warning("Oops! An error occurred while creating a select.");
        }
    }

    const handleNameBlur = () => {
        setValue('code', watch('name').replace(/\W/g, "-").toLowerCase())
    };

    return (
        <motion.form
            onSubmit={handleSubmit(onSubmit)}

            initial={{opacity: 0, translateY: 15}}
            animate={{opacity: 1, translateY: 0}}
            exit={{opacity: 0, translateY: 15}}

            transition={{type: "spring", duration: .3, ease: "linear"}}

            className="absolute right-0 min-h-32 w-72 z-10 bg-white border border-slate-200 rounded-2xl p-4 top-14 flex gap-4 flex-col"
        >
            <div className="flex flex-col gap-2">
                <label
                    htmlFor="name"
                    className="mb-2 block text-sm text-slate-500"
                >{errors.name ? <ErrorMessageRF content={errors.name.message}/> : "Name of Select"}</label>
                <input type="text"
                       placeholder="Enter new name ..."
                       id="name"
                       autoFocus={true}
                       {...register("name", {required: "This field is required!"})}
                       className="bg-transparent focus:outline-none rounded-sm ring ring-blue-50 h-10 px-2 w-full"
                       onBlur={() => handleNameBlur()}
                />
            </div>

            <div className="flex flex-col gap-2">
                <label
                    htmlFor="code"
                    className="mb-2 block text-sm text-slate-500"
                >{errors.code ? <ErrorMessageRF content={errors.code.message}/> : "Code of Select"}</label>
                <input type="text"
                       placeholder="Enter Code ..."
                       id="code"
                       {...register("code")}
                       className="bg-transparent focus:outline-none rounded-sm ring ring-blue-50 h-10 px-2 w-full"

                />
            </div>

            <div>
                <button type="submit" className="bg-blue-500 text-slate-50 text-xs py-2 px-4 rounded-full">Add
                </button>
            </div>


        </motion.form>
    );
};

export default CreateSelect;
