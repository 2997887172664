import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import ResourceEventTypeService from '../services/ResourceEventType'

export const useFetchListResourceEventType = ({
    resourceTypeId,
    isLoading,
    setIsLoading,
}) => {
    const token = useSelector((state) => state.login.user?.token) ?? null
    const [resourceEventTypes, setResourceEventTypes] = useState([])

    const fetchData = async (resourceTypeId, token) => {
        try {
            const eventService = new ResourceEventTypeService()
            const response =
                await eventService.getAllResourceEventTypeFromResourceTypeId(
                    resourceTypeId,
                    token
                )
            setResourceEventTypes(response['hydra:member'])
        } catch (error) {
            console.error('Error fetching resource event type data:', error)
        }
    }

    useEffect(() => {
        if (resourceTypeId && isLoading) {
            setIsLoading(true)
            fetchData(resourceTypeId, token)
            setIsLoading(false)
        }
    }, [resourceTypeId, isLoading])

    return { resourceEventTypes }
}
