import * as React from 'react';
import {createContext, useEffect, useState} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {object, string} from "yup";
import {makeValidate} from "mui-rff";

// Redux
import {useDispatch} from "react-redux";
import {displayMessage} from "../../../redux/alertAction";

// Design
import {Box} from "@mui/material";
import {Main, drawerWidth} from "../utils/DrawerAssets";
import { motion } from "framer-motion";
import {FiArrowLeft} from "react-icons/fi";
import {Loader} from "../../../components/loader/Loader";

// Components
import DrawerContent from "../components/DrawerContent";
import {PpaInvalidUrl} from "../components/PpaInvalidUrl";
import {PpaActivity} from "../components/ppaactivity/PpaActivity";
import ScrollTop from "../../../components/ScrollTop";
import ReusableFinalFormContainer from "../../../components/reusableform/page/ReusableFinalFormContainer";
import {PpaFormStructure} from "./PpaFormStructure";

// Custom Hooks
import {useFetchPpaTypeById, useFetchSingleNetwork} from "../hooks/useFetchPpa";
import {
    useFetchPpaJoinedInputs,
    useFetchPpaTypeFromCode
} from "../hooks/useFetchPpaForms";
import {useFetchUser} from "../../../hooks/useFetchUser";

// Services
import PPAService from "../../../services/PPAService";

// Mandatory Validation (schema, validate)
const schema = object({
    title: string()
        .required("The project title is required")
        .max(256, "The project title must be at most 256 characters"),
    firstname: string()
        .required("The firstname is required")
        .max(128, "Firstname field content must be at most 128 characters"),
    lastname: string()
        .required("The lastname is required")
        .max(128, "Lastname field content must be at most 128 characters"),
    email: string()
        .required("The email is required")
        .matches(
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            "The email entered must be in a valid format"
        )
        .max(320, "The email must be at most 320 characters"),
});

const validate = makeValidate(schema);

// Context
export const PpaFormContext = createContext(null)


export default function PpaFormContainer() {

    // Services Instanciations
    const ppaService = new PPAService();

    // React Router Dom Hooks
    const navigate = useNavigate();
    const location = useLocation();
    let { networkCode, ppaTypeCode } = useParams();

    // General Hooks
    const { userInfos } = useFetchUser()

    const [state, setState] = useState({
        isLoading: false,
        schema: {},
        project: {
            isSent: false,
            isValid: false,
            message: null,
            saveform: false,
            token: null,
            title: null,
            email: null,
            firstname: null,
            lastname: null,
            listInputValues: [],
            networkPpa: null,
            listInfrastructures: []
        },
    });

    useEffect(() => {
        // Check User Authenticated
        if (userInfos) {
            setState(prevState => ({
                ...prevState,
                project: {
                    ...prevState.project,
                    firstname: userInfos.firstname,
                    lastname: userInfos.lastname,
                    email: userInfos.email
                }
            }));
        }
    }, [userInfos]);

    /********** General Loading  **********/
    const [isGeneralLoading, setIsGeneralLoading] = useState(true);

    useEffect(() => {
        handleDrawerClose();

        setTimeout(() => {
            setIsGeneralLoading(false)
        }, 1400)
    }, [isGeneralLoading]);


    const [isLoading, setIsLoading] = useState(true);
    const [networkIdOrigin, setNetworkIdOrigin] = useState();
    const [ppaTypeOrigin, setPpaTypeOrigin] = useState();
    const [ppaId, setPpaId] = useState('');

    // Mandatory fields check
    const [validMandatory, setValidMandatory] = useState(false);
    const hasMandatoryFieldsStep = true;


    /********** Direct Links Verifications **********/
    const { isPpaLocked, ppaLockedMessage } = useFetchPpaTypeFromCode(ppaTypeCode, setIsLoading)
    const [isPpaHasExpired, setIsPpaHasExpired] = useState(false);
    const [showSubmitSuccess, setShowSubmitSuccess] = useState(false);


    /********** PPA Form in Database (SAVED or SUBMITTED) **********/
    let params = new URL(document.location).searchParams;
    let tokenFromURL = params.get("token");
    const [tokenDatas, setTokenDatas] = useState();
    const [isPpaActivityVisible, setIsPpaActivityVisible] = useState(false);


    const retriveNetworkDatas = async (network, token) => {

        try {

            const ppaTypeResponse = await ppaService.getPPANetworkFromCode(networkCode);
            const hydraMember = ppaTypeResponse["hydra:member"];

            const retrievedPpaTypeId = hydraMember.find((hydra) => hydra.code === ppaTypeCode).id;

            const retrievedPpaTypeNetwork = hydraMember.find((hydra) => hydra.code === ppaTypeCode).network;
            setPpaTypeOrigin(retrievedPpaTypeId);
            setNetworkIdOrigin(retrievedPpaTypeNetwork.id)

            if (network) {
                setState(prevState => ({
                    ...prevState,
                    project: {
                        ...prevState.project,
                        networkPpa: '/api/network_ppa/' + retrievedPpaTypeId
                    }
                }));
            }

            if (token) {

                const dataResponse = await ppaService.getSavedPPAFromToken(token);

                const ppaStatus = dataResponse.status
                const ppaHasExpired = dataResponse.hasExpired

                // Token Datas
                if (ppaStatus !== 'draft' && !ppaHasExpired) {
                    setTokenDatas(dataResponse)
                    setIsPpaActivityVisible(true)
                    setIsGeneralLoading(false)
                }

                // PPA Expired Date
                if (ppaHasExpired) {
                    setIsPpaHasExpired(true)
                }

                setPpaId(dataResponse.id)

                setState(prevState => ({
                    ...prevState,
                    project: {
                        ...prevState.project,
                        token: dataResponse.token,
                        title: dataResponse.title,
                        email: dataResponse.email,
                        firstname: dataResponse.firstname,
                        lastname: dataResponse.lastname,
                        listInputValues: dataResponse.listInputValues,
                        // listInfrastructures: dataResponse.listInfrastructures,
                        networkPpa: dataResponse.networkPpa,
                    }
                }));

            }

            setIsLoading(false);

        } catch (error) {
            console.error(error);
            navigate('/ppa');
        }
    }

    useEffect(() => {
        if(!location.state || tokenFromURL) {
            retriveNetworkDatas(networkCode, tokenFromURL).then();

            // Set Token in state
            setState(prevState => ({
                ...prevState,
                project: {
                    ...prevState.project,
                    token: tokenFromURL,
                }
            }));
        }
    }, [tokenFromURL]);


    /********** PPA Form NOT in Database **********/
    useEffect(() => {
        if(location.state) {

            setNetworkIdOrigin(location.state.networkId)
            setPpaTypeOrigin(location.state.ppaType)
            setState(prevState => ({
                ...prevState,
                project: {
                    ...prevState.project,
                    networkPpa: '/api/network_ppa/' + location.state.ppaType
                }
            }));
            setIsLoading(false)

        }
    }, [location.state]);

    // CentralHub Detection
    const { centralHubName, centralHubPath, listInfrastructures, isSingleNetworkLoading } = useFetchSingleNetwork(networkIdOrigin);

    useEffect(() => {
        // Central Hub in State
        if (centralHubPath) {
            setState(prevState => ({
                ...prevState,
                project: {
                    ...prevState.project,
                    listInfrastructures: [centralHubPath],
                }
            }));
        }
    }, [centralHubPath]);

    // Drawer Management
    const [open, setOpen] = React.useState(true);
    const {
        listTabs,
        drawerDatas,
        networkName,
        cleanedDescription,
        isPpaTypeLoading
    } = useFetchPpaTypeById(ppaTypeOrigin);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    // Specific Forms Data Management
    /* To have consistent input indexes */
    const { joinedInputs, setJoinedInputs, joinedForms, isLoadingJoinedContent, selectOptions } = useFetchPpaJoinedInputs(listTabs);
    // console.log(joinedInputs);



    /***** Save/Submit *****/
    const dispatch = useDispatch();

    /***** Sending PPA Form Request *****/
    const sendingFormRequest = async (values) => {

        // Sending Method Scenarios
        const { saveform } = values;

        let sendMethod, parametersContent;

        if(!tokenFromURL) {
            sendMethod = saveform ? 'savePPA' : 'submitPPA';
            parametersContent = [values];
        } else {
            sendMethod = saveform ? 'updatePPA' : 'finishPPA';
            parametersContent = [ppaId, values];
        }

        // console.log(parametersContent)

        const ppaMethod = ppaService[sendMethod];

        await ppaMethod(...parametersContent).then(data => {

            if (data.status === undefined || data.status === 201) {

                if (saveform) {
                    dispatch(displayMessage("Success","Your project has been saved","success"));
                } else {
                    dispatch(displayMessage("Success","Your project has been submitted","success"));
                }

                setState(prevState => ({
                    ...prevState,
                    project: {
                        ...prevState.project,
                        isSent: true,
                        isValid: true,
                        token: data.token,
                    },
                    isLoading: false,
                }));

                // Set token in URL => Relaunch useEffect to retrieve datas
                window.history.replaceState(null, "Project saved", `?token=${data.token}`)

                // Submit : Success & Status Page
                if(!saveform) {
                    setIsGeneralLoading(true)
                    setShowSubmitSuccess(true)
                    retriveNetworkDatas(networkCode, tokenFromURL).then();
                }

            } else {
                // Case error
                setState(prevState => ({
                    ...prevState,
                    project: {
                        isSent: true,
                        isValid: false,
                        message: data.data.detail,
                        token: values.token,
                        email: values.email,
                        firstname: values.firstname,
                        lastname: values.lastname,
                        listInputValues: [values.listInputValues],
                        networkPpa: values.networkPpa,
                        listInfrastructures: [values.listInfrastructures],
                    },
                    isLoading: false,
                }));
                console.log(data)
            }
        });
    }



    return (
        <Box sx={{ display: 'flex' }}>
            <Main open={open}>
                <motion.article
                    id="reusableform"
                    className={`min-h-screen flex justify-center pt-[35px] lg:pt-[70px]`}
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    transition={{duration: .5, delay: .3}}
                >
                    <div className="container">
                        <div className="wrapper flex flex-col lg:max-w-[996px]">

                            {(isLoading || isGeneralLoading) && (
                                <Loader content="Load Proposal Project form" />
                            )}
                            {(!isLoading && !isGeneralLoading) && (
                                !isPpaActivityVisible ? (
                                    <>
                                        <Link to="/"
                                              className="flex
                                                         items-center
                                                         gap-1
                                                         lg:top-20
                                                         text-md
                                                         text-slate-500
                                                         hover:text-slate-800
                                                         mb-6
                                                         lg:mb-10
                                                         w-fit">
                                            <span><FiArrowLeft/></span>
                                            Home
                                        </Link>

                                        {(isPpaLocked || isPpaHasExpired) ? (
                                            <PpaInvalidUrl ppaLockedMessage={ppaLockedMessage}
                                                           networkCode={networkCode}
                                                           isPpaHasExpired={isPpaHasExpired}
                                            />
                                        ) : (

                                            <PpaFormContext.Provider value={{
                                                // FormHeader
                                                handleDrawerOpen,
                                                networkName,
                                                validMandatory,

                                                // FormContentStepper
                                                hasMandatoryFieldsStep,

                                                // FormMandatory
                                                setValidMandatory,
                                                centralHubName,

                                                // FormSpecific
                                                handleDrawerClose,
                                                listTabs,
                                                joinedInputs,
                                                joinedForms,
                                                selectOptions,

                                                // List Type Form
                                                setJoinedInputs,
                                                setState,

                                                // Loadings
                                                isLoading,
                                                isSingleNetworkLoading,
                                                isLoadingJoinedContent,
                                                setIsGeneralLoading
                                            }}>

                                                <ReusableFinalFormContainer
                                                    // Fetching Datas
                                                    networkIdOrigin={networkIdOrigin}
                                                    listInfrastructures={listInfrastructures}

                                                    // Form
                                                    mainState={state.project}
                                                    isLoadingState={state.isLoading}
                                                    validate={validate}

                                                    // Submit Form
                                                    sendingFormRequest={sendingFormRequest}

                                                    // Form tag
                                                    isPpaLocked={isPpaLocked}

                                                    // FormSpecific
                                                    listTabs={listTabs}
                                                    joinedInputs={joinedInputs}

                                                    // List Type Form
                                                    setState={setState}

                                                    // FormContentButtons
                                                    validMandatory={validMandatory}

                                                    // Feature Content
                                                    featureEdition={
                                                        <PpaFormStructure />
                                                    }
                                                    isRead={false}

                                                    hideCaptcha={false}
                                                />
                                            </PpaFormContext.Provider>

                                        )}
                                    </>
                                ) : (
                                    <PpaActivity tokenDatas={tokenDatas} showSubmitSuccess={showSubmitSuccess} />
                                )

                            )}

                            <ScrollTop/>

                        </div>
                    </div>
                </motion.article>
            </Main>
            <DrawerContent open={open}
                           drawerWidth={drawerWidth}
                           handleDrawerOpen={handleDrawerOpen}
                           handleDrawerClose={handleDrawerClose}
                           drawerDatas={drawerDatas}
                           cleanedDescription={cleanedDescription}
                           isLoading={isPpaTypeLoading}
                           ppaForm
            />
        </Box>
    );
}