import { useEffect, useRef, useState } from 'react'
import Tree from 'react-d3-tree'
import './treeLink.scss'
export const TreeLink = ({ links, resource }) => {
    const [shouldRecenterTreeRef, setShouldRecenterTreeRef] = useState(true)
    const [treeTranslate, setTreeTranslate] = useState({ x: 0, y: 0 })
    const [tree, setTree] = useState([{ name: resource.name }])

    const treeContainerRef = useRef(null)
    useEffect(() => {
        const flattenLinks = links.map((link) => {
            if (link.resourceToLink.name !== resource.name) {
                return {
                    name: link.resourceToLink.resourceType.name,
                    id: link.resourceToLink.resourceType.id,
                    isClickable: false,
                    children: [
                        {
                            name:
                                link.resourceToLink.name.length > 25
                                    ? `${link.resourceToLink.name.substring(0, 25)}...`
                                    : link.resourceToLink.name,
                            id: link.resourceToLink.id,
                            isClickable: true,
                        },
                    ],
                }
            } else {
                return {
                    name: link.resourceOne.resourceType.name,
                    id: link.resourceOne.resourceType.id,
                    isClickable: false,
                    children: [
                        {
                            name:
                                link.resourceOne.name.length > 25
                                    ? `${link.resourceOne.name.substring(0, 25)}...`
                                    : link.resourceOne.name,
                            id: link.resourceOne.id,
                            isClickable: true,
                        },
                    ],
                }
            }
        })
        const tempTree = []

        for (const resourceType in flattenLinks) {
            if (tempTree.filter((item) => item.name === flattenLinks[resourceType].name).length > 0) {
                tempTree
                    .filter((item) => item.name === flattenLinks[resourceType].name)[0]
                    .children.push(flattenLinks[resourceType].children[0])
            } else {
                tempTree.push(flattenLinks[resourceType])
            }
        }
        setTree([{ name: resource.name, children: tempTree }])
    }, [links])

    const handleOnClickNode = (event) => {
        if (event.data.isClickable) {
            window.open(`/resourcetypes/${event.parent.data.id}?rid=${event.data.id}`, '_blank')
        }
    }
    useEffect(() => {
        if (treeContainerRef.current !== null && shouldRecenterTreeRef && tree[0].children) {
            const dimensions = treeContainerRef.current.getBoundingClientRect()
            setShouldRecenterTreeRef(false)
            setTreeTranslate({
                x: dimensions.width / 2,
                y: tree[0]?.children?.length > 0 ? 20 : dimensions.height / 2,
            })
        }
    }, [treeContainerRef.current, setTree])

    return (
        <div id="treeWrapper" ref={treeContainerRef} className="min-h-96 border rounded-lg m-auto w-full h-full bg-">
            <Tree
                data={tree}
                orientation={'vertical'}
                svgClassName="min-h-96"
                translate={treeTranslate}
                onNodeClick={handleOnClickNode}
                nodeClassName="node__branch"
                rootNodeClassName="node__root"
                leafNodeClassName="node__leaf"
                separation={{ siblings: 2, nonSiblings: 2 }}
            />
        </div>
    )
}
