import {FiPlus} from "react-icons/fi";
import {motion} from "framer-motion";

export const Button = ({ variant, setIsOpenTabs, itemId, setIsOpenInputs,setOpenFormFormsId, openFormFormsId, form, title }) => {

    const handleClickOpen = () => {
        switch (form) {
            case "tabs" :
                setIsOpenTabs(true);
                break;
            case "forms" :
                setOpenFormFormsId(openFormFormsId === itemId ? null : itemId)
                break;
            case "inputs" :
                setIsOpenInputs(true);
                break;
        }

    }


    return (
        <motion.button
            className={`${variant === "dark" ? "bg-slate-100" : "bg-white"}  border border-solid border-slate-200 rounded-xl text-slate-500 font-medium h-16 ${ form === "forms" ? "w-full min-w-60" : "w-72" } py-2 px-4 flex items-center justify-start gap-2`}
            whileHover={{ scale:1.045}}
            whileTap={{ scale: .945 }}

            onClick={ handleClickOpen }
        >
           <span className="border border-solid border-slate-200 p-2 rounded-full"><FiPlus /></span> {title ? title : "Add new"}
        </motion.button>
    )
}