import React, { useContext, useEffect, useState } from 'react'
import { Loader } from '../../../components/loader/Loader'
import { DataGrid } from '@mui/x-data-grid'
import {
    useFetchAllInstallationSuperAdmin,
} from '../../installation/hooks/useFetchAllInstallation'
import { InstallationForm } from './InstallationForm'
import { CompConfig, CompConfigContext } from '../../../components/CompConfig'
import { AiFillBuild } from 'react-icons/ai'
import { FiEdit } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { useFetchPlatform } from '../../platform/hook/useFetchPlatform'


export default function InstallationsSuperAdmin() {

    const token = useSelector(state => state.login.user?.token) ?? null;

    const [isOpenDrawer, setIsOpenDrawer] = useState(false)
    const [increment, setIncrement] = useState(0)
    useFetchPlatform(token, increment);

    const { data: listInstallations, isLoading, totalItems, error } = useFetchAllInstallationSuperAdmin(increment, token)

    const [data, setData] = useState([])

    useEffect(() => {
        if (!isLoading) {
            setData(listInstallations)
        }
    }, [
        isLoading,
        listInstallations,
    ])

    const handleDrawerClose = () => {
        setIsOpenDrawer(false)
        setIncrement(increment + 1);
    }

    const searchMethod = (data, searchTerm) => {
        return data?.filter(item => {
                return item.id.toString().toLowerCase().includes(searchTerm.toLowerCase())
                    || item.parent?.name?.toLowerCase().includes(searchTerm.toLowerCase())
                    || item.name?.toLowerCase().includes(searchTerm.toLowerCase())
            },
        )
    }

    if (isLoading) return <Loader content="Loading installations..." />

    return (
        <div className={'flex flex-col w-full h-[60vh]'}>
            <CompConfig
                title="Manage Installations"
                titleDrawer="Create a new Installation"
                titleDrawerUpdate="Update Installation"
                logoTitle={<AiFillBuild />}
                data={data}
                isLoading={isLoading}
                error={error}
                totalItem={totalItems}
                btntitle="Add an installation"
                content={<InstallationSuperAdminContent />}
                typeForm="manage-installation"
                setIsOpenDrawer={setIsOpenDrawer}
                onDrawerClose={handleDrawerClose}
                onDrawerOpen={handleDrawerClose}
                isEdit={true}
                drawerFormCreate={<InstallationForm increment={increment}
                                                    listInfrastructuresParent={listInstallations} />}
                overrideItemsPerRow={0}
                searchMethod={searchMethod}
            />
        </div>
    )
}

function InstallationSuperAdminContent() {

    const data = useContext(CompConfigContext)

    return (
        <div className={'flex flex-col w-full h-[50vh]'}>
            <DataGrid
                rows={data.myData}
                columns={[
                    { field: 'id', headerName: 'ID', flex: 0.1 },
                    { field: 'name', headerName: 'Name', flex: 0.4 },
                    { field: 'showable', headerName: 'Visible', flex: 0.2 },
                    { field: 'isGroupOnly', headerName: 'Group only', flex: 0.2 },
                    {
                        field: 'parent',
                        headerName: 'Parent',
                        valueGetter: (installation) => installation?.value?.name ?? 'No parent',
                        flex: 0.5,
                    },
                    {
                        field: 'listNetworks',
                        headerName: 'Networks',
                        valueGetter: (network) => {
                            const result = network.value.map(i => i.name)
                            if (result.length === 0) {
                                return 'No network'
                            }
                            return result.join(', ')
                        },
                        flex: 0.5,
                    },
                    {
                        headerName: 'Actions',
                        flex: 0.2,
                        renderCell: (installation) => {
                            return (
                                <button
                                    key={installation?.id}
                                    className="bg-blue-500 p-2 rounded-full text-white mr-2 cursor-pointer"
                                    title={'Edit installation'}
                                    onClick={() => {
                                        data.handleClickOpenDrawer('UPDATE', installation?.id.split('/').pop())
                                    }}
                                ><FiEdit />
                                </button>
                            )
                        },
                    },
                ]}
                getRowId={(row) => row['@id']}
            />
        </div>
    )
}