import { useEffect, useState } from 'react'
import InfrastructureService from '../../../installation/service/InfrastructureService'

export const useFetchInfrastructure = (infrastructureId, token, increment, setIsLoading) => {

    const [infrastructure, setInfrastructure] = useState(null)
    const [error, setError] = useState(null)

    useEffect(() => {
        const fetchInfrastructureData = async () => {
            try {
                setIsLoading(true)
                let res = null;
                if (typeof infrastructureId !== 'undefined' && undefined !== infrastructureId) {
                    const infrastructureService = new InfrastructureService()
                    res = await infrastructureService.getInfrastructure(infrastructureId, token, 'jsonld')
                }
                setInfrastructure(res)
            } catch (error) {
                setError(error)
            }
            setIsLoading(false)
        }

        fetchInfrastructureData().then()

    }, [
        infrastructureId,
        token,
        increment,
    ])

    return { infrastructure, error }
}