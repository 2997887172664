import {Pagination} from "@mui/material";
import {useEffect, useState} from "react";
import {changeLoadPage} from "../../utils/changeLoadPage";


export const CustomPagination = ({ setPage, totalItems, x, setPageLoad }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(totalItems / x);

    const handleChange = (event, value) => {
        setCurrentPage(value)
        setPage(value)

        setPageLoad(true);
    }

    useEffect(() => {
        // Scroll to the top when currentPage changes
        window.scroll(0, 0);
    }, [currentPage]);

    return (
        <div className="relative border-solid p-4">
            <Pagination
                onChange={ handleChange }
                style={{ display:"flex", justifyContent:"center" }}
                page={currentPage}
                count={totalPages}
                shape="circular"
                size={"small"}
                color="standard"
            />
        </div>
    )
}