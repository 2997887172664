import { FormControlLabel, Radio } from "@mui/material";
import {motion} from "framer-motion";


export const Card = ({ data }) => {

    return (
        <motion.div
            className="flex
                   flex-col
                   items-center

                   relative
                   cursor-pointer
                   rounded-xl
                   shadow-lg
                   shadow-slate-200

                   overflow-hidden
                   p-2
                   border
                   border-slate-100
                   "
            whileHover={{scale: .95}}
            initial={{opacity: 0, translateY: 25}}
            transition={{type: "spring", duration: .3, ease: "easeIn"}}
            whileInView={{opacity: 1, translateY: 0}}
        >
            <div className="card-network w-full">
                <FormControlLabel
                    control=
                        {
                            <>
                                <Radio value={data?.id.toString()}
                                       id={data?.code.toString()}
                                       sx={{position: 'absolute', top: '0px', right: '15px'}}
                                       size="small"
                                />
                            </>
                        }
                    label={data?.name}
                    sx={{
                        margin: "0",
                        padding: "0",
                        fontSize: {xs: ".845rem"},
                    }}
                    className="bg-white absolute w-full h-full top-0 left-0 m-0 p-0 z-10 border border-slate-200 rounded-xl"
                />
            </div>

        </motion.div>
    )
}