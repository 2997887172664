import {useFetchAllInstallationsByNetwork} from "../hooks/useFetchAllInstallationsByNetwork";
import {CatalogComp} from "../../catalog/components/CatalogComp";

export const  InstallationCatalog = ({ isOpen, setIsOpen, limit }) => {
    return (
        <CatalogComp
            fetchHook={useFetchAllInstallationsByNetwork}
            title="Installations"
            catalogType="installations"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            limit={limit}
            dataSelectorKey="dataInsta"
            bgColorType={"bg-orange-500"}
        />
    )
}