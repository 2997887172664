import {useNavigate} from "react-router-dom";
import {motion} from "framer-motion";
import {FiArrowLeft, FiXCircle} from "react-icons/fi";


export const Forbidden = ({isInDrawer}) => {

    const navigate = useNavigate();

    const handleClickHome = () => {
        return navigate('/')
    }

    return (
        <article className="min-h-screen flex items-center justify-center">
            <div className="container z-10">
                <div className="lg:w-9/12 w-11/12 flex flex-col items-center gap-10">
                    <div className="flex flex-col items-center gap-4 max-w-96">

                        <motion.div
                            className="border border-dotted border-slate-300 rounded-2xl p-4"
                            initial={{opacity: 0, translateY: 25}}
                            animate={{opacity: 1, translateY: 0}}
                            exit={{opacity: 0}}

                            transition={{type: "spring", duration: 0.2, ease: "linear"}}

                        >
                            <h1 className="text-center text-lg font-semibold text-slate-500">
                                <span className="text-slate-700 font-bold ">Page 403 : </span>Not authorized.
                            </h1>
                            <p className={`text-slate-500 text-sm text-center p-2`}>You don't have the required rights
                                to access to this page.</p>
                            {!isInDrawer &&
                                <p className={`text-slate-500 text-sm text-center p-2`}>Use our main menu to continue
                                    your investigations.</p>
                            }
                        </motion.div>
                        {!isInDrawer &&
                            <motion.button
                                className="text-sm font-medium flex gap-2 items-center group"
                                onClick={handleClickHome}
                                title={"Back Home"}
                                initial={{opacity: 0, translateY: -35}}
                                animate={{opacity: 1, translateY: 0}}
                                exit={{opacity: 0}}

                                transition={{type: "spring", duration: 0.2, ease: "linear"}}

                            >
                                <span
                                    className="border border-slate-300 text-xs font-bold text-slate-500 rounded-full w-6 h-6 flex items-center justify-center group-hover:bg-slate-200 transition ease-linear"><FiArrowLeft/></span> Back
                                home
                            </motion.button>
                        }

                        <motion.span
                            className="text-xl order-first text-red-500"
                            initial={{opacity: 0, translateY: -50}}
                            animate={{opacity: 1, translateY: 0}}
                            exit={{opacity: 0}}

                            transition={{type: "spring", duration: 0.2, ease: "linear"}}
                        >
                            <FiXCircle/>
                        </motion.span>
                    </div>
                </div>
            </div>
        </article>
    )
}