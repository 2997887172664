import {Box, Grid} from "@mui/material";
import { motion } from "framer-motion";
import {Card} from "../../network/components/Card";

export const CatalogTypeList = ({ catalogTypeData } ) => {

    return (
        <motion.div
            initial={{ opacity: 0, translateY: -50 }}
            animate={{ opacity: 1, translateY: 0 }}
            exit={{ opacity: 0, translateY: -50 }}
        >
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 8, md: 12 }}>
                    { catalogTypeData?.map( (item) =>
                        <Grid item key={item.id.toString()} xs={2} sm={4} md={4} sx={{width: "100%"}} >
                            <Card data={item} />
                        </Grid>
                    )}
                </Grid>
            </Box>
        </motion.div>
    )
}