import React, {useEffect, useState} from 'react';
import UserService from "../../../services/UserService";
import {Link, useNavigate} from "react-router-dom";
import {
    Avatar,
    Backdrop,
    Button,
    CircularProgress,
    InputAdornment,
    Typography
} from "@mui/material";
import {
    ArrowBackIosNewSharp,
    SendSharp,
    AlternateEmailSharp,
    KeySharp,
} from "@mui/icons-material";
import PasswordIcon from '@mui/icons-material/Password';
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import {makeValidate, TextField} from "mui-rff";
import {Form} from "react-final-form";
import {object, ref, string} from 'yup';
import { motion } from "framer-motion";
import {toast} from "sonner";

const schema = object({
    email: string().required("The email is required").email(),
});

const schemaSent = object({
    email: string()
        .required("The email is required")
        .email(),
    token: string()
        .required("The token is required"),
    password: string()
        .required("The password is required")
        .min(8, "The password must contain 8 characters. ")
        .matches(
            /^(?=.*[a-z])/,
            "Must contain one lowercase character. "
        )
        .matches(
            /^(?=.*[A-Z])/,
            "Must contain one uppercase character. "
        )
        .matches(
            /^(?=.*[0-9])/,
            "Must contain one number character. "
        )
        .matches(
            /^(?=.*[!@#$%^&*?])/,
            "Must contain one special case character. "
        ),
    confirmPassword: string()
        .required("The confirm password is required")
        .oneOf([ref('password')], 'Passwords must match')
});

const validate = makeValidate(schema);
const validateSent = makeValidate(schemaSent);

export const ForgotPassword = () => {

    const navigate = useNavigate();

    const [state, setState] = useState({
        forgotPassword: {
            isSent: false,
        },
        updatePassword: {
            isSent: false,
            isValid: false,
            message: null,
            token: null
        },
        isLoading: false,
        schema: {},
        currentEmail: null
    });

    useEffect(() => {
        if (state.forgotPassword.isSent) {
            toast.success(`An email has been sent to '${state.currentEmail}' with a token. Please fill it below`)
        }
    }, [state.forgotPassword.isSent]);

    useEffect(() => {
        if(state.updatePassword.isSent) {
            toast.success(`Your password has been updated`);
            navigate("/login");
        }
    }, [state.updatePassword.isSent]);

    const handleResendToken = async () => {
        setState(prevState => ({
            ...prevState,
            forgotPassword: {
                isSent: false,
                isValid: false
            },
            isLoading: false,
            updatePassword: {
                isSent: false,
                isValid: false,
            }
        }));
    };

    const handleSubmitUpdatePwd = async (formData) => {

        setState(prevState => ({
            ...prevState,
            isLoading: true,
        }));

        setState(prevState => ({
            ...prevState,
            currentEmail: formData.email,
        }));

        try {
            const userService = new UserService();
            await userService.updatePassword(formData); // Ajout de await pour attendre la réponse
            setState(prevState => ({
                ...prevState,
                updatePassword: {
                    isSent: true,
                    isValid: true,
                    message: null,
                },
                forgotPassword: {
                    isSent: false,
                },
                isLoading: false,
            }));
        } catch (error) {
            console.error(error);
            setState(prevState => ({
                ...prevState,
                isLoading: false,
            }));
            toast.error("Ooops something went wrong. Please try again later.");
        }
    };


    const handleSubmitForgotPassword = async (e) => {

        setState(prevState => ({
            ...prevState,
            isLoading: false,
        }));

        setState(prevState => ({
            ...prevState,
            currentEmail: e.email,
        }));

        try {
            const userService = new UserService();
            const res = await userService.forgotPassword(e);

            setState(prevState => ({
                ...prevState,
                forgotPassword: {
                    isSent: true
                },
                isLoading: false,
            }));
        } catch (error) {
            setState(prevState => ({
                ...prevState,
                isLoading: false,
            }));
            toast.error("Ooops something went wrong. Please try again later.");
        }
    }

    return (

        <motion.article
            className="min-h-screen flex items-center justify-center"
            initial={{ opacity: 0}}
            animate={{ opacity: 1 }}
            transition={{ duration:.5, type:"ease", delay:.2}}
        >
            <div className="container">
                <div className="wrapper flex items-center justify-center">
                    <motion.div
                        className="mt-4 flex flex-col items-center max-w-[400px]"
                        initial={{translateY: -100, opacity: 0}}
                        transition={{type: "spring", duration: .5, ease: "easeIn", delay: .4}}
                        animate={{translateY: 0, opacity: 1}}
                        exit={{translateY: -100, opacity: 0}}
                    >
                        <Avatar sx={{m: 1, background: '#3380FF'}}>
                            <PasswordIcon/>
                        </Avatar>

                        <Typography component="h1" variant="h2" sx={{ marginBottom:"25px"}}>
                            Forgot Password
                        </Typography>

                        <Backdrop
                            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                            open={state.isLoading}>
                            <CircularProgress color="inherit"/>
                        </Backdrop>


                            {state.forgotPassword.isSent === false ? (
                                <Form
                                    onSubmit={handleSubmitForgotPassword}
                                    validate={validate}
                                    initialValues={{"email": state.currentEmail}}
                                    // eslint-disable-next-line no-unused-vars
                                    render={({handleSubmit}) => (
                                        <form onSubmit={handleSubmit} noValidate>
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="email"
                                                label="Ex: test@isia.fr"
                                                name="email"
                                                type="email"
                                                sx={{ width: "100%"}}

                                            />

                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                color="dark"
                                                sx={{mt: 3, mb: 2, color: "#fafafa"}}
                                            >
                                                Send reset email
                                            </Button>
                                        </form>
                                    )}
                                />) :
                            (
                                <Form
                                        onSubmit={handleSubmitUpdatePwd}
                                        validate={validateSent}
                                        initialValues={{
                                            "email": state.currentEmail,
                                            "token": state.updatePassword.token
                                        }}
                                        // eslint-disable-next-line no-unused-vars
                                        render={({handleSubmit}) => (
                                            <form onSubmit={handleSubmit} noValidate
                                                className="flex flex-col gap-4 w-full"
                                            >

                                                    <TextField
                                                        required
                                                        name="email"
                                                        type="email"
                                                        label="Email attached to your account"
                                                        placeholder="Ex: test@isia.fr"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <AlternateEmailSharp/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />

                                                    <TextField
                                                        required
                                                        name="token"
                                                        type="text"
                                                        label="Token received by email"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />

                                                    <TextField
                                                        required
                                                        name="password"
                                                        type="password"
                                                        label="New password"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <KeySharp/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />


                                                    <TextField
                                                        required
                                                        name="confirmPassword"
                                                        type="password"
                                                        label="Confirm your new password"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <KeySharp/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />

                                                <div className="flex flex-col justify-end pt-2">
                                                    <Button
                                                        type="submit"
                                                        fullWidth
                                                        variant="contained"
                                                        color="dark"
                                                        sx={{mt: 3, mb: 2, color: "#fafafa"}}
                                                    >
                                                        Update my password
                                                    </Button>
                                                    <p className="flex gap-2 items-center">
                                                        Not received token ?
                                                        <span
                                                            className="text-blue-400 cursor-pointer"
                                                            onClick={handleResendToken}>
                                                            Resend it
                                                        </span>
                                                    </p>


                                                </div>
                                            </form>
                                        )}
                                />
                            )}

                        <Link
                            to="/login"
                            className="cursor-pointer mt-10 underline hover:text-blue-500"
                        >
                            back to login
                        </Link>
                    </motion.div>
                </div>
            </div>
        </motion.article>
    );
}