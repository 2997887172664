import {useEffect, useState} from "react";
import InfrastructureService from "../features/installation/service/InfrastructureService";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {fetchUserSelect} from "../redux/platform/platformAction";


export const useInfrastructuresSelect = (idIfra, token) => {
    const [userInfra, setUserInfra] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserInfra =  async () => {
            try {
                const infrastructureService = new InfrastructureService();
                const res = await infrastructureService.putInfrastructuresSelect( idIfra, token );

                dispatch(fetchUserSelect(res));
                setUserInfra(res);
                setIsLoading(false)
            } catch (error) {
                setError(error);
                setIsLoading(false)
            }
        }

        if ( idIfra > 0 ) {
            fetchUserInfra();
        }

    }, [token, idIfra]);

    return { userInfra, error, isLoading }
}