import ServiceCatalog from "../../../features/service/components/ServiceCatalog";
import {useParams} from "react-router-dom";
import {useState} from "react";
import {InstallationCatalog} from "../../installation/components/InstallationCatalog";
export const Catalog = () => {

    const { typecatalog } = useParams();
    const [isOpen, setIsOpen] = useState(false);

    if (typecatalog === "services") return <ServiceCatalog isOpen={isOpen} setIsOpen={setIsOpen} limit={16} />

    if (typecatalog === "installations") return <InstallationCatalog isOpen={isOpen} setIsOpen={setIsOpen} limit={16} />
}