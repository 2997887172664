import {Button} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PropTypes from 'prop-types';

export const LoadMoreButton = ({ title, color, variant, size, style, roundedborder, icon, width, nextItems, itemsPerRow,setNext }) => {

    const handleMoreItems = () => {
        setNext(nextItems + itemsPerRow);
    };

    return (
        <Button
            variant={variant}
            endIcon={icon && <ArrowForwardIcon fontSize="small" />}
            size={size}
            color={color}
            onClick={handleMoreItems}
            style={{ color: style ? "#f5f5f5" : "#272727",
                    borderRadius: `${roundedborder ? "50px" : "2px"}`,
                    width: width && "100%",
                    margin: "50px auto 0",
                    padding: '13px 33px',
                    border: '1px solid rgba(102, 102, 102, 0.19)',
                    display: 'flex',
            }}
        >
            { title }
        </Button>
    )
}

LoadMoreButton.propTypes = {
    title: PropTypes.string.isRequired,
    color: PropTypes.string,
    variant: PropTypes.string,
    size: PropTypes.string,
    style: PropTypes.bool,
    roundedborder: PropTypes.bool,
    icon: PropTypes.bool,
    width:PropTypes.bool
};