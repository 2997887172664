import { useState, useEffect } from 'react';
import ServiceService from "../service/ServiceService";

export const useFetchOneServices = (idcatalog, networkId) => {

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchOneService() {

            try {
                const serviceService =  new ServiceService();
                const res = await serviceService.getServiceById(networkId,idcatalog);

                setData(res);
                setIsLoading(false);
            } catch (error) {
                setError(error);
                setIsLoading(false);
            }
        }

        fetchOneService();

    }, [ idcatalog ]);

    return { data, isLoading, error };
}
