import {useEffect, useState} from "react";
import InfrastructureService from "../../installation/service/InfrastructureService";
import {useDispatch, useSelector} from "react-redux";
import {fetchAllPlatform} from "../../../redux/platform/platformAction";


export const useFetchPlatform = (token, increment) => {

    const dispatch = useDispatch();

    const data = useSelector((state) => state.platformReducers.data);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchPlatform () {
            try {
                const infrastructureService = new InfrastructureService();
                const res = await infrastructureService.getInfrastructuresFromUser(token);

                dispatch(fetchAllPlatform(res));
                // setData(res);
                setLoading(false);

            } catch (error) {
                setError(error);
                setLoading(false)
            }
        }

        fetchPlatform().then();

    }, [token, increment])

    return { data, loading, error }
}