import React, {createContext, useState} from 'react';

// Design
import {Box, IconButton} from "@mui/material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import {FiAlignJustify} from "react-icons/fi";
import {motion} from "framer-motion";

// Custom Functions
import {useMobile} from "../../../hooks/useMobile";
import {Loader} from "../../../components/loader/Loader";

// Context
export const NavigationSelectionsMenuContext = createContext(null);


export const NavigationSelectionsMenu = ({ children }) => {

    const [showSelectionsMenu, setShowSelectionsMenu] = useState(false);

    const headerElement = document.querySelector('header');
    const headerElementHeight = headerElement?.clientHeight;

    const handleDrawer = (opening) => {
        setShowSelectionsMenu(opening);
    }

    const largeScreenSelectionClasses = 'bg-gradient-to-t from-white to-stone-50 border border-solid border-slate-200 shadow-md rounded-md lg:max-w-[300px]'
    const isMobile = useMobile();


    return (
        <NavigationSelectionsMenuContext.Provider value={{ largeScreenSelectionClasses, isMobile }}>
            { isMobile ? (
                <Box component={'div'}>
                    <IconButton onClick={() => handleDrawer(true)}

                                aria-label={`Select an Installation`}
                                sx={{
                                    '&:hover': {background: 'transparent'},
                                    zIndex: '10',
                                    position: 'fixed',
                                    left: '-1px',
                                    padding: '0',
                                    top: `${headerElementHeight}px`,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                                disableRipple
                    >
                        <motion.div initial={{ x: -80, opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    transition={{ duration: .6 }}
                                    className="flex items-center text-left bg-white text-blue-500
                                               z-10 my-3 h-11 px-3 rounded-r-lg border border-solid
                                               border-slate-200"
                        >
                            <FiAlignJustify className={"text-lg text-white bg-blue-500 h-[28px] w-[28px] p-[4px] rounded-full"} />
                        </motion.div>

                    </IconButton>
                    <SwipeableDrawer open={showSelectionsMenu}
                                     onClose={() => handleDrawer(false)}
                                     onOpen={() => handleDrawer(true)}
                                     sx={{
                                         '& .MuiPaper-root': { minWidth: '80vw' }
                                    }}
                    >
                        {/* Several Children (Array) OR Only One Child (Object) */}
                        { Array.isArray(children) ? children.map((child, index) => (
                           child ? (
                                   <React.Fragment key={index}>{ child }</React.Fragment>
                               ) : (
                                   <Loader key={index} content={'Loading descriptions'} hfull />
                               )
                           )
                        ) : (
                            children ? ( <>{ children }</> ) : ( <Loader content={'Loading descriptions'} hfull /> )
                        )}
                    </SwipeableDrawer>
                </Box>
            ) : (
                <>
                    { children }
                </>
            )}
        </NavigationSelectionsMenuContext.Provider>
    )
};

