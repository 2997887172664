const INITIAL_STATE = {
    permissions: {
    }
}

// Si state == null, dans ce cas il prend INITIAL_STATE sinon le state fournit
export function getSelectedPermissions(state = INITIAL_STATE, action) {
    switch (action.type) {
        case "SET_PERMISSION":
            return {
                permissions: action.payload,
            };
        case "SET_USER_PERMISSION":
            return {
                ...state,
                permissions: {
                    ...state.permissions,
                    user: action.payload,
                },
            };
        case "SET_PROJECT_PERMISSION":
            return {
                ...state,
                permissions: {
                    ...state.permissions,
                    project: action.payload,
                },
            };
        case "SET_RESOURCE_PERMISSION":
            return {
                ...state,
                permissions: {
                    ...state.permissions,
                    resource: action.payload,
                },
            };
        default:
            return state;
    }
}