import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import ResourceEventTypeService from '../services/ResourceEventType'

export const useFetchResourceEventType = ({ resourceTypeId, setIsLoading }) => {
    const token = useSelector((state) => state.login.user?.token) ?? null
    const [resourceEventType, setResourceEventType] = useState(null)
    const fetchData = async (id, token) => {
        try {
            setIsLoading(true)
            const eventService = new ResourceEventTypeService()
            const response = await eventService.getResourceEventType(id, token)
            setResourceEventType(response)
            setIsLoading(false)
        } catch (error) {
            console.error('Error fetching resource event type data:', error)
        }
    }

    useEffect(() => {
        if (resourceTypeId) {
            fetchData(resourceTypeId, token)
        } else {
            setResourceEventType(null)
            setIsLoading(false)
        }
    }, [resourceTypeId])

    return { resourceEventType }
}
