import {Partner} from "./Partner";
import {StackContact} from "./stack/StackContact";
import ListDocs from "../pages/doc/ListDocs";
import ListFaq from "../pages/doc/ListFaq";
import {motion} from "framer-motion";

export const SectionComponent = ( { title, titleColor, description, image, partner, contact, docs, faq,  imageVisible, borderBottom, subDesc, subDesc2, v2 }) => {
    return (
        <article id="section" className={` w-full flex flex-col items-center lg:pt-10 pt-52`}>
            <div className="container">
                <div className=
                 {`
                    wrapper
                    flex
                    flex-col
                    gap-10
                    pb-10
                 `}
                >

                    <div className={`${v2 ? "lg:flex-row flex-col gap-4" : "flex-col"} flex`}>
                        <div className={`${borderBottom && "border-b border-slate-200 xl:h-52 lg:pb-0 pb-10"} flex w-full ${v2 ? "flex-col gap-2 " : "xl:flex-row flex-col justify-between"} lg:gap-0 gap-4`}>
                            <h2 className={`lg:text-4xl text-3xl lg:font-bold font-medium text-left max-w-[500px] text-slate-900 lg:mb-4`}>
                                {title} <span className={`text-blue-600/100 ${docs && "text-lg"}`}>{titleColor}</span>
                            </h2>

                            <p className={`lg:max-w-[550px] text-slate-700 lg:ml-8 ${subDesc || subDesc2 ? "flex flex-col gap-4" : "gap-0"}`}>
                                {description}
                                {subDesc && <span className={`block`}>{subDesc}</span>}
                                {subDesc2 && <span className={`block`}>{subDesc2}</span>}
                            </p>
                        </div>

                        { imageVisible &&
                            <div className={`w-full lg:h-[500px] h-[40vh]`}>
                                <motion.figure
                                    className="w-full lg:h-[500px] h-[40vh] overflow-hidden"
                                    initial={{ opacity:0, scale: .975 }}
                                    animate={{ opacity:1, scale: 1 }}
                                    exit={{ opacity:0, scale: .945 }}

                                    transition={{ type:"spring", duration: 0.3, ease:"linear" }}
                                >
                                    <img
                                        src={image}
                                        alt={title}
                                        className="w-full h-full object-cover md:rounded-3xl rounded-lg"
                                    />
                                </motion.figure>
                            </div>
                        }
                    </div>


                    {faq && <ListFaq />}
                    {docs && <ListDocs />}
                    {partner && <Partner />}
                    {contact && <StackContact/>}
                </div>
            </div>

        </article>
    )
}