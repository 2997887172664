export default function getFullName(fullName) {
    // Vérifier si fullName est défini et non vide
    if (!fullName || fullName.trim() === '') {
        return ''; // Retourner une chaîne vide si fullName est vide ou non défini
    }

    // Diviser le nom complet en prénom et nom de famille
    const nameParts = fullName.trim().split(' ');

    // Vérifier si le nom complet contient plus de deux parties (prénom et nom de famille)
    if (nameParts.length < 2) {
        return fullName; // Retourner le nom complet tel quel s'il n'est pas possible de le diviser en prénom et nom de famille
    }

    // Récupérer la première lettre de chaque partie
    const firstLetterFirstName = nameParts[0].charAt(0);
    const firstLetterLastName = nameParts[nameParts.length - 1].charAt(0);

    // Retourner les initiales séparées par un espace
    return `${firstLetterFirstName} ${firstLetterLastName}`;
}

