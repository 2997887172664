import React, {useEffect, useState} from "react";
import {Field} from "react-final-form";

// Design
import {Box, Grid} from "@mui/material";
import {CustomizedInputLabel, CustomizedTextField} from "./items/FormCustomizedInputs";
import { motion } from "framer-motion";

// Components
import {FormsTitle} from "./items/FormsTitle";
import {FormListInfrastructure} from "./FormListInfrastructure";


export const FormMandatory = ({ setValidMandatory,
                               centralHubName,
                               selectInfras,
                               values
                            }) => {

    const [checkListInfrastructures, setCheckListInfrastructures] = useState(false);

    useEffect(() => {
        if(!centralHubName) {
            !checkListInfrastructures ? setValidMandatory(false) : setValidMandatory(true);
        } else {
            setValidMandatory(true)
        }
    }, [centralHubName, checkListInfrastructures]);

    return (
        <motion.article
            className="pt-[10px]"
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{duration: .5, delay: .3}}
        >

            <FormsTitle title={<><span>Mandatory fields</span></>}/>

            <Box className="reusableform-box-structure">
                <Grid container rowSpacing={3} columnSpacing={{xs: 2, lg: 4}}>
                    <Grid item xs={12}>
                        <Field name="title">
                            {props => (
                                <>
                                    <CustomizedInputLabel shrink htmlFor="project_title">
                                        Project title *
                                    </CustomizedInputLabel>
                                    <CustomizedTextField
                                        name={props.input.name}
                                        value={props.input.value}
                                        // onChange={props.input.onChange}
                                        id="project_title"
                                        autoComplete="project-title"
                                    />
                                </>
                            )}
                        </Field>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Field name="firstname">
                            {props => (
                                <>
                                    <CustomizedInputLabel shrink htmlFor="first_name">
                                        First name *
                                    </CustomizedInputLabel>
                                    <CustomizedTextField
                                        name={props.input.name}
                                        value={props.input.value}
                                        // onChange={props.input.onChange}
                                        id="first_name"
                                        autoComplete="first-name"
                                    />
                                </>
                            )}
                        </Field>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Field name="lastname">
                            {props => (
                                <>
                                    <CustomizedInputLabel shrink htmlFor="last_name">
                                        Last name *
                                    </CustomizedInputLabel>
                                    <CustomizedTextField
                                        name={props.input.name}
                                        value={props.input.value}
                                        // onChange={props.input.onChange}
                                        id="last_name"
                                        autoComplete="last-name"
                                    />
                                </>
                            )}
                        </Field>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Field name="email"
                            // validate={mandatoryEmail}
                               type="email"
                        >
                            {props => (
                                <>
                                    <CustomizedInputLabel shrink htmlFor="email">
                                        Email *
                                    </CustomizedInputLabel>
                                    <CustomizedTextField
                                        name={props.input.name}
                                        value={props.input.value}
                                        // onChange={props.input.onChange}
                                        id="email"
                                        autoComplete="email"
                                    />
                                </>
                            )}
                        </Field>
                    </Grid>

                    {(!centralHubName && selectInfras.length !== 0) && (
                        <Grid item xs={12} lg={6}>
                            <FormListInfrastructure selectInfras={selectInfras}
                                                    values={values}
                                                    setCheckListInfrastructures={setCheckListInfrastructures}
                            />
                        </Grid>
                    )}
                </Grid>
            </Box>
        </motion.article>
    )
}
