import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import { useFetchListResourceEventType } from '../../hooks/useFetchListResourceEventType'
import { motion } from 'framer-motion'
import { FiEdit2, FiPlus } from 'react-icons/fi'
import { Checkbox } from '@mui/material'
import { DrawerTemporary } from '../../../../components/drawer/DrawerTemporary'
import { useEffect, useState } from 'react'
import { ResourceEventTypeForm } from '../form/ResourceEventTypeForm'
import { EventForm } from '../form/EventForm'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import timeGridPlugin from '@fullcalendar/timegrid'
import Tooltip from '@mui/material/Tooltip'
import { useFetchListEventByResourceType } from '../../hooks/useFetchListEventByResourceType'
export const Calendar = ({ resourceType, resource }) => {
    const selectUser = createSelector(
        (state) => state.login.user,
        (user) => user
    )
    const userInfra = useSelector((state) => state.platformReducers.userInfra) ?? null
    const user = useSelector(selectUser)

    const [calendarState, setCalendarState] = useState({
        isOpenDrawer: false,
        method: 'CREATE',
        isOpenEventDrawer: false,
        methodEvent: 'CREATE',
        selectedId: null,
        selectedEventId: null,
        from: null,
        to: null,
        isLoading: true,
        selectedEventTypes: [],
    })

    useEffect(() => {
        setEventIsLoading(true)
    }, [resource])

    const setIsLoading = (isLoading) => {
        setCalendarState((prev) => ({ ...prev, isLoading: isLoading }))
    }

    const setIsOpenDrawerEvent = (isOpen) => {
        setCalendarState((prev) => ({ ...prev, isOpenEventDrawer: isOpen }))
    }

    const setIsOpenDrawer = (isOpen) => {
        setCalendarState((prev) => ({ ...prev, isOpenDrawer: isOpen }))
    }

    const { resourceEventTypes } = useFetchListResourceEventType({
        resourceTypeId: resourceType.id,
        isLoading: calendarState.isLoading,
        setIsLoading: setIsLoading,
    })

    const { events, eventIsLoading, setEventIsLoading } = useFetchListEventByResourceType(
        userInfra,
        resource,
        user.token
    )

    const handleDrawerClose = () => {
        setCalendarState((prev) => ({
            ...prev,
            isOpenDrawer: false,
            isOpenEventDrawer: false,
            selectedId: null,
            isLoading: true,
            selectedEventId: null,
        }))
        setEventIsLoading(true)
    }

    const handleClickOpenDrawer = (openMethod, id) => {
        setCalendarState((prev) => ({ ...prev, method: openMethod ?? 'CREATE', selectedId: id, isOpenDrawer: true }))
    }

    const handleOpenDrawerEventCreate = (openMethod, info) => {
        setCalendarState((prev) => ({
            ...prev,
            methodEvent: openMethod ?? 'CREATE',
            selectedEventId: info.id ?? null,
            isOpenEventDrawer: true,
            from: info.start ?? new Date(),
            to: info.end ?? new Date(),
        }))
    }
    const handleOpenDrawerEventUpdate = (openMethod, info) => {
        setCalendarState((prev) => ({
            ...prev,
            methodEvent: openMethod ?? 'UPDATE',
            selectedEventId: info.event._def.publicId,
            isOpenEventDrawer: true,
            from: info.event._instance.range.start,
            to: info.event._instance.range.end,
        }))
    }

    useEffect(() => {
        setCalendarState((prev) => ({ ...prev, selectedEventTypes: resourceEventTypes }))
    }, [resourceEventTypes])
    const handleFilters = (resourceEventType) => {
        if (calendarState.selectedEventTypes.filter((eventType) => eventType.id === resourceEventType.id).length > 0) {
            setCalendarState((prev) => ({
                ...prev,
                selectedEventTypes: calendarState.selectedEventTypes.filter(
                    (eventType) => eventType.id !== resourceEventType.id
                ),
            }))
        } else {
            setCalendarState((prev) => ({
                ...prev,
                selectedEventTypes: [...calendarState.selectedEventTypes, resourceEventType],
            }))
        }
    }
    const handleSelectAll = (checked) => {
        if (checked) {
            setCalendarState((prev) => ({ ...prev, selectedEventTypes: resourceEventTypes }))
        } else {
            setCalendarState((prev) => ({ ...prev, selectedEventTypes: [] }))
        }
    }

    return (
        <>
            <div className="grid grid-cols-6">
                <div className="col-span-1 mr-4">
                    <div className={'mb-4 flex flex-row items-center justify-between'}>
                        <p>Event types</p>
                        <Tooltip title={calendarState.selectedEventTypes.length > 0 ? 'Deselect All' : 'Select All'}>
                            <Checkbox
                                onChange={(event, checked) => handleSelectAll(checked)}
                                checked={calendarState.selectedEventTypes.length > 0}
                            ></Checkbox>
                        </Tooltip>
                    </div>
                    {resourceEventTypes.map((item) => (
                        <div className="flex flew-row justify-between" key={item.id}>
                            <motion.li
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{
                                    type: 'spring',
                                    duration: 0.5,
                                    ease: 'linear',
                                }}
                                className={`grow relative p-3 rounded-sm border bg-slate-50 hover:border-blue-500 transition-all ease-in-out border-slate-100 flex items-center justify-between cursor-pointer group`}
                                onClick={() => {
                                    handleClickOpenDrawer('UPDATE', item.id)
                                }}
                            >
                                <span
                                    className={`absolute top-0 left-0 h-full w-1 bg-green-200`}
                                    style={{ background: item.color }}
                                ></span>
                                <p className="text-sm font-medium ">{item.name}</p>
                                {resource.canManage && (
                                    <button
                                        className={`block text-xs border border-slate-200 p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity`}
                                    >
                                        <FiEdit2 size={20} />
                                    </button>
                                )}
                            </motion.li>
                            <Checkbox
                                key={`fileter_${item.id}`}
                                onChange={(event) => handleFilters(item)}
                                checked={
                                    calendarState.selectedEventTypes.filter((eventType) => eventType.id === item.id)
                                        .length > 0
                                }
                            ></Checkbox>
                        </div>
                    ))}
                    {resource.canManage && (
                        <motion.li
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{
                                type: 'spring',
                                duration: 0.5,
                                ease: 'linear',
                            }}
                            className={`relative p-3 rounded-sm transition-all ease-in-out border-slate-100 flex items-start justify-between cursor-pointer group`}
                        >
                            <motion.button
                                className="bg-white  rounded-lg text-slate-800 h-16  max-w-72 py-2 px-4 flex items-center justify-start gap-2"
                                whileHover={{ scale: 1.015 }}
                                whileTap={{ scale: 0.945 }}
                                onClick={() => {
                                    handleClickOpenDrawer('CREATE')
                                }}
                            >
                                <p>New event type</p>
                                <span className="border border-solid border-slate-200 p-2 rounded-full items-center flex flex-row">
                                    <FiPlus size={30} />
                                </span>
                            </motion.button>
                        </motion.li>
                    )}
                </div>
                <div className="col-span-5">
                    {eventIsLoading ? null : (
                        <FullCalendar
                            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                            initialView="dayGridMonth"
                            aspectRatio={1.8}
                            headerToolbar={{
                                left: 'prev,next',
                                center: 'title',
                                right: `dayGridMonth,timeGridWeek,timeGridDay${resource.canManage ? ' newEvent' : ''}`,
                            }}
                            customButtons={{
                                newEvent: {
                                    text: 'Add',
                                    click: () => {
                                        handleOpenDrawerEventCreate('CREATE', {})
                                    },
                                },
                            }}
                            select={(info) => handleOpenDrawerEventCreate('CREATE', info)}
                            selectable={true}
                            locale="en"
                            slotMinTime={'08:00:00'}
                            slotMaxTime={'20:00:00'}
                            businessHours={{
                                daysOfWeek: [1, 2, 3, 4, 5],
                                startTime: '08:00',
                                endTime: '20:00',
                                allDay: false,
                            }}
                            firstDay={1}
                            defaultAllDay={'08:00'}
                            forceEventDuration={true}
                            displayEventEnd={true}
                            eventTimeFormat={{ hour: '2-digit', minute: '2-digit', meridiem: false, hour12: false }}
                            eventClick={(event) => handleOpenDrawerEventUpdate('UPDATE', event)}
                            events={events.map((event) => {
                                return {
                                    id: event.id,
                                    title: event.title,
                                    start: event.startingDate,
                                    end: event.endingDate,
                                    editable: true,
                                    allDay: false,
                                    display:
                                        calendarState.selectedEventTypes.filter(
                                            (eventType) => eventType.id === event.resourceEventType.id
                                        ).length > 0
                                            ? 'auto'
                                            : 'none',
                                    backgroundColor: event.resourceEventType.color,
                                }
                            })}
                        />
                    )}
                </div>
            </div>
            <DrawerTemporary
                isOpenDrawer={calendarState.isOpenDrawer}
                setIsOpenDrawer={setIsOpenDrawer}
                onDrawerClose={handleDrawerClose}
                method={calendarState.method}
                titleDrawer={'CREATE EVENT TYPE'}
                titleDrawerUpdate={'titleDrawerUpdate'}
                content={<ResourceEventTypeForm resourceType={resourceType} />}
                idSelected={calendarState.selectedId}
            />
            <DrawerTemporary
                isOpenDrawer={calendarState.isOpenEventDrawer}
                setIsOpenDrawer={setIsOpenDrawerEvent}
                onDrawerClose={handleDrawerClose}
                method={calendarState.methodEvent}
                titleDrawer={`Create new "${resource.name}" event`}
                titleDrawerUpdate={'titleDrawerUpdate'}
                content={
                    <EventForm
                        resourceType={resourceType}
                        resourceEventTypes={resourceEventTypes}
                        from={calendarState.from}
                        to={calendarState.to}
                        resource={resource}
                    />
                }
                idSelected={calendarState.selectedEventId}
            />
        </>
    )
}
