import React from 'react';
import {BasicAccordion} from "../../components/accordion/BasicAccordion";
import {dataFaq} from "../../utils/dataList";

const ListFaq = () => {
    return (
        <div className={`lg:w-2/3 w-full grow`}>
            <BasicAccordion data={dataFaq} noexpand />
        </div>
    );
};

export default ListFaq;
