import * as React from "react";
import {useContext, useEffect, useState} from "react";

// Design
import {Box} from "@mui/material";

// Components
import {NavigationTabsContainer} from "./NavigationTabsContainer";

// Context
import {NavigationSelectionsMenuContext} from "./NavigationSelectionsMenu";


const useOnWindowScroll = (callback) => {
    useEffect(() => {

        window.addEventListener('scroll', callback);

        return () => {
            window.removeEventListener('scroll', callback);
        };
    }, [callback]);
};

const NavigationContent = ({ props, headerElementHeight, fixedValue }) => {

    // Mui Tabs
    const {
        featureStandardDescriptions, standardTabValue, handleChangeStandard,
        featureAdditionalDescriptions, additionalTabValue, handleChangeAdditional,
        descriptionTitle, descriptionSubTitle
    } = props;

    // From Context (Mobile : When Content is in Drawer)
    const navigationSelectionsMenuContext = useContext(NavigationSelectionsMenuContext)
    const {
        largeScreenSelectionClasses: largeScreenSelectionClassesContext,
        isMobile: isMobileContext,
    } = navigationSelectionsMenuContext !== null && navigationSelectionsMenuContext;


    return (
        <Box component={"div"}
             className={`${isMobileContext ? 'static' : 'fixed py-6'} bg-transparent w-full flex justify-center lg:justify-start lg:w-1/5 lg:ml-4 lg:mr-8 z-10 `}
             sx={{
                 top: `${headerElementHeight}px`,
                 transition: 'all .2s ease-in-out',
                 ...!fixedValue && ({
                     top: `${headerElementHeight}px`,
                 })
             }}
        >
            <div className={`${!isMobileContext && largeScreenSelectionClassesContext} py-6 px-4 max-w-[300px] lg:min-w-[280px]`}>
                <div className="mb-8">
                    <div className="flex items-center justify-center">
                        <div className="flex items-center text-xl font-bold text-black">{descriptionTitle}</div>
                    </div>
                    {descriptionSubTitle &&
                        <div className="flex items-center justify-center text-md font-bold text-slate-500 mt-4 pl-4">{descriptionSubTitle}</div>
                    }
                </div>

                <Box component={'div'} className={"w-full flex flex-wrap"}>
                    <Box component={'div'} className={"w-full flex flex-wrap items-center"}>

                        {featureStandardDescriptions.length !== 0 && (
                            <NavigationTabsContainer featureDescriptions={featureStandardDescriptions}
                                                     handleChange={handleChangeStandard}
                                                     value={standardTabValue}
                                                     props={props}
                                                     type="Standard"
                            />
                        )}

                        {featureAdditionalDescriptions.length !== 0 && (
                            <NavigationTabsContainer featureDescriptions={featureAdditionalDescriptions}
                                                     handleChange={handleChangeAdditional}
                                                     value={additionalTabValue}
                                                     props={props}
                                                     type="Additional Fields"
                            />
                        )}
                    </Box>
                </Box>
            </div>

        </Box>

    )
}


export const NavigationTabsWrapper = (props) => {

    // Navigation Position
    const [navigationPosition, setNavigationPosition] = useState(0);
    const [fixedValue, setFixedValue] = useState(true);

    const hasInfrastructureChild = props.listInfrastructureChild && props.listInfrastructureChild.length > 1;

    const headerElement = document.querySelector(hasInfrastructureChild ? '#current-installation' : 'header');
    let headerElementHeight = headerElement?.clientHeight;
    if (hasInfrastructureChild) {
        headerElementHeight = headerElementHeight + 50
    }

    useOnWindowScroll(() => setNavigationPosition(window.scrollY));

    useEffect(() => {
        navigationPosition > headerElementHeight ? setFixedValue(false) : setFixedValue(true)

    }, [navigationPosition]);


    return (
        <NavigationContent props={props}
                           headerElementHeight={headerElementHeight}
                           fixedValue={fixedValue}
        />
    )
}
