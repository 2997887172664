let ImageType;
export const CustomImageNews = (dataType) => {

    switch (dataType) {
        case "update":
            ImageType = <img src={`/uploads/update.webp`} alt="Update news" className="w-full h-full object-cover rounded-2xl"/>;
            break;
        case "info":
            ImageType = <img src={`/uploads/others.webp`} alt="Info news" className="w-full h-full object-cover rounded-2xl"/>;
            break;
        case "formation":
            ImageType = <img src={`/uploads/formation.webp`} alt="formation news" className="w-full h-full object-cover rounded-2xl"/>;
            break;
        // case "other":
        //     ImageType = <img src={`/uploads/rectangle.jpg`} alt="rectangle" className="w-full h-full object-cover"/>;
        //     break;
        default:
            // Ajoutez un cas par défaut au besoin
            ImageType = <img src={`/uploads/others.webp`} alt="INfo news" className="w-full h-full object-cover rounded-2xl"/>; // Par exemple, si le type n'est pas reconnu, utilisez une image nulle
    }

    return ImageType
}
