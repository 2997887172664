import {ListTypeFormsTooltip} from "./ListTypeFormsTooltip";
import {motion} from "framer-motion";
import React from "react";

export const ListTypeFormsFieldsReadOnly = ({ input, mapListInputs }) => {

    const inputHtmlType = mapListInputs.find(found => found['@id'] === input.input)?.inputType.htmlType
    const formattedCheckboxValue = (input.value).toString() === 'true' ? 'Yes' : 'No';

    return (
        <motion.div
            initial={{x: 0, opacity: 0}}
            animate={{x: 0, opacity: 1}}
            transition={{duration: .4}}
        >
            <ListTypeFormsTooltip title={
                <div dangerouslySetInnerHTML={{__html: inputHtmlType === 'checkbox' ? formattedCheckboxValue : (input.value)}}/>
            }>
                <div className={'read-only-fields'}
                     name={`display_${input.input}`}
                     dangerouslySetInnerHTML={{__html: inputHtmlType === 'checkbox' ? formattedCheckboxValue : (input.value)}}
                />
            </ListTypeFormsTooltip>

        </motion.div>
    )
}
