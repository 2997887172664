import {useEffect, useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import {useFetchOneInput} from "../../../inputs/hooks/useFetchOneInput";
import {FiAlertTriangle, FiCheckSquare, FiEye, FiSlash, FiTrash} from "react-icons/fi";
import {motion} from "framer-motion";
import {useFetchInputTypes} from "../../../inputsType/hooks/useFetchInputType";
import {toast} from "sonner";
import InputsService from "../../../inputs/services/InputsServices";
import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import Select from "react-select";
import * as React from "react";
import ConfirmDialog from "../../../../components/confirm/ConfirmDialog";
import {UseFetchSelect} from "../../../inputs/hooks/useFetchSelect";
import ErrorMessageRF from "../../../../components/form/errorMessage/ErrorMessageRF";

const selectUser = createSelector(
    state => state.login.user,
    user => user
);

const FormInput = ({idInput, method, form, handleUpdateInput, defaultInputType, networkId}) => {

    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [increment, setIncrement] = useState(0);
    const [isList, setIsList] = useState(false);
    const [isCustomSelect, setIsCustomSelect] = useState(false);
    const [selectFieldName, setSelectFieldName] = useState('default');
    const [currentSelects, setCurrentSelects] = useState([]);
    const {data, isLoading} = useFetchOneInput(idInput);
    const {data: listSelects, isLoading: isLoadingSelect} = UseFetchSelect(networkId, increment);

    const {inputTypes} = useFetchInputTypes();

    const {
        control,
        register,
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: {errors},
        getValues
    } = useForm({
        defaultValues: {
            name: "",
            description: "",
            inputType: defaultInputType,
            select: null,
            form: form,
            position: 1,
            isHidden: false,
            isRequired: false,
            isBLocked: false,
            isIndexable: false,
            isMultiple: false,
            isFromConfig: true,
            teamsPreload: null,
            serviceTypesPreload: null,
            resourceTypesPreload: null,
            selectPpaPreload: null,
            resourcePreload: null,
            code: "",
            uri: ""
        },
    });

    useEffect(() => {
        if (data) {
            setValue("name", data.name);
            setValue("description", data.description);
            setValue("inputType", data.inputType);
            setValue("select", data.select);
            setValue("form", data.form);
            setValue("position", data.position);
            setValue("isHidden", data.isHidden);
            setValue("isBlocked", data.isBlocked);
            setValue("isRequired", data.isRequired);
            setValue("isIndexable", data.isIndexable);
            setValue("isMultiple", data.isMultiple);
            setValue("isFromConfig", data.isFromConfig);
            setValue("teamsPreload", data.teamsPreload);
            setValue("serviceTypesPreload", data.serviceTypesPreload);
            setValue("resourceTypesPreload", data.resourceTypesPreload);
            setValue("selectPpaPreload", data.selectPpaPreload);
            setValue("resourcePreload", data.resourcePreload);
            setValue("code", data.code);
            setValue("uri", data.uri);
        }
    }, [data]);


    const user = useSelector(selectUser);
    const token = user.token;

    const inputsService = new InputsService();

    const onSubmit = async (myData) => {
        if (!myData) return toast.message("Ooops! somthing went wrong!");

        myData.position = parseInt(myData.position, 10);

        myData.isHidden = Boolean(myData.isHidden);
        myData.isRequired = Boolean(myData.isRequired);
        myData.isBlocked = Boolean(myData.isBlocked);
        myData.isMultiple = Boolean(myData.isMultiple ?? false);

        // myData.isIndexable = Boolean(myData.isIndexable);
        // myData.isFromConfig = Boolean(myData.isFromConfig);

        const requestData = {
            name: myData.name,
            description: myData.description,
            position: myData.position,
            inputType: myData.inputType["@id"] ?? null,
            isHidden: myData.isHidden,
            isBlocked: myData.isBlocked,
            isRequired: myData.isRequired,
            isMultiple: myData.isMultiple,
            form: form,
            select: myData.select ? myData.select["@id"] : null,
        }

        try {
            let res, message;

            if (method === "POST") {
                res = await inputsService.AddInput(requestData, token);
                message = "Input has been added successfully"
            } else if (method === "PUT") {
                res = await inputsService.EditInput(requestData, idInput, token);
                message = "Input has been updated successfully"
            }

            if (res) {

                reset({
                    name: "",
                    description: "",
                    inputType: defaultInputType,
                    select: null,
                    form: form,
                    position: 1,
                    isHidden: false,
                    isRequired: false,
                    isBlocked: false,
                    // isIndexable: true,
                    isMultiple: false,
                    // isFromConfig: true,
                    // teamsPreload: null,
                    // serviceTypesPreload: null,
                    // resourceTypesPreload: null,
                    // selectPpaPreload: null,
                    // resourcePreload: null,
                    // code: "",
                    // uri: ""
                })

                if (typeof handleUpdateInput === 'function') {
                    handleUpdateInput();
                }

                return toast.success(message);
            }

        } catch (error) {
            console.error(error);
            return toast.warning("Something went wrong!");
        }

    }

    const handleDelete = async () => {
        try {
            inputsService.DeleteInput(idInput, token).then(() => {
                if (typeof handleUpdateInput === 'function') {
                    handleUpdateInput();
                }
            });

            return toast.success('Input has been deleted successfully ');
        } catch (error) {
            console.error(error);
            return toast.warning("Something went wrong!");
        }
    }

    useEffect(() => {
        const it = watch("inputType");
        setIncrement(increment + 1);
        setIsCustomSelect(false);
        setSelectFieldName('default');
        setCurrentSelects([]);
        if (it && 'select' === it.htmlType ) {
            setIsList(true);
            if ('custom' === it.code) {
                setIsCustomSelect(true);
                setSelectFieldName('select');
                setCurrentSelects(listSelects.reduce((groups, s) => {
                    const groupLabel = s.isStandard ? "Standard" : "Custom";

                    let group = groups.find(g => g.label === groupLabel);
                    if (!group) {
                        group = {label: groupLabel, options: []};
                        groups.push(group);
                    }

                    group.options.push(s);

                    return groups;
                }, []));
            }

        } else {
            setIsList(false);
            setValue("select", null);
            setValue("isMultiple", false);
        }
    }, [watch("inputType")]);


    return (

        <>
            {!isLoading &&

                <form onSubmit={handleSubmit(onSubmit)} className="w-11/12 mx-auto py-10">

                    <div className="w-full flex flex-col gap-8 ">
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-col gap-4">
                                <label htmlFor="name">{errors.name ? <ErrorMessageRF content={errors.name.message}/> : "Name *"}</label>
                                <input
                                    type="text"
                                    {...register("name", {required: "This field is required!"})}
                                    className="border border-slate-300 border-solid rounded-sm w-full grow h-12 p-4 focus:outline-none focus:ring focus:ring-blue-200"
                                />
                            </div>

                            <div className="flex flex-col gap-4">
                                <label htmlFor="description">Description <span className="text-xs text-slate-400">(Optionnel)</span></label>
                                <textarea
                                    {...register("description")}
                                    className="border border-slate-300 border-solid rounded-sm w-full grow p-4 focus:outline-none focus:ring focus:ring-blue-200"
                                    placeholder="Description"
                                    rows={3}
                                    cols={3}
                                />
                            </div>
                            <div className="flex flex-col gap-2">
                                <label htmlFor="position">Position *</label>
                                <input
                                    type="number"
                                    min="1"
                                    {...register("position")}
                                    className="border border-slate-300 border-solid rounded-sm w-full grow h-12 p-4 focus:outline-none focus:ring focus:ring-blue-200"
                                />
                            </div>

                        </div>

                        <div className="w-full flex flex-col gap-4">
                            <p>Type of input *</p>
                            <Controller
                                name="inputType"
                                control={control}
                                render={({field}) => (
                                    <Select
                                        {...register("inputType", {required: "Input type is required"})}
                                        {...field}
                                        onChange={(it) => {
                                            setValue("inputType", it);
                                        }}
                                        options={inputTypes.sort((a, b) => a.name.localeCompare(b.name))}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        isSearchable
                                    />
                                )}
                            />
                            {errors.inputType && !watch("inputType") && (
                                <span className={"text-sm text-red-500/70"}
                                      role="alert">{errors.inputType.message}</span>
                            )}
                            {isList && isCustomSelect &&
                                <>
                                    <Controller
                                        name={selectFieldName}
                                        control={control}
                                        render={({field}) => (
                                            <Select
                                                {...register(selectFieldName, {required: "This field is required"})}
                                                {...field}
                                                onChange={(it) => {
                                                    setValue(selectFieldName, it);
                                                }}
                                                options={currentSelects}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id}
                                                isSearchable
                                            />
                                        )}
                                    />
                                    {errors.selectFieldName && !watch(selectFieldName) && (
                                        <span className={"text-sm text-red-500/70"}
                                              role="alert">{errors.selectFieldName.message}</span>
                                    )}
                                </>
                            }
                        </div>

                        <ul className="flex flex-col gap-2 bg-white border border-slate-200 rounded-xl p-4">
                            {isList &&
                                <li className="flex justify-between gap-2 w-full text-sm text-slate-500">
                                    <div className="w-1/2 grow">
                                        <p className="flex items-center gap-1"> <span
                                            className="text-sm"><FiCheckSquare/></span>
                                            Multiple
                                        </p>
                                    </div>
                                    <div className=" text-blue-500 flex items-center gap-2">
                                        <label
                                            htmlFor="isRequired"
                                            className={`relative w-10 h-6 flex items-center justify-center rounded-full cursor-pointer text-xs font-medium border px-1 ${watch("isMultiple") ? "text-green-400 bg-green-100 border-green-300" : "text-orange-400 bg-orange-100 border-orange-300"}`}
                                        >
                                            <input
                                                type="checkbox"
                                                id="isRequired"
                                                defaultChecked={data?.isMultiple ?? false}
                                                {...register("isMultiple")}
                                                className="sr-only"
                                            />

                                            <span
                                                className={`${watch("isMultiple") ? "right-0 border-green-400" : "border-orange-400 left-0"} transition ease-linear h-6 w-6 rounded-full bg-white shadow-sm border absolute top-1/2 transform -translate-y-1/2`}></span>
                                        </label>
                                    </div>
                                </li>
                            }
                            <li className="flex justify-between gap-2 w-full text-sm text-slate-500">
                                <div className="w-1/2 grow">
                                    <p className="flex items-center gap-1"> <span
                                        className="text-sm"><FiAlertTriangle/></span>
                                        Required element
                                    </p>
                                </div>
                                <div className=" text-blue-500 flex items-center gap-2">
                                    <label
                                        htmlFor="isRequired"
                                        className={`relative w-10 h-6 flex items-center justify-center rounded-full cursor-pointer text-xs font-medium border px-1 ${watch("isRequired") ? "text-green-400 bg-green-100 border-green-300" : "text-orange-400 bg-orange-100 border-orange-300"}`}
                                    >
                                        <input
                                            type="checkbox"
                                            id="isRequired"
                                            defaultChecked={data?.isRequired ?? false}
                                            {...register("isRequired")}
                                            className="sr-only"
                                        />

                                        <span
                                            className={`${watch("isRequired") ? "right-0 border-green-400" : "border-orange-400 left-0"} transition ease-linear h-6 w-6 rounded-full bg-white shadow-sm border absolute top-1/2 transform -translate-y-1/2`}></span>
                                    </label>
                                </div>
                            </li>
                            <li className="flex justify-between gap-2 w-full text-sm text-slate-500">
                                <div className="w-1/2 grow">
                                    <p className="flex items-center gap-1"> <span
                                        className="text-sm"><FiSlash/></span>
                                        Blocked element
                                    </p>
                                </div>
                                <div className=" text-blue-500 flex items-center gap-2">
                                    <label
                                        htmlFor="isBlocked"
                                        className={`relative w-10 h-6 flex items-center justify-center rounded-full cursor-pointer text-xs font-medium border px-1 ${watch("isBlocked") ? "text-green-400 bg-green-100 border-green-300" : "text-orange-400 bg-orange-100 border-orange-300"}`}
                                    >
                                        <input
                                            type="checkbox"
                                            id="isBlocked"
                                            defaultChecked={data?.isBlocked ?? false}
                                            {...register("isBlocked")}
                                            className="sr-only"
                                        />

                                        <span
                                            className={`${watch("isBlocked") ? "right-0 border-green-400" : "border-orange-400 left-0"} transition ease-linear h-6 w-6 rounded-full bg-white shadow-sm border absolute top-1/2 transform -translate-y-1/2`}></span>
                                    </label>
                                </div>
                            </li>

                            <li className="flex justify-between gap-2 w-full text-sm text-slate-500">
                                <div className="w-1/2 grow">
                                    <p className="flex items-center gap-1"> <span
                                        className="text-sm"><FiEye/></span>
                                        Hidden element
                                    </p>
                                </div>
                                <div className=" text-blue-500 flex items-center gap-2">
                                    <label
                                        htmlFor="input-isHidden"
                                        className={`relative w-10 h-6 flex items-center justify-center rounded-full cursor-pointer text-xs font-medium border px-1 ${watch("isHidden") ? "text-green-400 bg-green-100 border-green-300" : "text-orange-400 bg-orange-100 border-orange-300"}`}
                                    >
                                        <input
                                            type="checkbox"
                                            id="input-isHidden"
                                            defaultChecked={data?.isHidden ?? false}
                                            {...register("isHidden")}
                                            className="sr-only"
                                        />

                                        <span
                                            className={`${watch("isHidden") ? "right-0 border-green-400" : "border-orange-400 left-0"} transition ease-linear h-6 w-6 rounded-full bg-white shadow-sm border absolute top-1/2 transform -translate-y-1/2`}></span>
                                    </label>
                                </div>
                            </li>

                        </ul>

                        <div className="flex items-center gap-4">
                            <motion.button
                                type="submit"
                                className={`grow border border-slate-200 border-dashed rounded-sm text-slate-700 bg-slate-200 text-md h-10 flex items-center justify-center hover:bg-blue-500 hover:text-slate-50 transition ease-linear`}
                            >
                                {method === "POST" ? "Create" : "Save"}
                            </motion.button>
                            {method !== "POST" && (
                                <>
                                    <motion.button
                                        type="button"
                                        onClick={() => {
                                            setOpenConfirmation(true);
                                        }}
                                        className={`border border-red-200 rounded-full bg-red-100 text-red-500 text-md h-10 w-10 flex items-center justify-center hover:bg-red-500 hover:text-slate-50 transition ease-linear`}
                                    ><FiTrash/>
                                    </motion.button>
                                    <ConfirmDialog
                                        isOpen={openConfirmation}
                                        onClose={() => setOpenConfirmation(false)}
                                        setIsOpen={setOpenConfirmation}
                                        onAgree={() => handleDelete()}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </form>
            }
        </>
    );
};

export default FormInput;