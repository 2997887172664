import useFetchConfigByNetworkId from "../../../../select/hooks/useFetchConfigByNetworkId";
import TableSelects from "../../../../select/components/TablesSelects";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

export const SelectBox = ({ networkId }) => {
    const [isSubmitting, setIsSubmitting]= useState(false);

    const data = useSelector(state => state.selectConfigByNetworkReducers.data) ?? null;
    const {isLoading, error, fetchConfigByNetworkId } = useFetchConfigByNetworkId(networkId);

    useEffect(() => {
        if (!isSubmitting) return;

        fetchConfigByNetworkId(networkId);
        setIsSubmitting(false);
    }, [data]);

    if (error) return "Error fetching data";

    return (
        <div className="w-11/12 flex gap-10 items-stretch relative">
            {!isLoading &&
                <TableSelects
                    data={data}
                    isLoading={isLoading}
                    fullWidth
                    setIsSubmitting={setIsSubmitting}
                    id={networkId}
                />
            }
        </div>
    )
}