// export const displayMessage = (payload) => ({
//     payload: payload,
//     type: 'DISPLAY_MESSAGE'
// });

export const displayMessage = (title = "Success", message = "Element updated", type = "success") => ({
    payload: {
        title: title,
        message: message,
        type: type,
    },
    type: 'DISPLAY_MESSAGE'
});

export const removeMessage = () => ({
    type: 'REMOVE_MESSAGE'
});
