import {useForm} from "react-hook-form";

import {motion} from "framer-motion";
import ErrorMessageRF from "../../../../components/form/errorMessage/ErrorMessageRF";

import {createSelector} from "reselect";
import { useSelector} from "react-redux";
import {SelectOptionsService} from "../../services/selectOptionsServices";
import {toast} from "sonner";
import {useEffect} from "react";
import {FiX} from "react-icons/fi";


const selectUser = createSelector(
    state => state.login.user,
    user => user
);

const FormSelectOptions = ({ method, data, id, optionId, setIsOpenBox, setIsSubmitting  }) => {

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
        reset
    } = useForm({
        defaultValues: {
            name: "",
            select: id ? `api/selects/${id}`: null,
            code:"",
        }
    })

    useEffect(() => {
        if (!data && !optionId) return;

        const option = data?.find(item => item.id === optionId);

        setValue("name", option.name);
        setValue("code", option.code);

    }, [data]);


    const user = useSelector(selectUser);
    const token = user.token;

    const onSubmit = async (mydata) => {

        try {
            const selectOptionsService = new SelectOptionsService();

            let res;

            if (method === "POST") res = await selectOptionsService.postSelectOptions(mydata, token);

            if (method === "PUT") res = await selectOptionsService.putSelectOptionsConfigById(optionId, mydata, token);

            if (!res) throw  new Error("Something is wrong when to post data");

            setIsSubmitting(true)
            setIsOpenBox(false);

            if (method === "POST") return toast.success("Select option added successfully!");
            if (method === "PUT") return toast.success("Select option updated successfully!");

        } catch (err) {
            console.error(err)
            return toast.warning("Oops! An error occurred while creating a select option.");
        }
    }

    const handleNameBlur = () => {
        setValue('code', watch('name').replace(/\W/g, "-").toLowerCase())
    };

    const handleCloseUpdateBox = () => {
        setIsOpenBox(false);
    }

    return (
        <motion.form
            onSubmit={handleSubmit(onSubmit)}

            initial={{opacity: 0, translateY: 15}}
            animate={{opacity: 1, translateY: 0}}
            exit={{opacity: 0, translateY: 15}}

            transition={{type: "spring", duration: .3, ease: "linear"}}

            className={`min-h-32 z-10 bg-white border border-slate-200 rounded-2xl p-4 flex gap-4 flex-col ${method === "PUT" ? "relative w-full" : "absolute right-0  w-72 top-14"}`}
        >

            {method === "PUT" &&
                <button
                    type="button"
                    onClick={handleCloseUpdateBox}
                    className="border border-red-200 text-red-500 bg-red-50 hover:bg-red-500 hover:text-slate-50 transition ease-linear duration-75 rounded-full h-8 w-8 flex items-center justify-center  text-sm absolute -right-2  -top-4">
                    <FiX />
                </button>
            }
            <div className="flex flex-col gap-2">
                <label
                    htmlFor="name"
                    className="mb-2 block text-sm text-slate-500"
                >{errors.name ? <ErrorMessageRF content={errors.name.message}/> : "Name of Select option"}</label>
                <input type="text"
                       placeholder="Enter new name ..."
                       id="name"
                       autoFocus={true}
                       {...register("name", {required: "This field is required!"})}
                       className="bg-transparent focus:outline-none rounded-sm ring ring-blue-50 h-10 px-2 w-full"
                       onBlur={() => handleNameBlur()}
                />
            </div>

            <div className="flex flex-col gap-2">
                <label
                    htmlFor="code"
                    className="mb-2 block text-sm text-slate-500"
                >Code of Select option</label>
                <input type="text"
                       placeholder="Enter code ..."
                       id="code"
                       {...register("code")}
                       className="bg-transparent focus:outline-none rounded-sm ring ring-blue-50 h-10 px-2 w-full"
                />
            </div>

            <div>
                <button type="submit" className="bg-blue-500 text-slate-50 text-xs py-2 px-4 rounded-full">{ method === "PUT" ? "Save" : "Add"}
                </button>
            </div>
        </motion.form>
    );
};

export default FormSelectOptions;
