import {useEffect, useState} from "react";
import ServiceTypeService from "../services/ServiceTypeService";

export const useFetchServiceType = (id, token, increment) => {

    const [serviceType, setServiceType] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchServiceType = async () => {
            try {
                const serviceTypeService = new ServiceTypeService();
                const res = await serviceTypeService.getAllServiceTypeByInfraId(id, token);
                setServiceType(res);
                setIsLoading(false);
            } catch (err) {
                setError(err);
                setIsLoading(false);
            }
        }

        fetchServiceType().then();

    }, [id, token, increment])

    return {serviceType, isLoading, error}
}

export const useFetchOneServiceType = (id, token, increment, setIsLoading) => {

    const [serviceType, setServiceType] = useState(null);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchServiceType = async () => {
            try {
                setIsLoading(true);
                if (undefined !== id && id > 0 && null !== id) {
                    const serviceTypeService = new ServiceTypeService();
                    const res = await serviceTypeService.getOneServiceTypeById(id, token);
                    setServiceType(res);
                } else {
                    setServiceType(null);
                }
                setIsLoading(false);
            } catch (err) {
                setError(err);
                setIsLoading(false);
            }
        }

        fetchServiceType().then();

    }, [id, token, increment])

    return {serviceType, error}
}