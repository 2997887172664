import AxiosService from "../../../services/AxiosService";


const urlTabs = "api/tabs";

class TabsService {
    GetTabs(token){
        return AxiosService.getWithToken(urlTabs, token, "jsonld");
    }
    GetTab(id, token){
        return AxiosService.getWithToken(urlTabs + "/" + id, token, "jsonld");
    }
    AddTab(data, token){
        data["token"] = token;
        return AxiosService.postWithToken(urlTabs, data, "jsonld");
    }
    EditTab(data, id, token){
        return AxiosService.putWithToken(urlTabs + "/" + id, data, token, "jsonld");
    }
    DeleteTab(id, token){
        return AxiosService.deleteWithToken(urlTabs + "/" + id, token, "jsonld");
    }
}

export default TabsService;