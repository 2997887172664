import { useState, useEffect } from 'react';
import NetworkService from '../../../services/NetworkService';
import {useDispatch, useSelector} from 'react-redux';
import {fetchNetworkConfig} from "../../../redux/networkConfig/networkConfigAction";

export const useFetchNetworkConfig = () => {
    const token = useSelector(state => state.login.user?.token) ?? null;
    const dispatch = useDispatch();

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [totalItem, setTotalItem] = useState(null);

    useEffect(() => {
        fetchAllNetworksConfig();

    }, [token]);

    async function fetchAllNetworksConfig() {
        try {
            const networkConfig = new NetworkService()
            const res = await networkConfig.getListNetworkConfig(token);

            dispatch(fetchNetworkConfig(res["hydra:member"]));

            setData(res["hydra:member"]);
            setTotalItem(res["hydra:totalItems"]);
            setIsLoading(false);

        } catch (error) {
            setError(error);
            setIsLoading(false);
        }
    }

    return { data, isLoading, error, totalItem, fetchAllNetworksConfig };
};
