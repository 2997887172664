import AxiosService from "../../../services/AxiosService";


const urlForms = "api/forms";

class FormService {
    GetForms(token) {
        return AxiosService.getWithToken(urlForms, token, "jsonld");
    }

    GetFormId(id, token) {
        return AxiosService.getWithToken(urlForms + "/" + id, token, "jsonld");
    }
    AddForm(data, token) {
        data["token"] = token;
        return AxiosService.postWithToken(urlForms, data, "jsonld");
    }
    EditForm(data, id, token) {
        // data["token"] = token;

        return AxiosService.putWithToken(urlForms + "/" + id, data, token, "jsonld");
    }
    DeleteForm(id, token) {
        return AxiosService.deleteWithToken(urlForms + "/" + id, token, "jsonld");
    }
}

export default FormService;