import { useState, useEffect } from 'react';
import NewsService from "../../../services/NewsService";

export const useFetchAllNews = (page, itemsPerPage) => {
    const [data, setData] = useState([]);
    const [totalItems, setTotalItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            async function fetchAllNews() {
                try {
                    // setIsLoading(true);
                    const res = await NewsService.getAllNews(page, itemsPerPage);

                    // Créer une copie inversée du tableau

                    setData(res["hydra:member"]);
                    setTotalItems(res["hydra:totalItems"]);
                    setIsLoading(false);
                } catch (error) {
                    setError(error);
                    setIsLoading(false);
                }
            }
            fetchAllNews();
        }, 0)

        return () => {
            clearTimeout(timer);
        };

    }, [page]);

    return { data, totalItems, isLoading, error, page };
}
