import React from 'react'
import { Loader } from '../../../components/loader/Loader'
import { DataGrid } from '@mui/x-data-grid'
import { useFetchAllUsers } from '../../user/hook/useFetchUserInfra'


export default function UsersSuperAdmin() {

    const { listUsers, isLoading } = useFetchAllUsers()

    if (isLoading) return <Loader content="Loading users..." />

    return (
        <div className={'flex flex-col w-full h-[60vh]'}>
            <DataGrid
                rows={listUsers['hydra:member']}
                columns={[
                    { field: 'id', headerName: 'ID', flex: 0.1 },
                    { field: 'email', headerName: 'Email', flex: 0.5 },
                    { field: 'fullName', headerName: 'Full name', flex: 0.4 },
                    { field: 'status', headerName: 'Status', flex: 0.2 },
                    {
                        field: 'listInstallations',
                        headerName: 'List of installations',
                        valueGetter: (installation) => {
                            const result = installation.value.map(i => i.name)
                            if (result.length === 0) {
                                return 'No installation'
                            }
                            return result.join(', ')
                        },
                        flex: 1,
                    },
                    { field: 'lastConnection', headerName: 'Last connection', flex: 0.3 },
                ]}
                getRowId={(row) => row['id']}
            />
        </div>
    )
}