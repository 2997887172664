import {motion} from "framer-motion";
import {FaInfo} from "react-icons/fa";

export  const ButtonSidebar = ({ isOpen, setIsOpen, catalogType }) => {

    const handleClick = (btnId) => {
        setIsOpen(!isOpen)
    }

    return (

        <motion.button
            className={`border border-slate-300 text-slate-500 rounded-full text-lg flex items-center justify-center w-8 h-8`}
            onClick={() => handleClick(buttonData.id)}

            whileHover={{scale: 1.025}}
            whileTap={{scale: .945}}

            title={buttonData.title}
            transition={{type: "spring", duration: .3, ease: "linear"}}
        >
            <span className={`text-sm`}>{buttonData.icon}</span>
        </motion.button>

    )
}

const buttonData = {
    id: 1,
    title: "About catalog",
    icon: <FaInfo />
}