import React from "react";
import {Box, InputAdornment, InputLabel, OutlinedInput} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const SearchFilter = ({ networkData, setSearchQuery, setSearchList, setNoMoreToLoad, setNoResult, itemsPerRow }) => {

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    const handleSearchInput = (event) => {
        const results = networkData.filter(network => {
            if (event.target.value === "") return networkData
            return network.name.toLowerCase().includes(event.target.value.toLowerCase())
        });

        setSearchQuery(event.target.value);
        setSearchList(results);

        setNoMoreToLoad(results.length < itemsPerRow);
        setNoResult(results.length === 0);
    }

    return (
        <Box component="div" sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <InputLabel htmlFor="search_filter" sx={{ display: 'none' }}>
                Search Filter
            </InputLabel>
            <OutlinedInput onChange={handleSearchInput}
                           placeholder="Search"
                           startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                           sx={{ display: 'flex', width: '100%', borderRadius: '10px', }}
                           onKeyDown={handleKeyDown}
                           id="search_filter"
            />
        </Box>
    )
}

export default SearchFilter;
