import * as React from 'react';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';

export default function SubMenu ({ setValue, panel }) {
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <TabList onChange={handleChange} aria-label="lab API tabs example"
                 visibleScrollbar={false}
                 centered
                 indicatorColor="secondary"
                 variant={"fullWidth"}

            sx={{
                border:"none !important",
                bgColor:"red"
            }}
        >
            { panel.map( item =>
                <Tab label={item.title} key={item.id} value={item.link} />
            )}
        </TabList>
    );
}