import React, {useEffect} from "react";

// Design
import {Box, Grid} from "@mui/material";
import List from "@mui/material/List";
import { motion } from "framer-motion";

// Components
import {FormsTitle} from "./items/FormsTitle";
import {FormContentForms} from "./FormContentForms";


export const FormSpecific = ({ handleDrawerClose,
                                   listTabs,
                                   activeStep,
                                   joinedInputs,
                                   joinedForms,
                                   preloadServices,
                                   values,
                                   selectOptions,
                                   setMissingFieldsChanges,
                                   hideMissingFields,

                                   setJoinedInputs,
                                   setState,
                                   isRead
                                }) => {



    const tabsIds = listTabs.map((item) => item.id);
    const tabsNames = listTabs.map((item) => item.name);
    let activeTabId = tabsIds[activeStep];
    let activeTabName = tabsNames[activeStep];


    useEffect(() => {
        handleDrawerClose && handleDrawerClose();
    }, []);


    return (
        <motion.article
            className="pt-[10px] w-full flex flex-col"
            initial={{ opacity: 0}}
            animate={{ opacity: 1}}
            transition={{ duration:.5, delay: .3}}
        >
            <FormsTitle title={<><span>{ activeTabName }</span></>} />

            <Box className="reusableform-box-structure">
                <Grid container>
                    <List sx={{width: '100%'}}>
                        <FormContentForms joinedInputs={joinedInputs}
                                          joinedForms={joinedForms}
                                          selectOptions={selectOptions}
                                          preloadServices={preloadServices}
                                          values={values}
                                          activeTabId={activeTabId}

                                          activeStep={activeStep}
                                          setMissingFieldsChanges={setMissingFieldsChanges}
                                          hideMissingFields={hideMissingFields}

                                          setJoinedInputs={setJoinedInputs}
                                          setState={setState}
                                          isRead={isRead}

                        />
                    </List>
                </Grid>
            </Box>
        </motion.article>
    )
}
