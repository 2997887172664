import {Button} from "@mui/material";


export const LoadMore = ({ dataLoad, totalItems, itemsPerPage, setItemsPerPage }) => {


    const handleLoadMore = () => {
        const dataToLoad = dataLoad;

        if (totalItems > itemsPerPage) {
            const newItemsPerPage = Math.min(itemsPerPage + dataToLoad, totalItems);
            setItemsPerPage(newItemsPerPage);
        }

        window.scrollBy({
            bottom: 0,
            behavior: 'smooth'
        });
    };

    return(

        <Button
            onClick={ handleLoadMore}
            disabled={ totalItems <= itemsPerPage }

            style={{
                color:"#272727",
                borderRadius: "50px",
                margin: "15px auto 0",
                border: '1px solid rgba(102, 102, 102, 0.19)',
                display: 'flex',
                zIndex:2,
                opacity:` ${totalItems <= itemsPerPage ? ".3" : "1"}`
            }}

            sx={{ padding: {xs:"10px 15px", lg: "13px 33px"} }}
        >{ totalItems <= itemsPerPage ? "All loaded" : "Load More"} </Button>
    )
}