import {Box, Typography} from "@mui/material";
import { formatDateToCustom } from "../../../utils/CustomDate";
import { CustomImageNews } from "../utils/CustomImageNews";
import { useNavigate } from "react-router-dom";
import {CheckWindowSize} from "../../../utils/CheckWindowSize";
import {motion} from "framer-motion";

export const Card = ({ data }) => {
    const navigate = useNavigate();
    const handleClick = (id) => {
        return navigate(`/news/${id}`)
    }
    const { mediaQuery } = CheckWindowSize();

    return (
        <>
            <Box component="div">
                <Typography component="h3" variant="h3">{data.title}</Typography>
                <span className="text-sm text-slate-400">{formatDateToCustom(data.createdAt)}</span>
            </Box>
            <motion.div
                className="order-first mb-5 overflow-hidden cursor-pointer rounded-2xl h-[350px]"
                onClick={ () => handleClick(data.id) }
            >
                <motion.figure
                    whileHover={{ scale: 1.1 }}
                    transition={{ type:"spring", stiffness: 200, damping: 25}}
                    whileTap={{ scale: .9 }}
                    className="h-[350px]"
                >
                    { CustomImageNews(data.type) }
                </motion.figure>
            </motion.div>
        </>
    );
};
