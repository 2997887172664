export const FETCH_ALL_NETWORK_CONFIG = 'FETCH_ALL_NETWORK_CONFIG';

export const ADD_NETWORK = 'ADD_NETWORK';

export const fetchNetworkConfig = (data) => ({
    type: FETCH_ALL_NETWORK_CONFIG,
    payload: data
})

// Action pour ajouter un nouveau réseau
export const addNetwork = (newNetwork) => ({
    type: ADD_NETWORK,
    payload: newNetwork
});