import React from "react";
import {Field} from "react-final-form";
import CKEditorWrapper from "./CKEditorWrapper";

// Design
import {Grid} from "@mui/material";
import {CustomizedInputLabel} from "./FormCustomizedInputs";
import {FormLabelTooltip} from "./FormLabelTooltip";
import {IoInformationCircleOutline} from "react-icons/io5";


export const FormTextArea = ({ listInput, name, description, id, inputIndex, values, keyLinkValue, isRead }) => {
    return (
        <Grid item xs={12} hidden={listInput.isHidden}>
            <CustomizedInputLabel shrink id={ `${id}` }>
                { listInput.name }{listInput.isRequired && " *"}

                { description && (
                    <FormLabelTooltip title={ <div dangerouslySetInnerHTML={{__html: description}}/> }>
                        <span>
                            <IoInformationCircleOutline className={"mt-[1.6px] text-blue-500 text-xl"}/>
                        </span>
                    </FormLabelTooltip>
                )}
            </CustomizedInputLabel>
            <Field name={ name }
                   component={CKEditorWrapper}
                   id={ id }
                   labelId={ `${id}` }
                   inputIndex={inputIndex}
                   values={values}
                   hide={listInput.isHidden}
                   required={listInput.isRequired}
                   disabled={isRead || listInput.isBlocked}
                   keyLinkValue={keyLinkValue}
            />
        </Grid>
    )
}
