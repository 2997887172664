import * as React from "react";

// Design
import {Box, Typography} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {NavigationAdditionalTab} from "./NavigationAdditionalTab";
import {GrCertificate} from "react-icons/gr";


const commonTabStyle= {
    margin: '0 0 20px',
    padding: '0',
    textAlign: 'left',
    "&.Mui-selected": {
        fontWeight: 'bold',
    },
}

export const NavigationTabsContainer = ({ featureDescriptions, handleChange, value, type, props }) => {

    // Mui Tabs
    const { a11yProps } = props

    return (
        <Box component={'div'} className={"w-full pl-4"}>

            <Typography variant="body2" color="textSecondary">
                { type }
            </Typography>

            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ paddingY: '14px', border: 'none' }}

                TabIndicatorProps={{
                    sx: { left: 0, bgcolor: 'orange', width: '3px' }
                }}
            >
                {featureDescriptions?.map((description, descriptionIndex) => (

                    !description.networkName ? (
                        <Tab key={descriptionIndex}
                             label={
                                 <div className={"flex gap-2 text-md"}>
                                     <GrCertificate className={"text-[28px] self-center"}/>
                                     { description.name }
                                 </div>
                             }
                             {...a11yProps(descriptionIndex)}
                             sx={{
                                 ...commonTabStyle,
                                 paddingLeft: '12px',
                             }}
                        />
                    ) : (
                        <Tab key={descriptionIndex}
                             wrapped={true}
                             label={
                                <NavigationAdditionalTab description={description}
                                                         descriptionIndex={descriptionIndex}
                                                         // a11yProps={a11yProps}
                                />
                             }
                             {...a11yProps(descriptionIndex)}
                             sx={{
                                 ...commonTabStyle,
                                 minHeight: 'unset',
                                 "& h3": {
                                     fontSize: '14px'
                                 },
                                 "&.Mui-selected": {
                                     fontWeight: 'bold',
                                     "& h3": {
                                         fontWeight: 'bold',
                                     }
                                 },
                             }}
                        />
                    )

                ))}
            </Tabs>
        </Box>
    )
}
