import { useState, useEffect } from 'react';
import InfrastructureService from "../service/InfrastructureService";
export const useFetchOneInstallation = (idcatalog, id ) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchOneInstallation() {

            try {
                const infraService =  new InfrastructureService();
                const res = await infraService.getInfrastructureById(idcatalog, id);
                setData(res);
                setIsLoading(false);
            } catch (error) {
                setError(error);
                setIsLoading(false);
            }
        }

        fetchOneInstallation();

    }, [id]);

    return { data, isLoading, error };
}
