import {motion} from "framer-motion";
import FormControl from "@mui/material/FormControl";
import {RadioGroup} from "@mui/material";
import {CatalogTypeList} from "./catalogTypeList";

export  const CatalogTypeBox = ({ catalogType, setCatalogType }) => {
    const handleChange = (event) => {
        setCatalogType(event.target.value);
    }

    return (
        <FormControl className="w-full flex items-center justify-start">
            <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="catalogtype"
                value={catalogType}
                onChange={ handleChange }
                className="w-full"
            >
                <CatalogTypeList  catalogTypeData={catalogTypeData} />
            </RadioGroup>
        </FormControl>
    )
}

const catalogTypeData = [
    // {
    //     id:1,
    //     name: "Resource",
    //     cattype:true
    // },
    {
        id:2,
        name: "Installations",
        cattype:true,
        code:2
    },
    {
        id:3,
        name: "Services",
        cattype:true,
        code:3
    }

]