import { motion } from 'framer-motion'
import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select'
import { useFetchTeamData } from '../../../admin/hooks/api/useFetchTeamData'
import { createSelector } from 'reselect'
import { useDispatch, useSelector } from 'react-redux'
import { FormLoader } from '../../../servicetype/loaders/Loaders'
import { useFetchInfrastructureUsers } from '../../../admin/hooks/api/useFetchInfrastructureUsers'
import { FaUser, FaUsers } from 'react-icons/fa'
import { useEffect, useState } from 'react'
import ResourceService from '../../services/Resource'
import { displayMessage } from '../../../../redux/alertAction'
import { useFetchResourceById } from '../../hooks/useFetchResourceById'

export const Access = ({ resource, resourceType }) => {
    const selectUser = createSelector(
        (state) => state.login.user,
        (user) => user
    )
    const [increment, setIncrement] = useState(0)
    const userInfra = useSelector((state) => state.platformReducers.userInfra) ?? null
    const user = useSelector(selectUser)
    const { teams, isLoading: eventsLoading } = useFetchTeamData(userInfra.id, user.token, false, 0)
    const { users, isLoading: usersLoading } = useFetchInfrastructureUsers(userInfra.id, user.token)
    const [isLoading, setIsLoading] = useState(true)
    const { resource: resourceAccess } = useFetchResourceById(
        resource.id,
        resourceType.id,
        user.token,
        setIsLoading,
        increment
    )
    const dispatch = useDispatch()
    const {
        control,
        register,
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { errors },
        getValues,
        setError,
    } = useForm({
        defaultValues: {
            resourceShareTeams: [],
            resourceShareUserInfras: [],
            resourceManagerTeams: [],
            resourceManagerUserInfras: [],
        },
    })

    useEffect(() => {
        if (!isLoading && resourceAccess) {
            setValue(
                'resourceShareTeams',
                resourceAccess.listResourceShareTeams.map((resourceShareTeam) => {
                    return { value: resourceShareTeam.id, label: resourceShareTeam.name }
                })
            )
            setValue(
                'resourceManagerTeams',
                resourceAccess.listResourceManagerTeams.map((resourceManagerTeam) => {
                    return { value: resourceManagerTeam.id, label: resourceManagerTeam.name }
                })
            )
            setValue(
                'resourceShareUserInfras',
                resourceAccess.listResourceShareUserInfras.map((user) => {
                    return { value: user.id, label: user.fullName }
                })
            )
            setValue(
                'resourceManagerUserInfras',
                resourceAccess.listResourceManagerUserInfras.map((user) => {
                    return { value: user.id, label: user.fullName }
                })
            )
        } else {
            reset({
                resourceShareTeams: [],
                resourceManagerTeams: [],
                resourceShareUserInfras: [],
                resourceManagerUserInfras: [],
            })
        }
    }, [resourceAccess, isLoading])

    const onSubmit = async (data) => {
        const body = {
            listResourceManagerTeams: data.resourceManagerTeams.map((team) => `api/teams/${team.value}`),
            listResourceShareTeams: data.resourceShareTeams.map((team) => `api/teams/${team.value}`),
            listResourceManagerUserInfras: data.resourceManagerUserInfras.map(
                (userInfra) => `api/user_infras/${userInfra.value}`
            ),
            listResourceShareUserInfras: data.resourceShareUserInfras.map(
                (userInfra) => `api/user_infras/${userInfra.value}`
            ),
        }

        const resourceService = new ResourceService()
        await resourceService.putResourceAccess(resource.id, body, user.token)
        reset()
        setIncrement((prev) => prev + 1)
        setIsLoading(true)
        dispatch(displayMessage('Success', 'Access have been updated', 'success'))
    }

    const validateShares = (value, formValues) => {
        if (formValues.resourceShareUserInfras.length > 0 || formValues.resourceShareTeams.length > 0) {
            return true
        }

        setError('resourceShareUserInfras', {
            type: 'manual',
            message: 'At least one of Team or User should be picked to share this resource',
        })
        return false
    }

    const validateManagers = (value, formValues) => {
        if (formValues.resourceManagerTeams.length > 0 || formValues.resourceManagerUserInfras.length > 0) {
            return true
        }
        setError('resourceManagerUserInfras', {
            type: 'manual',
            message: 'At least one of Team or User should be picked to manage this resource',
        })
        return false
    }

    return (
        <motion.article>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8">
                <div className="grid grid-cols-2 gap-4">
                    <div className="border border-slate-200 gap-4 rounded-lg flex flex-col">
                        <div className="flex flex-row gap-4 rounded-t-lg items-center justify-between bg-blue-400 py-4 px-8">
                            <h3 className="text-slate-800">Teams</h3>
                            <FaUsers size={30} />
                        </div>
                        {eventsLoading ? (
                            <FormLoader />
                        ) : (
                            <div className="flex flex-col gap-4 p-4">
                                <div>
                                    <label className="tracking-wide">Team(s) that manages the resource</label>
                                </div>
                                {errors.resourceManagerUserInfras && (
                                    <p className="text-red-600">{errors.resourceManagerUserInfras.message}</p>
                                )}
                                <Controller
                                    name="resourceManagerTeams"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...register('resourceManagerTeams', {
                                                validate: validateManagers,
                                                required: false,
                                            })}
                                            {...field}
                                            onChange={(t) => {
                                                setValue('resourceManagerTeams', t)
                                            }}
                                            isMulti
                                            options={teams.map((team) => {
                                                return {
                                                    value: team.id,
                                                    label: team.name,
                                                }
                                            })}
                                            isSearchable
                                        />
                                    )}
                                />
                                <div>
                                    <label className="tracking-wide">Team(s) with whom the resource is shared</label>
                                </div>
                                {errors.resourceShareUserInfras && (
                                    <p className="text-red-600">{errors.resourceShareUserInfras.message}</p>
                                )}
                                <Controller
                                    name="resourceShareTeams"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...register('resourceShareTeams', {
                                                validate: validateShares,
                                                required: false,
                                            })}
                                            {...field}
                                            onChange={(t) => {
                                                setValue('resourceShareTeams', t)
                                            }}
                                            isMulti
                                            options={teams.map((team) => {
                                                return {
                                                    value: team.id,
                                                    label: team.name,
                                                }
                                            })}
                                            isSearchable
                                        />
                                    )}
                                />
                            </div>
                        )}
                    </div>
                    <div className="border border-slate-200 rounded-lg flex flex-col gap-4">
                        <div className="text-lg flex flex-row gap-4 items-center p-4 bg-blue-500 rounded-t-lg justify-between py-4 px-8">
                            <h3 className="text-slate-800">Users</h3>
                            <FaUser size={20} />
                        </div>
                        {usersLoading ? (
                            <FormLoader />
                        ) : (
                            <div className="flex flex-col gap-4 p-4">
                                <div>
                                    <label className="tracking-wide">User(s) that manages the resource</label>
                                </div>
                                {errors.resourceManagerUserInfras && (
                                    <p className="text-red-600">{errors.resourceManagerUserInfras.message}</p>
                                )}
                                <Controller
                                    name="resourceManagerUserInfras"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...register('resourceManagerUserInfras', {
                                                validate: validateManagers,
                                                required: false,
                                            })}
                                            {...field}
                                            onChange={(t) => {
                                                setValue('resourceManagerUserInfras', t)
                                            }}
                                            isMulti
                                            options={users.map((user) => {
                                                return {
                                                    value: user.id,
                                                    label: user.user.fullName,
                                                }
                                            })}
                                            isSearchable
                                        />
                                    )}
                                />
                                <label className="tracking-wide">User(s) with whom the resource is shared</label>
                                {errors.resourceShareUserInfras && (
                                    <p className="text-red-600">{errors.resourceShareUserInfras.message}</p>
                                )}
                                <Controller
                                    name="resourceShareUserInfras"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...register('resourceShareUserInfras', {
                                                validate: validateShares,
                                                required: false,
                                            })}
                                            {...field}
                                            onChange={(t) => {
                                                setValue('resourceShareUserInfras', t)
                                            }}
                                            isMulti
                                            options={users.map((user) => {
                                                return {
                                                    value: user.id,
                                                    label: user.user.fullName,
                                                }
                                            })}
                                            isSearchable
                                        />
                                    )}
                                />
                            </div>
                        )}
                    </div>
                </div>
                {resource.canManage && (
                    <div className={`flex flex-row justify-center`}>
                        <input
                            type="submit"
                            value="Update"
                            className="py-3 px-4 bg-blue-500 text-white text-sm hover: rounded-full max-w-1/6 cursor-pointer"
                        />
                    </div>
                )}
            </form>
        </motion.article>
    )
}
