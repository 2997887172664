import * as React from "react";

// Design
import {Box, Button, Typography} from "@mui/material";
import {AiOutlineInfoCircle} from "react-icons/ai";


export const PpaHeader = ({ handleDrawerOpen,
                            networkName,
                            handleSave,
                            validMandatory,
                            valid,
                            ppaForm,
                            values }) => {


    return (
        <Box
            className="header"
            sx={{
                padding: {xs:'16px; 24px', lg:'32px 48px'},
                ...(ppaForm && {
                    padding: {xs:'16px; 24px', lg:'18px 48px'},
                }),
                background: 'white',
                border: '1px solid rgba(39, 39, 39, 0.12)',
                borderRadius: '10px',
                width: '100%'
            }}>

                { ppaForm ? (
                    <Box sx={{ width: '100%', display: 'flex', flexFlow: 'row wrap', alignItems: 'center', gap: '10px 0' }}>
                        <Typography variant="ppaHead" sx={{ color: '#3383BB', maxWidth: {xs: '66%'} }}>
                            { networkName + " Platform" }
                        </Typography>
                        <span className="mx-2">|</span>
                        <Button onClick={handleDrawerOpen}
                                sx={{ padding: '0',
                                      margin: '0',
                                      color: '#272727',
                                      fontSize: { xs: '14px', lg: '18px' },
                                      textDecoration: 'underline',
                                      '&:hover': { background: 'none' }
                                }}
                        >
                            <span className="mr-2">Guide</span><AiOutlineInfoCircle />
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!validMandatory || !valid}
                            className="button next"
                            sx={{ margin: { xs: '10px 0 0', lg: '0 0 0 auto' },
                                  width: { xs: '100%', lg: 'fit-content' },
                            }}
                            type="button"
                            onClick={() => {
                                values.saveform = true;
                                handleSave(values)
                                // console.log(values)
                            }}
                        >
                            Save
                        </Button>
                    </Box>
                ) : (
                    <Typography variant="ppaHead" sx={{ color: '#3383BB' }}>
                        Welcome to platform for submit project
                    </Typography>
                )}

        </Box>
    )
}
