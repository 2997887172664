import {List} from "../components/List"
import { motion } from "framer-motion";

export const News = () => {

    return (
        <motion.article
            id="news"
            className="
                       min-h-screen
                       lg:pt-10 pt-52
                       mb-10"
            initial={{ translateY: -15, opacity: 0 }}
            transition={{type:"spring", duration:.5, ease:"easeIn" }}
            animate={{ translateY: 0, opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <div className="container">
                <div className="
                            wrapper
                            flex
                            flex-col
                            gap-10
                            lg:gap-14
                            pb-10"
                >
                    <div className="content flex flex-wrap lg:gap-0 gap-4 justify-between pb-10">
                        <h2 className="lg:text-4xl text-3xl lg:font-bold font-medium text-left max-w-[500px] text-slate-900 lg:mb-4">
                            Stay <span className="text-blue-600/100">Informed</span> : Discover the Latest ISIA news
                        </h2>

                        <p className="max-w-[500px] text-slate-500">
                            Welcome to our news page dedicated to the latest and exciting features and updates on the
                            ISIA website. Stay tuned for updates and learn how they enhance your scientific research
                            experience
                        </p>
                    </div>

                    <List limit={8} hasPagination/>

                </div>
            </div>
        </motion.article>
    )
}