import * as React from 'react';
import {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Form} from "react-final-form";

// Design
import {Box, Button, Grid} from "@mui/material";
import {Main, drawerWidth} from "../utils/DrawerAssets";
import { motion } from "framer-motion";
import {BsArrowRight} from "react-icons/bs";
import {FiArrowLeft} from "react-icons/fi";

// Components
import {PpaHeader} from "../components/PpaHeader";
import {PpaChoiceStepper} from "../components/ppachoices/PpaChoiceStepper";
import {PpaNetwork} from "../components/ppachoices/PpaNetwork";
import {PpaType} from "../components/ppachoices/PpaType";
import DrawerContent from "../components/DrawerContent";
import {BackButton} from "../../../components/button/BackButton";

// Custom Hooks
import {useFetchPpaAllNetworks, useFetchSingleNetwork} from "../hooks/useFetchPpa";


export default function Ppa() {

    // Network Fetches
    const { networkData, isLoadingNetworks } = useFetchPpaAllNetworks();
    const [searchList, setSearchList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    // Get a Single Network
    const [networkId, setNetworkId] = useState('');
    const [networkCode, setNetworkCode] = useState('');

    // PPA Type (Network PPA)
    const [ppaType, setPpaType] = useState('');
    const [ppaTypeCode, setPpaTypeCode] = useState('');
    const [getStartedSwitch, setGetStartedSwitch] = useState(true);

    // Steppers Management
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        window.history.replaceState(null, "Choose your type of Network", `/ppa`)
    };


    // Drawer Management
    const [open, setOpen] = React.useState(false);

    const {
            drawerDatas,
            setDrawerDatas,
            logo,
            centralHubName,
            installationNumber,
            isSingleNetworkLoading } = useFetchSingleNetwork(networkId);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };


    // Change URL Management (Coming from URL containing networkCode)
    let { networkCodeUrl } = useParams();

    useEffect(() => {
        if(!networkCode && networkCodeUrl) {
            setIsLoading(true)
            setNetworkCode(networkCodeUrl)
            setActiveStep(1)
            setIsLoading(false)
        }
    }, [networkCodeUrl]);



    /*************************************/
    /********** Form management **********/
    /*************************************/

    const navigate = useNavigate();

    const handleSubmit = async () => {
        navigate(`/ppa/${networkCode}/${ppaTypeCode}`,  { state: { networkId: networkId, ppaType: ppaType, ppaTypeCode: ppaTypeCode }});
    }

    return (
        <Box sx={{ display: 'flex'}}>
            <Main open={open}>
                <motion.article
                    id="ppa"
                    className="min-h-screen flex pt-[35px] lg:pt-[70px]"
                    initial={{ opacity: 0}}
                    animate={{ opacity: 1}}
                    transition={{ duration:.5, delay: .3}}
                >
                    <div className="container">
                        <div className="wrapper flex flex-col lg:max-w-[996px]">
                            <Link to="/"
                                  className="flex items-center gap-1 top-10 lg:top-20 text-md text-slate-500 z-10 hover:text-slate-800 mb-6 lg:mb-10">
                                <span><FiArrowLeft/></span>
                                Home
                            </Link>
                            <PpaHeader/>
                            <PpaChoiceStepper activeStep={activeStep}
                                              networkId={networkId}
                                              handleNext={handleNext}
                                              handleBack={handleBack}

                            />
                            <Form
                                onSubmit={handleSubmit}
                                render={({handleSubmit}) => (
                                    <form className="ppa-form h-full flex flex-col justify-between"
                                          onSubmit={handleSubmit}>

                                        {(activeStep === 0) && (
                                            <PpaNetwork handleDrawerOpen={handleDrawerOpen}
                                                        networkData={networkData}
                                                        searchList={searchList}
                                                        setSearchList={setSearchList}
                                                        networkId={networkId}
                                                        setNetworkId={setNetworkId}
                                                        isLoading={isLoadingNetworks}

                                                        setNetworkCode={setNetworkCode}


                                                        setDrawerDatas={setDrawerDatas}
                                            />
                                        )}
                                        {(activeStep === 1) && (
                                            <PpaType handleDrawerOpen={handleDrawerOpen}
                                                     handleDrawerClose={handleDrawerClose}
                                                     searchList={searchList}
                                                     setSearchList={setSearchList}
                                                     networkCode={networkCode}
                                                     ppaType={ppaType}
                                                     setPpaType={setPpaType}
                                                     setPpaTypeCode={setPpaTypeCode}
                                                     setDrawerDatas={setDrawerDatas}
                                                     isLoading={isLoading}
                                                     setGetStartedSwitch={setGetStartedSwitch}

                                                     setNetworkId={setNetworkId}
                                            />
                                        )}

                                        {/* Stepper Navigation */}
                                        <Grid container
                                              justifyContent="space-between"
                                              sx={{
                                                  margin: '20px 0',
                                                  ...open && ({
                                                      position: {xs: 'fixed', lg: 'initial'},
                                                      bottom: {xs: '0'},
                                                      zIndex: {xs: '1300', lg: 'initial'},
                                                      width: {xs: '90%', lg: 'initial'},
                                                  })
                                              }}
                                        >
                                            <Grid item xs={6} lg={3}>
                                                {activeStep > 0 && (
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={handleBack}
                                                        className="button back"
                                                        sx={{margin: {xs: '0 auto 0 0'}}}
                                                    >
                                                        Back
                                                    </Button>
                                                )}
                                            </Grid>

                                            <Grid item xs={6} lg={3}>
                                                {activeStep === 0 && (
                                                    <Button className="button next"
                                                            color="primary"
                                                            variant="contained"
                                                            disabled={!networkId || isSingleNetworkLoading}
                                                            type="button"
                                                            onClick={handleNext}
                                                            sx={{margin: {xs: '0 0 0 auto'}}}
                                                    >
                                                        Continue
                                                        <BsArrowRight className={"icon"}/>
                                                    </Button>
                                                )}
                                                {activeStep === 1 && (
                                                    <Button className="button next"
                                                            variant="contained"
                                                            disabled={getStartedSwitch}
                                                            type="button"
                                                            onClick={handleSubmit}
                                                            sx={{margin: {xs: '0 0 0 auto'}}}
                                                    >
                                                        Get&nbsp;started
                                                        <BsArrowRight className={"icon"}/>
                                                    </Button>
                                                )}
                                            </Grid>
                                        </Grid>

                                    </form>
                                )}
                            />
                        </div>
                    </div>

                </motion.article>
            </Main>
            <DrawerContent open={open}
                           drawerWidth={drawerWidth}
                           handleDrawerOpen={handleDrawerOpen}
                           handleDrawerClose={handleDrawerClose}
                           activeStep={activeStep}
                           drawerDatas={drawerDatas}
                           logo={logo}
                           centralHubName={centralHubName}
                           installationNumber={installationNumber}
                           isLoading={isSingleNetworkLoading}
            />
        </Box>
    );
}