import {useEffect, useState} from 'react';
import {Typography} from "@mui/material";
import {FiArrowRight, FiGitlab, FiGitPullRequest} from "react-icons/fi";
import {FaRegCircleQuestion} from "react-icons/fa6";
import {DataDoc} from "../../utils/DataDoc";
import {useNavigate} from "react-router-dom";

const ListDocs = () => {

    const [activ, setActiv] = useState(null);
    const [data, setData] = useState([]);

    useEffect(() => {

        if (!activ) return;

        const newData = DataDoc.find(item => activ === item.id);
        setData(newData);

    }, [activ]);

    const url = process.env.REACT_APP_API_URL;

    let navigate = useNavigate();

    const handleClick = (link, id) => {
        if (link === "doc") return window.open(`https://cloud.agroserv.eu/s/gxRkf2kDkT36WgZ`, '_blank');

        if (link === "support") return window.open(`https://support.isia.cnrs.fr/isia/isia-web/-/issues`, '_blank');

        return navigate(`${link}`);
    }

    return (
        <div className="flex flex-col gap-10">
            <ul className="flex flex-wrap gap-4 items-center">
                {navDoc.map(item =>
                    <li
                        key={item.id}
                        className="group bg-white drop-shadow-md hover:drop-shadow-sm transition border border-solid border-slate-50 p-10 rounded-2xl lg:h-72 w-full xl:w-3/12 flex flex-wrap flex-col relative cursor-pointer bg-gradient-to-bl from-slate-50 to-white"
                        onClick={() => handleClick(item.link, item.id)}
                    >
                        <Typography component="h3" variant="h2" sx={{
                            marginBottom: "15px",
                            fontSize: "1.4rem !important",
                            fontWeight: "500"
                        }}>{item.title}</Typography>
                        <Typography component="p" variant="body"
                                    sx={{fontSize: ".945rem"}}>{item.text}</Typography>
                        <span
                            className="order-first mb-8 bg-blue-500 text-white w-[50px] h-[50px] flex items-center justify-center rounded-full text-2xl">{item.icon}</span>
                        <button
                            className="absolute bottom-4 right-4 bg-slate-50 border border-solid border-slate-100 text-sm rounded-full p-2 group-hover:bg-slate-200 transition">
                            <FiArrowRight/></button>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default ListDocs;

const navDoc = [
    {
        id: 1,
        title: "Guide",
        link: "doc",
        text: "Explore our tutorials to master ISIA and optimize your research.",
        icon: <FiGitPullRequest/>
    },
    {
        id: 2,
        title: "FaQ",
        link: "faq",
        text: "Find quick answers to common questions about using ISIA.",
        icon: <FaRegCircleQuestion/>
    },
    {
        id: 3,
        title: "Support",
        link: "support",
        text: "Need assistance? Our team is here to help. Contact us through GitLab for personalized support.",
        icon: <FiGitlab/>
    },
]
