import {
    Avatar,
    Button, Checkbox,
    FormControlLabel, Grid,
    Typography
} from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';
import {Link, Navigate, useLocation, useNavigate} from "react-router-dom";
import {makeValidate, TextField} from "mui-rff";
import {Form} from "react-final-form";
import { motion } from "framer-motion";
import React, {useEffect, useState} from "react";
import UserService from "../../../services/UserService";
import {login} from "../../../redux/user/authentificationAction";
import {displayMessage} from "../../../redux/alertAction";
import {useDispatch} from "react-redux";
import {object, string} from "yup";
import {toast} from "sonner";

const schema = object({
    username: string().required("Please enter your email or username"),
    password: string().required("The password is required"),
});

const validate = makeValidate(schema);
export const Login = () => {

    const [state, setState] = useState({
        user: {
            username: "",
            fullName: "",
        },
        isValidCredentials: true,
        isSubmitted: false,
        isLoading: false,
        forgotPassword: false,
    });


    const [usernameValue, setUsernameValue] = useState("");

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleBackToHome = () => {
        navigate("/");
    }

    const handleInputChange = (e) => {
        setUsernameValue(e.target.value);
    }

    const handleForgotPassword = () => {
        setState(previousState => ({
            ...previousState,
            forgotPassword: true,
        }));
    }
    const handleSubmit = async (e) => {

        setState(prevState => ({
            ...prevState,
            isSubmitted: true,
            isLoading: true,
        }));
        const userService = new UserService();

        try {
            await userService.login(e).then(data => {
                if (undefined === data) {
                    setState(prevState => ({
                        ...prevState,
                        isValidCredentials: false,
                        isLoading: false,
                    }));
                    return;
                }
                const userId = data["userId"] ?? "";
                const token = data["token"] ?? "";

                if ("" !== userId) {
                    userService.getUser(userId, token).then(resp => {
                        dispatch(login({
                            id: userId,
                            username: resp.username,
                            fullName: resp.fullName,
                            token: token,
                            role: resp.role,
                            isSuperAdministrator: resp.isSuperAdministrator
                        }));
                        dispatch(displayMessage("Log in success", "Welcome back " + resp.fullName, "success"));
                        setState(prevState => ({
                            ...prevState,
                            isValidCredentials: true,
                            isLoading: false,
                        }));


                        navigate("/");
                    });
                    return;
                }
                setState(prevState => ({
                    ...prevState,
                    isValidCredentials: false,
                    isLoading: false,
                }));

            });
        } catch (error) {
            setState(previousState => ({
                ...previousState,
                isValidCredentials: false,
            }));

            toast.warning("Incorrect email or password, please try again!")
        }

    }

    // Retrieve login after register
    const location = useLocation();
    const [successMessage, setSuccessMessage] = useState(false);

    const registerSuccess = () => {
        if(location.state) {
            setUsernameValue(location.state.email);
            setSuccessMessage(true);
        }
    }

    useEffect(() => {
        registerSuccess();
    }, []);

    useEffect(() => {
        if (successMessage) {
            toast.warning("You are now registered! You can log in with your email.")
        }
    }, [successMessage])
    return (
        <motion.article
            className="min-h-screen flex items-center justify-center"
            initial={{ opacity: 0}}
            animate={{ opacity: 1 }}
            transition={{ duration:.5, type:"ease", delay:.2}}
        >
            <div className="container">
                <div className="wrapper flex items-center justify-center">
                    <motion.div
                        className="mt-4 flex flex-col items-center max-w-[400px]"
                        initial={{translateY: -100, opacity: 0}}
                        transition={{type: "spring", duration: .5, ease: "easeIn", delay: .4}}
                        animate={{translateY: 0, opacity: 1}}
                        exit={{translateY: -100, opacity: 0}}
                    >
                        <Avatar sx={{m: 1, background: '#3380FF'}}>
                            <LockIcon/>
                        </Avatar>

                        <Typography component="h1" variant="h2" sx={{ marginBottom:"25px"}}>
                            Log In
                        </Typography>
                        <Form
                            onSubmit={handleSubmit}
                            validate={validate}
                            initialValues={{username: usernameValue, password: ""}}
                            // eslint-disable-next-line no-unused-vars
                            render={({handleSubmit}) => (
                                <form onSubmit={handleSubmit}>

                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        id="username"
                                        label="Email Adress or Username"
                                        name="username"
                                        type="text"
                                        autoComplete="off"
                                        autoFocus
                                        value={usernameValue}
                                        onChange={handleInputChange}
                                    />
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        id="password"
                                        label="Password"
                                        name="password"
                                        type="password"
                                        autoComplete="current-password"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox value="remember" color="primary"/>}
                                        label="Remember me"
                                    />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="dark"
                                        sx={{mt: 3, mb: 2, color: "#fafafa"}}
                                    >
                                        Log In
                                    </Button>

                                    <Grid container>
                                        <Grid item xs={5}>
                                            <Link
                                                to="#"
                                                className="cursor-pointer mt-10 underline hover:text-blue-500"
                                                onClick={handleForgotPassword}
                                            >
                                                Forgot password?
                                            </Link>
                                        </Grid>
                                    </Grid>
                                    {state.forgotPassword &&
                                        (<Navigate to="/security/forgotpwd"></Navigate>)
                                    }
                                </form>
                            )}
                        />

                    </motion.div>
                </div>
            </div>

        </motion.article>
    )
}