import {useEffect, useState} from 'react';
import NetworkService from "../../../services/NetworkService";
import {useSelector} from "react-redux";

const useFetchNetworkConfigById = (id, increment) => {
    const token = useSelector(state => state.login.user?.token) ?? null;

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchData()
    }, [id, increment]);

    async function fetchData() {
        setIsLoading(true);

        try {
            const networkService = new NetworkService()
            const res = await networkService.getNetworkConfigById(id, token);

            setData(res);
            setIsLoading(false);

        } catch (error) {
            setError(error);
            setIsLoading(false);
        }
    }

    return { data, isLoading, error}
};

export default useFetchNetworkConfigById;
