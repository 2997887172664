import React, {useContext} from 'react';

// Design
import {Box, TextField, Typography} from "@mui/material";
import {
    CustomizedAutoComplete,
} from "../../../components/reusableform/components/contents/items/FormCustomizedInputs";

// Context
import { NavigationSelectionsMenuContext } from "../navigation/NavigationSelectionsMenu";


const InstallationsDescriptionsSelectionContent = (props) => {

    const { listInfrastructureChild, selectedInfrastructure, setSelectedInfrastructure } = props;


    return (
        <>
            <div className="mb-8">
                <div className="flex items-center justify-center">
                    <Typography component="h4" variant="body2" color="textSecondary">
                        <span
                            className="flex items-center text-lg font-bold text-black">Installation descriptions</span>
                    </Typography>
                </div>
            </div>

            {listInfrastructureChild.length !== 0 && (
                <CustomizedAutoComplete
                    id="installation-selection"

                    // Data Management
                    options={listInfrastructureChild?.map((item) => item.id)}
                    getOptionLabel={(option) => {
                        return listInfrastructureChild?.find(item => item.id === Number(option))?.name
                    }}
                    value={selectedInfrastructure}
                    onChange={(event, newValue) => setSelectedInfrastructure(newValue)}

                    // Render Case
                    renderInput={(params) => <TextField {...params} />}
                    renderOption={(props, option) => {
                        const { key, ...rest } = props;

                        return (
                            <ul key={key} {...rest}>
                                <li>{listInfrastructureChild?.find(item => item.id === Number(option))?.name}</li>
                            </ul>
                        )
                    }}

                    // General Props - No clear cross icon
                    disableClearable
                />
            )}
        </>
    )
}

export const InstallationsDescriptionsSelectionView = (props) => {

    const { listInfrastructureChild } = props;

    // From Context (Mobile : When Content is in Drawer)
    const navigationSelectionsMenuContext = useContext(NavigationSelectionsMenuContext)
    const {
        largeScreenSelectionClasses: largeScreenSelectionClassesContext,
        isMobile: isMobileContext,
    } = navigationSelectionsMenuContext !== null && navigationSelectionsMenuContext;


    return (
        <Box component={"div"}
             id={'current-installation'}
             className={`${isMobileContext ? 'static' : 'fixed py-6'} bg-transparent w-full flex justify-center lg:justify-start lg:w-1/5 lg:ml-4 lg:mr-8 z-50`}
             sx={{
                 transition: 'all .2s ease-in-out',
                 display: listInfrastructureChild.length > 1 ? 'initial' : 'none',
             }}
        >
            <div
                className={`${!isMobileContext && largeScreenSelectionClassesContext} px-4 pt-6 pb-0 lg:pb-6 lg:min-w-[280px]`}>
                <InstallationsDescriptionsSelectionContent {...props} />
            </div>
        </Box>
    );

}

