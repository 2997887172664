import * as React from "react";

// Design
import {Box, Typography} from "@mui/material";
import {FaNetworkWired} from "react-icons/fa";


export const NavigationAdditionalTab = ({ description }) => {

    return (
        <div>
            <Box component={'div'}
                 className={`tab-text bg-stone-100 rounded-md p-3 w-full grid grid-cols-12 items-center gap-2 relative cursor-pointer `}
            >
                <FaNetworkWired className={"text-lg col-span-1"}/>
                <Typography component={"h3"}
                            className={`col-span-9 pl-2 whitespace-nowrap overflow-hidden text-ellipsis`}
                >
                    {description.networkName}
                </Typography>
            </Box>
        </div>
    )
}
