import AxiosService from "./AxiosService";


const urlDocumentsUnsigned = "api/documents/unsigned/infrastructure/"
class DocumentsService {

    getDocumentsUnsigned(id, token) {
        return AxiosService.getWithToken(urlDocumentsUnsigned + id, token, "jsonld");
    }

}

export default DocumentsService;