import Logo from "../assets/images/isia_logo.png"
import {Link} from "react-router-dom";
import {isLoginRoute} from "../utils/variables";

export const Brand = ({ auth }) => {
    return (
        <h1 className="lg:h-12 w-10 lg:w-12 h-10 relative">
            <figure className="lg:w-12 w-10 lg:h-12 h-10">
                <img src={ Logo } alt="logo isia" className="w-full h-full object-contain"/>
            </figure>
            <Link to={ isLoginRoute && auth ? "/" : "/"} className="absolute w-full h-full top-0 left-0"/>
        </h1>
    )
}