import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import { Box, IconButton, Snackbar } from '@mui/material';
import { CloseSharp } from '@mui/icons-material';
import AlertTitle from '@mui/material/AlertTitle';
import PropTypes from 'prop-types';
import { removeMessage } from '../../redux/alertAction';
import { useDispatch } from 'react-redux';

const AlertCommon = ({ type, title, message }) => {
    const [open, setOpen] = useState(true);
    const dispatch = useDispatch();

    const handleClose = () => {
        setOpen(false);
        dispatch(removeMessage());
    };

    return (
        <Snackbar
            open={open}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            onClose={handleClose}
        >
            <Alert
                severity={type}
                variant="filled"
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={handleClose}
                    >
                        <CloseSharp fontSize="inherit" />
                    </IconButton>
                }
            >
                <AlertTitle>{title}</AlertTitle>
                {message}
            </Alert>
        </Snackbar>
    );
};

AlertCommon.propTypes = {
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
};

export default AlertCommon;