import AxiosService from "../../../services/AxiosService";

const urlAvailable = "api/standard_available";

class StandardDescriptionService {

    getStandardDescriptionAvailable(token) {
        return AxiosService.getWithToken(urlAvailable, token, "jsonld");
    }
}

export default StandardDescriptionService;