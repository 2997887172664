import {CloseButton} from "../../features/ppa/components/form/CloseButton";
import {Outlet} from "react-router-dom";
import * as React from "react";

export const LayoutWithDrawer = () => {

    return (
        <main>
            <Outlet/>
        </main>
    )
}