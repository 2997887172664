import React from "react";

const MyNotes = () => {
    return (
        <></>


    );
};

export default MyNotes