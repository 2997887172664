import AxiosService from './AxiosService';

const urlUser = 'api/users';
const urlUserConnect = 'security/json_login';
const urlUserForgotPassword = 'api/users/forgotpwd';
const urlUserUpdatePassword = 'api/users/updatepwd';
const urlUserInvite = 'api/users/invite';
const urlUserInfra = 'api/user_infras';


class UserService {
    getUser(id, token) {
        return AxiosService.getWithToken(urlUser + "/" + id, token);
    }

    getListUsers(token, format) {
        return AxiosService.getWithToken(urlUser, token, format, false);
    }

    getListUserFromInfrastructure(infrastructureId, token, format) {
        return AxiosService.getWithToken(urlUserInfra + `/infra/${infrastructureId ?? 0}`, token, format);
    }

    getListUserInfraValidatedFromInfrastructure(infrastructureId, token, format) {
        return AxiosService.getWithToken(urlUserInfra + `/infra/${infrastructureId ?? 0}?status[]=validate&status[]=on_hold`, token, format);
    }

    login(data) {
        return AxiosService.postWithoutToken(urlUserConnect, data);
    }

    forgotPassword(data) {
        return AxiosService.postWithoutToken(urlUserForgotPassword, data);
    }

    updatePassword(data) {
        return AxiosService.postWithoutToken(urlUserUpdatePassword, data);
    }

    register(data) {
        return AxiosService.postWithoutToken(urlUser + "/register", data);
    }

    profilUpdate(userId, data, token) {
        return AxiosService.putWithToken(urlUser + "/" + userId, data, token);
    }
    inviteUsers(data, token) {
        data["token"] = token ?? "";
        return AxiosService.postWithToken(urlUserInvite, data, 'jsonld')
    }

    getUserInfrastructures(userId, token) {
        return this.getUser(userId, token).then(user => {
            return Promise.resolve(user.listInfrastructures);
        });
    }

    getUserInfra(userInfraId, token, format) {
        return AxiosService.getWithToken(urlUserInfra + "/" + userInfraId, token, format)
    }
    delete(userId, token) {
        return AxiosService.deleteWithToken(urlUserInfra + "/" + userId, token)
    }

    update(userInfraId, data, token) {
        return AxiosService.putWithToken(urlUserInfra + "/" + userInfraId, data, token, 'jsonld')
    }

    /**
     * Check if the token match with the userId (token expired)
     * @param userId
     * @param token
     * @returns {*}
     */
    isTokenValid(userId, token) {
        return AxiosService.getWithToken(urlUser + "/verify/token/" + userId, token, "json", true);
    }
}

export default UserService;