import {AnimatePresence} from "framer-motion";
import { useEffect, useState } from 'react'
import * as React from "react";
import { useParams, useSearchParams } from 'react-router-dom'
import {ResourceContent} from "../../resource/components/ResourceContent";
import {ResourceSidebar} from "../../resource/components/ResourceSidebar";
import {useFetchOneResourceType} from "../hooks/useFetchResourceType";
import {Loader} from "../../../components/loader/Loader";
import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import {Typography} from "@mui/material";
import {FiBox} from "react-icons/fi";



export const ResourceTypeDashboard = () => {
    const params= useParams();

    const selectUser = createSelector(
        state => state.login.user,
        user => user
    );
    const user = useSelector(selectUser);

    const resourceTypeId = params.id;
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingResource, setIsLoadingResource] = useState(true);

    const {resourceType} = useFetchOneResourceType(resourceTypeId, user?.token, 0, setIsLoading);
    const [resource, setResource] = useState(null);
    const [tab, setTab] = useState('overview');

    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        setSearchParams( sp => {
            sp.set('tab', tab);
            return sp;
        })
    }, [tab])

    useEffect(() => {
        const tabParam = searchParams.get('tab');
        if(tabParam){
            setTab(tabParam);
        }
    }, [])

    return (
        <article className="min-h-screen w-full flex gap-10">
            <AnimatePresence>
                {(isLoading || null === resourceType) ?
                    (<Loader content="Loading"/>)
                    :
                    (
                       <>
                           {resource === null ?
                                (
                                    <div className="w-1/4 flex flex-col items-stretch grow pt-10">
                                        <div className="flex items-center justify-center min-h-[600px] ">
                                            <Typography component="p" variant="h3" sx={{ display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                                                <span className=" text-2xl block text-blue-500"><FiBox /></span>
                                                Resource Type <b>{resourceType.name}</b><br/>You must select at least one resource
                                            </Typography>
                                        </div>
                                    </div>
                                )
                                :
                                (
                                <ResourceContent resourceType={resourceType} resource={resource} isLoadingResource={isLoadingResource} tab={tab} setTab={setTab} />
                                )
                            }
                           <ResourceSidebar resourceType={resourceType} setResource={setResource} setIsLoadingResource={setIsLoadingResource} />
                       </>
                    )
                }

            </AnimatePresence>
        </article>
    )
}