import {Box, Divider, Stack, Typography} from "@mui/material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
export const StackContact = () => {

    return (
        <Stack
            direction="row"
            spacing={2}
            divider={<Divider orientation="vertical" flexItem />}
            sx={{ justifyContent:"center"}}
        >
            { data.map(item =>
                <Box sx={{ background:"none", border:"none", display:"flex", gap:"10px", flexDirection:"column"}} className="w-3/12">
                    <Typography variant="h3" className="font-semibold ">{item.icon} {item.title}</Typography>
                    <Typography variant="body1">{item.text}</Typography>
                    <Typography variant="body1" className="text-gray-700"> {item.info}</Typography>
                </Box>
            )}
        </Stack>
    )
}

const data = [
    {
        id:1,
        title:"Visit us",
        text:"Need something cleared up? Here are our most frequently asked question.",
        info:"78 rue du chateaux, St Pierre les Nemours",
        icon:<LocationOnIcon fontSize="smalle"/>

    },
    {
        id:2,
        title:"Call us",
        text:"Need something cleared up? Here are our most frequently asked question.",
        info:"+33 1 21 11 67 71",
        icon:<PhoneAndroidIcon fontSize="smalle"/>

    },
    {
        id:3,
        title:"Contact us",
        text:"Need something cleared up? Here are our most frequently asked question.",
        info:"isia@bio.ens.psl.eu",
        icon:<AlternateEmailIcon fontSize="smalle"/>

    },
]