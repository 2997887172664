import AxiosService from "../../../services/AxiosService";
import {data} from "autoprefixer";

const urlSelect = "api/selects";

export class SelectService {
    getAllSelects (token) {
       return AxiosService.getWithToken(urlSelect, token);
    }
    getSelectById (id, token) {
        return AxiosService.getWithToken(`${urlSelect}/config/${id}`, token, "jsonld");
    }
    getSelectConfigByNetworkId(id, token) {
        return AxiosService.getWithToken(`${urlSelect}/config/network/${id}`, token, "jsonld");
    }

    postSelectConfigByNetworkId(data, token) {
        data["token"] = token;
        return AxiosService.postWithToken(`${urlSelect}`, data, "jsonld");
    }
    deleteSelectById(id, token) {
        data["token"] = token;
        return AxiosService.deleteWithToken(`${urlSelect}/${id}`, token, "jsonld");
    }
    putSelectConfigByNetworkId(id, data, token) {
        return AxiosService.putWithToken(`${urlSelect}/${id}`, data, token, "jsonld");
    }

}