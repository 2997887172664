import {FiLogOut} from "react-icons/fi";
import {useNavigate} from "react-router-dom";

export const LogoutBtn = ({ full }) => {

    let navigate = useNavigate();
    const handleClickLogout = () => {
        navigate('/logout');
    }

    return (
        <button
            title="Logout"
            onClick={handleClickLogout}
            className={`flex items-center justify-center gap-2 h-12 bg-pink-600 text-slate-50 text-center ${full ? "absolute bottom-0 left-0 w-full" : "relative rounded-full px-4" }`}>
            <span><FiLogOut /></span> Log Out
        </button>
    )
}