import { useFetchLinksByResourceLinkTo, useFetchLinksByResourceOne } from '../../hooks/useFetchLinksByResourceId'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { FormLoader } from '../../../servicetype/loaders/Loaders'
import { motion } from 'framer-motion'
import { DrawerTemporary } from '../../../../components/drawer/DrawerTemporary'
import { FiPlus } from 'react-icons/fi'
import { useEffect, useRef, useState } from 'react'
import LinkForm from '../form/LinkForm'
import Tree from 'react-d3-tree'
import { FaPen, FaTrash } from 'react-icons/fa'
import { TreeLink } from './TreeLink'

export const Link = ({ resource, resourceType }) => {
    const selectUser = createSelector(
        (state) => state.login.user,
        (user) => user
    )

    const user = useSelector(selectUser)
    const [drawerState, setDrawerState] = useState({
        isLoading: true,
        isOpen: false,
        method: 'CREATE',
        idSelected: null,
        increment: 0,
    })
    const { links: linkedTo, isLoading: isLoadingLinkedTo } = useFetchLinksByResourceOne(
        resource.id,
        user.token,
        drawerState.increment
    )
    const { links: linkedBy, isLoading: isLoadingLinkedBy } = useFetchLinksByResourceLinkTo(
        resource.id,
        user.token,
        drawerState.increment
    )
    const [links, setLinks] = useState([])
    useEffect(() => {
        if (!isLoadingLinkedTo & !isLoadingLinkedBy) {
            setLinks([...linkedTo, ...linkedBy])
        }
    }, [isLoadingLinkedBy, isLoadingLinkedTo, linkedBy, linkedTo])
    const [tree, setTree] = useState([])
    useEffect(() => {
        const flattenLinks = links.map((link) => {
            if (link.resourceToLink.name !== resource.name) {
                return {
                    name: link.resourceToLink.resourceType.name,
                    id: link.resourceToLink.resourceType.id,
                    children: [
                        {
                            name: link.resourceToLink.name,
                            id: link.id,
                        },
                    ],
                }
            } else {
                return {
                    name: link.resourceOne.resourceType.name,
                    id: link.resourceOne.resourceType.id,
                    children: [
                        {
                            name: link.resourceOne.name,
                            id: link.id,
                        },
                    ],
                }
            }
        })
        const tempTree = []

        for (const resourceType in flattenLinks) {
            if (tempTree.filter((item) => item.name === flattenLinks[resourceType].name).length > 0) {
                tempTree
                    .filter((item) => item.name === flattenLinks[resourceType].name)[0]
                    .children.push(flattenLinks[resourceType].children[0])
            } else {
                tempTree.push(flattenLinks[resourceType])
            }
        }
        setTree([{ name: resource.name, children: tempTree }])
    }, [links])
    const setIsOpenDrawer = (isOpen) => {
        setDrawerState((prev) => {
            return { ...prev, isOpen: isOpen }
        })
    }

    const handleDrawerClose = () => {
        setDrawerState((prev) => {
            return {
                ...prev,
                isOpen: false,
                isLoading: true,
                idSelected: null,
                increment: prev.increment + 1,
            }
        })
    }

    const handleClickOpenDrawer = (openMethod, id = null) => {
        setDrawerState((prev) => {
            return {
                ...prev,
                isOpen: true,
                isLoading: false,
                idSelected: id,
                method: openMethod ?? 'CREATE',
            }
        })
    }

    return (
        <div className="flex flex-col gap-4 ">
            {isLoadingLinkedTo & isLoadingLinkedBy ? (
                <FormLoader />
            ) : (
                <>
                    <TreeLink links={links} resource={resource} />
                    <motion.ul className={`grid grid-cols-4 gap-4`}>
                        {tree[0]?.children.map((resourceType) => (
                            <motion.li key={`link_item_${resourceType.name}`} className={`border rounded-lg`}>
                                <h3 className={`bg-blue-500 p-2 text-slate-200 rounded-t-lg`}>{resourceType.name}</h3>
                                {resourceType.children.map((link) => (
                                    <div key={`item_action_link${link.id}`} className={`grid grid-cols-2`}>
                                        <p className={`m-2`}>{link.name}</p>
                                        {resource.canManage && (
                                            <span className={`justify-self-end`}>
                                                <button
                                                    className={`border rounded-full p-2 m-2`}
                                                    onClick={() => handleClickOpenDrawer('UPDATE', link.id)}
                                                >
                                                    <FaPen className={`text-slate-500`} />
                                                </button>
                                                <button
                                                    className={`border rounded-full p-2 m-2`}
                                                    onClick={() => handleClickOpenDrawer('UPDATE', link.id)}
                                                >
                                                    <FaTrash className={`text-slate-500`} />
                                                </button>
                                            </span>
                                        )}
                                    </div>
                                ))}
                            </motion.li>
                        ))}
                    </motion.ul>
                </>
            )}
            {resource.canManage && (
                <motion.button
                    className="bg-white  rounded-lg text-slate-800 h-16 py-2 px-4 flex items-center justify-end gap-2"
                    whileHover={{ scale: 1.015 }}
                    whileTap={{ scale: 0.945 }}
                    onClick={() => {
                        handleClickOpenDrawer('CREATE')
                    }}
                >
                    <p>Create new Link</p>
                    <span className="border border-solid border-slate-200 p-2 rounded-full items-center flex flex-row">
                        <FiPlus size={30} />
                    </span>
                </motion.button>
            )}
            <DrawerTemporary
                isOpenDrawer={drawerState.isOpen}
                setIsOpenDrawer={setIsOpenDrawer}
                onDrawerClose={handleDrawerClose}
                method={drawerState.method}
                titleDrawer={'New Link'}
                titleDrawerUpdate={'Update Link'}
                content={<LinkForm resource={resource} resourceType={resourceType} />}
                idSelected={drawerState.idSelected}
            />
        </div>
    )
}
