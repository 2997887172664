import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import Stepper from "@mui/material/Stepper";


export const LabelStep = ( { activeStep, backStep, nextStep } ) => {

    const label = [
        {
            id:1,
            func: backStep,
            title:"Choose your Network",
        },
        {
            id:2,
            func: nextStep,
            title:"Choose your Catalog type"
        }
    ]



    return(
        <Stepper activeStep={activeStep} alternativeLabel sx={{ width: { xs:"100%", lg:"750px" } }}>
            { label.map( (lab) =>
                <Step key={lab.id}>
                    <StepLabel><Typography component="p" variant="body" sx={{ fontSize: { xs:".945rem"}}}>
                        <span onClick={lab.func} className="cursor-pointer">{lab.title}</span>
                    </Typography></StepLabel>
                </Step>
            )}
        </Stepper>
    )
}