import React, {useEffect, useState} from "react";

// Design
import {Box, RadioGroup, Typography} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { motion } from "framer-motion";

// Components
import SearchFilter from "../form/SearchFilter";
import {FormsTitle} from "../form/FormsTitle";
import {CardsLoader} from "../loaders/Loaders";
import CardItem from "../form/CardItem";
import {LoadMoreButton} from "../../../../components/button/LoadMoreButton";


// Services
import NetworkService from "../../../../services/NetworkService";


export const PpaNetwork = ({ networkData,
                             searchList,
                             setSearchList,
                             handleDrawerOpen,
                             networkId,
                             setNetworkId,
                             isLoading,
                             setNetworkCode,

                             setDrawerDatas,
                           }) => {

    // Search States
    const [searchQuery, setSearchQuery] = useState('');

    // More Items States
    const itemsPerRow = 8;
    const [nextItems, setNext] = useState(itemsPerRow);
    const [noMoreToLoad, setNoMoreToLoad] = useState(false);
    const [noResult, setNoResult] = useState(false);


    const handleGetSingleNetwork = async () => {
        try {
            const networkService = new NetworkService();
            const response = await networkService.getNetworkById(networkId);
            setDrawerDatas(response);
        } catch (error) {
            console.error(error);
        }
    }

    const handleChange = (event) => {
        setNetworkId(event.target.value);
        setNetworkCode(event.target.id);
    };

    useEffect(() => {
        if (networkId) {
            handleGetSingleNetwork().then();

            const timer = setTimeout(() => {
                handleDrawerOpen();
            }, 1000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [networkId]);

    return (
        <motion.article
            className="pt-[10px]"
            initial={{ opacity: 0}}
            animate={{ opacity: 1}}
            transition={{ duration:.5, delay: .3}}
        >
            <div className="flex flex-wrap items-center">

                <FormsTitle title={<>Choose your preferred <span>Network</span></>} />

                {/* Filter Research */}
                <SearchFilter networkData={networkData}
                              setSearchQuery={setSearchQuery}
                              setSearchList={setSearchList}
                              setNoMoreToLoad={setNoMoreToLoad}
                              setNoResult={setNoResult}
                              itemsPerRow={itemsPerRow}
                />

                <Box sx={{ flexGrow: 1, margin: '20px 0' }}>
                    { isLoading && ( <CardsLoader itemsPerRow={itemsPerRow} />)}
                    <FormControl className="w-full">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="ppanetwork"
                            value={networkId}
                            onChange={handleChange}
                        >
                            {/* Cards */}
                            <CardItem searchQuery={searchQuery}
                                      networkData={networkData}
                                      searchList={searchList}
                                      nextItems={nextItems}
                                      handleDrawerOpen={handleDrawerOpen}
                            />
                        </RadioGroup>
                    </FormControl>

                    {/* More Network */}
                    {(nextItems < networkData?.length && !noMoreToLoad ) && (
                        <LoadMoreButton title={"Load More"}
                                        nextItems={nextItems}
                                        itemsPerRow={itemsPerRow}
                                        setNext={setNext}
                                        roundedborder
                        />
                    )}

                    {/* No Results */}
                    {noResult && (
                        <Typography component={"div"}
                                    variant="subtitle1"
                                    sx={{
                                        marginBottom: "3rem",
                                        width: "100%",
                                        textAlign: 'center',
                                    }}
                        >
                            No results
                        </Typography>
                    )}
                </Box>

            </div>
        </motion.article>
    )
}
