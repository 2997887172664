import {toast} from "sonner";

export const VersionComp = () => {
    let bgColor;

    switch (process.env.REACT_APP_ENV) {
        case "dev":
            bgColor = "bg-orange-300";
            break;
        case "test":
            bgColor = "bg-blue-500";
            break
        default:
    }

    const handleClick = () => {
        if (process.env.REACT_APP_ENV) return toast.message(`This is the ${process.env.REACT_APP_ENV} version of our app.`)
    }

    return (
        <div className={`block ${bgColor} text-white z-40 bottom-0 left-0 fixed`}>
            <button
                className={`absolute -top-12 left-6 flex ${bgColor} w-10 h-10 flex items-center justify-center rounded-full`}
                onClick={ () => handleClick()}
            >
                  <span className={`relative inline-flex rounded-full text-slate-50 lg:text-sm text-xs`}>{process.env.REACT_APP_ENV}</span>
            </button>
        </div>
    )
}