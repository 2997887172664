import {useEffect, useState} from "react";
import StandardDescriptionService from "../service/StandardDescriptionService";
import {createSelector} from "reselect";
import {useSelector} from "react-redux";

const selectUser = createSelector(
    state => state.login.user,
    user => user
);

export const useFetchStandardDescriptionAvailable = () => {
    const [dataStandardDescription, setDataStandardDescription] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const user = useSelector(selectUser);
    const token = user.token;

    async function getStandardDescriptionAvailable() {
        try {
            const standardDesciption = new StandardDescriptionService();
            const res = await standardDesciption.getStandardDescriptionAvailable(token);
            setDataStandardDescription(res["hydra:member"])
        } catch (error) {
            console.error(error)
            setError(error)
        }
    }

    useEffect(() => {
        getStandardDescriptionAvailable()
    }, [token]);

    return { dataStandardDescription, isLoading, error }
}