export const FETCH_SELECT_CONFIG_BY_NETWORK = 'FETCH_SELECT_CONFIG_BY_NETWORK';

export const FETCH_SELECT_CONFIG_BY_ID = 'FETCH_SELECT_CONFIG_BY_ID';

export const ADD_SELECT = 'ADD_SELECT';

export const DELETE_SELECT = 'DELETE_SELECT';

export const UPDATE_SELECT = 'UPDATE_SELECT';

export const fetchSelectConfigByNetwork = (data) => ({
    type: FETCH_SELECT_CONFIG_BY_NETWORK,
    payload: data
})

export const fetchSelectConfigById = (selectId) => ({
    type: FETCH_SELECT_CONFIG_BY_ID,
    payload: selectId
})

// Action pour ajouter un nouveau réseau
export const addSelect = (newSelect) => ({
    type: ADD_SELECT,
    payload: newSelect
});

export const deleteSelect = (selectId) => ({
    type: DELETE_SELECT,
    payload: selectId
})

export const updateSelect = (updatedSelect) => ({
    type: UPDATE_SELECT,
    payload: updatedSelect
})