import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import {useFetchRoleData} from "../hooks/api/useFetchRoleData";
import {useFetchTeamData} from "../hooks/api/useFetchTeamData";
import {Loader} from "../../../components/loader/Loader";
import {FiUser} from "react-icons/fi";
import {useFetchInfrastructureUsers} from "../hooks/api/useFetchInfrastructureUsers";
import {useEffect, useState} from "react";
import {ListUsers} from "../components/ListUsers";
import {CompConfig} from "../../../components/CompConfig";
import * as React from "react";
import {UserForm} from "../components/form/UserForm";


export function UserManagementContainer({ roleCan }) {
    const [data, setData] = useState([]);
    const [totalItem, setTotalItem] = useState(0);
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [incrementList, setIncrementList] = useState(0);
    const [incrementEdit, setIncrementEdit] = useState(0);

    const selectUser = createSelector(
        state => state.login.user,
        user => user
    );

    const user = useSelector(selectUser);

    const selectCurrentInfrastructure = createSelector(
        state => state.currentInfrastructure,
        ci => ci
    );
    const infrastructureId = useSelector(selectCurrentInfrastructure);

    const {users , isLoading, error} =  useFetchInfrastructureUsers(infrastructureId, user.token, incrementList);
    const {roles } =  useFetchRoleData(infrastructureId, user.token, incrementList);
    const {teams } =  useFetchTeamData(infrastructureId, user.token, true);

    useEffect(() => {
        if(!isLoading) {
            setData(users);
            setTotalItem(users?.length ?? 0);
        }
    }, [isLoading, users]);

    const onDrawerCloseForm = () => {
        setIncrementList(incrementList + 1);
    }

    const onDrawerOpenForm = () => {
        setIncrementEdit(incrementEdit + 1);
    }

    const searchMethod = (data, searchTerm) => {
        return data?.filter(item => {
                return item.user?.fullName?.toLowerCase().includes(searchTerm.toLowerCase())
                    || item.user?.email?.toLowerCase().includes(searchTerm.toLowerCase())
                    || item.user?.username?.toLowerCase().includes(searchTerm.toLowerCase())
                    || item.role?.name?.toLowerCase().includes(searchTerm.toLowerCase())
                    || item.status?.toLowerCase().includes(searchTerm.toLowerCase())
            }
        );
    }

    return (
        undefined === roles ?
            (<Loader/>)
            :
            (<CompConfig
            title={"Manage users"}
            titleDrawer="Invite a new user"
            titleDrawerUpdate="Update an user"
            logoTitle={<FiUser />}
            data={data}
            isLoading={isLoading}
            error={error}
            totalItem={totalItem}
            btntitle="Invite user"
            content={<ListUsers />}
            typeForm="usersmanage"
            setIsOpenDrawer={setIsOpenDrawer}
            onDrawerClose={onDrawerCloseForm}
            onDrawerOpen={onDrawerOpenForm}
            isEdit={true}
            drawerFormCreate={<UserForm increment={incrementEdit} roles={roles} teams={teams} />}
            searchMethod={searchMethod}
            userIsSuperAdmin={user.isSuperAdministrator}
            roles={roles}
        />)
    )
}