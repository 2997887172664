import { UPDATE_INFRASTRUCTURE_FILTERS } from './infrastructureCatalogAction';
import { UPDATE_INFRASTRUCTURE_PAGE } from './infrastructureCatalogAction';

const initialState = {
    route: 'api/infrastructures',
    filters: '',
    page: 1
};

// This function builds a route which will then be requested.
export function infrastructureCatalogReducer (state = initialState, action) {
    switch (action.type) {
        case UPDATE_INFRASTRUCTURE_FILTERS:
            return {
                ...state,
                filters: action.payload
            }
        case UPDATE_INFRASTRUCTURE_PAGE:
            return {
                ...state,
                page: action.payload
            }
        default:
            return state;
    }
}