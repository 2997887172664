import {toast} from "sonner";
import {SelectOptionsService} from "../services/selectOptionsServices";
import {useDispatch, useSelector} from "react-redux";
import {deleteSelectOption} from "../../../redux/selectoption/selectOptionAction";

const useDeleteSelectOption = () => {

    const token = useSelector(state => state.login.user?.token);
    const dispatch = useDispatch();

    const handleDeleteSelectOption = (id) => {

        if (!id) return;

        try {
            const selectOptionService = new SelectOptionsService();
            const res = selectOptionService.deleteSelectOptionsById(id, token);

            if(!res) throw new Error("Unable to delete the option");

            dispatch(deleteSelectOption(id));

            return toast.success("successfully deleted!");

        } catch (error) {
            console.error(error);
            return toast.error("Something is wrong, please try late");
        }
    }


    return { handleDeleteSelectOption }
};

export default useDeleteSelectOption;
