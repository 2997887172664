import {motion} from "framer-motion";
import {LoadMore} from "../../../components/button/LoadMore";
import {useContext} from "react";
import {CompConfigContext} from "../../../components/CompConfig";

export const ResourceType = () => {
    // TODO A CONTINUER ICI. Mettre la recherche pour toutes les ressources
    const data = useContext(CompConfigContext);
    const isEdit = data.isEdit ?? false;

    const handleClickPath = (id) => {
        if (isEdit) {
            data.handleClickOpenDrawer("UPDATE", id)
        } else {
            data.handleClickPath(`/resourcetypes/${id}`)
        }
    }
    return (
        <div className="pb-10 min-h-[500px] flex items-start justify-center">
            <div className="container">
                <div className={`wrapper relative flex flex-col items-start gap-4`}>
                    { data.error ?
                        <div className="min-h-[200px] flex items-center justify-center w-full">
                            <p className="text-slate-500 text-center text-md font-bold">Ooops! we have a
                                problem {data.error.message}</p>
                        </div>
                        :
                        <>
                            {!data.myData || data.myData.length === 0 ?

                                <div className="min-h-[200px] flex items-center justify-center w-full">
                                    <p
                                        className="text-slate-500 text-center text-sm"
                                    >
                                        No results
                                    </p>
                                </div>
                                :
                                <>
                                    <motion.ul
                                        className={`grid w-full mt-20 ${data.gridMode ? 'grid-cols-4 gap-8' : 'grid-cols-1 gap-2'}`}
                                        initial={{opacity: 0, translateY: 100}}
                                        animate={{opacity: 1, translateY: 0}}
                                        exit={{opacity: 0, translateY: 100}}
                                        transition={{type: "spring", duration: .6, ease: "easeOut"}}
                                    >
                                        {data.myData.map(item =>
                                            <motion.li
                                                className={`cursor-pointer relative border border-slate-200 rounded-md overflow-hidden flex ${data.gridMode ? 'flex-col' : 'flex-row'}`}
                                                key={item.id}

                                                onClick={() => handleClickPath(item.id)}
                                            >
                                                <div className={`flex flex-col items-center justify-between bg-slate-100`}>

                                                    <div
                                                        className="h-24 flex items-center justify-center w-full"
                                                        style={{
                                                            backgroundColor: item.color ?? "#7689FA",
                                                        }}
                                                    >
                                                        <h3 className={`text-sm font-bold text-center w-full text-slate-50 flex items-center justify-center gap-2`}>{item.name} <span className="text-slate-200 text-sm font-medium">({item.nbListResources})</span></h3>
                                                    </div>
                                                </div>
                                            </motion.li>
                                        )}
                                    </motion.ul>

                                    <LoadMore
                                        dataLoad={4}
                                        itemsPerPage={data.itemsPerRow}
                                        setItemsPerPage={data.setItemsPerRow}
                                        totalItems={data.totalItem}
                                    />
                                </>
                            }

                        </>
                    }

                </div>
            </div>
        </div>
    )
}