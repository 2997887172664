import React, { memo } from 'react'
import {useLocation, useNavigate} from "react-router-dom";

// Components
import {PlatformListView} from "./PlatformListView";
import {FiSettings} from "react-icons/fi";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {changeInfrastructure} from "../../../redux/user/changeInfrastructureAction";
import {createSelector} from "reselect";

// Custom Hooks
import {useFetchPlatform} from "../hook/useFetchPlatform";
import {useInfrastructuresSelect} from "../../../hooks/useInfrastructuresSelect";
import {useFetchUserRole} from "../../../hooks/useFetchUserRole";
import {useMobile} from "../../../hooks/useMobile";


const selectCurrentInfrastructure = createSelector(
    state => state.currentInfrastructure,
    ci => ci
);

const selectUser = createSelector(
    state => state.login.user,
    user => user
);


export const PlatformList = memo(function PlatformList() {
    const currentInfrastructure = useSelector(selectCurrentInfrastructure);

    const user = useSelector(selectUser);
    const token = user?.token;

    const location = useLocation();

    const role = useFetchUserRole();

    const isMobile = useMobile();

    // const {data } = useFetchPlatform(token);
    useFetchPlatform(token);
    useInfrastructuresSelect(currentInfrastructure, token);
    const data = useSelector((state) => state.platformReducers.data);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleChangePlatform = async (e, platform) => {
        const getId = new Promise((resolve) => {

            let id = null !== platform ? platform.id : 0;

            if (null !== platform && null !== platform.parent && false === platform.parent.isGroupOnly) {
                id = platform.parent.id;
            }
            resolve(id);
        });

        await getId.then(id => {
            dispatch(changeInfrastructure(id));
            if (id > 0) {
                navigate("/dashboard");
            } else {
                navigate("/")
            }
        });
    }


    /***************** List Management *********************/
    let filterWithParentGroupOnlyFalse = data?.filter( option => false === option.isGroupOnly);

    /**** Order/Group by parent name ****/
    let groupedByParentArrays = filterWithParentGroupOnlyFalse.reduce((accumulator, obj) => {
        let parentName = obj.parent?.name;
        if (!accumulator[parentName]) {
            accumulator[parentName] = [];
        }
        accumulator[parentName].push(obj);
        return accumulator;
    }, {});

    // console.dir(groupedByParentArrays)

    let arrayOfGroupedByParentArrays = Object.values(groupedByParentArrays);

    /* Insert parent at the top (unshift) of the Group */
    arrayOfGroupedByParentArrays.forEach((element) => {
        const last = element.findLast((found) => found).parent;
        element.unshift(last);
    });

    const completeListOrderedByParentName = arrayOfGroupedByParentArrays.flatMap((item) => item);


    /**** Dissociate No parent (parent: null && isGroupOnly: false) from others ****/
    const arrayWithoutParentNullAndGroupOnlyFalse = completeListOrderedByParentName.filter((item) => !(!item?.isGroupOnly && !item?.parent));

    const arrayFilledWithParentNullAndGroupOnlyFalse = completeListOrderedByParentName.filter((item) => (!item?.isGroupOnly && !item?.parent) && item);
    const noParentGroupHeader = {
        name: completeListOrderedByParentName.length !== 0 ? 'No parent installations' : 'No options',
        logo: '',
        parent: null,
        isGroupOnly: true,
        notANetwork: true,
        noOptions: !(completeListOrderedByParentName.length !== 0)
    }
    const noParentOptionsList = arrayFilledWithParentNullAndGroupOnlyFalse.length !== 0 ? [noParentGroupHeader, ...arrayFilledWithParentNullAndGroupOnlyFalse] : [];

    /**** Place children right after parents ****/
    /* Remove duplicated installations */
    const removeSameParentOccurences = [];
    const detectedIds = new Set();

    arrayWithoutParentNullAndGroupOnlyFalse.forEach((item) => {
        if (!detectedIds.has(item?.id)) {
            removeSameParentOccurences.push(item);
            detectedIds.add(item?.id);
        }
    });

    /* Place children */
    const placeChildrenRightAfterParents = (sourceArray, targetArray) => {
        sourceArray.forEach((element) => {

            const indexFound = targetArray.findIndex((found) => found.id === element?.parent?.id);

            if (indexFound !== -1) {

                let nextIndex = indexFound + 1;
                while (targetArray[nextIndex] && targetArray[nextIndex]?.parent?.id === element?.parent?.id) {
                    nextIndex++
                }
                /* Insert at the right place */
                targetArray.splice(nextIndex, 0, element);
            }
        })
    }

    /* Root parents array (Group) */
    const rootParents = removeSameParentOccurences.filter((item) => (item?.isGroupOnly && !item?.parent) && item);
    rootParents.sort((a, b) => -b?.name.localeCompare(a.name));

    /* First Level Installation array */
    const firstLevelInstallations = removeSameParentOccurences.filter((item) => (!item?.isGroupOnly && item?.parent && item?.parent?.isGroupOnly) && item);

    const firstLevelOptionsList = [...rootParents];
    placeChildrenRightAfterParents(firstLevelInstallations, firstLevelOptionsList);

    /* Second Level Installation array */
    const secondLevelInstallations = removeSameParentOccurences.filter((item) => (!item?.isGroupOnly && item?.parent && !item?.parent?.isGroupOnly) && item);

    const secondLevelOptionsList = [...firstLevelOptionsList];
    placeChildrenRightAfterParents(secondLevelInstallations, secondLevelOptionsList);

    /* Insert (parent: null && isGroupOnly: false) at the end */
    const finalOptionsList = [...secondLevelOptionsList, ...noParentOptionsList];
    // console.dir(finalOptionsList)


    const handleEditInstalltion = () => {
        return navigate("/installation/description");
    }

    const matchInfraDescriptionRoute = location.pathname.match(/^\/installation\/description$/);


    return (
        <div className={`${isMobile ? "bg-slate-50 relative w-full order-first p-4 border-b border-slate-200" : "absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"} z-20`}>
            <div className="lg:min-w-96 border border-solid border-slate-200 h-14 rounded-full drop-shadow-sm flex gap-0 overflow-hidden">

                <div className="w-1/6 grow h-full flex items-center justify-center">
                    <PlatformListView finalOptionsList={finalOptionsList}
                                      dataValue={data.find((option) => option.id === currentInfrastructure) ?? null}
                                      handleChangePlatform={handleChangePlatform}
                    />
                </div>

                {(currentInfrastructure !== null && currentInfrastructure > 0 && role && (role.isRoleAdmin || user.isSuperAdministrator)) && (

                    <div className={`relative order-first border-none w-14 flex items-center justify-center ${ matchInfraDescriptionRoute ? "bg-orange-500" : "bg-slate-200" }`}>
                        <button
                            type="button"
                            onClick={handleEditInstalltion}
                            className={`${ matchInfraDescriptionRoute ? "text-slate-50" : "text-slate-700 hover:text-orange-500" } text-xl cursor-pointer transition ease-linear`}
                            title={"Click here to edit your installation"}
                        >
                            <FiSettings />
                        </button>
                    </div>

                )}
            </div>

        </div>
    );
});