import AxiosService from "../../../services/AxiosService";


const urlInputs = "api/inputs";

class InputsService {
    GetInputs(token){
        return AxiosService.getWithToken(urlInputs, token, "jsonld");
    }
    GetInputById(id, token){
        return AxiosService.getWithToken(urlInputs + "/" + id, token, "jsonld");
    }
    AddInput(data, token){
        data["token"] = token;
        return AxiosService.postWithToken(urlInputs, data, token, "jsonld");
    }
    EditInput(data, id, token){
        return AxiosService.putWithToken(urlInputs + "/" + id, data, token, "jsonld");
    }
    DeleteInput(id, token){
        return AxiosService.deleteWithToken(urlInputs + "/" + id, token, "jsonld");
    }
}

export default InputsService;