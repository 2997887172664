import React, {forwardRef, useEffect, useRef, useState} from 'react';
import {Avatar, Typography} from "@mui/material";
import {AnimatePresence, motion} from "framer-motion";
import {getInitials} from "../../utils/getInitials";
import {Link, useNavigate} from "react-router-dom";
import {FiHelpCircle, FiLogOut, FiSettings, FiUser, FiX} from "react-icons/fi";
import {useFetchUser} from "../../hooks/useFetchUser";
import {useMobile} from "../../hooks/useMobile";
import {Loader} from "../loader/Loader";
import {useSelector} from "react-redux";
import {useFetchOneUserInfra} from "../../features/user/hook/useFetchUserInfra";
import {createSelector} from "reselect";
import {LogoutBtn} from "../../features/auth/components/button/LogoutBtn";
import NavbarMiddle from "../../features/auth/components/header/NavbarMiddle";
import UserListTeamInfra from "../../features/user/components/UserListTeamInfra";
import userListTeamInfra from "../../features/user/components/UserListTeamInfra";
import {ProfilBtn} from "../../features/auth/components/button/ProfilBtn";



export const UserCardInfos = ({isOpen, handle, isLoading, userListTeamInfra, userRoleInInfra, ref, setOpenBox, openBox}) => {
    const isMobile = useMobile();

    const handleClose = () => {
        return handle()
    }

    return (
        <AnimatePresence>
            {isOpen &&
                <motion.div
                    className={`bg-white lg:w-[300px] w-full min-h-[450px] rounded-sm absolute top-0 lg:top-[80px] right-0 flex flex-col items-center border border-slate-200 overflow-hidden z-20`}
                    initial={{opacity: 0, y: 10}}
                    animate={{opacity: 1, y: 0}}
                    exit={{opacity: 0, y: 10}}
                    transition={{type: "spring", stiffness: 300, damping: 24}}
                    id="user-card-infos"
                    // ref={ref}
                >
                    {isMobile && <button onClick={handleClose}
                                         className="absolute right-8 top-10 z-20 rounded-full border border-solid border-slate-300 p-1">
                        <span className="text-sm text-slate-500"><FiX/></span>
                    </button>
                    }

                    {!isLoading ? (
                        <>
                            <ProfilBtn
                                openBox={openBox}
                                setOpenBox={setOpenBox}
                                userRoleInInfra={userRoleInInfra}
                            />

                            {/* Middle menu */}
                            <div className="h-2/4 w-full mb-10 P-4">
                                <NavbarMiddle />
                                <UserListTeamInfra userListTeamInfra={userListTeamInfra} />
                            </div>

                            <LogoutBtn full />

                        </>
                    ) : (<Loader content="Loading"/>)}
                </motion.div>
            }
        </AnimatePresence>
    )
}

