import React from "react";
import {TextField} from "@mui/material";
import {ListTypeFormsTooltip} from "./ListTypeFormsTooltip";



export const ListTypeFormsSelectsReadOnly = ({ selectOptionsInCurrentTab, input }) => {
    return (
        <>
            {selectOptionsInCurrentTab.length !== 0 && (
                <>
                    {/* SELECT : Just after a SAVE => values are sent as STRINGS */}
                    {/* One string value */}
                    {!Array.isArray(input.value) && selectOptionsInCurrentTab.find(found => found.id === Number(input.value)) && (
                        <ListTypeFormsTooltip title={
                            <div>
                                { selectOptionsInCurrentTab.find(found => found.id === Number(input.value)).name }
                            </div>
                        }>
                            <TextField
                                name={`display_${input.input}`}
                                value={`${
                                    ' ' + selectOptionsInCurrentTab.find(found => found.id === Number(input.value)).name
                                }`}
                                className={'read-only-fields select-value'}
                                disabled
                            />
                        </ListTypeFormsTooltip>
                    )}

                    {/* Multiple string values */}
                    {((isNaN(input.value)) && (input.value.indexOf(',') > -1)) && (
                        <ListTypeFormsTooltip title={
                            <div>
                                <ul>
                                    { input.value.split(',').map((item, index) => (
                                        <li key={index}>{ selectOptionsInCurrentTab.find(found => found.id === Number(item)).name }</li>
                                    )) }
                                </ul>
                            </div>
                        }>
                            <TextField
                                name={`display_${input.input}`}
                                value={`${
                                    input.value.split(',').map((item) => (
                                        ' ' + selectOptionsInCurrentTab.find(found => found.id === Number(item)).name
                                    ))
                                }`}
                                className={'read-only-fields select-value'}
                                disabled
                            />
                        </ListTypeFormsTooltip>
                    )}

                    {/* SELECT : During edition => values are stored in ARRAY */}
                    {(Array.isArray(input.value) && (input.value).length !== 0) && (

                        <ListTypeFormsTooltip title={
                            <div>
                                <ul className={'list-disc px-4'}>
                                    {input.value.map((item, index) => (
                                      <li key={index} className={'py-1'}>{ selectOptionsInCurrentTab.find(found => found.id === Number(item))?.name }</li>
                                    ))}
                                </ul>
                            </div>
                        }>
                            <TextField
                                name={`display_${input.input}`}
                                value={`${
                                    input.value.map((item) => (
                                        ' ' + selectOptionsInCurrentTab.find(found => found.id === Number(item))?.name
                                    ))
                                }`}
                                className={'read-only-fields select-value'}
                                disabled
                            />
                        </ListTypeFormsTooltip>
                    )}
                </>
            )}
        </>
    )
}
