import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export const MyEditor = ({ initialvalue, setEditorData, setwidth }) => {

    const editorConfiguration = {
        toolbar: [
            'heading',
            'bold',
            'italic',
            'blockQuote',
            'outdent',
            'indent'
        ]
    };

    const handleChange = (e, editor) => {
        const data = editor.getData();
        setEditorData(data);
    }

    return (
        <div className={`${ setwidth ? "w-4/5" : "" }`}>
            <CKEditor
                config={editorConfiguration}
                editor={ClassicEditor}
                data={initialvalue}

                onChange={ handleChange }
            />
        </div>
    );
};