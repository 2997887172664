import { useEffect, useState } from 'react'
import ResourceLinkService from '../services/ResourceLink'

export const useFetchLinkById = (id, token) => {
    const [link, setLink] = useState(null)
    const [error, setError] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        async function fetchLinksByResourceId() {
            try {
                if (null !== id) {
                    const linkService = new ResourceLinkService()
                    const res = await linkService.getResourceLink(id, token)
                    setLink(res)
                    setIsLoading(false)
                } else {
                    setLink(null)
                    setIsLoading(false)
                }
            } catch (err) {
                setError(err)
            }
        }
        fetchLinksByResourceId().then()
    }, [id])

    return { link, error, isLoading }
}
