import {useEffect} from "react";

export const useCloseOnOutsideClick = (refElement, setOpenElement) => {

    // console.log(refElement);

    useEffect(() => {
        const handleCloseOnClickOutside = (event) => {
            // Vérification que refElement et refElement.current existent
            if (refElement?.current && !refElement.current.contains(event.target)) {
                setOpenElement(false);
            }
        };

        // Ajout de l'événement au document
        document.addEventListener("mousedown", handleCloseOnClickOutside);

        // Nettoyage de l'événement quand le composant est démonté
        return () => {
            document.removeEventListener("mousedown", handleCloseOnClickOutside);
        };
    }, [refElement]); // refElement doit être une dépendance valide
}