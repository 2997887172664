import {useEffect, useState} from "react";
import {Link, Navigate, useNavigate, useParams} from "react-router-dom";
import {useFetchOneServices} from "../hooks/useFetchOneService";
import {AnimatePresence, motion} from "framer-motion";
import {
    FiGlobe,
    FiPhone,
    FiAtSign,
    FiChevronDown, FiChevronUp, FiArrowUpRight, FiInfo, FiLayers, FiCpu
} from "react-icons/fi";

import {Tables} from "../../../components/tables/Tables";
import {CustomBreadcrumbs} from "../../../components/breadcrumb/CustomBreadcrumbs";
import {Loader} from "../../../components/loader/Loader";
import {ItemDesc} from "../../catalog/components/ItemDesc";
import {truncateTextLetter} from "../../../utils/truncateText";

export const Service = () => {
    const { idcatalog, id } = useParams();

    const [activeLeftMenu, setActiveLeftMenu] = useState(1);

    const [isVisible, setIsVisible ] = useState(true);
    const [sliceNb, setSliceNb] = useState(12)
    const [openKeyword, setOpenKeyword] = useState(false);

    const { data, isLoading, error } = useFetchOneServices(idcatalog, id);

    let navigate = useNavigate()
    // Resource
    const res = data.listResources;

    useEffect(() => {
        // Check if listResources is not undefined and not null
        if (res && typeof res === 'object') {
            // Check if all elements in the listResources array are empty
            const allEmpty = Object.values(res).every(item => !item || Object.keys(item)?.length === 0);

            // If all elements are empty, set isVisible to false
            if (allEmpty) {
                setIsVisible(false);
            }
        } else {
            // If listResources is undefined or null, set isVisible to false
            setIsVisible(true);
        }
    }, [res]);

    const mylinkData = [

        {
            id:3,
            title:"Service catalog",
            link: "#"
        },
        {
            id:4,
            title:data?.name,
            link:"#"
        }
    ]



    const handleClickMenu = (menuId) => {
        if (menuId !== 3) return setActiveLeftMenu(menuId);
        window.confirm("Are you sur to submit the preposal project?");
        // navigate(`/ppa/${data.id}`);
    }

    if (error){
        return <Navigate to='/notfound'/>
    }

    if (isLoading) {
        return <Loader content="Loading descriptive page" />
    }

    const keywordFilter = data?.keywords?.slice(0, sliceNb);

    const handleKeyword = () => {
        if (!openKeyword) {

            const lengthKey = data?.keywords.length;
            setSliceNb(lengthKey)

            setOpenKeyword(true)
        } else {
            setSliceNb(12);
            setOpenKeyword(false)
        }
    }

    return (
        <AnimatePresence>
            { !isLoading &&
                <motion.article
                    id="section"
                    className={`min-h-screen w-full flex lg:flex-row flex-col items-stretch`}

                    initial={{opacity: 0}}
                    transition={{type: "spring", duration: .5, ease: "easeIn"}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                >
                    {/*Left Box*/}
                    <div className="lg:w-2/5 w-full grow py-10 flex items-stretch justify-center gap-4 bg-slate-50 border-r border-slate-300">
                        <div className={`lg:w-9/12 w-11/12 flex flex-col gap-2`}>
                            {/* Title */}
                            <h2 className={`lg:text-3xl text-xl font-medium fontfamilly`}>{data.name}</h2>
                            {/* Tagline */}
                            {!data.tagLine ?
                                ""
                                :
                                <p className={`text-sm text-slate-500`}>{data.tagLine}</p>
                            }
                            {/* BreadCrumb */}
                            <div className="order-first"><CustomBreadcrumbs linkData={mylinkData}/></div>

                            {/* Image preview */}
                            <motion.div
                                className=" w-full lg:h-[500px] overflow-hidden my-4"
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                                exit={{opacity: 0}}

                                transition={{type: "spring", duration: .5, ease: "easeIn"}}
                            >
                                <figure className="lg:h-[500px]">
                                    <img
                                        src={data.mainImage || data.mainImage === "" ? `${process.env.REACT_APP_API_URL}${data.mainImage}` : `/uploads/update.jpg`}
                                        alt={`${data.name}`}
                                        className="w-full h-full rounded-lg"/>
                                </figure>
                            </motion.div>

                            {/* description */}
                            {data.description &&
                                <div className={`flex flex-col gap-4 mb-4`}>
                                    <h3 className={`text-center underline`}>General description</h3>
                                    <div className="text-slate-700" dangerouslySetInnerHTML={{__html: data.description}}/>
                                </div>
                            }

                            {/* Specifique Infos */}
                            {data.functionalities &&
                                <div className="flex flex-col gap-4 mb-4">
                                    <h3 className={`text-center underline`}>Specific informations</h3>
                                    <div
                                        className={`p-4 bg-slate-50 border border-solid border-slate-200 rounded-xl text-slate-700`}
                                        dangerouslySetInnerHTML={{__html: data.functionalities}}
                                    />

                                </div>
                            }

                            {/* Resource */}
                            { isVisible &&
                                <>
                                    <h3 className={`text-center underline`}>Our resource</h3>
                                    <div className={`flex flex-col gap-4 overflow-y-hidden overflow-x-auto relative`}>
                                        <Tables tab={data.listResources}/>
                                    </div>
                                </>

                            }
                        </div>


                    </div>
                    {/* Right Box */}
                    <div className="lg:w-2/5 w-full lg:p-4 flex gap-4 rounded-2xl relative">
                        <div className="lg:fixed right-0 top-0 lg:w-2/5 w-full flex lg:flex-row flex-col gap-0 h-screen">
                            <div className={`bg-white lg:w-1/12 w-full px-8 py-10 grow relative min-h-screen overflow-y-auto`}>
                                { activeLeftMenu === 1 &&
                                    <div className={`lg-w-9/12 w-12/11 flex flex-col items-start gap-8`}>
                                        {data.logo &&
                                            <div className="h-14">
                                                <figure className="h-14">
                                                    <img
                                                        src={`${process.env.REACT_APP_API_URL}${data.logo}`}
                                                        alt=""
                                                        className="w-full h-full object-contain"/>
                                                </figure>
                                            </div>
                                        }

                                        {/* General Infos */}
                                        <div className="mb-4 px-4 py-2 w-full">
                                            <ul className="flex flex-col gap-2 divide-y divide-slate-300">
                                                {data.provider &&
                                                    <li className={`py-2 flex flex-col gap-2`}>
                                                        <h3 className={`lg:text-xl text-lg font-medium fontfamilly`}>Provider</h3>
                                                        <p className={`text-sm text-slate-700`}>{data.provider}</p>
                                                    </li>
                                                }
                                                {data.organisation &&

                                                    <li className={`py-2 flex flex-col gap-2`}>
                                                        <h3 className={`lg:text-xl text-lg font-medium fontfamilly`}>Organisation</h3>
                                                        <p className={`text-sm text-slate-700`}>{data.organisation}</p>
                                                    </li>
                                                }
                                                {data.location?.city || data.location?.country ?
                                                    <li className={`py-2 flex flex-col gap-2`}>
                                                        <div className="flex flex-wrap items-start gap-2 mt-4">
                                                            {data.location?.city &&
                                                                <p className={`text-sm text-slate-700`}>
                                                                    <span
                                                                        className="text-slate-500"><FiGlobe/></span> {data.location.city}
                                                                </p>
                                                            }
                                                            {data.location?.country &&
                                                                <p className={`text-sm text-slate-700`}>
                                                                    <span
                                                                        className="text-slate-500"><FiGlobe/></span> {data.location.country}
                                                                </p>
                                                            }
                                                        </div>
                                                    </li>
                                                    :
                                                    ""
                                                }

                                                {data.infraId &&
                                                    <li>
                                                        <h3 className={`lg:text-xl text-lg font-medium fontfamilly`}>Installation Catalog</h3>
                                                        <Link to={`/catalog/${id}/installations/${data.infraId}`}
                                                              target="_blank"
                                                              className="flex items-center gap-2 text-sm text-slate-700 underline"
                                                              title={`See the installation of ${data.name}`}
                                                        >
                                                            {data.name}
                                                            {/*<span className="text-slate-500 block"><FiArrowUpRight/></span>*/}
                                                        </Link>
                                                    </li>

                                                }
                                            </ul>
                                        </div>

                                        {/* ALL keywords */}
                                        {!data.keywords || data.keywords?.length === 0 ?
                                            ""
                                            :
                                            <div
                                                className={`flex items-center justify-center w-full min-h-20 bg-none border-dashed border border-slate-300 rounded-xl relative`}>
                                                <ul className="flex flex-wrap gap-2 p-4 w-full">
                                                    {keywordFilter.map((item, index) =>
                                                        <li key={index}
                                                            className="flex items-center justify-center p-3 h-[20px] bg-slate-100 rounded-2xl text-xs text-gray-500">{item}</li>
                                                    )}
                                                </ul>
                                                {data?.keywords.length > 12 &&
                                                    <button onClick={handleKeyword}
                                                            className="border border-solid border-blue-300 bg-blue-50 text-blue-500 text-lg font-bold flex items-center justify-center rounded-full absolute -bottom-2">
                                                        {openKeyword ? <FiChevronUp/> : <FiChevronDown/>}
                                                    </button>
                                                }
                                            </div>
                                        }

                                        <hr/>

                                        {/* Info */}
                                        {!data.contact && !data.contact?.length === 0 ?
                                            ""
                                            :
                                            <>
                                                {data.contact?.map((c, index) =>
                                                    <div key={index}
                                                         className={` flex flex-col items-center justify-center w-full min-h-20`}>
                                                        <div className="w-full flex flex-col items-start justify-center gap-4 border-radius-xl h-full">

                                                            <div className="flex flex-col gap-2 items-start w-full">
                                                                <div
                                                                    className="w-full mb-4 border-b border-slate-500 px-4 py-2">
                                                                    {c.firstname || c.lastname ?
                                                                        <p className={`lg:text-xl text-lg font-medium fontfamilly`}>{c.firstname} {c.lastname}</p>
                                                                        :
                                                                        ""
                                                                    }
                                                                    {c.position &&
                                                                        <p className={`text-sm text-slate-700`}>{c.position}</p>}
                                                                </div>

                                                                <div className="w-full">
                                                                    <ul className={`flex flex-col gap-0 divide-y divide-slate-200`}>
                                                                        {c.email &&

                                                                            <li className={`py-2 flex items-center justify-between`}>
                                                                                <p className={`text-sm flex gap-2 items-center  `}>
                                                                                    <span className="font-bold text-xs text-slate-500"><FiAtSign/></span>
                                                                                    Send an email
                                                                                </p>
                                                                                <Link
                                                                                    to={`mailto:${c.email}`}
                                                                                    target="_blank"
                                                                                    className="text-slate-500 hover:text-slate-700 transition ease-linear"
                                                                                    title={`Send an email to ${c.email}`}
                                                                                >
                                                                                    {c.email}
                                                                                </Link>
                                                                            </li>
                                                                        }

                                                                        {c.phone &&
                                                                            <li className={`py-2 flex items-center justify-between`}>
                                                                                <p
                                                                                    className={`text-sm flex gap-2 items-center `}>
                                                                                    <span className="font-bold text-xs text-slate-500"><FiPhone/></span>
                                                                                    Call us
                                                                                </p>
                                                                                <Link
                                                                                    to={`tel:${c.phone}`}
                                                                                    target="_blank"
                                                                                    className="text-slate-500 hover:text-slate-700 transition ease-linear"
                                                                                    title={`Phone`}
                                                                                >
                                                                                    {c.phone}
                                                                                </Link>
                                                                            </li>
                                                                        }
                                                                        {data.website &&
                                                                            <li className={`py-2 flex items-center justify-between`}>
                                                                                <p className={`text-sm flex gap-2 items-center `}>
                                                                                    <span
                                                                                        className="font-bold text-xs text-slate-500"><FiGlobe/></span>
                                                                                    Visit our website</p>
                                                                                <Link
                                                                                    to={data.website}
                                                                                    target="_blank"
                                                                                    className="text-slate-500 hover:text-slate-700 transition ease-linear"
                                                                                    title={`See the website of ${data.website}`}
                                                                                >
                                                                                    { truncateTextLetter(data.website, 35) }
                                                                                </Link>
                                                                            </li>
                                                                        }
                                                                    </ul>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        }
                                    </div>
                                }

                                {activeLeftMenu === 2 &&
                                    <div className="flex w-full">
                                        <ul className="grid lg:grid-cols-2 grid-cols-1 gap-6 w-full">
                                            { data.scientificDomain?.length !== 0 &&
                                                <ItemDesc data={data.scientificDomain} title="Scientific Domaine"/>
                                            }
                                            { data.scientificSubDomain?.length !== 0 &&
                                                <ItemDesc data={data.scientificSubDomain} title="Scientific SubDomaine"/>
                                            }
                                            { data.targetUsers?.length !== 0 &&
                                                <ItemDesc data={data.targetUsers} title="Target Users"/>
                                            }
                                            { data.accessType?.length !== 0 &&
                                                <ItemDesc data={data.accessType} title="Access Type"/>
                                            }
                                            { data.accessMode?.length !== 0 &&
                                                <ItemDesc data={data.accessMode} title="Access Mode"/>
                                            }
                                        </ul>
                                    </div>
                                }
                            </div>

                            <div className={`bg-slate-100 lg:w-1/12 w-full lg:order-last order-first flex items-stretch justify-center`}>
                                <ul className="flex lg:flex-col lg:items-center w-full lg:relative">
                                    { leftMenu.map(item =>
                                        <li
                                            key={item.id}
                                            className={`flex items-center justify-center cursor-pointer w-full h-14 ${activeLeftMenu === item.id ? "bg-white text-blue-500" : "text-slate-500"} `}
                                            onClick={() => handleClickMenu(item.id)}
                                            title={item.title}
                                        ><span className="font-bold text-xl">{item.icon}</span>
                                        </li>
                                    ) }

                                    { data.infraId &&
                                        <li className="text-slate-50 rounded-full lg:absolute fixed right-4 lg:left-1/2 transform lg:-translate-x-1/2 bottom-4 w-12 h-12">
                                            <motion.button
                                                type="button"
                                                onClick={() => navigate(`/catalog/${id}/installations/${data.infraId}`)}

                                                className="bg-blue-500 h-12  w-12 rounded-full relative flex items-center justify-center"

                                                whileHover={{scale: 1.015}}
                                                whileTap={{scale: .945}}
                                                transition={{type: "spring", duration: .6, ease: "linear"}}

                                                title="See our installation"
                                            >
                                                <span className="text-center text-slate-50"><FiCpu/></span>
                                            </motion.button>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>

                </motion.article>
            }
        </AnimatePresence>

    )
}


const leftMenu = [
    {
        id:1,
        icon:<FiInfo />,
        title:"Information of service"
    },
    {
        id:2,
        icon:<FiLayers />,
        title:"Specifique infomation"
    }
]