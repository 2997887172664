import {
    Avatar,
    Box, Button,
    Container,
    FormGroup,
    Typography
} from "@mui/material";
import {motion} from "framer-motion";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import {createSelector} from "reselect";
import ChartersService from "../../../services/ChartersService";
import GradingIcon from '@mui/icons-material/Grading';
import {HexaLoader} from "../../ppa/components/loaders/Loaders";
import dayjs from "dayjs";
import UpdateIcon from '@mui/icons-material/Update';
import Alert from "@mui/material/Alert";
import AccessesService from "../../../services/AccessesService";
import {removeInfrastructure} from "../../../redux/user/changeInfrastructureAction";
import {useNavigate} from "react-router-dom";
import CancelIcon from '@mui/icons-material/Cancel';
import {SuccessIcon} from "../../ppa/components/icons/success/SuccessIcon";
import {ArrowLeftIcon} from "@mui/x-date-pickers";
import '../assets/scss/_Charter.scss';

export const CharterSign = () => {

    const selectUser = createSelector(
        state => state.login.user,
        user => user
    );
    const selectCurrentInfrastructure = createSelector(
        state => state.currentInfrastructure,
        ci => ci
    );
    const dispatch = useDispatch();

    const user = useSelector(selectUser);
    const currentInfrastructure = useSelector(selectCurrentInfrastructure);
    const [currentCharter, setCurrentCharter] = useState(null);
    const [totalCharter, setTotalCharter] = useState(0);
    const [sChar, setSChar] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [isSigned, setIsSigned] = useState(false);
    const [isError, setIsError] = useState(false);
    const [signedMessage, setSignedMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isFullySigned, setIsFullySigned] = useState(false);

    const accessesServices = new AccessesService();

    const navigate = useNavigate();

    const handleCancel = async (e) => {
        dispatch(removeInfrastructure());
        navigate("/");
    };

    const handleGoToDashboard = async (e) => {
        navigate("/dashboard");
    };

    useEffect(() => {
        setSCharFct();
    });

    const setSCharFct = () => {
        setSChar(totalCharter > 1 ? 's' : '');
    }
    useEffect(() => {
        const fetchCharters = async () => {
            try {
                setIsLoading(true);
                const chartersService = new ChartersService();
                const result = await chartersService.getChartersInfraById(user.token, currentInfrastructure);
                const tempCurrentCharter = result["hydra:member"][0] ?? null;
                const tempTotalCharter = result["hydra:member"].length ?? 0;
                setTotalCharter(tempTotalCharter);
                setCurrentCharter(tempCurrentCharter);
                setSCharFct();
                setIsFullySigned(tempTotalCharter === 0 && tempCurrentCharter === null);
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        }
        fetchCharters();
    }, [isSigned]);

    const handleSign = async (e) => {
        setIsLoading(true);
        setIsError(false);
        setIsSigned(false);

        const data = {
            "document": currentCharter["@id"],
        };
        await accessesServices.postAccessWithToken(data, user.token).then(data => {
            if (data.status === undefined || data.status === 201) {
                setSignedMessage(`The document <b>${currentCharter?.name}</b> has been signed successfully.`);
                setIsSigned(true);
                setIsError(false);
            } else {
                setIsSigned(false);
                setIsError(true);
                setErrorMessage(`The document <b>${currentCharter?.name}</b> has not been signed.`);
                console.log(data)
            }
            setIsFullySigned((totalCharter - 1) === 0);
            setIsLoading(false);
        });
    }

    return (
        <motion.article
            className="min-h-screen flex items-center justify-center"
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{duration: .5, type: "ease", delay: .2}}
        >

            <Container
                component="div"
                sx={{
                    margin: "20px 0",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <React.Fragment>
                    {!isLoading && isFullySigned ? (
                            <motion.article
                                id="charter_success"
                                className="min-h-screen pt-[35px] flex"
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                                transition={{duration: .5}}
                            >
                                <div className="wrapper flex flex-col lg:max-w-[996px]">
                                    <motion.div
                                        initial={{y: -120, opacity: 0}}
                                        animate={{y: 0, opacity: 1}}
                                        transition={{duration: .4}}
                                        className={"success-box"}
                                    >
                                        <SuccessIcon/>
                                        <Typography component={"h2"}
                                                    variant="h2-poppins"
                                                    className={"success-title"}
                                        >
                                            <span>Success!</span>
                                        </Typography>

                                        <Box component={"div"}
                                             className={"success-text"}
                                        >
                                            <Typography component={"h3"}
                                                        variant="h3-poppins"
                                            >
                                                All documents are signed
                                            </Typography>
                                        </Box>
                                        <Button type="button"
                                                color="info"
                                                variant="contained"
                                                startIcon={<ArrowLeftIcon/>}
                                                sx={{
                                                    borderRadius: "25px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    alignSelf: "center",
                                                    gap: "4px",
                                                    width: "100%"
                                                }}
                                                onClick={handleGoToDashboard}
                                        >
                                            Back to Dashboard
                                        </Button>
                                    </motion.div>
                                </div>
                            </motion.article>
                        ) :
                        (
                            <>
                                <Box
                                    sx={{
                                        marginBottom: "45px",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        gap: "15px"

                                    }}
                                >
                                    <Avatar sx={{m: 1, background: '#0B767F'}}>
                                        <AssignmentTurnedInIcon/>
                                    </Avatar>
                                    <Typography component="h1" variant="h2">
                                        You need to sign {totalCharter} document{sChar} to access this installation
                                    </Typography>

                                    {isSigned &&
                                        <Alert variant="filled" severity="success">
                                            <Typography
                                                dangerouslySetInnerHTML={{__html: signedMessage}}
                                            ></Typography>
                                        </Alert>
                                    }
                                    {isError &&
                                        <Alert variant="filled" severity="error">
                                            <Typography
                                                dangerouslySetInnerHTML={{__html: errorMessage}}
                                            ></Typography>
                                        </Alert>
                                    }
                                </Box>

                                {isLoading && (
                                    <HexaLoader/>
                                )}

                                {!isLoading &&
                                    (
                                        <React.Fragment>
                                            <FormGroup>
                                                <Box
                                                    sx={{
                                                        backgroundColor: "#efefef",
                                                        padding: "25px",
                                                        margin: "25px",
                                                        borderRadius: "5px"
                                                    }}
                                                >
                                                    <Typography component="h2" variant="h2">
                                                        {currentCharter?.name}
                                                        <Typography
                                                            component="p"
                                                            sx={{
                                                                opacity: "0.8"
                                                            }}
                                                        >
                                                            <UpdateIcon/> {dayjs(currentCharter?.updatedAt).format('dddd, MMMM D, YYYY')} at {dayjs(currentCharter?.updatedAt).format('HH:mm:ss')}
                                                        </Typography>
                                                    </Typography>
                                                    <hr className="my-6 border border-green-700"/>
                                                    <Typography
                                                        dangerouslySetInnerHTML={{__html: currentCharter?.content}}
                                                    >
                                                    </Typography>
                                                </Box>
                                            </FormGroup>
                                            <Button type="submit"
                                                    id="signButton"
                                                    variant="contained"
                                                    startIcon={<GradingIcon/>}
                                                    sx={{
                                                        borderRadius: "25px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        alignSelf: "center",
                                                        gap: "4px",
                                                        width: "25%"
                                                    }}
                                                    onClick={handleSign}
                                            >
                                                I agree and sign this document
                                            </Button>
                                            <Button type="button"
                                                    color="error"
                                                    variant="contained"
                                                    startIcon={<CancelIcon/>}
                                                    sx={{
                                                        borderRadius: "25px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        alignSelf: "center",
                                                        gap: "4px",
                                                        width: "25%"
                                                    }}
                                                    onClick={handleCancel}
                                            >
                                                I disagree and back to home
                                            </Button>
                                        </React.Fragment>
                                    )
                                }
                            </>
                        )
                    }
                </React.Fragment>
            </Container>
        </motion.article>

    )
}