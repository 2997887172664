import { Breadcrumbs, Typography } from "@mui/material";
import {Link, Navigate, useNavigate, useParams} from "react-router-dom";
import {FiGrid, FiHome} from "react-icons/fi";
import React, {useEffect, useState} from "react";
import {truncateTextLetter} from "../../utils/truncateText";
import {useMobile} from "../../hooks/useMobile";

export const CustomBreadcrumbs = ({ linkData, isDashboard }) => {

    const { id, typecatalog } = useParams();

    const [activeStep, setActiveStep] = useState(0);
    // const [servUrl, setServUrl] = useState(localStorage.getItem("servUrl") || null);
    const isMobile = useMobile();

    useEffect(() => {
        // Get the activeStep value from localStorage
        const storedActiveStep = localStorage.getItem("activeStep");
        if (storedActiveStep) {
            setActiveStep(parseInt(storedActiveStep, 10));
        }
    }, []);

    const handleLinkClick = (index) => {
        // Update the activeStep value in localStorage when a link is clicked
        localStorage.setItem("activeStep", index.toString());
        setActiveStep(index);
    };

    return (

        <Breadcrumbs aria-label="breadcrumb">
            {!isDashboard ?
                <Link underline="hover" color="inherit" to={"/"} title={`Back to home`}>
                    <span className={`${isMobile && "flex items-center justify-center border border-slate-200 rounded-full w-8 h-8 text-sm" }`}><FiHome /></span>
                </Link>
                :
                <Link underline="hover" color="inherit" to={"/dashboard"} title={`Back to dashboard`}>
                    <FiGrid /> Dashboard
                </Link>
            }

            { linkData.map((item, index) => {
                const isLast = index === linkData.length - 1;

                return (
                    <div key={index}>
                        { isLast ? (
                            <Typography title={item.title} component="p" variant="body" sx={{ color: 'dark.main', fontSize: ".845rem" }}>{ truncateTextLetter(item.title, 40)}</Typography>
                        ) : (
                            item.id === 3 ?
                                    <Link
                                    to={`/catalog/${id}/${typecatalog}`}
                                    title={item.title}
                                    sx={{ color: 'primary.main' }}
                                    >{item.title}</Link>
                                    :
                                    <Link
                                        to={`/${item.link}`}
                                        title={item.title}
                                        sx={{ color: 'dark.main' }}

                                        onClick={ () => handleLinkClick(item.activ)}
                                    >{isMobile ? <span className={`flex items-center justify-center border border-slate-200 rounded-full w-8 h-8 text-sm`}>{item.icon}</span> : item.title}</Link>

                        )}
                    </div>
                );
            })}
        </Breadcrumbs>
    );
}
