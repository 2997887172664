import {motion} from "framer-motion";
import {useContext} from "react";
import {CompConfigContext} from "../../../components/CompConfig";
import {LoadMore} from "../../../components/button/LoadMore";
import * as React from "react";
import {toast} from "sonner";
import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import {getStatusColor} from "../../user/utils/getStatusColor";

const selectUser = createSelector(
    state => state.login.user,
    user => user
);

export const ListUsers = () => {
    const data = useContext(CompConfigContext);

    const user = useSelector(selectUser);

    const handleClickOpenDrawer = (method, id) => {

        if ( data.role?.canUpdateUsers || user?.isSuperAdministrator  ) {
            data.handleClickOpenDrawer(method, id)
        }else {
            return toast.info("Access to this resource is restricted due to insufficient rights.")
        }


    }

    return (
        <div className={`min-h-80 py-20`}>
            <div className="container min-h-80 flex items-start justify-center">
                <div className="wrapper">
                    <motion.ul
                        className={`grid w-full mb-10 ${data.gridMode ? 'grid-cols-4 gap-8' : 'grid-cols-1 gap-2'}`}
                        initial={{opacity: 0, translateY: 100}}
                        animate={{opacity: 1, translateY: 0}}
                        exit={{opacity: 0, translateY: 100}}
                        transition={{type: "spring", duration: .6, ease: "easeOut"}}
                    >
                        {data?.myData?.map(item =>

                            <motion.li
                                className={`cursor-pointer relative border border-slate-200 rounded-2xl overflow-hidden flex flex-col`}
                                title={`See ${item?.user.fullName.replace(/\s+/g, '') ? item?.user.fullName : item?.user.email}`}
                                whileHover={{scale: !data.role?.canUpdateUsers ? 1 : 1.025}}
                                transition={{type: "spring", duration: .6, ease: "linear"}}
                                key={item.id}

                                onClick={() => handleClickOpenDrawer("UPDATE", item.id)}
                            >

                                <div
                                    className={`${getStatusColor(item.status)} relative w-full order-first h-14 flex items-center justify-center`}>
                                    <p className="text-sm text-slate-700 font-bold flex flex-col gap-0 items-center text-center">
                                        {(item?.user.fullName.replace(/\s+/g, '')) ? item?.user.fullName : (item?.user?.email) ? item?.user.email : "N/A"}
                                    </p>
                                </div>

                                {/*Bottom Box*/}
                                <div className={`h-36 start flex items-center justify-center px-6`}>

                                    <ul className={`flex flex-col gap-2 w-full text-center`}>
                                        <li className={`flex items-center justify-between`}>
                                            <p className="text-sm text-slate-700">
                                                Status
                                            </p>
                                            <span
                                                className={`border border-slate-50 rounded-sm h-6 px-4 text-xs flex items-center justify-center font-bold ${getStatusColor(item.status)}`}>
                                                    {item.status}
                                                </span>
                                        </li>
                                        <li className={`flex items-center justify-between`}>
                                            <p className="text-sm text-slate-700">
                                                Role
                                            </p>
                                            <span
                                                className={`border border-slate-200 rounded-sm h-6 px-4 text-xs flex items-center justify-center`}>
                                                    {item.role ? item.role?.name : "N/A"}
                                                </span>
                                        </li>
                                        <li className={`flex items-center justify-between`}>
                                            <p className="text-sm text-slate-700">
                                                Team(s)
                                            </p>
                                            <span
                                                title={item?.listTeams?.filter((t) => !t.isProject).map(t =>
                                                    t.name
                                                ).join(", ")}
                                                className={`border border-slate-200 rounded-sm h-6 px-4 text-xs flex items-center justify-center`}>
                                                {item?.listTeams?.filter((t) => !t.isProject).length}
                                            </span>
                                        </li>
                                    </ul>
                                </div>

                                <hr className="w-2/3 m-auto"/>

                                <span className="px-8 h-8 text-center flex items-center justify-center text-xs text-slate-500">
                                    {item?.user.email ? item?.user.email : "N/A"}
                                </span>
                            </motion.li>
                        )}
                    </motion.ul>

                    <LoadMore
                        dataLoad={4}
                        itemsPerPage={data.itemsPerRow}
                        setItemsPerPage={data.setItemsPerRow}
                        totalItems={data.totalItem}
                    />

                </div>
            </div>
        </div>
    )
}