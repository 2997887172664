import {FETCH_ALL_PLATFORM, FETCH_USER_INFRA, RESET} from "./platformAction";

const initialState = {
    data: [],
    userInfra: []
}

export const platformReducers = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_PLATFORM:
            return {
                ...state,
                data: action.payload,
            };
        case FETCH_USER_INFRA:
            return {
                ...state,
                userInfra: action.payload,
            };
        case RESET:
            return {
                data: [],
                userInfra: []
            }
        default:
            return state;
    }

}