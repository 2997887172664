import { useState, useEffect } from 'react';
import NetworkService from "../../../services/NetworkService";

export const useFetchAllNetworks = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [totalItems, setTotalItems] = useState(null);


    useEffect(() => {
            async function fetchAllNetworks() {
                try {
                    const newNetwork = new NetworkService()
                    const res = await newNetwork.getAllNetwork();
                    setData(res["hydra:member"]);
                    setTotalItems(res["hydra:totalItems"]);
                    setIsLoading(false);
                } catch (error) {
                    setError(error);
                    setIsLoading(false);
                }
            }

            fetchAllNetworks();

    }, []);
    return { data, isLoading, error, totalItems };
}
