export const getStatusColor = (status) => {
    switch (status) {
        case "validate":
            return "bg-green-100 text-green-500";
        case "new":
            return "bg-blue-100 text-blue-500";
        case "invited":
            return "bg-red-100 text-red-500";
        case "on_hold":
            return "bg-orange-100 text-orange-500";
        default:
            return "bg-slate-100 text-slate-500";
    }
};

export  const getStatusName = (status) => {
    switch (status) {
        case "validate":
            return "Validate";
        case "new":
            return "New";
        case "invited":
            return "Invited";
        case "on_hold":
            return "On hold";
        default:
            return "/";
    }
};