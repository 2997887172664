import {useEffect, useState} from "react";

const mediaWatcher = window.matchMedia("(max-width: 768px )");
export const CheckWindowSize = (page) => {
    const [mediaQuery, setMediaQuery] = useState(false);

    useEffect(() => {
        setMediaQuery(mediaWatcher.matches);
        function updateIsNarrowScreen(e) {
            setMediaQuery(e.matches);
        }

        if (mediaWatcher.addEventListener) {
            mediaWatcher.addEventListener('change', updateIsNarrowScreen)
            return function cleanup() {
                mediaWatcher.removeEventListener('change', updateIsNarrowScreen)
            }
        } else {
            mediaWatcher.addEventListener(updateIsNarrowScreen)
            return function cleanup() {
                mediaWatcher.removeEventListener(updateIsNarrowScreen)
            }
        }
    }, [])

    return { mediaQuery }
}