import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {useContext, useEffect, useState} from "react";
import {Loader} from "../../../../components/loader/Loader";
import {displayMessage} from "../../../../redux/alertAction";
import {DrawerTemporaryContext} from "../../../../components/drawer/DrawerTemporary";
import {FormLoader} from "../../loaders/Loaders";
import ConfirmDialog from "../../../../components/confirm/ConfirmDialog";
import {useFetchOneServiceType} from "../../hooks/useFetchServiceType";
import ServiceTypeService from "../../services/ServiceTypeService";
import {useNavigate} from "react-router-dom";
import {useFetchUserInfra} from "../../../user/hook/useFetchUserInfra";
import Select from "react-select";
import * as React from "react";
import {useFetchUserRole} from "../../../../hooks/useFetchUserRole";
import {Forbidden} from "../../../../pages/Forbidden/Forbidden";
import {FiAlertTriangle, FiArrowUpRight, FiPlay, FiTrash} from "react-icons/fi";

const selectUser = createSelector(
    state => state.login.user,
    user => user
);

const selectCurrentInfrastructure = createSelector(
    state => state.currentInfrastructure,
    ci => ci
);


export const FormServiceType = ({increment}) => {

    // User token
    const user = useSelector(selectUser);
    const token = user.token;

    const context = useContext(DrawerTemporaryContext)

    const currentInfrastructure = useSelector(selectCurrentInfrastructure);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const method = context.method ?? "CREATE";
    const isCreate = method === "CREATE";
    const isUpdate = method === "UPDATE";
    const role = useFetchUserRole();

    const [submitting, setSubmitting] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);

    const {
        control,
        register,
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: {errors},
        getValues
    } = useForm({
        defaultValues: {
            name: "",
            description: "",
            responsable: [],
            visible: false,
        },
    });

    const [isLoading, setIsLoading] = useState(true);

    const {serviceType} = useFetchOneServiceType(context.idSelected, user.token, increment, setIsLoading);

    const {userInfra} = useFetchUserInfra(currentInfrastructure);
    const listResponsable = [...userInfra];


    useEffect(() => {
        if (!isLoading) {
            if (null !== serviceType) {
                setValue('name', serviceType.name ?? "");
                setValue('description', serviceType.description ?? "");
                setValue('responsable', serviceType.responsable.id ?? null);
                setValue('visible', serviceType.isVisible ?? false);
            } else {
                reset({
                    name: "",
                    description: "",
                    responsable: null,
                    visible: false,
                });
            }
        }
    }, [
        isLoading,
        serviceType
    ]);


    /****
     * SUBMIT FORMS FUNCTION
     * ****/
    const onSubmit = async (data) => {
        try {
            setSubmitting(true);
            if (user.isSuperAdministrator || ((isCreate && role.canCreateServiceTypes) || (isUpdate && role.canUpdateServiceTypes))) {
                const requestData = {
                    name: data.name,
                    description: data.description,
                    infrastructure: `/api/infrastructures/${currentInfrastructure}`,
                    responsable: `/api/user_infras/${data.responsable}`,
                    isVisible: data.visible
                };

                const serviceTypeService = new ServiceTypeService();
                if (isCreate) {
                    await serviceTypeService.postServiceTypeWithToken(requestData, token);
                } else if (isUpdate) {
                    await serviceTypeService.putServiceTypeWithToken(requestData, context.idSelected, token);
                }

                reset({
                    name: "",
                    description: "",
                    responsable: null,
                    visible: false,
                });

                dispatch(displayMessage("Success", isCreate ? "Element created" : "Element updated", "success"));

                context.onDrawerClose();
            }
            setSubmitting(false);

        } catch (error) {
            setSubmitting(false);
            console.log(error);
            dispatch(displayMessage(error.data.title, error.data.detail, "error"));
        }
    };

    const handleDelete = async () => {
        try {
            if (role && (user.isSuperAdministrator || role.canDeleteServiceTypes)) {
                setSubmitting(true);
                const serviceTypeService = new ServiceTypeService();
                await serviceTypeService.deleteServiceTypeWithToken(context.idSelected, token);


                reset({
                    name: "",
                    description: "",
                    responsable: null,
                    visible: false,
                });

                dispatch(displayMessage("Success", "Element deleted", "success"));
                setSubmitting(false);
                context.onDrawerClose();
            }
        } catch (error) {
            setSubmitting(false);
            console.log(error);
            dispatch(displayMessage(error.data.title, error.data.detail, "error"));
        }
    }

    if (!role
        || (!user.isSuperAdministrator
            && (
                ((!role.canUpdateServiceTypes && !role.canDeleteServiceTypes) && isUpdate)
                || (!role.canCreateServiceTypes && isCreate)
            )
        )) {
        return <Forbidden isInDrawer={true} />
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8">

            {submitting ? (
                    <Loader content="Submitting"/>
                ) :
                (
                    <>
                    {/* 01 */}
                    {isLoading ?
                        (<FormLoader/>)
                        :
                        (
                            <>
                                <div className="flex flex-col gap-4">
                                    <div className="border border-slate-100 bg-slate-50 text-slate-500 h-32 justify-center flex flex-col gap-4 px-4 text-sm rounded-sm">
                                        <p className="text-slate-500">
                                            In this form, you can configure various service types to align with your application’s requirements.
                                        </p>

                                        {(user.isSuperAdministrator || role.canAccessServiceTypes) &&
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    window.open('/config/servicetypes/description/' + serviceType.id, '_blank');
                                                }}
                                                className="cursor-pointer text-slate-700 flex gap-2 items-center underline max-w-52 hover:text-blue-500 transition ease-linear">
                                                See the description <span><FiArrowUpRight /></span>
                                            </button>
                                        }
                                    </div>
                                    <div className="flex flex-col gap-4 ">
                                        <div className="w-full flex flex-col gap-2">
                                            <label htmlFor="name"
                                                   className="text-slate-700 text-md font-bold">Name</label>

                                            <div className="w-full">
                                                <input type="text"
                                                       placeholder="Name"
                                                       {...register("name", {required: "Name is required"})}
                                                       className="border border-slate-300 border-solid rounded-sm w-full grow h-12 p-4 focus:outline-none focus:ring focus:ring-blue-200"
                                                />
                                                {errors.name && !watch("name") && (
                                                    <span className={"text-sm text-red-500/70"}
                                                          role="alert">{errors.name.message}</span>
                                                )}
                                            </div>

                                        </div>

                                        <div className="w-full flex flex-col gap-2">
                                            <label htmlFor="description"
                                                   className="text-slate-700 text-md font-bold">Description</label>

                                            <div className="w-full">
                                                <input type="text"
                                                       placeholder="Description"
                                                       {...register("description")}
                                                       className="border border-slate-300 border-solid rounded-sm w-full grow h-12 p-4 focus:outline-none focus:ring focus:ring-blue-200"
                                                />
                                            </div>

                                        </div>

                                        <div className="w-full flex flex-col gap-2">
                                            <label htmlFor="users"
                                                   className="text-slate-700 text-md font-bold">Responsable</label>

                                            <div className="w-full">
                                                <Controller
                                                    name="users"
                                                    control={control}
                                                    render={({field}) => (
                                                        <Select
                                                            {...register("responsable", {required: "Responsable is required"})}
                                                            {...field}
                                                            onChange={(userInfra) => {
                                                                setValue("responsable", userInfra.id);
                                                            }}
                                                            options={listResponsable}
                                                            defaultValue={listResponsable.find((option) => option.id === serviceType?.responsable?.id ?? null)}
                                                            getOptionLabel={(option) => option?.user?.fullName} // Récupère le nom de l'option
                                                            getOptionValue={(option) => option.id} // Récupère la valeur de l'option (id)
                                                            isSearchable
                                                        />
                                                    )}
                                                />
                                                {errors.responsable && !watch("responsable") && (
                                                    <span className={"text-sm text-red-500/70"}
                                                          role="alert">{errors.responsable.message}</span>
                                                )}
                                            </div>

                                        </div>

                                        <div className="w-full flex items-center justify-between border border-slate-200 h-12 rounded-sm px-4 mt-8">
                                            <p className="text-slate-700 text-md font-bold">Display in Catalog</p>

                                            <label
                                                htmlFor="visible"
                                                className={`relative w-10 h-6 flex items-center justify-center rounded-full cursor-pointer text-xs font-medium border px-1 ${watch("visible") ? "text-green-400 bg-green-100 border-green-300" : "text-orange-400 bg-orange-100 border-orange-300"}`}
                                            >
                                                <input
                                                    type="checkbox"
                                                    id="visible"
                                                    {...register("visible")}
                                                    className="sr-only"
                                                />

                                                <span className={`${watch("visible") ? "right-0 border-green-400" : "border-orange-400 left-0"} transition ease-linear h-6 w-6 rounded-full bg-white shadow-sm border absolute top-1/2 transform -translate-y-1/2`}></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {/* 02 save change button */}
                                <div className="flex items-center gap-4">
                                    <button
                                        type="submit"
                                        className={`grow border border-slate-200 border-dashed rounded-sm text-slate-700 bg-slate-200 text-md h-10 flex items-center justify-center hover:bg-blue-500 hover:text-slate-50 transition ease-linear`}
                                    >
                                        {isCreate ? "Create new service type" : "Save change"}
                                    </button>
                                    {isUpdate &&
                                        <>
                                            {(user.isSuperAdministrator || role.canDeleteServiceTypes) &&
                                                <>
                                                    <button
                                                        type="button"
                                                        onClick={() => setOpenConfirmation(true)}
                                                        className={`border border-red-200 rounded-full bg-red-100 text-red-500 text-md h-10 w-10 flex items-center justify-center hover:bg-red-500 hover:text-slate-50 transition ease-linear`}
                                                    >
                                                        <FiTrash/>
                                                    </button>
                                                    <ConfirmDialog
                                                        isOpen={openConfirmation}
                                                        onClose={() => setOpenConfirmation(false)}
                                                    setIsOpen={setOpenConfirmation}
                                                    onAgree={() => handleDelete()}
                                                />
                                            </>
                                        }
                                </>
                                }
                            </div>
                        </>)
                    }
                    </>
                )}
        </form>
    )
}