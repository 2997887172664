import React from "react";
import {AnimatePresence, motion} from "framer-motion";
import {Button} from "@mui/material";
import {GrRevert} from "react-icons/gr";
import {AiOutlineDelete} from "react-icons/ai";


export const FormFileRemoveConfirm = ({ setRemoveFileConfirm, handleRemoveFile}) => {
    return (
        <AnimatePresence>

            <motion.div
                initial={{y: "-100%", opacity: 0}}
                animate={{y: "-20%", opacity: 1}}
                transition={{duration: .3}}
                exit={{y: "100%", opacity: 0}}
                className={`absolute right-0 text-center z-50 p-3 bg-white border rounded-md shadow-sm shadow-slate-300 w-fit`}
            >
                <div className={'flex justify-center font-bold py-2'}>
                    Delete the file ?
                </div>

                <div className={'flex w-full justify-center'}>
                    <Button
                        onClick={() => handleRemoveFile()}
                        sx={{padding: '3px 10px', minWidth: 'unset'}}
                        variant="contained"
                        color="error"
                    >
                        <AiOutlineDelete className={'text-xl mr-2'}/>
                        <span>Confirm</span>
                    </Button>
                    <Button onClick={() => setRemoveFileConfirm(false)}
                            sx={{padding: '3px 10px', minWidth: 'unset',}}
                            variant="contained"
                            color="warning"
                    >
                        <GrRevert className={'text-xl mr-2'}/>
                        <span>Cancel</span>
                    </Button>
                </div>

            </motion.div>

        </AnimatePresence>
    )
}
