import {useEffect, useState} from "react";
import TeamService from "../../../../services/TeamService";

export const useFetchTeam = (teamId, token, increment, setIsLoading) => {

    const [team, setTeam] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTeamData = async () => {
            try {
                setIsLoading(true);
                if (null !== teamId && undefined !== teamId) {
                    const teamService = new TeamService();
                    const res = await teamService.getTeam(teamId, token, 'jsonld');
                    setTeam(res);
                } else {
                    setTeam(null);
                }

            } catch (error) {
                setError(error);
            }
            setIsLoading(false)
        }
        fetchTeamData().then();
    }, [
        teamId,
        error,
        token,
        increment
    ]);

    return {team}
}