import * as React from "react";

// Context
import {useContext} from "react";
import {PpaFormContext} from "./PpaFormContainer";
import {ReusableFormContext} from "../../../components/reusableform/page/ReusableFinalFormContainer";

// Design
import {Box} from "@mui/material";

// Components
import {FormHeader} from "../../../components/reusableform/components/FormHeader";
import {FormContentStepper} from "../../../components/reusableform/components/navigation/FormContentStepper";
import {Loader} from "../../../components/loader/Loader";
import {FormMandatory} from "../../../components/reusableform/components/contents/FormMandatory";
import {FormSpecific} from "../../../components/reusableform/components/contents/FormSpecific";


export const PpaFormStructure = () => {

    const ppaFormContext = useContext(PpaFormContext)
    const reusableFormContext = useContext(ReusableFormContext)

    return (
        <>
            <FormHeader // PpaFormContext
                        handleDrawerOpen={ppaFormContext.handleDrawerOpen}
                        networkName={ppaFormContext.networkName}
                        validMandatory={ppaFormContext.validMandatory}

                        // ReusableFormContext
                        handleSave={reusableFormContext.handleSave}
                        form={reusableFormContext.form}
                        valid={reusableFormContext.valid}
                        values={reusableFormContext.values}
                        ppaForm
            />

            <FormContentStepper // PpaFormContext
                                steps={ppaFormContext.listTabs}
                                validMandatory={ppaFormContext.validMandatory}
                                hasMandatoryFieldsStep={ppaFormContext.hasMandatoryFieldsStep}

                                // ReusableFormContext
                                activeStep={reusableFormContext.activeStep}
                                setActiveStep={reusableFormContext.setActiveStep}
                                missingFields={reusableFormContext.missingFields}
                                hideMissingFields={reusableFormContext.hideMissingFields}
                                valid={reusableFormContext.valid}
            />

            {ppaFormContext.isLoading || ppaFormContext.isSingleNetworkLoading || reusableFormContext.isLoadingSelectInfras ? (

                <Loader content="Load Proposal Project form" hfull/>
            ) : (
                <Box sx={{
                    ...((reusableFormContext.activeStep === 0) ? {
                        display: 'flex',
                    } : {
                        display: 'none',
                    })
                }}>
                    <FormMandatory // PpaFormContext
                                   setValidMandatory={ppaFormContext.setValidMandatory}
                                   centralHubName={ppaFormContext.centralHubName}

                                   // ReusableFormContext
                                   selectInfras={reusableFormContext.selectInfras}
                                   values={reusableFormContext.values}
                    />
                </Box>
            )}

            {(!ppaFormContext.isLoadingJoinedContent && !ppaFormContext.isLoading) ? (
                <Box sx={{
                    ...((reusableFormContext.activeStep > 0 && ppaFormContext.listTabs.length > 0) ? {
                        display: 'flex',
                    } : {
                        display: 'none',
                    }),
                    height: '100%'
                }}
                >
                    <FormSpecific // PpaFormContext
                                  handleDrawerClose={ppaFormContext.handleDrawerClose}
                                  listTabs={ppaFormContext.listTabs}
                                  joinedInputs={ppaFormContext.joinedInputs}
                                  joinedForms={ppaFormContext.joinedForms}
                                  selectOptions={ppaFormContext.selectOptions}

                                  // List Type Form
                                  setJoinedInputs={ppaFormContext.setJoinedInputs}
                                  setState={ppaFormContext.setState}

                                  // ReusableFormContext
                                  activeStep={reusableFormContext.activeStep}
                                  preloadServices={reusableFormContext.preloadServices}
                                  values={reusableFormContext.values}
                                  setMissingFieldsChanges={reusableFormContext.setMissingFieldsChanges}
                                  hideMissingFields={reusableFormContext.hideMissingFields}
                    />
                </Box>
            ) : (
                reusableFormContext.activeStep > 0 && (
                    <Loader content="Load Proposal Project form" hfull/>
                )
            )}
        </>
    )
}
