import {CustomButton} from "../../button/CustomButton";
import {useMobile} from "../../../hooks/useMobile";

export const AuthButtons = () => {
    const mobile = useMobile()
    return (
            <div className="w-full lg:w-auto md:relative absolute bottom-0 left-0">
                <CustomButton
                    title="Log In"
                    path="login"
                    color={ mobile ? "primary" : "dark"}
                    variant={mobile ? "contained" : "outlined"}
                    size="small"
                    roundedborder={ !mobile }
                    width
                    style={ !!mobile }
                />
            </div>
    )
}
