import React from 'react';
import {Autocomplete, Box, FormControl, Paper, TextField} from "@mui/material";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import {motion} from "framer-motion";
import {darken, lighten, styled} from "@mui/system";

// eslint-disable-next-line no-undef
const url = process.env.REACT_APP_API_URL;

const GroupHeader = styled('div')(({theme}) => ({
    position: 'sticky',
    top: '0',
    zIndex: 10,
    color: theme.palette.primary.main,
    backgroundColor:
        theme.palette.mode === 'light'
            ? lighten(theme.palette.primary.light, 0.85)
            : darken(theme.palette.primary.main, 0.8),
}));

const CustomPaper = (props) => {
    return (
        <Paper elevation={8}
               sx={{ position: 'relative', top: '20px' }}
               {...props}
        />
    );
};

const RenderOptionComponent = ({option, groupParent, props}) => {

    return (
        <Box
            {...props}
            component="li"
            key={`box-${option?.id}`}
            sx={{
                '&.MuiAutocomplete-option': {
                    padding: '0 16px',
                    width: '100%',
                    ...(groupParent) && (
                        {
                            backgroundColor: 'rgba(187, 234, 254, 0.3)',
                            // margin: '0 0 4px 0',
                        }
                    ),
                    '&:hover .children-arrow-icon': { color: 'rgba(51, 128, 255, 1)' },
                    '&[aria-disabled="true"]': { opacity: 1, }
                },
            }}
            disabled={groupParent}
            aria-disabled={groupParent}
        >
            {(option.parent && !option.parent.isGroupOnly) && (
                <SubdirectoryArrowRightIcon
                    sx={{
                        fontSize: 18,
                        margin: '0 8px',
                        color: 'rgba(0, 0, 0, .3)'
                    }}
                    className={"children-arrow-icon"}
                />
            )}

            <motion.div className={"flex w-full items-center h-[46px]"}
                        whileHover={{ x: 10 }}
                        transition={{ duration: .2, bounce: 0 }}
            >
                {option?.logo && (
                    <img loading="lazy"
                         src={url + option?.logo}
                         alt={option?.name}
                         className={"option-logo max-w-[30px] h-[auto] mr-2"}
                    />
                )}

                <span className={`option-name w-10/12 overflow-hidden whitespace-nowrap text-ellipsis ${(groupParent) ? 'font-bold text-md' : 'text-sm'}`}
                      title={option?.name}
                >

                    {option?.name}
                </span>

                {(groupParent && !option?.notANetwork) && (
                    <span className={"ml-2 w-fit bg-blue-500 px-3 py-[6px] text-xs font-bold text-white rounded-md"}>
                        Group
                    </span>
                )}
            </motion.div>
        </Box>
    )
}


export const PlatformListView = (props) => {

    const { finalOptionsList, dataValue, handleChangePlatform } = props;

    return (
        <FormControl variant="standard" sx={{width: "100%", border: "none !important"}}>
            <ul className="w-full">
                <Autocomplete
                    size="small"
                    getOptionLabel={(option) => `${option?.name}`}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label=""
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                            placeholder="Select an installation"
                            sx={{
                                fieldset: {
                                    border: 'none',
                                },
                                background: "transparent !important"
                            }}
                        />
                    )}

                    renderOption={(props, option) => {

                        const groupParent = !option?.parent && option?.isGroupOnly;

                        return (
                            <React.Fragment key={props.id}>
                                {option && (

                                    groupParent ? (
                                        <GroupHeader>
                                            <RenderOptionComponent option={option}
                                                                   groupParent={groupParent}
                                                                   props={props}
                                            />
                                        </GroupHeader>
                                    ) : (
                                        <RenderOptionComponent option={option}
                                                               groupParent={groupParent}
                                                               props={props}
                                        />
                                    )

                                )}
                            </React.Fragment>
                        )
                    }}

                    // open={true} - Keep open during dev

                    // Styles
                    PaperComponent={CustomPaper}
                    ListboxProps={{
                        sx: {
                            padding: "0",
                            '&::-webkit-scrollbar': {
                                width: '10px',
                                backgroundColor: 'rgba(0, 0, 0, .1)'
                            }
                        }
                    }}

                    options={finalOptionsList}
                    onChange={handleChangePlatform}
                    value={dataValue}
                    sx={{
                        fontSize: "1.75rem",
                        fontWeight: "500",
                        textAlign: "center",
                        ".MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
                            color: "rgb(148 163 184)",
                            background: "none"
                        },
                        "@media (max-width: 1024px)": {
                            ".MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
                                backgroundColor: "rgb(241 245 249)",
                                color: "rgb(148 163 184)",
                                height: "55px"
                            },
                            ".MuiAutocomplete-input": {
                                textAlign: "center"
                            }
                        },
                    }}
                ></Autocomplete>
            </ul>
        </FormControl>
    )
}

