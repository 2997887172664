import {useEffect, useState} from "react";

// Redux
import { useDispatch } from "react-redux";
import { reusableFormSlice } from "../../../components/reusableform/store/reusableFormSlice";

import PPAService from "../../../services/PPAService";
import SelectService from "../../../services/SelectService";
import InfrastructureService from "../../installation/service/InfrastructureService";
import {useNavigate} from "react-router-dom";

const ppaService = new PPAService();
const selectService = new SelectService();
const infrastructureService = new InfrastructureService();



export const useFetchPpaTypeFromCode = (ppaTypeCode, setIsLoading) => {

    const [isPpaLocked, setIsPpaLocked] = useState(false);
    const [ppaLockedMessage, setPpaLockedMessage] = useState('');
    const defaultErrorMessage = "This pre-proposal form doesn't exist"

    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {

            if (ppaTypeCode) {
                async function handleGetPpaTypeFromCode() {
                    try {

                        setIsLoading(true)

                        const ppaService = new PPAService();
                        const response = await ppaService.getPPANetworkFromPPANetworkCode(ppaTypeCode);

                        if (response.length !== 0) {
                            if (response.isLocked) {
                                setIsPpaLocked(true);
                                setPpaLockedMessage(response.lockMessage)
                            }
                        } else {
                            setIsPpaLocked(true);
                            setPpaLockedMessage(defaultErrorMessage)
                        }

                        setIsLoading(false)

                    } catch (error) {
                        console.error(error);
                        navigate('/ppa');
                    }
                }
                handleGetPpaTypeFromCode().then();
            }

        }, 0)

        return () => {
            clearTimeout(timer);
        };
    }, [ppaTypeCode]);

    return { isPpaLocked, ppaLockedMessage };
}



export const useFetchPpaJoinedInputs = (listTabs) => {

    const [joinedInputs, setJoinedInputs] = useState([]);
    const [joinedForms, setJoinedForms] = useState([]);
    const [isLoadingJoinedContent, setIsLoadingJoinedContent] = useState(true);

    const tabsIds = listTabs.map((item) => item.id);
    const allTabsId = tabsIds.slice(1);

    // For SELECTS
    const [selectOptions, setSelectOptions] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        const timer = setTimeout(() => {
            async function handleGetPpaJoinedInputs(){
                try {
                    setIsLoadingJoinedContent(true);

                    // Redux - Clean updateListTypeFormsVisual in store
                    dispatch(reusableFormSlice.actions.updateListTypeFormsVisual([]));

                    // All Tabs parsing
                    const tabsResult = [];

                    for (const tab of allTabsId) {
                        try {
                            const response = await ppaService.getPPATabById(tab);
                            tabsResult.push(response);
                        } catch (error) {
                            console.error(`Error fetching data from ${tab}: ${error}`);
                        }
                    }

                    const tabsFormsList = tabsResult.map((item) => item.listForms);

                    // All Forms parsing and joined
                    const allFormsDatas = [].concat(...tabsFormsList);

                    const formsIds = allFormsDatas.map((item) => item.id);

                    const joinedFormsResult = [];

                    for (const form of formsIds) {
                        try {
                            const response = await ppaService.getPPAForms(form);
                            joinedFormsResult.push(response);

                        } catch (error) {
                            console.error(`Error fetching data from ${form}: ${error}`);
                        }
                    }

                    setJoinedForms(joinedFormsResult) /* TO USE FOR FORMS SEPARATION */

                    /****** *********** *****/
                    /****** BASIC FORMS *****/
                    /****** *********** *****/
                    // const formsListInputs = joinedFormsResult.map((item) => item.listInputs);

                    const basicTypeForms = joinedFormsResult.filter((item) => item.typeOfForm === null);

                    const basicTypeFormsListInputs = basicTypeForms.map((item) => item.listInputs);

                    const allBasicFormsListInputs = [].concat(...basicTypeFormsListInputs);
                    setJoinedInputs(allBasicFormsListInputs); /* TO USE FOR ALL INITIAL INPUTS */


                    /****** *************** *****/
                    /****** LIST TYPE FORMS *****/
                    /****** *************** *****/
                    const listTypeForms = joinedFormsResult.filter((item) => item.typeOfForm !== null);
                    // dispatch(ppaSlice.actions.addListTypeForms(listTypeForms))
                    dispatch(reusableFormSlice.actions.addListTypeForms(listTypeForms))


                    /****** ****************************** *****/
                    /****** ALL INPUTS present in the form *****/
                    /****** ****************************** *****/
                    // To Get selectOptions
                    const completeFormsListInputs = joinedFormsResult.map((item) => item.listInputs);
                    const allFormsListInputs = [].concat(...completeFormsListInputs);


                    // For SELECTS
                    const haveSelect = allFormsListInputs.filter((item) => item.select);
                    const haveInputIds = haveSelect.map((input) => input.id);

                    const selectsResult = [];

                    for (const list of haveInputIds) {
                        try {
                            const response = await selectService.getSelectInputs(list);
                            selectsResult.push(response);
                        } catch (error) {
                            console.error(`Error fetching data from ${list}: ${error}`);
                        }
                    }

                    // Do not display when isHidden: true
                    const onlyVisibleSelectsResult = selectsResult.filter((select) => !select.isHidden)

                    setSelectOptions(onlyVisibleSelectsResult);

                    setIsLoadingJoinedContent(false);

                } catch (error) {
                    console.error(error);
                }
            }
            // if(listTabs && !joinedInputs.length > 0) {
            if(listTabs && joinedInputs.length === 0) {
                handleGetPpaJoinedInputs().then();
            }
        }, 0)

        return () => {
            clearTimeout(timer);
        };
    }, [listTabs]);

    return { joinedInputs, setJoinedInputs, joinedForms, isLoadingJoinedContent, selectOptions };

}


export const useFetchPpaSelectInfras = (listInfrastructures, networkId) => {

    const [selectInfras, setSelectInfras] = useState([]);
    const [isLoadingSelectInfras, setIsLoadingSelectInfras] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {

            if(listInfrastructures && Array.isArray(listInfrastructures)) {
                async function handleGetPpaSelectInfras(){
                    try {
                        setIsLoadingSelectInfras(true);

                        if (undefined !== networkId) {
                            const response = await infrastructureService.getInfrastructureByNetwork(networkId);
                            const hydraMember = response["hydra:member"];
                            // console.log(hydraMember)

                            setSelectInfras(hydraMember);
                        }
                        setIsLoadingSelectInfras(false);

                    } catch (error) {
                        console.error(error);
                    }
                }
                handleGetPpaSelectInfras().then();
            }

        }, 0)

        return () => {
            clearTimeout(timer);
        };
    }, [networkId]);

    return { selectInfras, isLoadingSelectInfras };

}


export const useFetchPpaPreloadServices = (networkId) => {

    const [preloadServices, setPreloadServices] = useState([]);
    const [isLoadingPreloadServices, setIsLoadingPreloadServices] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {

            if(networkId) {
                async function handleGetPpaPreloadServices(){
                    try {
                        setIsLoadingPreloadServices(true);
                        const response = await ppaService.getPPAPreloadServices(networkId);
                        const hydraMember = response["hydra:member"];
                        // console.log(hydraMember)

                        setPreloadServices(hydraMember);
                        setIsLoadingPreloadServices(false);

                    } catch (error) {
                        console.error(error);
                    }
                }
                handleGetPpaPreloadServices().then();
            }

        }, 0)

        return () => {
            clearTimeout(timer);
        };
    }, [networkId]);

    return { preloadServices, isLoadingPreloadServices };

}