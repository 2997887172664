import AxiosService from "./AxiosService";

const urlTeams = "api/teams"

class TeamService {
    getTeamsByInfrastructureId(infraId, token, format) {
        return AxiosService.getWithToken(urlTeams + "?pagination=false&exists[deletedAt]=false&infrastructure.id=" + infraId, token, format);
    }

    getTeam(id, token, format) {
        return AxiosService.getWithToken(urlTeams+'/'+id, token, format);
    }

    deleteTeam(id, token) {
        return AxiosService.deleteWithToken(urlTeams+'/'+id, token);
    }

    createTeam(team, token) {
        team["token"] = token;
        return AxiosService.postWithToken(urlTeams, team, token, 'jsonld')
    }

    updateTeam(id, team, token) {
        return AxiosService.putWithToken(urlTeams+"/"+id, team, token, 'jsonld')
    }
}

export default TeamService;