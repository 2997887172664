import {useEffect, useState} from 'react';
import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import FormService from "../service/FormService";

const selectUser = createSelector(
    state => state.login.user,
    user => user
);

const useFetchOneForm = (id, updateFormIncrement) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const user = useSelector(selectUser);
    const token = user.token;

    useEffect(() => {
        fetchData();
    }, [id, updateFormIncrement]);

    async function fetchData () {
        setIsLoading(true)
        try {
            if(undefined !== id && null !== id) {
                const formServices = new FormService();

                const res = await formServices.GetFormId(id, token);
                setData(res);
            } else {
                setData(null);
            }
            setIsLoading(false);
        } catch (error) {
            setError(error);
            setIsLoading(false);
        }
    }

    return { data, isLoading, error };
};

export default useFetchOneForm;
