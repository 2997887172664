import {FETCH_ALL_FILTER_SERVICES} from "./filterActions";

const initialState = {
    data: [],
};

export const filterReducers = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_FILTER_SERVICES:
            return {
                ...state,
                data: action.payload,
            };
        default:
            return state;
    }
};