import {
    Avatar,
    Button,
    Grid,
    Typography
} from "@mui/material";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {Checkboxes, makeValidate, TextField} from "mui-rff";
import {Form} from "react-final-form";
import { motion } from "framer-motion";
import React, { useState} from "react";
import UserService from "../../../services/UserService";
import {displayMessage} from "../../../redux/alertAction";
import {useDispatch} from "react-redux";
import {object, ref, string} from "yup";
import Alert from "@mui/material/Alert";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import AlertTitle from "@mui/material/AlertTitle";

const schema = object({
    firstname: string()
        .required("The first name is required")
        .max(128),
    lastname: string()
        .required("The last name is required")
        .max(128),
    email: string()
        .required("The email is required")
        .email(),
    token: string()
        .required("The token is required"),
    password: string()
        .required("The password is required")
        .min(8, "The password must contain 8 characters. ")
        .matches(
            /^(?=.*[a-z])/,
            "Must contain one lowercase character. "
        )
        .matches(
            /^(?=.*[A-Z])/,
            "Must contain one uppercase character. "
        )
        .matches(
            /^(?=.*[0-9])/,
            "Must contain one number character. "
        )
        .matches(
            /^(?=.*[!@#$%^&*?])/,
            "Must contain one special case character. "
        ),
    password2: string()
        .required("The confirm password is required")
        .oneOf([ref('password')], 'Passwords must match'),
    // eula: boolean().required("You must accept EULA to continue")
});

const validate = makeValidate(schema);


export const Register = () => {

    const [searchParams, setSearchParams] = useSearchParams();

    const [state, setState] = useState({
        isLoading: false,
        schema: {},
        register: {
            isSent: false,
            isValid: false,
            message: null,
            token: searchParams.get("token") ?? null,
            email: searchParams.get("email") ?? null,
            firstname: null,
            lastname: null
        },
    });

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const handleSubmit = async (e) => {

        setState(prevState => ({
            ...prevState,
            isLoading: true,
        }));

        const userService = new UserService();

        await userService.register(e).then(data => {

            if (data.status === undefined || data.status === 200) {
                dispatch(displayMessage("Register success","You are now registered. You can login with your email","success"));
                setState(prevState => ({
                    ...prevState,
                    register: {
                        isSent: true,
                        isValid: true,
                        message: null,
                        token: null,
                        email: null,
                        firstname: null,
                        lastname: null,
                        password:null,
                        password2:null
                    },
                    isLoading: false,
                }));
                navigate("/login", { state: { email: e.email }});
            } else {
                // Case error
                setState(prevState => ({
                    ...prevState,
                    register: {
                        isSent: true,
                        isValid: false,
                        message: data.data.detail,
                        token: e.token,
                        email: e.email,
                        firstname: e.firstname,
                        lastname: e.lastname,
                        password:e.password,
                        password2:e.password2
                    },
                    isLoading: false,
                }));
                console.log(data);
            }
        });
    }

    return (
        <motion.article
            className="min-h-screen flex items-center justify-center"
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{duration: .5, type: "ease", delay: .2}}
        >
            <div className="container">
                <div className="wrapper flex items-center justify-center">
                    <motion.div
                        className="mt-4 flex flex-col items-center max-w-[500px]"
                        initial={{translateY: -100, opacity: 0}}
                        transition={{type: "spring", duration: .5, ease: "easeIn", delay: .4}}
                        animate={{translateY: 0, opacity: 1}}
                        exit={{translateY: -100, opacity: 0}}
                    >

                        {!state.register.token &&
                            <Alert variant="filled" severity="info">
                                To register a new membership in ISIA, you must receive an invitation from an installation manager.
                            </Alert>
                        }

                        <Avatar sx={{m: 1, background: '#3380FF'}}>
                            <PersonAddAltIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h2" sx={{ marginBottom:"25px"}}>
                            Register
                        </Typography>

                        {(state.register.isSent && false === state.register.isValid) && (
                            <Alert severity="error"
                                   sx={{
                                       width: '100%',
                                       display: 'flex',
                                       margin: '20px 0'
                                   }}
                            >
                                <AlertTitle>Error</AlertTitle>
                                {state.register.message}
                            </Alert>
                        )}

                        <Form
                            onSubmit={handleSubmit}
                            validate={validate}
                            initialValues={state.register}
                            render={({handleSubmit}) => (
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="firstname"
                                                required
                                                fullWidth
                                                id="firstName"
                                                label="First Name"
                                                autoFocus
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                required
                                                fullWidth
                                                id="lastName"
                                                label="Last Name"
                                                name="lastname"
                                                autoComplete="family-name"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                id="email"
                                                label="Email Address"
                                                name="email"
                                                autoComplete="email"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                name="password"
                                                label="Password"
                                                type="password"
                                                id="password"
                                                autoComplete="new-password"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                name="password2"
                                                label="Comfirm password"
                                                type="password"
                                                id="password2"

                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                name="token"
                                                label="Token received by mail"
                                                type="text"
                                                id="token"
                                                autoComplete="off"

                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            {/*<FormControlLabel*/}
                                            {/*    control={<Checkbox value="allowExtraEmails" color="primary" name="eula" onChange={handleCheckbox} />}*/}
                                            {/*    label={*/}
                                            {/*        <span>I understand, accept the <Link to="/" className="text-blue-700 font-medium border hover:border-b-4">EULA</Link>  and give my permission for ISIA to collect personal data</span>*/}
                                            {/*    }*/}
                                            {/*/>*/}
                                            <Checkboxes
                                                required
                                                name="eula"
                                                data={{
                                                    label: 'I understand, accept the EULA and give my permission for ISIA to collect personal data',
                                                    // value: true
                                                }}
                                                formControlProps={{margin: 'normal'}}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="dark"
                                        sx={{mt: 3, mb: 2, color: "#fafafa"}}
                                    >
                                        Register
                                    </Button>
                                    <Grid container justifyContent="flex-end">
                                        <Grid item>
                                            <Link to="/login">
                                                Already have an account? Log In
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        />
                    </motion.div>
                </div>
            </div>
        </motion.article>

)}