import * as React from "react";
import {memo} from "react";

// Components
import {FormTextField} from "./items/FormTextField";
import {FormTextArea} from "./items/FormTextArea";
import {FormCheckBox} from "./items/FormCheckBox";
import FormDateAndTimePickers from "./items/FormDateAndTimePickers";
import {FormImageUpload} from "./items/FormImageUpload";
import {FormAutoCompleteContainer} from "./items/FormAutoCompleteContainer";



export const FormContentInputs = memo(function FormContentInputs ({ inputData,
                                      inputIndex,
                                      selectOptions,
                                      formId,
                                      preloadServices,
                                      values,

                                      keyLinkValue,
                                      isRead
                                  }) {

    // console.log(inputData)

    // Select Inputs Management
    // PPA Case || Descriptions Case
    const filteredSelects = selectOptions.filter((optionList) => optionList.form ? (optionList.form['@id'] === `/api/forms/${formId}`) : (optionList.formId === formId));

    // Date Fields/Inputs
    const dateInputTypes = ["date", "datetime", "time"];

    return (
        <>

        {/* Inputs Loop */}

            {( inputData.inputType.htmlType === "text" || inputData.inputType.htmlType === "email" || inputData.inputType.htmlType === "url") && (
                <FormTextField name={ `listInputValues[${inputIndex}].value` }
                               description={ inputData.description }
                               label={ inputData.name }
                               type={inputData.inputType.htmlType}
                               hide={inputData.isHidden}
                               required={inputData.isRequired}
                               isBlocked={inputData.isBlocked}

                              // For value send formatting
                               id={ inputData.id }
                               inputIndex={inputIndex}
                               values={values}

                              // For List Type Forms
                               keyLinkValue={keyLinkValue ?? null}
                               isRead={isRead}

                />
            )}

            {( inputData.inputType.htmlType === "textarea") && (
                <FormTextArea listInput={inputData}
                              name={ `listInputValues[${inputIndex}].value` }
                              description={ inputData.description }

                              // For value send formatting
                              id={ inputData.id }
                              inputIndex={inputIndex}
                              values={values}

                              // For List Type Forms
                              keyLinkValue={keyLinkValue ?? null}
                              isRead={isRead}
                />
            )}

            {( inputData.inputType.htmlType === "select") && (
                <FormAutoCompleteContainer listInput={inputData}
                                           filteredSelects={filteredSelects}

                                           name={ `listInputValues[${inputIndex}].value` }
                                           description={ inputData.description }
                                           formId={formId}
                                           preloadServices={preloadServices}
                                           hide={inputData.isHidden}
                                           required={inputData.isRequired}
                                           isBlocked={inputData.isBlocked}

                                           // For value send formatting
                                           id={ inputData.id }
                                           inputIndex={inputIndex}
                                           values={values}
                                           isRead={isRead}

                                           // For List Type Forms
                                           keyLinkValue={keyLinkValue ?? null}
                />
            )}

            {( inputData.inputType.htmlType === "checkbox") && (
                <FormCheckBox name={ `listInputValues[${inputIndex}].value` }
                              description={ inputData.description }
                              label={ inputData.name }
                              type={inputData.inputType.htmlType}
                              required={inputData.isRequired}
                              isBlocked={inputData.isBlocked}
                              hide={inputData.isHidden}

                              // For value send formatting
                              id={ inputData.id }
                              inputIndex={inputIndex}
                              values={values}
                              isRead={isRead}

                              // For List Type Forms
                              keyLinkValue={keyLinkValue ?? null}
                />
            )}

            {( dateInputTypes.includes(inputData.inputType.htmlType)) && (
                <FormDateAndTimePickers name={ `listInputValues[${inputIndex}].value` }
                                        description={ inputData.description }
                                        label={ inputData.name }
                                        required={inputData.isRequired}
                                        isBlocked={inputData.isBlocked}
                                        hide={inputData.isHidden}

                                       // For value send formatting
                                        id={ inputData.id }
                                        inputIndex={inputIndex}
                                        values={values}
                                        isRead={isRead}

                                       // For List Type Forms
                                        keyLinkValue={keyLinkValue ?? null}

                                       // Date Type
                                        dateType={inputData.inputType.htmlType}
                />
            )}

            {( inputData.inputType.htmlType === "file") && (
                <FormImageUpload name={ `listInputValues[${inputIndex}].value` }
                                 description={ inputData.description }
                                 label={ inputData.name }
                                 type={inputData.inputType.htmlType}
                                 hide={inputData.isHidden}
                                 required={inputData.isRequired}
                                 isBlocked={inputData.isBlocked}

                                // For value send formatting
                                 id={ inputData.id }
                                 inputIndex={inputIndex}
                                 values={values}
                                 isRead={isRead}

                                // For List Type Forms
                                 keyLinkValue={keyLinkValue ?? null}

                />
            )}
        </>
    )
});
