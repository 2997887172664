import React from "react";

export const PreviewImage = ({resource}) => {

    return (
        <div className="bg-[#fafafa] p-2 rounded-md">
            <figure className="w-12 h-12">
                <img src={resource.ListImages} alt="Preview"/>
            </figure>
        </div>
    )
}