export const FreeTextExample = [
    {
        id:0,
        description:"When you enter a string of characters, the search will return all elements containing that string.",
        example:"'climate change' will return all elements containing the term 'climate change'"
    },
    {
        id:1,
        description:"You can also search for all elements containing certain terms by separating them with a comma.",
        example:"'climate, change' will return all elements containing the term 'climate' or the term 'change'"
    }
]