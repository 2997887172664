import {useFetchAllServices} from "../hooks/useFetchAllServices";
import {CatalogComp} from "../../catalog/components/CatalogComp";

function ServiceCatalog ({ isOpen, setIsOpen, limit }) {

    return (
        <CatalogComp
            fetchHook={useFetchAllServices}
            title="Service"
            catalogType="services"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            limit={limit}
            dataSelectorKey="data"
            bgColorType={"bg-blue-500"}
        />
    )
}

export default ServiceCatalog;
