import React from "react";
import {styled} from "@mui/material/styles";
import {Tooltip, tooltipClasses} from "@mui/material";

export const ListTypeFormsTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#d4d4d4',
        color: 'rgba(0, 0, 0, 1)',
        fontSize: theme.typography.pxToRem(12),
        padding: '8px 16px',
        borderRadius: '5px',
        boxShadow: '0 10px 15px -3px rgb(0 0 0 / 0.06)',
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#d4d4d4',
    },
}));