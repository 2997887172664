import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useState } from "react";
import * as React from "react";

export const ItemDesc = ({ data, typeInstall, title }) => {
    const [currentPage, setCurrentPage] = useState(1);

    console.log(data);

    // Pagination in the filter
    const ITEMS_PER_PAGE = 4;
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const currentItems = data?.slice(startIndex, endIndex);
    const totalPages = Math.ceil(data?.length / ITEMS_PER_PAGE);

    const handleNextPage = (e) => {
        e.preventDefault();
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    const handlePrevPage = (e) => {
        e.preventDefault();
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    return (
        <li className="border bg-white rounded-2xl h-52 shadow-sm overflow-hidden flex flex-col">
            <div className="h-1/5 flex items-center justify-center bg-blue-500">
                <p className="lg:text-md text-md text-center font-medium fontfamilly text-slate-50">{title}</p>
            </div>

            <div className="h-1/5 grow relative">
                <ul className="flex flex-col gap-0 h-full">
                    {currentItems?.map((item, index) => (
                        <li
                            key={!typeInstall ? index : item?.id}
                            className="text-sm text-slate-500 text-center p-2"
                        >
                            {!typeInstall ? item : item?.value}
                        </li>
                    ))}
                </ul>

                {data?.length > ITEMS_PER_PAGE && (
                    <>
                        <button
                            className={`absolute left-4 top-1/2 transform -translate-y-1/2 border rounded-full p-1 ${currentPage > 1 ? "border-slate-200" : "border-slate-200"}`}
                            onClick={handlePrevPage}
                        >
                            <span className="text-sm text-slate-500"><FiChevronLeft /></span>
                        </button>

                        <button
                            className={`absolute right-4 top-1/2 transform -translate-y-1/2 border border-slate-200 rounded-full p-1 ${currentPage < totalPages ? "border-slate-200" : "border-slate-200"}`}
                            onClick={handleNextPage}
                        >
                            <span className="text-sm text-slate-500"><FiChevronRight /></span>
                        </button>
                    </>
                )}
            </div>
        </li>
    );
};
