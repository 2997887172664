const INITIAL_STATE = {
    name: "",
}
// Si state == null, dans ce cas il prend INITIAL_STATE sinon le state fournit
export function newSelectName (state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'CHANGE_SELECTNAME':
            return {
                name: action.payload
            }
        case 'REMOVE_SELECTNAME':
            return {
                name: null
            };
        default:
            return state;
    }
}
