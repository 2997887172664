import {Outlet, useNavigate} from "react-router-dom";
import {createSelector} from "reselect";
import {useDispatch, useSelector} from "react-redux";
import {displayMessage} from "../../redux/alertAction";
import { createContext, useEffect, useState } from 'react'
import UserService from '../../services/UserService'

export const LayoutWithInstallationContext = createContext(null)

function LayoutWithInstallation() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectUser = createSelector(
        state => state.login.user,
        user => user
    );
    const selectCurrentInfrastructure = createSelector(
        state => state.currentInfrastructure,
        ci => ci
    );

    const user = useSelector(selectUser);
    const currentInfrastructure = useSelector(selectCurrentInfrastructure);
    const userService = new UserService();
    const [isValid, setIsValid] = useState(null);

    useEffect(() => {
        userService.isTokenValid(user?.id, user?.token).then((result, r) => {
            setIsValid(result && result.status === 200);
        }).catch((err) => {
            setIsValid(false);
        });
    }, [])

    useEffect(() => {
        if (null === user || false === isValid) {
            dispatch(displayMessage("Session expired", "Your session has expired, you must connect again", "error"));
            navigate("/logout?expired=1");
        }

        if (null === currentInfrastructure || 0 === currentInfrastructure) {
            dispatch(displayMessage("Forbidden", "You must select an installations to access to this page", "error"));
            navigate("/");
        }
    }, [selectUser, selectCurrentInfrastructure, isValid]);


    return (
        <LayoutWithInstallationContext.Provider value={{user, currentInfrastructure}}>
            <Outlet/>
        </LayoutWithInstallationContext.Provider>
    );
}


export default LayoutWithInstallation