import AxiosService from '../../../services/AxiosService'

const urlResourceLinks = 'api/resource_links'
class ResourceLinkService {
    getFromResourceOneId(resourceId, token) {
        return AxiosService.getWithToken(`${urlResourceLinks}?resourceOne.id=${resourceId}`, token, 'json-ld')
    }

    getFromResourceLinkToId(resourceId, token) {
        return AxiosService.getWithToken(`${urlResourceLinks}?resourceToLink.id=${resourceId}`, token, 'json-ld')
    }

    getResourceLink(id, token) {
        return AxiosService.getWithToken(`${urlResourceLinks}/${id}`, token, 'json-ld')
    }

    postResourceLink(body, token) {
        body['token'] = token
        return AxiosService.postWithToken(`${urlResourceLinks}`, body, 'json-ld')
    }

    putResourceLink(id, body, token) {
        return AxiosService.putWithToken(`${urlResourceLinks}/${id}`, body, token, 'json-ld')
    }

    deleteResourceLink(id, token) {
        return AxiosService.deleteWithToken(`${urlResourceLinks}/${id}`, token, 'json-ld')
    }
}
export default ResourceLinkService
