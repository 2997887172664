import {useNavigate} from "react-router-dom";
import {motion} from "framer-motion";
import {FiHome, FiSettings} from "react-icons/fi";

const PageComingSoon = () => {
    const navigate = useNavigate();

    const handleClickHome = () => {
        return navigate('/')
    }

    return (
        <article className="min-h-screen flex items-center justify-center">
            <div className="container z-10">
                <div className="lg:w-9/12 w-11/12 flex flex-col items-center gap-10">
                    <div className="flex flex-col items-center gap-2 max-w-72">
                        <h1 className="flex flex-col items-center gap-2 text-center text-lg font-semibold text-slate-500">
                            Imminent Discovery
                        </h1>
                        <p className={`text-slate-500 text-sm text-center`}>A new page is in the works, filled with innovative content. Be ready to explore soon.</p>
                        <span className="text-lg text-slate-500 order-first animate-spin duration-100"><FiSettings /></span>
                        <motion.button
                            whileHover={{scale: 1.0145}}
                            whileTap={{scale: .945}}
                            className="p-4 rounded-full mt-10 border border-slate-300 text-slate-500 animate-pulse"
                            onClick={handleClickHome}

                            title={"Back to isia"}
                        >
                            <FiHome />
                        </motion.button>
                    </div>
                </div>
            </div>
        </article>
    )
};

export default PageComingSoon;
