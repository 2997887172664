import * as React from 'react';

// Design
import { motion, AnimatePresence } from "framer-motion";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Alert from "@mui/material/Alert";
import {PpaStepConnector, PpaStepIcon} from "../../utils/StepperAssets";


export const FormContentStepper = ({ steps, activeStep, setActiveStep, validMandatory, valid, missingFields, hideMissingFields, hasMandatoryFieldsStep }) => {

    const handleClick = (stepIndex) => {
        setActiveStep(stepIndex)
    }


    return (
        <Stepper activeStep={activeStep}
                 alternativeLabel
                 className="stepper w-full py-4 mt-4 lg:mt-6 lg:mb-9"
                 connector={<PpaStepConnector />}
        >
            {steps.filter((step) => !step.isHidden).map((label, index) => (
                <Step key={label.id}
                      sx={{ display: { xs: `${activeStep === index ? "block" : "none" }`, lg: 'flex' },
                            height: '100%',
                            flexFlow: 'row wrap',
                            justifyContent: 'center',
                          }}
                      onClick={() => hasMandatoryFieldsStep ? (validMandatory && valid) && handleClick(index) : handleClick(index)}

                >
                    {/* error= Return Boolean depending on missing fields value PER TAB ID */}
                    <StepLabel sx={{
                                    // Clickable Step
                                    ...((hasMandatoryFieldsStep ? (validMandatory && valid) : valid) && {
                                        '&:hover': { cursor: 'pointer' },
                                    }),
                                    position: 'relative',
                                }}
                               StepIconComponent={PpaStepIcon}
                               error={(missingFields.filter((item) => ((item.id === label.id) && (item.value > 0))).map((item) => item.value) > 0) && !hideMissingFields}
                    >
                        <div className={`name w-full mb-[20px] lg:mb-0 ${activeStep === index ? "text-base font-bold" : 'text-sm'}`}>
                            { label.name }
                        </div>

                        {/*{ index !== 0 && (*/}
                            <div className="error-alert mt-auto absolute">
                                { missingFields.map((item, index) => (
                                    <React.Fragment key={index}>
                                        {item.id === label.id && (
                                            <AnimatePresence>
                                                {(item.value > 0 && !hideMissingFields) && (
                                                    <motion.div
                                                        initial={{y: -40, opacity: 0}}
                                                        animate={{y: 50, opacity: 1}}
                                                        transition={{duration: .4}}
                                                        exit={{ y: -40, opacity: 0 }}
                                                    >
                                                        <Alert severity="error"
                                                               variant="filled"
                                                               sx={{ minWidth: '180px',
                                                                     fontSize: '13px',
                                                                     fontWeight: '600',
                                                                     justifyContent: 'center',
                                                                     alignItems: 'flex-start',
                                                                     padding: '0 6px',
                                                                     minHeight: '36px',
                                                                     margin: '0 auto',
                                                                     position: 'relative',
                                                                     bottom: { xs: 20, lg: 0 }
                                                               }}
                                                               icon={false}
                                                        >
                                                            {item.value > 1 ? (
                                                                <div>{item.value} required fields left</div>
                                                            ) : (
                                                                <div>One missing required field</div>
                                                            )}

                                                        </Alert>
                                                    </motion.div>
                                                )}
                                            </AnimatePresence>
                                        )}
                                    </React.Fragment>
                                ))}
                            </div>
                        {/*)}*/}
                    </StepLabel>

                </Step>
            ))}
        </Stepper>
    )
}