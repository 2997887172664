import RoleService from "../../../../services/RoleService";
import {useEffect, useState} from "react";

export const useFetchRoleData = ( infrastructureId, token, incrementList ) => {

    const [roles, setRoles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchRolesData =  async () => {
            try {
                setIsLoading(true);
                if (infrastructureId > 0) {
                    const roleService = new RoleService();
                    const res = await roleService.getRolesByInfrastructureId(infrastructureId, token, 'jsonld');
                    setRoles(res["hydra:member"]);
                }
            } catch (error) {
                setRoles([]);
                setError(error);
            }
            setIsLoading(false);
        }
        fetchRolesData().then();

    }, [infrastructureId, token, incrementList]);

    return { roles , isLoading, error}
}