import React, {useState} from 'react';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {truncateTextLetter} from "../../../utils/truncateText";

const ListCheckBox = ({ item, key, checkboxStates, handleCheckboxChange, isMobile}) => {

    const [hoveredItem, setHoveredItem] = useState(null);

    return (
        <li
            key={key}
            className="text-slate-700 w-[300px] relative"
            onMouseEnter={() => {
                const truncatedText = item.name?.slice(0, 20);

                if (truncatedText.length === 20) {
                    setHoveredItem(item.name)
                }

            }}
            onMouseLeave={() => setHoveredItem(null)}
        >
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checkboxStates[item.id] || false}
                        onChange={() =>
                            handleCheckboxChange(item.id, item.name)
                        }
                        size="smaller"
                        sx={{
                            color: "dark.main",
                            '&.Mui-checked': {
                                color: "primary.main",
                            },
                            fontSize: {
                                xs: ".845rem",
                                lg: "1.0rem"
                            }
                        }} f
                    />
                }
                label={truncateTextLetter(item.name, isMobile ? 15 : 30)}
            />

            { hoveredItem === item.name && (
                <span className="absolute top-full left-1/2 transform -translate-x-1/2 bg-black text-white rounded-md p-2 z-50 transition">
                    {item.name}
                </span>
            )}
        </li>
    );
};

export default ListCheckBox;
