import {useState, useEffect} from 'react';
import InputsService from "../services/InputsServices";
import {useSelector} from "react-redux";

export const useFetchOneInput = (id) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const token = useSelector(state => state.login.user?.token) ?? null;

    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData() {

        try {
            if (null !== id) {
                const inputService = new InputsService();
                const res = await inputService.GetInputById(id, token);

                setData(res);
            } else {
                setData(null);
            }
            setIsLoading(false);
        } catch (error) {
            setError(error);
            setIsLoading(false);
        }
    }

    return {data, isLoading, error};
}
