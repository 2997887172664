import TeamService from "../../../../services/TeamService";
import {useEffect, useState} from "react";

export const useFetchTeamData = (infrastructureId, token, excludeProject, incrementList) => {

    const [teams, setTeams] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchTeamsData = async () => {
            try {
                setIsLoading(true);
                if (infrastructureId > 0) {
                    const teamService = new TeamService();
                    const res = await teamService.getTeamsByInfrastructureId(infrastructureId, token, 'jsonld');
                    let hydra = res['hydra:member'];
                    if (excludeProject) {
                        hydra = hydra.filter((t) => !t.isProject);
                    }
                    setTeams(hydra);
                }
            } catch (error) {
                setTeams([]);
                setError(error);
            }
            setIsLoading(false);
        }
        fetchTeamsData().then();
    }, [
        infrastructureId,
        token,
        incrementList
    ]);

    return {teams, isLoading, error}
}