import {motion} from "framer-motion";
import {useParams} from "react-router-dom";
import {useFetchNetworkById} from "../../../network/hooks/useFetchNetworkById";
import {FiSend} from "react-icons/fi";
import {toast} from "sonner";
export const ButtonPpa = () => {

    const { id } = useParams();

    const { data } = useFetchNetworkById(id);
    const handleClickPpa = () => {

        if (data) return window.open(`/ppa/${data.code}`, '_blank');

        return toast.warning("Something went wrong. Try again later.");
    }

    return (
        <motion.button
            type="button"
            onClick={handleClickPpa}

            className="bg-blue-500 h-12 w-12 rounded-full relative flex items-center justify-center"

            whileHover={{scale: 1.015}}
            whileTap={{scale: .945}}
            transition={{type: "spring", duration: .6, ease: "linear"}}

            title="submit project"
        >
            <span className="text-center text-slate-50"><FiSend /></span>
        </motion.button>
    )
}