export const FETCH_SELECT_OPTION = 'FETCH_SELECT_OPTION';

export const ADD_SELECT_OPTION = 'ADD_SELECT_OPTION';

export const DELETE_SELECT_OPTION = 'DELETE_SELECT_OPTION';

export const UPDATE_SELECT_OPTION = 'UPDATE_SELECT_OPTION';

export const fetchSelectOption = (data) => ({
    type: FETCH_SELECT_OPTION,
    payload: data
})

// Action pour ajouter un nouveau réseau
export const addSelectOption = (newSelectOption) => ({
    type: ADD_SELECT_OPTION,
    payload: newSelectOption
});

export const deleteSelectOption = (selectOptionId) => ({
    type: DELETE_SELECT_OPTION,
    payload: selectOptionId
})

export const updateSelectOption = (updatedSelectOption) => ({
    type: UPDATE_SELECT_OPTION,
    payload: updatedSelectOption
})