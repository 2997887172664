import {useEffect, useState} from "react";
import ResourceService from "../services/Resource";

export const useFetchResourceById = (id, resourceTypeId, token, setIsLoading, increment) => {

    const [resource, setResource] = useState(null);
    const [error, setError] = useState(false);

    useEffect(() => {
        async function fetchResourceById() {
            try {
                setIsLoading(true)
                if (undefined !== id && null !== id && !Array.isArray(id) && undefined !== resourceTypeId && null !== resourceTypeId) {
                    const resource = new ResourceService();
                    const res = await resource.getResourceById(id, resourceTypeId, token);
                    setResource(res)
                } else {
                    setResource(null);
                }
            } catch (err) {
                setError(err)
            }
            setIsLoading(false)
        }

        fetchResourceById().then();

    }, [id, increment])

    return {resource, error}
}