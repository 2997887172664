import * as React from "react";

// Design
import {styled} from "@mui/material/styles";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ErrorIcon from '@mui/icons-material/Error';
import {StepConnector, stepConnectorClasses} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';


export const PpaStepConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 22px)',
        right: 'calc(50% + 22px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.primary.main,
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.primary.main,
        },
    },
    [`&.${stepConnectorClasses.error}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: 'red',
            [`&:after`]: {
                borderColor: 'red',
            }
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[400],
        borderTopWidth: 1,
        borderRadius: 1,
        [theme.breakpoints.down('lg')]: {
            display: 'none',
        },
    },
}));

const PpaStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    '& .PpaStepIcon-completedIcon': {
        color: 'white',
        zIndex: 1,
        fontSize: 28,
        borderRadius: '50%',
        padding: 4,
        backgroundColor: theme.palette.primary.main,
        // ...(ownerState.error && {
        //     display: 'none',
        // })
    },
    '& .PpaStepIcon-errorIcon': {
        zIndex: 1,
        fontSize: 28,
        ...(ownerState.active && {
            fontSize: 34,
        }),
    },
    '& .PpaStepIcon-circle': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        color: 'white',
        width: 28,
        height: 28,
        fontSize: 15,
        borderRadius: '50%',
        padding: 4,
        margin: '0 auto',
        backgroundColor: theme.palette.primary.main,
        ...(ownerState.active && {
            width: 34,
            height: 34,
            fontSize: 16,
            padding: 6,
        }),
    },
    '& .mandatory': {
        padding: 2,
        'svg': { width: 20, },
        ...(ownerState.active && {
            padding: 4,
            'svg': { width: 24, },
        }),
    },
}));


export function PpaStepIcon(props) {
    // const { active, completed, className, error, icon } = props;
    const { active, className, error, icon } = props;

    const icons = {
        1: <PersonIcon />,
    };

    return (
        <PpaStepIconRoot ownerState={{ active, error }} className={className}>
            {/*{completed && (*/}
            {/*    <Check className="PpaStepIcon-completedIcon" />*/}
            {/*)}*/}

            {!error && (
                <>
                    {icon === 1 ? (
                        <div className="PpaStepIcon-circle mandatory">
                            {icons[String(props.icon)]}
                        </div>
                    ) : (
                        <div className="PpaStepIcon-circle">
                            {icon}
                        </div>
                    )}
                </>
            )}
            {error && (
                active ? <ErrorIcon className="PpaStepIcon-errorIcon" color="error" /> : <ErrorOutlineIcon className="PpaStepIcon-errorIcon" color="error" />
            )}
        </PpaStepIconRoot>
    );
}