import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ActivitiesService from "../../../services/ActivitiesService";


export const useFetchActivities = () => {
    const token = useSelector(state => state.login.user?.token) ?? null;
    const [data, setData] = useState([]);
    const infrastructure = useSelector(state => state.currentInfrastructure) ?? null;

    const fetchData = async () => {
        try {
            const activitiesService = new ActivitiesService();
            const res =  await activitiesService.getListActivities(infrastructure, token);
            setData(res);
        } catch (error) {
            console.error("Error fetching network data:", error);
        }
    };

    useEffect(() => {
        if (infrastructure) {
            fetchData();
        }
    }, [infrastructure]);

    return { data };
};