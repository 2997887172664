import React from 'react';
import {motion} from "framer-motion";

const ErrorMessageRF = ({ textSize, content }) => {
    return (
        <motion.span
            className={`${textSize ? textSize : "text-xs"} text-red-400 font-light`}

            initial={{opacity: 0, scale: .945}}
            whileInView={{opacity: 1, scale: 1}}
            transition={{type: "spring", duration: .3, ease: "linear"}}
        >
            {content}
        </motion.span>
    );
};

export default ErrorMessageRF;
