import { Controller, useForm } from 'react-hook-form'
import { createSelector } from 'reselect'
import { useDispatch, useSelector } from 'react-redux'
import { useContext, useEffect, useState } from 'react'
import { DrawerTemporaryContext } from '../../../../components/drawer/DrawerTemporary'
import { Loader } from '../../../../components/loader/Loader'
import { FormLoader } from '../../../servicetype/loaders/Loaders'
import ConfirmDialog from '../../../../components/confirm/ConfirmDialog'
import { MyEditor } from '../../../../components/editor/MyEditor'
import Select from 'react-select'
import { DateTimePicker, renderTimeViewClock } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { useFetchResourceFromResourceTypeId } from '../../hooks/useFetchResourceFromResourceTypeId'
import { displayMessage } from '../../../../redux/alertAction'
import EventService from '../../../../services/EventService'
import { useFetchEventById } from '../../hooks/useFetchEventById'

const selectUser = createSelector(
    (state) => state.login.user,
    (user) => user
)
export function EventForm({ resourceType, resourceEventTypes, from, to, resource }) {
    dayjs.locale('fr')
    const user = useSelector(selectUser)

    const context = useContext(DrawerTemporaryContext)
    const method = context.method ?? 'CREATE'
    const isCreate = method === 'CREATE'
    const isUpdate = method === 'UPDATE'
    const [submitting, setSubmitting] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [description, setDescription] = useState('')

    const dispatch = useDispatch()
    const { listResources } = useFetchResourceFromResourceTypeId(resourceType.id, user.token, setIsLoading)
    const { event, eventIsLoading } = useFetchEventById(context.idSelected, user.token)

    const {
        control,
        register,
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { errors },
        getValues,
    } = useForm({
        defaultValues: {
            eventType: null,
            title: '',
            description: '',
            from: null,
            to: null,
            resources: [],
        },
    })

    useEffect(() => {
        if (!eventIsLoading) {
            if (event) {
                setValue('title', event.title)
                setValue('description', event.content)
                setValue('eventType', event.resourceEventType)
                setValue('from', dayjs(event.startingDate))
                setValue('to', dayjs(event.endingDate))
                setValue('resources', { label: event.resource.name, value: event.resource.id })
            } else {
                reset()
                setValue('from', dayjs(from?.toJSON()))
                setValue('to', dayjs(to?.toJSON()))
                setValue('resources', [{ label: resource.name, value: resource.id }])
            }
        }
    }, [isLoading, from, to, event])

    const onSubmit = async (data) => {
        try {
            setSubmitting(true)
            if (isCreate || isUpdate) {
                const eventService = new EventService()
                if (isCreate) {
                    for (const resourceEvent of data.resources) {
                        const requestData = {
                            title: data.title,
                            content: description,
                            resource: 'api/resources/' + resourceEvent.value,
                            startingDate: data.from,
                            endingDate: data.to,
                            resourceEventType: data.eventType['@id'],
                        }
                        await eventService.postEvent(requestData, user.token).then()
                    }
                } else {
                    const requestData = {
                        title: data.title,
                        content: description,
                        resource: 'api/resources/' + data.resources.value,
                        startingDate: data.from,
                        endingDate: data.to,
                        resourceEventType: data.eventType['@id'],
                    }
                    await eventService.putEvent(context.idSelected, requestData, user.token).then()
                }

                reset()

                dispatch(displayMessage('Success', isCreate ? 'Element created' : 'Element updated', 'success'))

                context.onDrawerClose()
            }
            setSubmitting(false)
        } catch (error) {
            setSubmitting(false)
            dispatch(displayMessage(error.data.title, error.data.detail, 'error'))
        }
    }
    const handleEditorChange = (data) => {
        setDescription(data)
    }
    const handleDelete = async () => {
        try {
            const eventService = new EventService()
            eventService.deleteEvent(context.idSelected, user.token)
            reset()

            dispatch(displayMessage('Success', 'Element deleted', 'success'))

            context.onDrawerClose()
        } catch (error) {
            setSubmitting(false)
            dispatch(displayMessage(error.data.title, error.data.detail, 'error'))
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8">
            {submitting ? (
                <Loader content="Submitting" />
            ) : (
                <>
                    {/* 01 */}
                    {isLoading ? (
                        <FormLoader />
                    ) : (
                        <>
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col gap-4">
                                    <div>
                                        <h3 className="text-xl font-bold mb-2 tracking-wide required">Title</h3>
                                    </div>
                                    <div className="w-full">
                                        <input
                                            type="text"
                                            placeholder="Event title"
                                            {...register('title', {
                                                required: 'Event title is required',
                                            })}
                                            className="border border-slate-300 border-solid rounded-sm w-full grow h-12 p-4 focus:outline-none focus:ring focus:ring-blue-200"
                                        />
                                        {errors.name && !watch('title') && (
                                            <span className={'text-sm text-red-500/70'} role="alert">
                                                {errors.title.message}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="w-full">
                                <div>
                                    <h3 className="text-xl font-bold mb-2 tracking-wide required">Event type</h3>
                                </div>
                                <Controller
                                    name="eventType"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={resourceEventTypes}
                                            isClearable
                                            required={true}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <h3 className="text-xl font-bold mb-2 tracking-wide required">Resources</h3>
                            </div>
                            <div className="w-full">
                                <Controller
                                    name="resources"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...register('resources', {
                                                required: 'Resources is required',
                                            })}
                                            {...field}
                                            onChange={(t) => {
                                                setValue('resources', t)
                                            }}
                                            isMulti={!isUpdate}
                                            required={true}
                                            options={listResources['hydra:member'].map((resource) => {
                                                const options = [
                                                    {
                                                        label: resource.name,
                                                        value: resource.id,
                                                    },
                                                ]
                                                return {
                                                    options: options.concat(
                                                        resource.children.map((res) => {
                                                            return {
                                                                label: res.name,
                                                                value: res.id,
                                                            }
                                                        })
                                                    ),
                                                }
                                            })}
                                            isSearchable
                                        />
                                    )}
                                />
                                {errors.resources && !watch('resources') && (
                                    <span className={'text-sm text-red-500/70'} role="alert">
                                        {errors.resources.message}
                                    </span>
                                )}
                            </div>
                            <div className="w-full">
                                <div>
                                    <h3 className="text-xl font-bold mb-2 tracking-wide">Description</h3>
                                </div>
                                <Controller
                                    control={control}
                                    name="description"
                                    render={({ field }) => (
                                        <MyEditor
                                            initialvalue={watch('description')}
                                            setEditorData={handleEditorChange}
                                        />
                                    )}
                                />
                            </div>
                            <div className="w-full">
                                <div>
                                    <h3 className="text-xl font-bold mb-2 tracking-wide required">From</h3>
                                </div>
                                <Controller
                                    control={control}
                                    name="from"
                                    render={({ field }) => {
                                        return (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateTimePicker
                                                    label="From"
                                                    name="from"
                                                    ampm={false}
                                                    value={field.value}
                                                    inputRef={field.ref}
                                                    viewRenderers={{
                                                        hours: renderTimeViewClock,
                                                        minutes: renderTimeViewClock,
                                                    }}
                                                    onChange={(date) => {
                                                        field.onChange(date)
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        )
                                    }}
                                />
                            </div>
                            <div className="w-full">
                                <div>
                                    <h3 className="text-xl font-bold mb-2 tracking-wide required">To</h3>
                                </div>
                                <Controller
                                    control={control}
                                    name="to"
                                    render={({ field }) => {
                                        return (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateTimePicker
                                                    label="To"
                                                    name="to"
                                                    ampm={false}
                                                    viewRenderers={{
                                                        hours: renderTimeViewClock,
                                                        minutes: renderTimeViewClock,
                                                    }}
                                                    value={field.value}
                                                    inputRef={field.ref}
                                                    onChange={(date) => {
                                                        field.onChange(date)
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        )
                                    }}
                                />
                            </div>
                            {resource.canManage && (
                                <>
                                    <input
                                        type="submit"
                                        value={isCreate ? 'Create' : 'Update'}
                                        className="py-3 px-4 bg-blue-500 text-white text-sm hover: rounded-full max-w-1/6 cursor-pointer"
                                    />
                                    {isUpdate ? (
                                        <>
                                            <input
                                                type="button"
                                                value="Delete"
                                                onClick={() => {
                                                    setOpenConfirmation(true)
                                                }}
                                                className="py-3 ml-2 px-4 bg-red-500 text-white text-sm hover: rounded-full max-w-1/6 cursor-pointer"
                                            />
                                            <ConfirmDialog
                                                isOpen={openConfirmation}
                                                onClose={() => setOpenConfirmation(false)}
                                                setIsOpen={setOpenConfirmation}
                                                onAgree={() => handleDelete()}
                                            />
                                        </>
                                    ) : null}
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </form>
    )
}
