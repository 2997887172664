import React from "react";

// Design
import {Typography} from "@mui/material";


export const FormsTitle = ({ title }) => {
    return (
        <Typography component={"h2"}
                    variant="h2-poppins"
                    sx={{   marginBottom: {xs: '2.4rem', lg: '3rem'},
                            width: "100%",
                    }}
        >
            { title }
        </Typography>
    )
}
