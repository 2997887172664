import {Card} from "./Card";
import {useEffect, useState} from "react";
import {CustomPagination} from "../../../components/pagination/CustomPagination";
import {useFetchAllNews} from "../hooks/useFetchAllNews";
import {Box, Skeleton, Typography} from "@mui/material";
import {FiArchive} from "react-icons/fi";
import {Loader} from "../../../components/loader/Loader";
import {CheckWindowSize} from "../../../utils/CheckWindowSize";
// import {changeLoadPage} from "../../../utils/changeLoadPage";

export const List = ({ limit, hasPagination, setHideElement }) => {
    const [pageLoad, setPageLoad] = useState(false);
    const [page, setPage] = useState(1);

    const itemsPerPage = limit;
    const { data, totalItems, isLoading, error } = useFetchAllNews(page, itemsPerPage);

    useEffect(() => {
        if(!data) {
            setHideElement( hide => hide = true )
        }
        changeLoadPage()
    }, [pageLoad])

    const [currentPage, setCurrentPage] = useState(1);

    // Calculez l'indice de début et de fin de la page actuelle
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

    const changeLoadPage = () => {
        if (!pageLoad) return;

        setTimeout(() => {
            setPageLoad(false)
        }, 2000);
    }

    const { mediaQuery } = CheckWindowSize();

    return (
        <>
            { !data ?
                <div className="flex items-center justify-center min-h-[300px]">
                    <Typography component="p" variant="body" className="flex flex-col items-center justify-content">
                        <span className="text-blue-500"><FiArchive /></span>
                        Data not found!
                    </Typography>
                </div>
                :
                (
                    <ul className="list
                       grid
                       grid-cols-1
                       md:grid-cols-2
                       lg:grid-cols-4
                       gap-4
                       lg:gap-8"
                    >
                        {(isLoading ? Array.from(new Array(limit)) : currentItems)?.map((item, index) => (
                            <li className={"card flex flex-col mb-5 " + (mediaQuery ? "mobile" : "")}
                                key={index + 1}
                            >
                                {   item && !pageLoad ? (
                                    <Card data={item} />

                                ) : (
                                    <>
                                        <Skeleton variant="rectangular"
                                                  sx={{aspectRatio: '1/1', marginBottom: '20px'}}
                                                  width="100%"
                                                  height="auto"
                                        />
                                        <Skeleton width="100%"/>
                                        <Skeleton width="60%"/>
                                    </>
                                )}
                            </li>
                        ))}
                    </ul>
                )
            }

            { hasPagination && <CustomPagination setPage={setPage} totalItems={totalItems} x={10} setPageLoad={setPageLoad}/> }
        </>
    )
}