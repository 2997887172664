import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import UserService from "../services/UserService";
import {displayMessage} from "../redux/alertAction";
import {useNavigate} from "react-router-dom";


export const useFetchUser = () => {

    const [ userInfos, setUserInfos] = useState([]);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ error, setError ] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const authUser = useSelector(state => state.login.user);

    useEffect(() => {
        const fetchUserInfos = async () => {
            if (authUser) {

                try {
                    const userService = new UserService();
                    const { id, token } = authUser;

                    const resp = await userService.getUser(id, token);

                    setUserInfos({
                        firstname: resp.firstname,
                        lastname: resp.lastname,
                        email: resp.email,
                        phone: resp.phone,
                        status: resp.status,
                        title: resp.title,
                        workplace: resp.workplace,
                        orcid: resp.orcid,
                        isSuperAdministrator: resp.isSuperAdministrator,
                        registerSince: resp.registerSince,
                        lastConnection: resp.lastConnection,
                    });

                    setIsLoading(false);
                } catch (err){
                    dispatch(displayMessage("Session expired", "Your session has expired, you must connect again", "error"));
                    console.log(err);
                    setError(err)
                    setIsLoading(false);
                    navigate("/logout?expired=1");
                }

            } else {
                // console.log("An error occurred");
                setIsLoading(false);
            }
        }

        fetchUserInfos()
    }, []);

    return { userInfos, isLoading, error }
}