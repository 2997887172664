const INITIAL_STATE = {
    alert: null
}

// Si state == null, dans ce cas il prend INITIAL_STATE sinon le state fournit
export function displayMessage(state = INITIAL_STATE, action) {
    switch (action.type) {
        case "DISPLAY_MESSAGE":
            return {
                alert: {
                    title: action.payload.title,
                    message: action.payload.message,
                    type: action.payload.type,
                    isNew: true
                },
            }
        case "REMOVE_MESSAGE":
            return {
                alert: {
                    title: null,
                    message: null,
                    type: null,
                    isNew: false
                },
            }
        default:
            return state;
    }
}