import {BoxStepper} from "../../../components/stepper/BoxStepper";
import { motion} from "framer-motion";
import MyDrawer from "../../../components/drawer/MyDrawer";
import {useState} from "react";
import {FiArrowLeft} from "react-icons/fi";
import {Link} from "react-router-dom";
import {Main} from "../../ppa/utils/DrawerAssets";
import {useMobile} from "../../../hooks/useMobile";

export const CatalogStepper = () => {
    const [isOpen, setIsOPen] = useState(null);
    const [closeDrawer, setCloseDrawer] = useState(false);
    const [isShow, setIsShow] = useState(false);

    const isMobile = useMobile();
    // Récupérer les données du localStorage
    const storedData = localStorage.getItem("selectedData");
    const parsedData = storedData ? JSON.parse(storedData) : null;

    // Extraire networkId et catalogType des données
    const initialNetworkId = parsedData ? parsedData.networkId : null;
    const initialCatalogType = parsedData ? parsedData.catalogType : null;

    // Initialiser les états avec les données extraites
    const [networkId, setNetworkId] = useState(initialNetworkId);
    const [catalogType, setCatalogType] = useState(initialCatalogType);

    return (

        <motion.div
            className="flex"
            initial={{opacity: 0}}
            transition={{type: "spring", duration: .5, ease: "easeIn"}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        >
            <Main open={isOpen}>
                <motion.article
                    className={`min-h-[500px] lg:pt-40 pt-32 flex items-center justify-center relative`}>
                    <div className="container">
                        <div className={`${(isOpen && !isMobile) ? 'w-9/12' : 'wrapper'} flex flex-col`}>
                            <Link to="/" className="flex items-center justify-center gap-1 absolute top-10 lg:top-20 text-lg text-slate-500 z-10 hover:text-slate-800"
                                onClick={() => {
                                    localStorage.removeItem("selectedData");
                                }}
                            >
                                <span><FiArrowLeft/></span>
                                Home
                            </Link>

                            <BoxStepper
                                isOpen={isOpen}
                                setIsOpen={setIsOPen}
                                networkId={networkId}
                                catalogType={catalogType}
                                setNetworkId={setNetworkId}
                                setCatalogType={setCatalogType}
                                closeDrawer={closeDrawer}
                                setCloseDrawer={setCloseDrawer}
                                setIsShow={setIsShow}
                            />

                        </div>
                    </div>
                </motion.article>
            </Main>

            <MyDrawer
                isOpen={isOpen}
                networkId={networkId}
                setCloseDrawer={setCloseDrawer}
                closeDrawer={closeDrawer}

            />
        </motion.div>
    )
}