import {useContext} from "react";
import {LayoutWithInstallationContext} from "../components/layouts/LayoutWithInstallation";


export const useLayoutWithInstallationContext = () => {
    const layoutWithInstallationContext = useContext(LayoutWithInstallationContext);

    if(!layoutWithInstallationContext) {
        throw new Error("Context Error")
    }

    return layoutWithInstallationContext
}