import {CompConfig} from "../../../components/CompConfig";
import * as React from "react";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import {useEffect, useState} from "react";
import {useFetchServiceType} from "../hooks/useFetchServiceType";
import {ServiceType} from "./ServiceType";
import {FormServiceType} from "./form/FormServiceType";
import {FiGitPullRequest} from "react-icons/fi";


export const ListServiceType = ({isEdit}) => {

    const selectUser = createSelector(
        state => state.login.user,
        user => user
    );
    const selectCurrentInfrastructure = createSelector(
        state => state.currentInfrastructure,
        ci => ci
    );
    const [data, setData] = useState([]);
    const [totalItem, setTotalItem] = useState(0);
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [incrementList, setIncrementList] = useState(0);
    const [incrementEdit, setIncrementEdit] = useState(0);

    const user = useSelector(selectUser);
    const currentInfrastructure = useSelector(selectCurrentInfrastructure);

    const {  serviceType, isLoading, error } = useFetchServiceType(currentInfrastructure, user.token, incrementList);

    useEffect(() => {
        if(!isLoading) {
            setData(serviceType["hydra:member"]);
            setTotalItem(serviceType["hydra:member"]?.length ?? 0);
        }
    }, [isLoading, serviceType]);

    const onDrawerCloseForm = () => {
        setIncrementList(incrementList + 1);
    }

    const onDrawerOpenForm = () => {
        setIncrementEdit(incrementEdit + 1);
    }

    return (
        <CompConfig
            title={"Service type config"}
            titleDrawer="Create a new service type"
            titleDrawerUpdate="Update a service type"
            logoTitle={<FiGitPullRequest />}
            data={data}
            isLoading={isLoading}
            error={error}
            totalItem={totalItem}
            btntitle="Add service type"
            content={<ServiceType />}
            typeForm="service-type"
            setIsOpenDrawer={setIsOpenDrawer}
            onDrawerClose={onDrawerCloseForm}
            onDrawerOpen={onDrawerOpenForm}
            isEdit={isEdit}
            drawerFormCreate={<FormServiceType increment={incrementEdit}/>}
        />
    );
}