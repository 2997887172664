import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { useFetchResourceActivities } from '../../hooks/useFetchResourceActivities'
import { DataGrid } from '@mui/x-data-grid'
import { FormLoader } from '../../../servicetype/loaders/Loaders'

export const Activity = ({ resource }) => {
    const selectUser = createSelector(
        (state) => state.login.user,
        (user) => user
    )
    const user = useSelector(selectUser)
    const { activities, isLoading: isActivitiesLoading } = useFetchResourceActivities(resource.id, user.token)

    return (
        <div className="">
            {isActivitiesLoading ? (
                <FormLoader />
            ) : (
                <DataGrid
                    rows={activities['hydra:member']}
                    columns={[
                        { field: 'actionType', headerName: 'Action Type', flex: 0.2 },
                        { field: 'category', headerName: 'Category', flex: 0.2 },
                        { field: 'message', headerName: 'Message', flex: 1 },
                        { field: 'createdAt', headerName: 'date', flex: 0.3 },
                        {
                            field: 'userInfra',
                            headerName: 'User',
                            valueGetter: (userInfra) => userInfra.value.fullName,
                            flex: 0.3,
                        },
                    ]}
                    getRowId={(row) => row['@id']}
                    // initialState={{ pagination: { paginationModel } }}
                    // pageSizeOptions={[5, 10]}
                    // checkboxSelection
                    sx={{ border: 0 }}
                />
            )}
        </div>
    )
}
