import React from "react";
import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";

export const FormLabelTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'white',
        color: 'rgba(0, 0, 0, 1)',
        fontSize: theme.typography.pxToRem(12),
        padding: '16px',
        borderRadius: '5px',
        boxShadow: '0 2px 6px 3px rgb(0 0 0 / 0.06)',
        'a': {
            textDecoration: 'underline',
            '&:hover': { textDecoration: 'none' }
        },
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: 'white',
    },
}));