import * as React from "react";

// Design
import {Skeleton} from "@mui/material";


export const FormLoader = () => {
    return (
        <div className="w-full">
            <Skeleton width="50%" height="50px"/>
            <Skeleton width="100%" height="60px"/>
            <Skeleton width="50%" height="50px"/>
            <Skeleton width="100%" height="50px"/>
            <div className={"flex gap-2"}>
                <Skeleton width="15%" height="70px"/>
                <Skeleton width="15%" height="70px"/>
            </div>
        </div>
    )
}