import AxiosService from "./AxiosService";


const urlAllCharters = "api/documents"
const urlchartesrifra = "api/documents/unsigned/infrastructure"
class ChartersService {


    /*
    getAllCharters(page) {
        return axios.get(urlAllCharters, {
            params: {
                page: page,
                itemsPerPage: 3
            }
        }).then(resp => {
            return Promise.resolve(resp.data);
        });
    }

    */


    getAllCharters() {
        return AxiosService.getWithoutToken(urlAllCharters , "jsonld");
    }

    getChartersById(id) {
        return AxiosService.getWithoutToken(urlAllCharters + "/" + id, "jsonld");
    }

    getChartersInfraById(token, id) {
        return AxiosService.getWithToken(urlchartesrifra + "/" + id, token, "jsonld")
    }
    postCharters(jsonFormData) {
        return AxiosService.postWithToken(urlAllCharters, jsonFormData, "jsonld");

    }

    postChartersWithToken(data, token) {
        data["token"] = token;
        return AxiosService.postWithToken(urlAllCharters, data,  "jsonld");

    }

    static putChartersByIdWithToken(id, jsonFormData, token) {
        return AxiosService.putWithToken(urlAllCharters + "/" + id, jsonFormData, token, "jsonld");

    }

    static deleteChartersByIdWithToken(id, token) {

        return AxiosService.deleteWithToken(urlAllCharters + "/" + id, token, "jsonld");
    }
}

export default ChartersService;