import AxiosService from "./AxiosService";

const urlDefaultNetwork = "api/networks"
const urlAllNetworksCatalog = "api/networks/list/catalog"
const urlListNetworkConfig = 'api/networks/list/config'
const urlInfraDescription = "api/networks/config"

class NetworkService {
    getAllNetwork() {
        return AxiosService.getWithoutToken(urlAllNetworksCatalog, "jsonld");
    }

    getListNetworkConfig(token){
        return AxiosService.getWithToken(urlListNetworkConfig,token, 'jsonld');
    }

    getNetworkConfigById(id, token){
        return AxiosService.getWithToken(urlInfraDescription + "/" + id, token, 'jsonld');
    }
    putNetworkConfigById(id, data, token){
        return AxiosService.putWithToken(urlDefaultNetwork + "/" + id, data, token, 'jsonld');
    }


    getNetworkById(id) {
        return AxiosService.getWithoutToken(urlDefaultNetwork + "/" + id, "jsonld");
    }

    postNetwork(jsonFormData) {
        return AxiosService.postWithToken(urlDefaultNetwork, jsonFormData, "jsonld");

    }

    postNetworkWithToken(data, token) {
        data["token"] = token;
        return AxiosService.postWithToken(urlDefaultNetwork, data, "jsonld");

    }

    putNetworkByIdWithToken(id, data, token) {

        return AxiosService.putWithToken(urlDefaultNetwork + "/" + id, data, token, "jsonld");

    }

    deleteNetworkByIdWithToken(id, token) {
        return AxiosService.deleteWithToken(urlDefaultNetwork + "/" + id, token, "jsonld");
    }

    // Network infrastructure Description
    getNetworkInfraDescription(id, token) {
        return AxiosService.getWithToken(urlInfraDescription + "/" + id , token,  "jsonld");
    }

}

export default NetworkService;