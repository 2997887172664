import {AnimatePresence, motion} from "framer-motion";
import {Link} from "react-router-dom";
import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import React from "react";
import {getLimitedData} from "../../utils/getLimetedData";
import CustomError from "../error/CustomError";


export const ProjectCard = ({isOpen}) => {

    const selectUser = createSelector(
        state => state.login.user,
        user => user
    );
    const selectCurrentInfrastructure = createSelector(
        state => state.currentInfrastructure,
        ci => ci
    );

    const user = useSelector(selectUser);
    const currentInfrastructure = useSelector(selectCurrentInfrastructure);

    const projects =  []; //useFetchResourceType(currentInfrastructure, user?.token, isOpen);

    const list = projects["hydra:member"]?.sort((a, b) => a.name.localeCompare(b.name));
    const data = getLimitedData(list, 8);

    return (
        <AnimatePresence>
            { isOpen &&
                <motion.div
                    className="bg-white lg:w-72 w-96 drop-shadow-md top-[70px] absolute left-0 flex-col items-center rounded-3xl overflow-hidden z-10"
                    initial={{opacity: 0, y: 10}}
                    animate={{opacity: 1, y: 0}}
                    exit={{opacity: 0, y: 10}}
                    transition={{type: "spring", stiffness: 300, damping: 24}}
                >
                    <div
                        className="bg-slate-300 border border-slate-100 h-14 flex items-center justify-center text-sm px-10 w-full">
                        <div className="h-14 flex items-center justify-center text-md font-semibold px-10 mb-2">
                            <p className="text-slate-700 text-sm text-center">Projects</p>
                        </div>
                    </div>

                    <div className="w-full flex flex-col gap-0 divide-y divide-slate-200">
                        {data?.length > 0 ?
                            <ul className="flex flex-col justify-between h-full w-full text-left">
                                {data?.map(item =>
                                    <li key={item.id} className="w-full h-full text-center grow">
                                        <Link
                                            to={`/project/${item.id}`}
                                            className="w-full h-full flex gap-2 items-center justify-start p-4 text-slate-500 hover:bg-slate-50"
                                        >  {item.title && item.title}
                                        </Link>
                                    </li>
                                )}
                                <li key={'project-list'} className="w-full h-10 text-center grow bg-slate-100">
                                    <Link
                                        to={`/list/projects`}
                                        title='See all projects'
                                        className="w-full h-full flex items-center justify-center p-4 text-slate-500 hover:bg-slate-300"
                                    >See all projects
                                    </Link>
                                </li>
                            </ul>
                            :
                            <div className="min-h-52 flex items-center justify-center w-full">
                                <CustomError content="No projects found" />
                            </div>
                        }
                    </div>
                </motion.div>
            }
        </AnimatePresence>
    )
}