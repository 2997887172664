// catalogActions.js

export const setNetworkId = (networkId) => {
    return {
        type: 'SET_NETWORK_ID',
        payload: networkId,
    };
};

export const setCatalogType = (catalogType) => {
    return {
        type: 'SET_CATALOG_TYPE',
        payload: catalogType,
    };
};


export const FETCH_ALL_MAP = 'FETCH_ALL_MAP';

export const fetchSuccess = (data) => ({
    type: FETCH_ALL_MAP,
    payload: data,
});

export const FETCH_ALL_CATALOG = 'FETCH_ALL_CATALOG';

export const fetchCatalog = (data) => ({
    type: FETCH_ALL_CATALOG,
    payload: data
})