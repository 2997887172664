import { UserCardInfos} from "../cards/UserCardInfos";
import {ProfilBtn} from "../../features/auth/components/button/ProfilBtn";
import {useState} from "react";

export const Profil = ({ userListTeamInfra, isLoading, userRoleInInfra }) => {

    const [refCard, setRefCard] = useState(null) || null;
    const [openBox, setOpenBox] = useState(false);

    return (
        <div className="lg:relative lg:w-auto md:w-full md:left-0 md:top-0">
            <ProfilBtn
                openBox={openBox}
                setOpenBox={setOpenBox}
                userRoleInInfra={userRoleInInfra}
                setRefCard={setRefCard}
                clicked
            />
            <UserCardInfos
                isOpen={openBox}
                // ref={refCard}
                userListTeamInfra={userListTeamInfra}
                isLoading={isLoading}
                userRoleInInfra={userRoleInInfra}
                openBox={openBox}
                setOpenBox={setOpenBox}
            />
        </div>
    );
}