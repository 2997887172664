import {SuccessIcon} from "../icons/success/SuccessIcon";
import {Box, Typography} from "@mui/material";
import * as React from "react";

// Design
import { motion } from "framer-motion";


export const PpaSuccessContent = () => {

    return (
        <motion.div
            id="ppa_success"
            className="flex w-full lg:pt-6"
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{duration: .5}}
        >

            <div className="container">
                <div className="wrapper flex flex-col lg:max-w-[996px]">
                    <motion.div
                        initial={{y: -60, opacity: 0}}
                        animate={{y: 0, opacity: 1}}
                        transition={{duration: .4}}
                        className={"success-box"}
                    >
                        <Box component={"div"}
                             className={"success-text"}
                        >
                            <SuccessIcon/>
                        </Box>

                        <Box component={"div"}
                             className={"success-text"}
                        >
                            <Typography component={"h2"}
                                        variant="h2-poppins"
                                        className={"success-title"}
                            >
                                <span>Success!</span>
                            </Typography>
                            <Typography component={"h3"}
                                        variant="h3-poppins"
                            >
                                Thank you for submitting the form.
                            </Typography>
                            <Typography component={"p"}>
                                Your project has been received.
                            </Typography>
                        </Box>

                    </motion.div>
                </div>
            </div>


        </motion.div>
    )
}
