export const formatDateToCustom = (dateString) => {
    // Créez un objet Date à partir de la chaîne de date
    const date = new Date(dateString);

    // Tableau des noms de mois en texte complet
    const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    // Récupérez le mois en utilisant getMonth() et obtenez le nom du mois à partir du tableau
    const month = months[date.getMonth()];

    // Récupérez le jour en utilisant getDate()
    const day = date.getDate();

    // Récupérez l'année en utilisant getFullYear()
    const year = date.getFullYear();

    // Formatez la date dans le style "Sept 20, 2023" et retournez-la
    const formattedDate = `${month} ${day}, ${year}`;
    return formattedDate;
}

// // Utilisation de la fonction
// const date = "2023-09-20"; // Votre date d'origine au format ISO 8601
// const formattedDate = formatDateToCustom(date);
// console.log(formattedDate); // Affichera "Sept 20, 2023"
