import useScrollTrigger from '@mui/material/useScrollTrigger';
import Fade from '@mui/material/Fade';
import {FiChevronsUp} from "react-icons/fi";
import {motion} from "framer-motion";

function ScrollTop(props) {
    const { children } = props;

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector('#sectionCatalog, #ppa, #root');
        anchor && window.scrollTo({ behavior: "smooth", top: anchor.offsetTop });
    };

    return (
        <Fade in={trigger}>
            <motion.button
                onClick={handleClick}
                className="sticky bottom-10 right-10 rounded-full p-4 bg-transparent w-14 h-14 text-black border border-solid border-slate-300 flex items-center justify-center mr-10 scroll-top"
                whileHover={{ scale:1.145 }}
                whileTap={{ scale:.945 }}
                transition={{ type:"spring", stiffness:100, damping:50 }}
            >
                {children}
            </motion.button>
        </Fade>
    );
}

export default function BackToTop() {
    return (
        <ScrollTop>
            <span className="text-2xl">
                <FiChevronsUp />
            </span>
        </ScrollTop>
    );
}