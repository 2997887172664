import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import UserService from '../../../services/UserService'

const selectUser = createSelector(
    state => state.login.user,
    user => user,
)

export const useFetchUserInfra = (infraId) => {
    const [userInfra, setUserInfra] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    const user = useSelector(selectUser)
    const token = user?.token

    async function fetchUserInfra(infraId) {

        try {
            let res

            if (!infraId) {
                res = []
            } else {
                const userServices = new UserService()
                res = await userServices.getListUserInfraValidatedFromInfrastructure(infraId, token)
            }

            setUserInfra(res)
            setIsLoading(false)
        } catch (error) {
            setError(error)
            setIsLoading(false)
        }

    }

    useEffect(() => {
        setIsLoading(true)
        fetchUserInfra(infraId)

    }, [
        infraId,
        token,
    ])

    return { userInfra, isLoading, error, fetchUserInfra }
}

export const useFetchOneUserInfra = (userInfraId, token, increment, setIsLoading) => {
    const [userInfra, setUserInfra] = useState(null)
    const [error, setError] = useState(null)

    useEffect(() => {
        const fetchOneUserInfra = async () => {

            try {
                setIsLoading(true)
                const userServices = new UserService()

                let res

                if (undefined !== userInfraId && userInfraId > 0 && null !== userInfraId) {
                    res = await userServices.getUserInfra(userInfraId, token)
                } else {
                    res = null
                }

                setUserInfra(res)
            } catch (error) {
                setError(error)
            }
            setIsLoading(false)
        }

        fetchOneUserInfra().then()

    }, [
        userInfraId,
        token,
        increment,
    ])

    return { userInfra, error }
}

export const useFetchUserInfraFromMultipleInfraId = (listInfraId) => {
    const [listUserInfra, setListUserInfra] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    const user = useSelector(selectUser)
    const token = user?.token

    async function fetchUserInfra(listInfraId) {

        try {
            let res
            res = []
            setIsLoading(true)
            if (listInfraId && 0 < listInfraId.length) {
                const userServices = new UserService()
                res = await userServices.getListUserInfraValidatedFromInfrastructure(listInfraId.map(i => i.id).join(','), token)
            }

            setListUserInfra(res)
            setIsLoading(false)
        } catch (error) {
            setError(error)
            setIsLoading(false)
        }

    }

    useEffect(() => {
        setIsLoading(true)
        fetchUserInfra(listInfraId).then()

    }, [
        listInfraId,
        token,
    ])

    return { listUserInfra, isLoading, error, fetchUserInfra }
}


export const useFetchAllUsers = () => {
    const [listUsers, setListUsers] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(null)

    const user = useSelector(selectUser)
    const token = user?.token

    async function fetchAllUsers() {

        try {
            let res = []
            const userServices = new UserService()
            res = await userServices.getListUsers(token,'jsonld')
            setListUsers(res)
            setIsLoading(false)
        } catch (error) {
            setError(error)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchAllUsers().then()

    }, [
        token,
    ])

    return { listUsers, isLoading, error }
}