import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {useNavigate} from "react-router-dom";
export const BackButton = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        return navigate('/')
    }
    return (
        <IconButton
            color="dark"
            aria-label="return to home page"
            onClick={handleClick}
            sx={{ position:"absolute",
                  right:{xs:"14px", lg:"35px", xl:"70px"},
                  top:{xs:"14px", lg:"25px", xl:"50px"},
                  border:'1px solid #272727',
                  zIndex:"10",
                  width:{xs:"26px", lg:"36px", xl:"inherit"},
                  height:{xs:"26px", lg:"36px", xl:"inherit"},
            }}
        >
            <CloseIcon color="dark"
                       sx={{
                           width:{xs:"20px", xl:"26px"},
                           height:{xs:"20px", xl:"26px"},
                       }}
            />
        </IconButton>
    )
}