import {Outlet, useLocation} from "react-router-dom";
import { Footer } from "../partials/Footer";
import {Header} from "../partials/auth/Header";
import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";

function MainLayout() {
    const [auth, setAuth] = useState(false);
    const [isNetworkConfig, setIsNetworkConfig] = useState(true);

    const location = useLocation();
    const pathname = location.pathname;

    const regex = /^\/list\/networks\/.*$/;


    const selectUser = createSelector(
        state => state.login.user,
        user => user
    );

    const user = useSelector(selectUser);

    useEffect(() => {
        if(regex.test(pathname)) {
            setIsNetworkConfig(false);
        } else {
            setIsNetworkConfig(true);
        }
    }, [isNetworkConfig, pathname]);

    useEffect(() => {
        if (user) {
            setAuth(true)
        } else {
            setAuth(false)
        }
    }, [user, auth]);

    return (
        <>
            <Header auth={auth} isNetworkConfig={isNetworkConfig}/>
            <main>
                <Outlet/>
            </main>
            { isNetworkConfig && <Footer/> }
        </>
    );
}


export default MainLayout