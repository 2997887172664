import {styled} from "@mui/material/styles";

export const drawerWidth = 550;
export const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        // padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.up('lg')]: {
            marginRight: `-${drawerWidth}px`,
        },
        ...(open && {
            [theme.breakpoints.up('lg')]: {
                marginRight: 0,
            },
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
        position: 'relative',
    }),
);