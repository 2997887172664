import { useEffect, useState } from 'react'
import ResourceLinkService from '../services/ResourceLink'

export const useFetchLinksByResourceLinkTo = (id, token, increment) => {
    const [links, setLinks] = useState(null)
    const [error, setError] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        async function fetchLinksByResourceId() {
            try {
                if (null !== id) {
                    const linkService = new ResourceLinkService()
                    const res = await linkService.getFromResourceLinkToId(id, token)
                    setLinks(res)
                    setIsLoading(false)
                } else {
                    setLinks(null)
                    setIsLoading(false)
                }
            } catch (err) {
                setError(err)
            }
        }
        fetchLinksByResourceId().then()
    }, [id, increment])

    return { links, error, isLoading }
}

export const useFetchLinksByResourceOne = (id, token, increment) => {
    const [links, setLinks] = useState(null)
    const [error, setError] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        async function fetchLinksByResourceId() {
            try {
                if (null !== id) {
                    const linkService = new ResourceLinkService()
                    const res = await linkService.getFromResourceOneId(id, token)
                    setLinks(res)
                    setIsLoading(false)
                } else {
                    setLinks(null)
                    setIsLoading(false)
                }
            } catch (err) {
                setError(err)
            }
        }
        fetchLinksByResourceId().then()
    }, [id, increment])

    return { links, error, isLoading }
}
