import { FiPlus } from "react-icons/fi";

const CustomButtonNetwork = ({ handler, title, variant = "contained", isOpenBox}) => {

    const handleClick = () => {
        handler();
    };

    // Styles pour les variantes "contained" et "outlined"
    const getButtonStyles = () => {
        if (variant === "contained") {
            return isOpenBox
                ? "text-slate-50 bg-red-400 hover:bg-red-500"
                : "text-slate-50 bg-blue-400 hover:bg-blue-500";
        } else if (variant === "outlined") {
            return isOpenBox
                ? "border border-red-500 text-red-500 bg-transparent"
                : "border border-slate-500 text-slate-500 bg-transparent";
        }
        return "";
    };

    return (
        <button
            title={title}
            type="button"
            onClick={handleClick}
            className={`h-12 w-12 flex items-center justify-center rounded-full transition ease-in-out duration-75 hover:scale-110 group ${getButtonStyles()}`}
        >
            <span className={`text-2xl font-bold transition-transform ${isOpenBox ? "rotate-45" : "rotate-0"}`}>
                <FiPlus />
            </span>
        </button>
    );
};

export default CustomButtonNetwork;
