import React from "react";
import {AnimatePresence, motion} from "framer-motion";
import {Button} from "@mui/material";
import {GrRevert} from "react-icons/gr";
import {AiOutlineDelete} from "react-icons/ai";


export const LIstTypeFormsRemoveLine = ({   showRemoveConfirm,
                                            handleRemoveConfirm,
                                            handleRemoveInputs,
                                            listindexarray,
                                            listinputarray
                                        }) => {
    return (
        <AnimatePresence>
            {showRemoveConfirm && (

                <motion.div
                    initial={{x: "100%", opacity: 0}}
                    animate={{x: "-1%", opacity: 1}}
                    transition={{duration: .3}}
                    exit={{x: "100%", opacity: 0}}
                    className={`absolute right-0 bottom-[6px] w-fit flex items-center z-50 pl-4 pr-1 py-[2px] bg-white border rounded-md shadow-sm shadow-slate-300`}
                >
                    <div className={'flex w-full text-nowrap text-center font-bold mr-3'}>
                        Remove this line ?
                    </div>

                    <div className={'w-full flex'}>
                        <Button onClick={() => handleRemoveConfirm()}
                                sx={{padding: '3px 10px', minWidth: 'unset',}}
                                variant="contained"
                                color="warning"
                        >
                            <GrRevert className={'text-xl mr-2'}/>
                            <span>Cancel</span>
                        </Button>
                        <Button
                            onClick={() => handleRemoveInputs(listindexarray, listinputarray)}
                            sx={{padding: '3px 10px', minWidth: 'unset'}}
                            variant="contained"
                            color="error"
                        >
                            <AiOutlineDelete className={'text-xl mr-2'}/>
                            <span>Confirm</span>
                        </Button>
                    </div>

                </motion.div>

            )}
        </AnimatePresence>
    )
}
