import {useDispatch, useSelector} from "react-redux";
import {SelectService} from "../services/Select";
import {
    updateSelect
} from "../../../redux/selects/selectConfigByNetworkAction";
import {toast} from "sonner";

const useUpdateSelect = () => {

    const token = useSelector( state => state.login.user?.token);
    const dispatch = useDispatch();

    const handleUpdate = async (id, data) => {

        try {
            const selectService = new SelectService();
            const res = await selectService.putSelectConfigByNetworkId(id, data, token);

            if (!res) throw new Error("Unable to update");

            dispatch(updateSelect(res));

            return toast.success("successfully update!");

        } catch (error) {
            console.error(error);
        }
    }
    return { handleUpdate }
};

export default useUpdateSelect;
