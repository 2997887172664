export const truncateText = (text, maxWords) => {
    const words = text?.split(' ');

    if (words?.length <= maxWords) {
        // Si le texte contient moins de mots que la limite, retournez-le tel quel.
        return text;
    } else {
        // Sinon, tronquez le texte au nombre maximal de mots et ajoutez "..." à la fin.
        const truncatedText = words?.slice(0, maxWords).join(' ');
        return truncatedText + '...';
    }
}

export const truncateTextLetter = (text, maxLetters) => {
    if (text?.length <= maxLetters) {
        // Si la longueur du texte est inférieure ou égale à la limite, retournez-le tel quel.
        return text;
    } else {
        // Sinon, tronquez le texte au nombre maximal de lettres et ajoutez "..." à la fin.
        const truncatedText = text?.slice(0, maxLetters);
        return truncatedText + '...';
    }
}