import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import MarkerClusterGroup from 'react-leaflet-cluster'; //
import { Item } from "../../features/catalog/components/Item";
import marker from '../../assets/images/location.svg';
import { Icon } from 'leaflet';

const myIcon = new Icon({
    iconUrl: marker,
    iconSize: [32, 32]
});

const MapLeaflet = ({ data, catalogType, list }) => {

    const minZoomLevel = 3;
    const maxZoomLevel = 10;

    const coordinates = data?.filter(item => item?.location?.longitude && item?.location?.latitude);

    return (

        <MapContainer
            center={[46.6035, 1.8888]}
            zoom={2}
            maxZoom={maxZoomLevel}
            minZoom={minZoomLevel}
            trackResize={true}
            style={{ height: "100vh", width: '100%', zIndex:2 }}
            maxBounds={[
                [-90, -180], // Sud-ouest
                [90, 180], // Nord-est
            ]}
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <MarkerClusterGroup>
                { coordinates?.map((item, index) => (
                    <div key={index}>
                        {!isNaN(item?.location?.latitude) && !isNaN(item?.location?.longitude) ? (
                            <Marker position={[parseFloat(item?.location?.latitude), parseFloat(item?.location?.longitude)]} icon={myIcon}>
                                <Popup>
                                    <Item item={item} catalogType={catalogType} list={list}/>
                                </Popup>
                            </Marker>
                        ) : ""}
                    </div>
                ))}
            </MarkerClusterGroup>
        </MapContainer>
    );
};

export default MapLeaflet;
