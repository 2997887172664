import * as React from "react";
import {Link} from "react-router-dom";

// Design
import {TimelineConnector, TimelineContent, TimelineItem, TimelineOppositeContent, TimelineSeparator} from "@mui/lab";
import { motion } from "framer-motion";
import {Box, Typography} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditNoteIcon from "@mui/icons-material/EditNote";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ClearIcon from "@mui/icons-material/Clear";
import {FlashingDotsLoader} from "../../loaders/Loaders";
import {FaExternalLinkAlt} from "react-icons/fa";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

// Functions
import dayjs from "dayjs";

// .Env
const url = process.env.REACT_APP_API_URL;


export const CustomTimeLine = ({ datas, status, totatSavedStatus, pdfName }) => {

    const icons = {
        generic: <MoreHorizIcon />,
        draft: <EditNoteIcon />,
        incoming: status === 'incoming' ? <FlashingDotsLoader /> : <MoreHorizIcon />,
        validated: status === 'validated' ? <ThumbUpAltIcon className="bounce" /> : <MoreHorizIcon />,
        deleted: <ClearIcon className="bounce" />,
    };

    const animationDelays = {
        draft: .6,
        incoming: 1.2,
        pending: 1.8,
        validated: 1.8,
        deleted: 1.8,
    };

    const HexaIcon = ({ currentStatus }) => {
        return (
            <div id="clock">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                     x="0px" y="0px" width="83.333px" height="93.333px" viewBox="0 0 63.333 73.333"
                >
                    <polygon className={`clockline ${currentStatus}`}
                             points="4.701,21.098 4.701,52.235 31.667,67.804 58.632,52.235 58.632,21.098 31.667,5.529 "
                    />
                </svg>
                <div className={`icon ${currentStatus}`}>
                    {currentStatus && (
                        <>
                            {icons[currentStatus]}
                        </>
                    )}
                </div>
            </div>
        )
    }

    const TimeLineDates = ({ sentStatus, sentDate }) => {
        return (
            <div className="date-box">
                <strong>
                    {( sentStatus === 'draft') && (<>Last saving date:</>)}
                    {( sentStatus === 'incoming') && (<>Submission date:</>)}
                    {( sentStatus === 'validated') && (<>Validation date:</>)}
                    {( sentStatus === 'deleted') && (<>Deletion date:</>)}
                </strong>

                <div className={`${sentStatus} md:block`}>
                    {dayjs(sentDate).format('dddd, MMMM D, YYYY')}
                    <p>{dayjs(sentDate).format('h:mm:ss A')}</p>
                </div>

            </div>
        )
    }


    return (
        <>
        { datas?.map((sent, index) => (
            <React.Fragment key={index}>

                <TimelineItem className={`${sent.status}`}>
                    <TimelineOppositeContent sx={{
                        display: {xs: 'none', md: 'flex'},
                        paddingLeft: '0',
                        justifyContent: 'flex-end'
                    }}
                    >
                        {/* Dates : Desktop View  */}
                        <motion.div
                            initial={{x: -100, opacity: 0}}
                            animate={{x: -6, opacity: 1}}
                            transition={{duration: .4, delay: `${animationDelays[sent.status]}` - .3}}
                        >

                            <Box sx={{ position: 'relative',
                                top: '-35px'
                            }}
                            >

                                <TimeLineDates sentStatus={sent.status}
                                               sentDate={sent.createdAt}
                                />

                            </Box>
                        </motion.div>

                    </TimelineOppositeContent>
                    <TimelineSeparator>

                        <HexaIcon currentStatus={`${sent.status}`}/>
                        {(sent.status === 'draft') ? (
                            <TimelineConnector sx={{ backgroundColor: '#e6c229' }} className={"connector"} />
                        ) : (
                            <TimelineConnector sx={{
                                                   ... (status !== 'incoming') && {
                                                       backgroundColor: '#3380FF'
                                                   },
                                                   ...((status !== 'incoming') && (index === datas.length - 1)) && {
                                                       display: 'none'
                                                   }
                                               }}
                                               className={"connector"}
                            />
                        )}

                    </TimelineSeparator>
                    <TimelineContent sx={{ minHeight: {xs: 'unset', md: '180px'}, margin: '4px 0 20px', }}>

                        <motion.div
                            initial={{x: 100, opacity: 0}}
                            animate={{x: 0, opacity: 1}}
                            transition={{duration: .4, delay: `${animationDelays[sent.status]}`}}
                            className={"flex flex-col gap-2"}
                        >
                            <Typography component={"h3"}
                                        variant="h3-poppins"
                                        sx={{textTransform: 'capitalize', fontSize: {xs: '20px', lg: '32px'}}}
                            >
                                {sent.status}
                            </Typography>

                            <Typography component={"p"}>
                                {(sent.status === 'draft') ? (
                                    <span>{`${sent.message} ${totatSavedStatus} ${totatSavedStatus > 1 ? 'times' : 'time'} before project submission`}</span>
                                ) : (
                                    <span>{sent.message}</span>
                                )}
                            </Typography>

                            {/* Dates : Mobile View  */}
                            <Box sx={{display: {xs: 'flex', md: 'none'}}}>

                                <TimeLineDates sentStatus={sent.status}
                                               sentDate={sent.createdAt}
                                />

                            </Box>

                            <motion.div
                                initial={{y: -100, opacity: 0}}
                                animate={{y: 0, opacity: 1}}
                                transition={{duration: .4, delay: 2.4 }}
                                className={`link-box ${(sent.status === 'incoming' && pdfName) ? 'flex' : 'hidden'}`}
                            >
                                <Box component={"div"}>
                                    <Link to={url + pdfName}
                                          target="_blank"
                                          className={"flex items-center hover:underline"}
                                    >
                                        <PictureAsPdfIcon fontSize={'small'}/> <span className="mx-2">Check out the project summary</span>
                                        <FaExternalLinkAlt/>
                                    </Link>
                                </Box>
                            </motion.div>

                        </motion.div>

                    </TimelineContent>
                </TimelineItem>

                {/* Incoming/Pending for decision*/}
                {sent.status === 'incoming' && index === datas.length - 1 && (
                    <TimelineItem>
                        <TimelineOppositeContent sx={{ display: {xs: 'none', md: 'flex'}, paddingLeft: '0' }}>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <HexaIcon currentStatus={`generic`}/>
                            <TimelineConnector sx={{ backgroundColor: '#e6c229'}} className={"connector"} />
                        </TimelineSeparator>
                        <TimelineContent sx={{ display: 'flex', alignItems: 'center' }}>
                            <motion.div
                                initial={{x: 100, opacity: 0}}
                                animate={{x: 0, opacity: 1}}
                                transition={{duration: .4, delay: `${animationDelays['pending']}`}}
                            >
                                <Typography component={"h3"}
                                            variant="h3-poppins"
                                            sx={{textTransform: 'capitalize', fontSize: {xs: '20px', lg: '32px'}}}
                                >
                                    Pending for decision
                                </Typography>
                            </motion.div>
                        </TimelineContent>
                    </TimelineItem>
                )}

            </React.Fragment>
        ))}
        </>
    )
}
