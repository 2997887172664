import {AnimatePresence, motion} from "framer-motion";
import {Link, Navigate} from "react-router-dom";
import {useFetchResourceType} from "../../features/resourcetype/hooks/useFetchResourceType";
import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import React from "react";
import {getLimitedData} from "../../utils/getLimetedData";
import {FiArrowUpRight} from "react-icons/fi";


export const ResourceTypeCard = ({isOpen}) => {

    const selectUser = createSelector(
        state => state.login.user,
        user => user
    );
    const selectCurrentInfrastructure = createSelector(
        state => state.currentInfrastructure,
        ci => ci
    );

    const user = useSelector(selectUser);
    const currentInfrastructure = useSelector(selectCurrentInfrastructure);

    const {resourceType} = useFetchResourceType(currentInfrastructure, user?.token, isOpen);

    const list = resourceType["hydra:member"]?.sort((a, b) => a.name.localeCompare(b.name));
    const data = getLimitedData(list, 8);

    return (
        <AnimatePresence>
            { isOpen &&
                <motion.div
                    className="bg-white lg:w-72 w-96 drop-shadow-md top-[70px] absolute left-0 flex-col items-center rounded-3xl overflow-hidden z-10"
                    initial={{opacity: 0, y: 10}}
                    animate={{opacity: 1, y: 0}}
                    exit={{opacity: 0, y: 10}}
                    transition={{type: "spring", stiffness: 300, damping: 24}}
                >
                    <div className="bg-slate-300 border border-slate-100 h-14 flex items-center justify-center text-sm px-10 w-full">
                        <div className="h-14 flex items-center justify-center text-md font-semibold px-10 mb-2">
                            <p className="text-slate-700 text-sm text-center">Resources</p>
                        </div>
                    </div>
                    <ul className="flex flex-col gap-0 justify-between h-full w-full divide-slate-100 divide-y divide-solid">
                        {data?.slice(0, 5).map(item =>
                            <li key={item.id} className="w-full h-full text-center grow">
                                <Link
                                    to={`/resourcetypes/${item.id}`}
                                    title={item.name}
                                    className="w-full h-full flex items-center justify-center p-4 text-slate-500 hover:bg-slate-50"
                                >{item.name}
                                </Link>
                            </li>
                        )}
                        <li key={'resource-list'} className="w-full h-full text-center grow bg-slate-100">
                            <Link
                                to={`/list/resourcetypes`}
                                title='See all resources'
                                className="w-full h-full flex items-center justify-center p-4 text-slate-700 hover:bg-slate-300"
                            >See all resources
                            </Link>
                        </li>
                    < /ul>
                </motion.div>
            }
        </AnimatePresence>
    )
}