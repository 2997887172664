import {useState} from "react";
import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import {useFetchResourceTypeSearch} from "../../../resourcetype/hooks/useFetchResourceType";
import {motion} from "framer-motion";
import {FaSearch} from "react-icons/fa";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    InputAdornment,
    MenuItem, Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import Select from "@mui/material/Select";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {FiArchive, FiSearch} from "react-icons/fi";
import {useMobile} from "../../../../hooks/useMobile";

export const SearchModal = () => {
    const globalSearchType = 'global';
    const resourceSearchType = 'resource';
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [inputValue, setInputValue] = useState('');
    const selectUser = createSelector(
        state => state.login.user,
        user => user
    );
    const [searchType, setSearchType] = useState(globalSearchType);
    const user = useSelector(selectUser);
    const selectCurrentInfrastructure = createSelector(
        state => state.currentInfrastructure,
        ci => ci
    );
    const currentInfrastructure = useSelector(selectCurrentInfrastructure);
    const {resourceTypes} = useFetchResourceTypeSearch(currentInfrastructure, inputValue, user.token);
    const handleChangeSearchType = (event) => {
        setSearchType(event.target.value)
      };

    const isMobile = useMobile();

    return (
        <>
        {(currentInfrastructure !== null && currentInfrastructure !== 0) ?
            <div className="flex items-center gap-2 text-slate-500">
                { !isMobile && "Search" }
                <motion.button
                    className="border border-slate-400 bg-slate-transparent h-8 w-8 rounded-full"
                    onClick={handleOpen}
                    whileTap={{ scale: 0.97 }}
                    whileHover={{ scale: 1.04 }}
                >
                    <span className="w-full h-full flex justify-center items-center text-slate-400 text-md"><FiSearch /></span>
                </motion.button>
            </div>: null

        }
        <Dialog
            open={open}
            onClose={handleClose}
            scroll={"paper"}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <DialogTitle>
                <div className={"flex flex-row"}>
                    <TextField
                        className={"basis-2/3"}
                        placeholder="Type something to start searching"
                        name="searchTerm"
                        value={inputValue}
                        onChange={(event) => {
                            setInputValue(event.target.value);
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Select
                        className={"basis-1/3"}
                        name="searchTypeSelect"
                        labelId="searchTypeSelectLabel"
                        id="searchTypeSelect"
                        value={searchType}
                        label="Age"
                        onChange={handleChangeSearchType}
                    >
                        <MenuItem value={globalSearchType}>Everywhere</MenuItem>
                        <MenuItem value={resourceSearchType}>Resources</MenuItem>
                    </Select>
                </div>
            </DialogTitle>
            <DialogContent style={{
                maxHeight: '60vh',
                minHeight: '20vh',
                display:"flex",
                alignItems:"center",
                justifyContent:'center'
            }}>
                {resourceTypes.length > 0
                    ?
                    resourceTypes.map(resourceType => (
                        <Accordion key={'resourceType_'+resourceType.id}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                {resourceType.name} ({resourceType.resources.length})
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    {resourceType.resources.map(resource => (
                                        <ListItemButton
                                            key={"resource"+resource.id}
                                            className={"ml-8"}
                                            href={`/resourcetypes/${resourceType.id}?rid=${resource.id}`}
                                            target={"_blank"}
                                        >
                                            <OpenInNewIcon/> <ListItemText className={"pl-3"} primary={resource.name} />
                                        </ListItemButton>
                                    ))}
                                </List>
                            </AccordionDetails>
                        </Accordion>
                    ))
                    :
                    <p className={"flex flex-col gap-2 justify-center items-center text-slate-500 text-sm"}>
                        <span className="text-slate-400 text-xl"><FiArchive /></span>
                        Nothing to display, please search again
                    </p>
                }

            </DialogContent>
          </Dialog>
        </>
    );
}