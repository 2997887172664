import { useState } from 'react';
import {DrawerTemporary} from "../../../components/drawer/DrawerTemporary";
import FormSelect from "./form/FormSelect";
import {useSelector} from "react-redux";
import CustomTables from "../../network/components/table/CustomTables";
import useDeleteSelect from "../hooks/useDeleteSelect";

const TableSelects = ({ data, fullWidth, setIsSubmitting, id, }) => {

    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [idSelect, setIdSelect] = useState(null);

    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [backCover, setBackCover] = useState("#2d7ef5");

    const token = useSelector(state => state.login.user?.token);
    const { handleDelete } = useDeleteSelect();

    // Row tables
    // const row = [
    //     {
    //         id:1,
    //         label:"ID",
    //         growElement:false,
    //         centerElement:false,
    //     },
    //     {
    //         id:2,
    //         label:"Name",
    //         growElement:true,
    //         centerElement:false,
    //     },
    //     {
    //         id:3,
    //         label:"Options",
    //         growElement:false,
    //         centerElement:true,
    //     },
    //     {
    //         id:4,
    //         label:"Standard",
    //         growElement:false,
    //         centerElement:true,
    //     },
    //     {
    //         id:5,
    //         label:"Action",
    //         growElement:false,
    //         centerElement:true,
    //     }
    // ]

    // // Drawer function
    const handleOpenDrawer = (idSelect) => {

        if (idSelect) {
            setIdSelect(idSelect);
        }

        setIsOpenDrawer(true);
    };

    const onDrawerClose = () => {
        setIsOpenDrawer(false);
    };
    return (
        <>
            <div className="w-full flex flex-col gap-8 relative">
                <CustomTables
                    data={data}
                    setIsSubmitting={setIsSubmitting}
                    id={id}
                    fullWidth
                    handlerDelete={handleDelete}
                    handler={handleOpenDrawer}
                    tableFor="customSelect"
                    method={"POST"}
                />
            </div>

            <DrawerTemporary
                isOpenDrawer={isOpenDrawer}
                setIsOpenDrawer={setIsOpenDrawer}
                setSubmitting={setIsSubmitting}
                onDrawerClose={onDrawerClose}
                content={ <FormSelect id={idSelect} token={token} openConfirmation={openConfirmation} setOpenConfirmation={setOpenConfirmation} /> }
                backCover={backCover}
                titleDrawerUpdate="Update Select"
                method={"UPDATE"}
                idSelected={idSelect}
            />
        </>


    );
};

export default TableSelects;
