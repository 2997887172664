import getFullName from "../../../../utils/getFullName";
import {motion} from "framer-motion";
import {FiShield} from "react-icons/fi";
import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import {useCloseOnOutsideClick} from "../../../../hooks/useCloseOnOutsideClick";
import {useMobile} from "../../../../hooks/useMobile";
import {toast} from "sonner";
import {useEffect, useRef} from "react";

const selectUser = createSelector(
    state => state.login.user,
    user => user
);

export const ProfilBtn = ( { openBox, setOpenBox, setRefCard, clicked, userRoleInInfra }) => {

    const user = useSelector(selectUser);
    const isMobile = useMobile();

    const handleClick = () => {

        if (!clicked) return;
        setOpenBox(!openBox)
    }

    // const refCard = useRef(null);

    const handleSuperAdminMessage = () => {
        if (clicked) return;
        return toast.message("With great power as an administrator comes great responsibility.")
    }

    // Outside Element Click Detection
    // useCloseOnOutsideClick(refCard, setOpenBox)

    const handleOpenUserBoxInfos = () => {
        setOpenBox(!openBox)
    }
    return (
        <div className={`w-full flex items-center gap-4 ${!clicked && "md:border border-slate-200 md:p-2 h-24"} ${isMobile && "relative"}`}>
            <motion.button
                className={`border border-slate-200 rounded-full h-14 w-14 flex items-center justify-center font-bold bg-slate-200 uppercase`}
                onClick={handleClick}
                title="See profil"
                onBlur={ handleOpenUserBoxInfos }
            >
                {getFullName(user?.fullName)}
            </motion.button>

            { user?.isSuperAdministrator &&
                <button
                    title={"Super Administrator"}
                    className={`text-slate-100 bg-red-400 rounded-full flex items-center justify-center absolute ${ isMobile ? "right-4 h-8 w-8 text-lg" : !clicked ? "right-4 h-8 w-8 text-lg" : "-right-4 h-4 w-4 -top-2 text-xs" } `}
                    onClick={ handleSuperAdminMessage }
                ><FiShield/></button>
            }

            { isMobile || !clicked ?
                <p className="text-slate-700 text-md flex flex-col gap-0">
                    { user.fullName }
                    {userRoleInInfra && <span className="text-xs text-slate-500 underline" title="Role">{userRoleInInfra}</span> }
                </p>
                :
                ""
            }
        </div>

    );
};
