import { useState, useEffect } from 'react';
import NetworkService from "../../../services/NetworkService";
import PPAService from "../../../services/PPAService";
import InfrastructureService from "../../installation/service/InfrastructureService";

const networkService = new NetworkService();
const infrastructureService = new InfrastructureService();
const ppaService = new PPAService();

export const useFetchPpaAllNetworks = () => {
    const [networkData, setNetworkData] = useState([]);
    const [isLoadingNetworks, setIsLoadingNetworks] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            async function handleGetListNetwork(){

                try {
                    const response = await networkService.getAllNetwork();
                    const hydraMember = response["hydra:member"];
                    hydraMember.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

                    // Filter on listNetworkPPA presence
                    const filteredNetwork = hydraMember.filter((network) => (
                            // Detect if listNetworkPPA is an Array or an Object
                            Array.isArray(network.listNetworkPPA) ? (network.listNetworkPPA.length > 0) : (Object.keys(network.listNetworkPPA).length > 0)
                        )
                    );

                    setNetworkData(filteredNetwork);

                    setIsLoadingNetworks(false);
                } catch (error) {
                    console.error(error);
                }
            }
            handleGetListNetwork().then();
        }, 0)

        return () => {
            clearTimeout(timer);
        };
    }, []);

    return { networkData, isLoadingNetworks };
}


export const useFetchSingleNetwork = (networkId) => {

    const [isSingleNetworkLoading, setIsSingleNetworkLoading] = useState(false);
    const [drawerDatas, setDrawerDatas] = useState([]);
    const [logo, setLogo] = useState('');
    const [centralHubName, setCentralHubName] = useState('');
    const [centralHubPath, setCentralHubPath] = useState('');
    const [installationNumber, setInstallationNumber] = useState(0);
    const [listInfrastructures, setListInfrastructures] = useState([]);
    const url = process.env.REACT_APP_API_URL;

    const validateLogo = ( validLogo ) => {
        const imageUrlRegex = /\.(jpeg|jpg|gif|png|svg)$/i;
        const isValidImage = (imageUrlRegex.test(validLogo));

        if(isValidImage) {
            setLogo(validLogo)
        } else {
            setLogo('')
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {

            if(networkId) {
                async function handleGetSingleNetwork(){
                    try {
                        setIsSingleNetworkLoading(true);
                        const response = await networkService.getNetworkById(networkId);

                        // Fix Logo
                        validateLogo(url + response.logo);

                        // Central Hub
                        if(response.infrastructureCentralHub) {
                            setCentralHubName(response.infrastructureCentralHub.name)
                            setCentralHubPath(response.infrastructureCentralHub['@id'])
                        } else {
                            setCentralHubName('')
                            setCentralHubPath('')
                        }

                        // Number of installations
                        if(response.listInfrastructures) {
                            /* Infrastructure List */
                            setListInfrastructures(response.listInfrastructures)

                            /* Infrastructure Number */
                            const totalListInfrastructures = Object.keys(response.listInfrastructures).length;
                            setInstallationNumber(totalListInfrastructures);
                        } else {
                            setInstallationNumber(0)
                        }

                        setIsSingleNetworkLoading(false);

                    } catch (error) {
                        console.error(error);
                    }
                }
                handleGetSingleNetwork().then();
            }

        }, 0)

        return () => {
            clearTimeout(timer);
        };
    }, [networkId]);

    return { drawerDatas,
             setDrawerDatas,
             logo,
             centralHubName,
             centralHubPath,
             installationNumber,
             listInfrastructures,
             isSingleNetworkLoading
    };
}


export const useFetchPpaTypeById = (ppaType) => {

    // PPA Type (Network PPA) - Tabs Management
    const mandatoryFields = [{ id: 1, name: "Mandatory fields" }]
    const [listTabs, setListTabs] = useState([]);

    const [drawerDatas, setDrawerDatas] = useState([]);
    const [networkName, setNetworkName] = useState('');
    const [cleanedDescription, setCleanDescription] = useState('');
    const [isPpaTypeLoading, setIsPpaTypeLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {

            if(ppaType) {
                async function handleGetPpaTypeById(){
                    try {
                        setIsPpaTypeLoading(true);
                        const response = await ppaService.getPPANetworkById(ppaType);

                        if (null !== response) {

                            // Fetch & concat for stepper
                            const tabsList = Object.values(response.listTabs);
                            setListTabs([...mandatoryFields, ...tabsList]);

                            // Fetch for Drawer Guide
                            setDrawerDatas(response);

                            // Network name
                            setNetworkName(response.name)

                            // Fix Description
                            setCleanDescription(response.description)
                            setIsPpaTypeLoading(false);
                        }

                    } catch (error) {
                        console.error(error);
                    }
                }
                handleGetPpaTypeById().then();
            }

        }, 0)

        return () => {
            clearTimeout(timer);
        };
    }, [ppaType]);

    return { listTabs, drawerDatas, networkName, cleanedDescription, isPpaTypeLoading };
}

