

export async function encodeFilesToBase64(files) {
    try {
        const fileBase64Array = [];
        for (const file of files) {
            const base64 = await readFileAsBase64(file);
            fileBase64Array.push(base64);
        }
        return fileBase64Array;
    } catch (error) {
        throw new Error("An error occurred while encoding files to base64: " + error.message);
    }
}

function readFileAsBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            resolve(reader.result.split(',')[1]);
        };
        reader.onerror = (error) => {
            reject(error);
        };
        reader.readAsDataURL(file);
    });
}