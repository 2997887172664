import * as React from "react";
import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";

export const CustomTooltip = styled(({className, children, ...props}) => (
    <Tooltip children={children} {...props} arrow classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        maxHeight: 280,
        overflow: 'scroll'
    },
}));
