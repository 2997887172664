import AxiosService from "./AxiosService";

const urlRoles = "api/roles"

class RoleService {
    getRoles(token,format) {
        return AxiosService.getWithToken(urlRoles, token, format);
    }

    getRolesByInfrastructureId(infraId, token, format) {
        return AxiosService.getWithToken(urlRoles + "?pagination=false&exists[deletedAt]=false&infrastructure.id=" + infraId, token, format);
    }


    getRole(id, token, format) {
        return AxiosService.getWithToken(urlRoles + "/" + id, token, format)
    }

    // Edit a role by giving role id
    updateRole(id, data, token) {
        return AxiosService.putWithToken(urlRoles + "/" + id ,data, token)
    }

    // Create a role
    createRole(data, token) {
        data["token"] = token;
        return AxiosService.postWithToken(urlRoles, data);
    }

    deleteRole(roleId, token) {
        return AxiosService.deleteWithToken(urlRoles+'/'+roleId, token)
    }
}

export default RoleService;